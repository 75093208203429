import { RomaneioSolicitacao } from "@modules/romaneio/interfaces/RomaneioSolicitacao"

export const formRomaneioCreateValues = {
    veiculoPesquisa: '',

    idFilial: 0,
    idTipoOperacao: 0,
    obs: '',

    motoristaDoc: '',
    motoristaNome: '',
    motoristaContratante: '',

    veiculoPlaca: '',
    veiculoPlaca1: '',
    veiculoPlaca2: '',
    veiculoPlaca3: '',
    rastreadorCavalo: '',
    bloqueadorCavalo: '',

    valorMercadoria: '',
    valorFreteTotal: '',
    valorFrete: '',

    solicitacoes: [] as RomaneioSolicitacao[],

    destinatarioDoc: '',
    destinatarioNome: '',
    destinatarioCidade: '',
    destinatarioUf: '',

    particularidades: ''
}