import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { handleResponse, initRequest } from "@utils/response"
import { ResponsePattern } from "@utils/response/types"
import { formLoginValues } from "../pages/Login/components/FormLogin/config"
import { UserData } from "../interfaces/UserData"
import { setAuthLogin } from "../reducers/authReducer"
import { requestSendAuthLogin } from "../reducers/requestAuthReducer"

export default async function setLogin(dispatch: DispatchType, setRedirect: Function, values: typeof formLoginValues){
    initRequest(dispatch, requestSendAuthLogin)

    const options = {messages: {errorMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/usuario/login`
    const body = { ...values }
    const response = await request.post<ResponsePattern<UserData>>({endpoint, body})

    handleResponse("handleLogin", dispatch, response, requestSendAuthLogin, options)
    .then(data => {
        dispatch(setAuthLogin(data))
        setRedirect(true)
    })
}