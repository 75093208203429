import React, { useLayoutEffect, useState } from 'react'
import { Document, PDFViewer, Text } from '@react-pdf/renderer'
import PDF from '@components/Common/PDF'
import { Romaneio } from '@modules/romaneio/interfaces/Romaneio'
import storage from '@utils/storage'
import { formatCurrency, formatDate } from '@utils/format'
import PdfRomaneioProtocoloItem from './components/List/Item'
import PdfRomaneioProtocoloHeader from './components/List/Header'
import PdfRomaneioProtocoloFooter from './components/List/Footer'

const PdfRomaneioProtocolo: React.FC = () => {

    const [data, setData] = useState<Romaneio | null>(null)

    useLayoutEffect(() => {
        const localData = storage.getObject<Romaneio>('pdfRomaneio')

        if(!!localData){
            setData(localData)
        }
    }, [])

    return(

        <>
            {!!data && (
                <PDFViewer style = {{ width: '100vw', height: '100vh' }}>
                    <Document>
                        <PDF.Page>
                            <PDF.Header>
                                <PDF.Container style = {{width: '200px', display: 'flex', justifyContent: 'flex-end'}}>
                                    <PDF.Container style = {{marginBottom: 4}}>
                                        <Text style = {{marginBottom: 2, fontSize: 10, textAlign: 'right'}}>COMANDO LOG E TRANSPORTES LTDA</Text>
                                        <Text style = {{marginBottom: 2, fontSize: 10, textAlign: 'right'}}>ROMANEIO DE CARREGAMENTO</Text>
                                        <Text style = {{marginBottom: 2, fontSize: 10, textAlign: 'right'}}>{`ROMANEIO Nro. ${data.id}`}</Text>
                                    </PDF.Container>
                                    <PDF.Container style = {{flexDirection: 'row', justifyContent: 'flex-end'}}>
                                        <PDF.TextContainer
                                            title = "Emitido em"
                                            text = {formatDate(data.dtCadastro)}
                                            titleWidth = "60px"
                                        />
                                    </PDF.Container>
                                </PDF.Container>
                            </PDF.Header>

                            <PDF.Line />

                            <PDF.Section style = {{flexDirection: 'row', marginBottom: 12}}>
                                <PDF.Container style = {{width: '70%'}}>
                                    <PDF.TextContainer
                                        title = "Filial"
                                        text = {data.filial.nomeFantasia}
                                        titleWidth = "80px"
                                    />
                                    <PDF.TextContainer
                                        title = "Tipo de Operação"
                                        text = {`${data.tipo_operacao.descricao} (${data.tipo_operacao.id})`}
                                        titleWidth = "80px"
                                    />
                                    <PDF.TextContainer
                                        title = "Motorista"
                                        text = {data.motoristaNome}
                                        titleWidth = "80px"
                                    />
                                    <PDF.TextContainer
                                        title = "Frete Combinado"
                                        text = {formatCurrency(data.valorFrete)}
                                        titleWidth = "80px"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.TextContainer
                                        title = "Cavalo"
                                        text = {data.veiculoPlaca}
                                        titleWidth = "40px"
                                    />
                                    <PDF.TextContainer
                                        title = "Carreta 1"
                                        text = {data.veiculoPlaca1}
                                        titleWidth = "40px"
                                    />
                                    <PDF.TextContainer
                                        title = "Carreta 2"
                                        text = {data.veiculoPlaca2}
                                        titleWidth = "40px"
                                    />
                                    <PDF.TextContainer
                                        title = "Carreta 3"
                                        text = {data.veiculoPlaca3}
                                        titleWidth = "40px"
                                    />
                                </PDF.Container>
                            </PDF.Section>

                            <PDF.Section>
                                <PdfRomaneioProtocoloHeader />

                                {data.solicitacoes.map((item, index) => (
                                    <PdfRomaneioProtocoloItem
                                        key = {index}
                                        {...item}
                                    />
                                ))}

                                <PdfRomaneioProtocoloFooter {...data} />
                            </PDF.Section>

                            <PDF.Section style = {{marginTop: 12}}>
                                <PDF.TextContainer
                                    text = "Observações"
                                />
                            </PDF.Section>
                            <PDF.Section style = {{marginTop: 4}}>
                                <PDF.TextContainer
                                    text = {data.obs}
                                />
                            </PDF.Section>

                            <PDF.Section style = {{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 100}}>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.Signature
                                        label = "EXPEDIÇÃO"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.Signature
                                        label = "OPERAÇÃO"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.Signature
                                        label = "RASTREAMENTO"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%', marginTop: 32}}>
                                    <PDF.Signature
                                        label = "2ª CONFERÊNCIA"
                                    />
                                </PDF.Container>
                            </PDF.Section>
                        </PDF.Page>
                    </Document>
                </PDFViewer>
            )}
        </>

    )

}

export default PdfRomaneioProtocolo