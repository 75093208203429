import { DocumentoVisualizacao } from "@modules/documento/interfaces/DocumentoVisualizacao"
import { Documento, DocumentoFile } from "@modules/documento/interfaces/Documento"
import { Conferencia, DocumentacoesViagem } from "@modules/conferencia/interfaces/Conferencia"

function createItens(files: DocumentoFile[] | null, tipo: string){
    if(!files) return []

    return files.map(item => {
        return {
            url: item.url as string,
            tipo
        }
    })
}

export default function formatDocumentos(documento: Documento | DocumentacoesViagem): DocumentoVisualizacao[] {
    const newDocs: DocumentoVisualizacao[] = []
    
    newDocs.push(...createItens(documento.CTS, "Doc CTS"))
    newDocs.push(...createItens(documento.MDF, "Doc MDF"))
    newDocs.push(...createItens(documento.contrato, "Doc Contrato"))

    return newDocs
}