import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"

interface State {
    requestGetFiliais: ResponseDefault<any>
    requestGetTiposOperacao: ResponseDefault<any>
    requestGetTiposVeiculo: ResponseDefault<any>
}

const initialState: State = {
    requestGetFiliais: responseInitialValues,
    requestGetTiposOperacao: responseInitialValues,
    requestGetTiposVeiculo: responseInitialValues,
}

const requestCoreSlice = createSlice({
    name: 'requestCore',
    initialState,
    reducers: {
        setRequestGetFiliaisData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetFiliais.data = action.payload
            state.requestGetFiliais.loading = false
            state.requestGetFiliais.error = false
        },
        setRequestGetFiliaisLoading: (state: State) => {
            state.requestGetFiliais.loading = true
            state.requestGetFiliais.error = false
        },
        setRequestGetFiliaisError: (state: State) => {
            state.requestGetFiliais.loading = false
            state.requestGetFiliais.error = true
        },
        setRequestGetFiliaisMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetFiliais.message = action.payload
        },
        resetRequestGetFiliais: (state: State) => {
            state.requestGetFiliais = {...responseInitialValues}
        },

        setRequestTiposOperacaoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetTiposOperacao.data = action.payload
            state.requestGetTiposOperacao.loading = false
            state.requestGetTiposOperacao.error = false
        },
        setRequestTiposOperacaoLoading: (state: State) => {
            state.requestGetTiposOperacao.loading = true
            state.requestGetTiposOperacao.error = false
        },
        setRequestTiposOperacaoError: (state: State) => {
            state.requestGetTiposOperacao.loading = false
            state.requestGetTiposOperacao.error = true
        },
        setRequestTiposOperacaoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetTiposOperacao.message = action.payload
        },
        resetRequestTiposOperacao: (state: State) => {
            state.requestGetTiposOperacao = {...responseInitialValues}
        },

        setRequestTiposVeiculoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetTiposVeiculo.data = action.payload
            state.requestGetTiposVeiculo.loading = false
            state.requestGetTiposVeiculo.error = false
        },
        setRequestTiposVeiculoLoading: (state: State) => {
            state.requestGetTiposVeiculo.loading = true
            state.requestGetTiposVeiculo.error = false
        },
        setRequestTiposVeiculoError: (state: State) => {
            state.requestGetTiposVeiculo.loading = false
            state.requestGetTiposVeiculo.error = true
        },
        setRequestTiposVeiculoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetTiposVeiculo.message = action.payload
        },
        resetRequestTiposVeiculo: (state: State) => {
            state.requestGetTiposVeiculo = {...responseInitialValues}
        },
    }
})

const { actions } = requestCoreSlice

export const requestGetFiliaisActions = {
    data: actions.setRequestGetFiliaisData,
    loading: actions.setRequestGetFiliaisLoading,
    error: actions.setRequestGetFiliaisError,
    message: actions.setRequestGetFiliaisMessage,
    reset: actions.resetRequestGetFiliais,
}

export const requestGetTiposOperacaoActions = {
    data: actions.setRequestTiposOperacaoData,
    loading: actions.setRequestTiposOperacaoLoading,
    error: actions.setRequestTiposOperacaoError,
    message: actions.setRequestTiposOperacaoMessage,
    reset: actions.resetRequestTiposOperacao,
}

export const requestGetTiposVeiculoActions = {
    data: actions.setRequestTiposVeiculoData,
    loading: actions.setRequestTiposVeiculoLoading,
    error: actions.setRequestTiposVeiculoError,
    message: actions.setRequestTiposVeiculoMessage,
    reset: actions.resetRequestTiposVeiculo,
}

export default requestCoreSlice.reducer