import React from 'react'
import Form from '@components/Common/Form'

const TabSalarial: React.FC = () => {

    return(

        <>
            <Form.Section>
                <Form.Row columns = {4}>
                    <Form.Group
                        label = "Função"
                        inputID = "funcao"
                        inputName = "funcao"
                    />

                    <Form.Group
                        label = "Cargo"
                        inputID = "cargo"
                        inputName = "cargo"
                    />

                    <Form.Group
                        label = "Área"
                        inputID = "area"
                        inputName = "area"
                    />

                    <Form.Group
                        label = "Departamento"
                        inputID = "departamento"
                        inputName = "departamento"
                    />
                </Form.Row>

                <Form.Row columns = {4}>
                    <Form.Group
                        label = "Setor"
                        inputID = "setor"
                        inputName = "setor"
                    />

                    <Form.Group
                        label = "Seção"
                        inputID = "secao"
                        inputName = "secao"
                    />

                    <Form.Group
                        label = "Tomador frete"
                        inputID = "tomadorFrete"
                        inputName = "tomadorFrete"
                    />

                    <Form.Group
                        label = "Escala"
                        inputID = "escala"
                        inputName = "escala"
                    />
                </Form.Row>

                <Form.Row columns = {3} grid = "200px 200px 1fr">
                    <Form.Group
                        label = "Salário base"
                        inputID = "salario"
                        inputName = "salario"
                    />

                    <Form.Group
                        label = "Porc IRF"
                        inputID = "porcIRF"
                        inputName = "porcIRF"
                    />
                </Form.Row>
            </Form.Section>
        </>

    )

}

export default TabSalarial