import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import Loader, { LoaderContainer } from '@components/Feedback/Loader'
import { getCliente } from '@modules/cliente/controllers/clienteController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormEdit from './components/FormEdit'

const ClienteEditar: React.FC = () => {

    const dispatch = useAppDispatch()
    const { cliente } = useAppSelector(s => s.cliente)
    const { requestGetCliente } = useAppSelector(s => s.requestCliente)

    const { idCliente } = useParams()

    const CR = conditionaRender(requestGetCliente, cliente)

    useEffect(() => {
        if(!!idCliente){
            getCliente(dispatch, idCliente)
        }
    }, [dispatch, idCliente])

    return(

        <>
            <PageTitle title = "Editar Cliente" />
            {CR.LOADING && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            {CR.DATA && (
                <Section>
                    <FormEdit />
                </Section>
            )}
        </>

    )

}

export default ClienteEditar