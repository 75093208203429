import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Pernoite } from "../interfaces/Pernoite"

interface State {
    requestGetPernoites: ResponseDefault<Pernoite[]>
    requestGetPernoite: ResponseDefault<Pernoite>
}

const initialState: State = {
    requestGetPernoites: responseInitialValues,
    requestGetPernoite: responseInitialValues,
}

const requestPernoiteSlice = createSlice({
    name: 'requestPernoite',
    initialState,
    reducers: {
        setRequestGetPernoitesData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetPernoites.data = action.payload
            state.requestGetPernoites.loading = false
            state.requestGetPernoites.error = false
        },
        setRequestGetPernoitesLoading: (state: State) => {
            state.requestGetPernoites.loading = true
            state.requestGetPernoites.error = false
        },
        setRequestGetPernoitesError: (state: State) => {
            state.requestGetPernoites.loading = false
            state.requestGetPernoites.error = true
        },
        setRequestGetPernoitesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetPernoites.message = action.payload
        },
        resetRequestGetPernoites: (state: State) => {
            state.requestGetPernoites = {...responseInitialValues}
        },

        setRequestGetPernoiteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetPernoite.data = action.payload
            state.requestGetPernoite.loading = false
            state.requestGetPernoite.error = false
        },
        setRequestGetPernoiteLoading: (state: State) => {
            state.requestGetPernoite.loading = true
            state.requestGetPernoite.error = false
        },
        setRequestGetPernoiteError: (state: State) => {
            state.requestGetPernoite.loading = false
            state.requestGetPernoite.error = true
        },
        setRequestGetPernoiteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetPernoite.message = action.payload
        },
        resetRequestGetPernoite: (state: State) => {
            state.requestGetPernoite = {...responseInitialValues}
        },
    }
})

const { actions } = requestPernoiteSlice

export const requestGetPernoitesActions = {
    data: actions.setRequestGetPernoitesData,
    loading: actions.setRequestGetPernoitesLoading,
    error: actions.setRequestGetPernoitesError,
    message: actions.setRequestGetPernoitesMessage,
    reset: actions.resetRequestGetPernoites,
}

export const requestGetPernoiteActions = {
    data: actions.setRequestGetPernoiteData,
    loading: actions.setRequestGetPernoiteLoading,
    error: actions.setRequestGetPernoiteError,
    message: actions.setRequestGetPernoiteMessage,
    reset: actions.resetRequestGetPernoite,
}

export default requestPernoiteSlice.reducer