import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getDocumentosFinanceiro } from '@modules/documento/controllers/documentoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableDocumentos from './components/TableDocumentos'

const DocumentosListaFinanceiro: React.FC = () => {

    const dispatch = useAppDispatch()
    const { documentosFinanceiro } = useAppSelector(s => s.documento)

    useEffect(() => {
        getDocumentosFinanceiro(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Pagamentos (${documentosFinanceiro?.length ?? 0})`} />
            <Section>
                <TableDocumentos />
            </Section>
        </>

    )

}

export default DocumentosListaFinanceiro