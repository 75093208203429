import ENV from "@env/index"
import { UserData } from "@modules/auth/interfaces/UserData"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Conferencia } from "../interfaces/Conferencia"
import { ConferenciaValidacao } from "../interfaces/ConferenciaValidacao"
import { formConferenciaEditValues } from "../pages/ConferenciaEditar/components/FormConferenciaEdit/config"
import { setConferencia, setConferenciaValidacoes, setConferencias } from "../reducers/conferenciaReducer"
import { requestDeleteConferenciaActions, requestGetConferenciaActions, requestGetConferenciaValidacoesActions, requestGetConferenciasActions, requestInvalidateConferenciaActions, requestUpdateConferenciaActions, requestUpdateConferenciaValidacaoActions, requestValidateConferenciaActions } from "../reducers/requestConferenciaReducer"
import React from "react"
import { getAllViagens } from "@modules/viagem/controllers/viagemController"
import { getRomaneios } from "@modules/romaneio/controllers/romaneioController"

export async function getConferencias(dispatch: DispatchType) {
    initRequest(dispatch, requestGetConferenciasActions)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/listar-todas`
    const params = `?tipoLista=3`
    const response = await request.get<ResponsePattern<Conferencia[]>>({ endpoint, params })

    handleResponse('getConferencias', dispatch, response, requestGetConferenciasActions, options)
        .then(data => {
            dispatch(setConferencias(data))
        })
}

export async function getConferencia(dispatch: DispatchType, idConferencia: string) {
    initRequest(dispatch, requestGetConferenciaActions, setConferencia)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/getone`
    const params = `/${idConferencia}`
    const response = await request.get<ResponsePattern<Conferencia>>({ endpoint, params })

    handleResponse('getConferencia', dispatch, response, requestGetConferenciaActions, options)
        .then(data => {
            dispatch(setConferencia(data))
        })
}

export async function updateConferencia(
    dispatch: DispatchType,
    user: UserData,
    values: ReturnType<typeof formConferenciaEditValues>
) {
    initRequest(dispatch, requestUpdateConferenciaActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/salvar-viagem`
    const body = {
        ...values,
        dadosTomador: values.dadosTomador,
        dadosCidade: values.dadosCidade,

        idUsuario: user.id,
        flagCargaMista: false,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateConferencia', dispatch, response, requestUpdateConferenciaActions, options)
        .then(() => {

        })
}

export async function deleteConferencia(dispatch: DispatchType, user: UserData, idConferencia: number, setOpen?: (open: boolean) => void) {
    initRequest(dispatch, requestDeleteConferenciaActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/apagar`
    const body = {
        idUsuarioExclusao: user.id,
        idLista: idConferencia
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteConferencia', dispatch, response, requestDeleteConferenciaActions, options)
        .then(() => {
            if (!!setOpen) setOpen(false)

            getAllViagens(dispatch)
            getConferencias(dispatch)
            getRomaneios(dispatch)
        })
}

export async function getConferenciaValidacoes(dispatch: DispatchType, idConferencia: string) {
    initRequest(dispatch, requestGetConferenciaValidacoesActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista-processo-validacao/listar`
    const params = `/?q=${idConferencia}`
    const response = await request.get<ResponsePattern<ConferenciaValidacao[]>>({ endpoint, params })

    handleResponse('getConferenciaValidacoes', dispatch, response, requestGetConferenciaValidacoesActions)
        .then(data => {
            dispatch(setConferenciaValidacoes((data as any).processos))
        })
}

export async function validateConferencia(
    dispatch: DispatchType,
    user: UserData,
    conferencia: Conferencia,
    processoValidacao: ConferenciaValidacao
) {
    initRequest(dispatch, requestValidateConferenciaActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/lista-processo-validacao/validar`
    // const params = `?idLista=${conferencia.id}&idProcessoValidacao=${processoValidacao.id}`
    const body = {
        idLista: conferencia.id,
        idProcessoValidacao: processoValidacao.id,
        idUsuarioValidacao: user.id,
        obs: '',
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('validateConferencia', dispatch, response, requestValidateConferenciaActions, options)
        .then(() => {
            getConferenciaValidacoes(dispatch, conferencia.id.toString())
        })
}

export async function invalidateConferencia(
    dispatch: DispatchType,
    user: UserData,
    conferencia: Conferencia,
    processoValidacao: ConferenciaValidacao
) {
    initRequest(dispatch, requestInvalidateConferenciaActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/lista-processo-validacao/desvalidar`
    // const params = `?idLista=${conferencia.id}&idProcessoValidacao=${processoValidacao.id}`
    const body = {
        idLista: conferencia.id,
        idProcessoValidacao: processoValidacao.id,
        idUsuarioValidacao: user.id,
        obs: '',
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('invalidateConferencia', dispatch, response, requestInvalidateConferenciaActions, options)
        .then(() => {
            getConferenciaValidacoes(dispatch, conferencia.id.toString())
        })
}

export async function updateConferenciaValidacao(
    dispatch: DispatchType,
    conferencia: Conferencia,
    values: any,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
) {
    initRequest(dispatch, requestUpdateConferenciaValidacaoActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/lista-processo-validacao/salvar-obs`
    const body = values
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateConferenciaValidacao', dispatch, response, requestUpdateConferenciaValidacaoActions, options)
        .then(() => {
            getConferenciaValidacoes(dispatch, conferencia.id.toString())

            setOpenModal(false)
        })
}

export async function iniciateViagem(
    dispatch: DispatchType,
    values: any,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>,
) {
    initRequest(dispatch, requestUpdateConferenciaValidacaoActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/iniciar-viagem`
    const body = {
        id: Number(values.id),
        AE: values.ae,
        SM: values.sm
    }
    const response = await request.put<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateIniciarViagem', dispatch, response, requestUpdateConferenciaValidacaoActions, options)
        .then(() => {
            getConferenciaValidacoes(dispatch, values.idLista)

            setOpenModal(false)
            setRedirect(true)
        })
}