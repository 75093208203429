import React, { useState } from 'react'
import { TabProps } from './types'
import './styles.scss'

const Tab: React.FC <TabProps> = ({ tabs }) => {

    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const changeTab = (index: number) => {
        setActiveTabIndex(index)
    }

    return(

        <div className = "tabWrapper">
            <div className = "tabControlContainer">
                {tabs.map((tab, index) => (
                    <div
                        key = {index}
                        className = {`tabControl ${activeTabIndex === index ? 'active' : ''}`}
                        onClick = {() => changeTab(index)}
                    >
                        {tab.title}
                    </div>
                ))}
            </div>

            {tabs[activeTabIndex]?.content}
        </div>

    )

}

export default Tab