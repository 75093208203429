import React from 'react'
import { mdiCity, mdiEarth, mdiHomeCity, mdiMapMarker, mdiRoad, mdiText } from '@mdi/js'
import Modal from '@components/Common/Modal'
import Container from '@components/Page/Container'
import TextContainer from '@components/Common/TextContainer'
import { ModalProps } from '@interfaces/Modal'
import { useAppSelector } from '@redux/hooks'
import TableViagemAplicativoNotas from '../TableViagemAplicativoNotas'

const ModalViagemDestinatario: React.FC <ModalProps> = props => {

    const { currentDestinatario } = useAppSelector(s => s.viagem)

    return(

        <>
            {!!currentDestinatario && (
                <Modal {...props} title = {currentDestinatario.idDestinatario.toString()} width = "100%">
                    <Container direction = "row" justify = "between" padding = {false} className = "mb-6">    
                        <TextContainer
                            icon = {mdiMapMarker}
                            title = "Endereço"
                            text = {currentDestinatario.endereco}
                        />
                        <TextContainer
                            icon = {mdiText}
                            title = "Complemento"
                            text = {currentDestinatario.complemento}
                        />
                        <TextContainer
                            icon = {mdiRoad}
                            title = "CEP"
                            text = {currentDestinatario.cep}
                        />
                        <TextContainer
                            icon = {mdiHomeCity}
                            title = "Bairro"
                            text = {currentDestinatario.bairro}
                        />
                        <TextContainer
                            icon = {mdiCity}
                            title = "Cidade"
                            text = {currentDestinatario.cidade}
                        />
                        <TextContainer
                            icon = {mdiEarth}
                            title = "UF"
                            text = {currentDestinatario.uf}
                        />
                    </Container>

                    <Container padding = {false}>
                        <TableViagemAplicativoNotas />
                    </Container>
                </Modal>
            )}
        </>

    )

}

export default ModalViagemDestinatario