import React from 'react'

import { FormRowProps } from '../../types'

const FormRow: React.FC <FormRowProps> = ({ children, grid, columns }) => {

    return <div className = "formRow" style = {{gridTemplateColumns: grid ?? `repeat(${columns.toString()}, 1fr)`}}>{children}</div>

}

export default FormRow