import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getConferencia, getConferenciaValidacoes } from '@modules/conferencia/controllers/conferenciaController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableValidacoes from './components/TableValidacoes'
import Button from '@components/Common/Button'
import ModalIniciarViagem from './components/ModalIniciarViagem'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'
import { getDocumento } from '@modules/documento/controllers/documentoController'

const ConferenciaValidar: React.FC = () => {

    const dispatch = useAppDispatch()
    const [openModalIniciarViagem, setOpenModalIniciarViagem] = useState(false)
    const { conferenciaValidacoes } = useAppSelector(s => s.conferencia)

    const { idConferencia } = useParams()

    useEffect(() => {
        if (!!idConferencia) {
            getConferencia(dispatch, idConferencia)
            getConferenciaValidacoes(dispatch, idConferencia)
            getDocumento(dispatch, idConferencia)
        }
    }, [dispatch, idConferencia])

    return (

        <>
            <PageTitle title="Validar Conferência" />
            <Section>
                <TableValidacoes />
                <div className='ml-auto mt-6'>
                    <Button
                        label='Iniciar Viagem'
                        onClick={() => {
                            const conferenciaNaoValidada = conferenciaValidacoes?.find((c) => !c.flagValidado)
                            if (conferenciaNaoValidada) {
                                return dispatch(setAlert(createAlert('warning', `${conferenciaNaoValidada.descricao} é obrigatório para iniciar a viagem`)))
                            }
                            setOpenModalIniciarViagem(true)
                        }}
                    />
                </div>
                <ModalIniciarViagem
                    open={openModalIniciarViagem}
                    setOpen={setOpenModalIniciarViagem}
                />
            </Section>
        </>

    )

}

export default ConferenciaValidar