import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import Loader, { LoaderContainer } from '@components/Feedback/Loader'
import { getPernoite } from '@modules/pernoite/controllers/pernoiteController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const Pernoite: React.FC = () => {

    const dispatch = useAppDispatch()
    const { pernoite } = useAppSelector(s => s.pernoite)
    const { requestGetPernoite } = useAppSelector(s => s.requestPernoite)

    const { idViagem } = useParams()

    const CR = conditionaRender(requestGetPernoite, pernoite, true)

    useEffect(() => {
        if(!!idViagem){
            getPernoite(dispatch, idViagem)
        }
    }, [dispatch, idViagem])

    return(

        <>
            {CR.LOADING && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            {CR.DATA && (
                <>
                    <Section>
                        <SectionBox title = "Localização" padding = {false}>

                        </SectionBox>
                    </Section>
                    <Section>
                        
                    </Section>
                </>
            )}
        </>

    )

}

export default Pernoite