import React from 'react'
import { mdiDelete } from '@mdi/js'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import ModalMessage from '@components/Common/Modal/Message'
import { deleteFilial } from '@modules/filial/controllers/filialController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { ModalDeleteFilialProps } from './types'

const ModalDeleteFilial: React.FC <ModalDeleteFilialProps> = modalProps => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentFilial } = useAppSelector(s => s.filial)
    const { requestDeleteFilial } = useAppSelector(s => s.requestFilial)

    const { setOpen } = modalProps

    return(

        <Modal {...modalProps} title = "Deletar solicitação">
            <ModalMessage message = "Deseja deletar essa filial?" />
            <ModalActions>
                <Button
                    label = "Cancelar"
                    onClick = {() => setOpen(false)}
                />
                <Button
                    icon = {mdiDelete}
                    label = "Deletar"
                    status = "error"
                    loading = {requestDeleteFilial.loading}
                    onClick = {() => {
                        deleteFilial(dispatch, userData!, currentFilial!, setOpen)
                    }}
                />
            </ModalActions>
        </Modal>

    )

}

export default ModalDeleteFilial