import React, { useState } from 'react'
import { Navigate } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import { mdiClose, mdiMagnify, mdiPlus } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import FileSelect from '@components/Common/File/components/FileSelect'
import ButtonGroup from '@components/Common/Button/Group'
import selectUF from '@constants/select/uf'
import ModalMotorista from '@modules/viagem/components/Search/SearchMotorista/Modal'
import { motoristaFields } from '@modules/viagem/components/DEPRACTED_FormViagemFields/config'
import { createDocumento } from '@modules/documento/controllers/documentoController'
import { searchMotorista } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'
import { formCreateDocumentoSchema, formCreateDocumentoValues } from './config'

const FormCreateDocumento: React.FC = () => {

    const dispatch = useAppDispatch()
    const { filiais } = useAppSelector(s => s.core)
    const { userData } = useAppSelector(s => s.auth)
    const { requestSearchMotorista } = useAppSelector(s => s.requestViagem)
    const { requestCreateDocumento } = useAppSelector(s => s.requestDocumento)

    const [redirect, setRedirect] = useState(false)
    const [openModalMotorista, setOpenModalMotorista] = useState(false)

    return(

        <>
            {redirect && <Navigate to = {`/documento`} />}
            <Formik
                initialValues = {formCreateDocumentoValues}
                validationSchema = {formCreateDocumentoSchema}
                onSubmit = {v => {
                    // createDocumento(dispatch, userData!, v, filiais?.find(f => f.id === v.idFilial)!, setRedirect)
                }}
            >
                {({ values, setFieldValue, handleChange }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Section>
                                <Form.Row columns = {3} grid = "200px 1fr">
                                    <Select
                                        type = "outline"
                                        label = "Filial"
                                        fieldName = "idFilial"
                                        setFieldValue = {setFieldValue}
                                        value = {values.idFilial}
                                        items = {createSelectItems(filiais, 'descricao', 'id')}
                                    />
                                </Form.Row>
                            </Form.Section>
                            <Form.Section title = "Motorista">
                                <Form.Row columns = {3} grid = "auto auto 1fr">
                                    <Form.Group
                                        label = "Pesquisar motorista"
                                        inputID = "motoristaPesquisa"
                                        inputName = "motoristaPesquisa"
                                    />
                                    <ButtonGroup>
                                        <Button
                                            icon = {mdiMagnify}
                                            loading = {requestSearchMotorista.loading}
                                            status = "success"
                                            onClick = {() => {
                                                searchMotorista(dispatch, values.motoristaPesquisa, setFieldValue)
                                            }}
                                        />
                                        <Button
                                            icon = {mdiClose}
                                            status = "error"
                                            onClick = {() => {
                                                ['motoristaPesquisa', ...motoristaFields]
                                                .forEach(field => setFieldValue(field, ''))
                                            }}
                                        />
                                        <Button
                                            icon = {mdiPlus}
                                            status = "info"
                                            onClick = {() => {
                                                setOpenModalMotorista(true)  
                                            }}
                                        />
                                    </ButtonGroup>
                                </Form.Row>

                                <Form.Row columns = {2} grid = "200px 1fr 200px">
                                    <Form.Group
                                        label = "Documento"
                                        inputID = "motoristaDoc"
                                        inputName = "motoristaDoc"
                                        mask = {[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Nome"
                                        inputID = "motoristaNome"
                                        inputName = "motoristaNome"
                                    />
                                    <Form.Group
                                        label = "Telefone"
                                        inputID = "motoristaTelefone"
                                        inputName = "motoristaTelefone"
                                        mask = {['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        handleChange = {handleChange}
                                    />
                                </Form.Row>
                                <Form.Row columns = {7} grid = "100px 1fr 70px 100px 150px 200px 70px">
                                    <Form.Group
                                        label = "CEP"
                                        inputID = "motoristaCep"
                                        inputName = "motoristaCep"
                                        mask = {[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Endereço"
                                        inputID = "motoristaEndereco"
                                        inputName = "motoristaEndereco"
                                    />
                                    <Form.Group
                                        label = "Número"
                                        inputID = "motoristaNumero"
                                        inputName = "motoristaNumero"
                                    />
                                    <Form.Group
                                        label = "Complemento"
                                        inputID = "motoristaComplemento"
                                        inputName = "motoristaComplemento"
                                        
                                    />
                                    <Form.Group
                                        label = "Bairro"
                                        inputID = "motoristaBairro"
                                        inputName = "motoristaBairro"
                                        
                                    />
                                    <Form.Group
                                        label = "Cidade"
                                        inputID = "motoristaCidade"
                                        inputName = "motoristaCidade"
                                    />
                                    <Select
                                        type = "outline"
                                        label = "UF"
                                        fieldName = "motoristaUf"
                                        value = {values.motoristaUf}
                                        items = {selectUF}
                                        setFieldValue = {setFieldValue}
                                    />
                                </Form.Row>
                            </Form.Section>

                            <Form.Section title = "Doc. Contrato">
                                <Form.Row columns = {1} grid = "80%">
                                    <FileSelect
                                        name = "docContrato"
                                        values = {values.docContrato}
                                        accept = ".pdf"
                                        setFieldValue = {setFieldValue}
                                    />
                                </Form.Row>
                            </Form.Section>

                            <Form.Section title = "Doc. MDF-e">
                                <Form.Row columns = {1} grid = "80%">
                                    <FileSelect
                                        name = "docMdfe"
                                        values = {values.docMdfe}
                                        accept = ".pdf"
                                        setFieldValue = {setFieldValue}
                                    />
                                </Form.Row>
                            </Form.Section>

                            <Form.Section title = "Doc. CTE">
                                <Form.Row columns = {1} grid = "80%">
                                    <FileSelect
                                        name = "docCts"
                                        values = {values.docCts}
                                        accept = ".pdf"
                                        setFieldValue = {setFieldValue}
                                    />
                                </Form.Row>
                            </Form.Section>

                            <ButtonGroup>
                                <Button
                                    loading = {requestCreateDocumento.loading}
                                    label = "Salvar"
                                    status = "success"
                                    type = "submit"
                                />
                            </ButtonGroup>

                            <ModalMotorista
                                open = {openModalMotorista}
                                setOpen = {setOpenModalMotorista}
                            />
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormCreateDocumento