import React, { useState } from 'react'
import { mdiEye } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import determinaSituacao from '@modules/viagem/utils/determineSituacao'
import { setCurrentDestinatario } from '@modules/viagem/reducers/viagemReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import ModalViagemDestinatario from '../ModalViagemDestinatario'

const TableViagemAplicativoDestinatario: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagemAplicativoMotorista } = useAppSelector(s => s.viagem)
    const { requestGetViagemAplicativoMotorista } = useAppSelector(s => s.requestViagem)

    const [openModal, setOpenModal] = useState(false)
    const [tableConfig] = useState(createTableConfig(
        'viagemAplicativoDestinatarios',
        ["Destinatário", "Nº Notas", ""]
    ))

    const CR = conditionaRender(requestGetViagemAplicativoMotorista, viagemAplicativoMotorista)

    return(

        <>
            <TablePaginator
                data = {viagemAplicativoMotorista?.destinatarios ?? null}
                config = {tableConfig}
                conditionalRender = {CR}
                renderItem = {(item, index) => {
                    const situacao = determinaSituacao(item)

                    return(
                        <TableRow key = {index} status = {situacao === 'Finalizado' ? 'success' : situacao === 'Não Finalizado' ? 'info' : 'error'}>
                            <TableColumn>{item.nome}</TableColumn>
                            <TableColumn>{item.notas.length}</TableColumn>
                            <TableColumn>
                                <TableOption
                                    icon = {mdiEye}
                                    onClick = {() => {
                                        dispatch(setCurrentDestinatario(item))
                                        setOpenModal(true)
                                    }}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />

            <ModalViagemDestinatario
                open = {openModal}
                setOpen = {setOpenModal}
            />
        </>

    )

}

export default TableViagemAplicativoDestinatario