import React from 'react'
import { mdiDelete } from '@mdi/js'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalMessage from '@components/Common/Modal/Message'
import ModalActions from '@components/Common/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { deleteCliente } from '@modules/cliente/controllers/clienteController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const ModalDeleteCliente: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentCliente } = useAppSelector(s => s.cliente)
    const { requestDeleteCliente } = useAppSelector(s => s.requestCliente)

    return(

        <Modal
            {...props}
            title = "Deletar cliente"
        >
            <ModalMessage message = "Deseja deletar esse cliente?" />
            <ModalActions>
                <Button
                    label = "Fechar"
                    onClick = {() => props.setOpen(false)}
                />

                <Button
                    icon = {mdiDelete}
                    label = "Deletar"
                    status = "error"
                    loading = {requestDeleteCliente.loading}
                    onClick = {() => {
                        deleteCliente(dispatch, userData!, currentCliente!, props)
                    }}
                />
            </ModalActions>
        </Modal>

    )

}

export default ModalDeleteCliente