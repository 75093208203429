import React, { useEffect, useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { useAppSelector } from '@redux/hooks'
import sleep from '@utils/sleep'
import { viagemFormValues } from './config'
import FormViagemFields from '@modules/viagem/components/FormViagemFields'

const FormViagem: React.FC = () => {

    const { viagem } = useAppSelector(s => s.viagem)
    const [formValues, setFormValues] = useState<ReturnType<typeof viagemFormValues>>()

    useEffect(() => {
        (async () => {
            setFormValues(undefined)
            if (!!viagem) {
                await sleep(500)
                setFormValues(viagemFormValues(viagem))
            }
        })()
    }, [viagem])

    return (

        <>
            <Formik
                initialValues={formValues!}
                onSubmit={() => { }}
            >
                {() => (
                    <FormikForm>
                        <FormViagemFields
                            readonly={true}
                        />
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormViagem