import { DocumentoFile } from "@modules/documento/interfaces/Documento"

export default function formatFormFile(files: DocumentoFile[] | null){
    if(!files) return []

    return files.map(item => {
        return {
            id: item.number,
            file: item.url,
        }
    })
}