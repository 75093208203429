import React, { useEffect } from 'react'
import { setAuthLogout } from '@modules/auth/reducers/authReducer'
import { useAppDispatch } from '@redux/hooks'

const Logout: React.FC = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setAuthLogout())
    }, [dispatch])

    return <div />
}

export default Logout