import * as yup from 'yup'

export const formCreateMotoristasValues = {
    documento: '',
    nome: '',
}

export const formCreateMotoristasSchema = yup.object().shape({
    documento: yup.string().required('Documento é obrigatório'),
    nome: yup.string().required('Nome é obrigatório'),
})