import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getSolicitacoesCte } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector, } from '@redux/hooks'
import TableCte from './components/TableCte'

const SolicitacaoCteLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { solicitacoesCte } = useAppSelector(s => s.solicitacao)

    useEffect(() => {
        getSolicitacoesCte(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`CTE (${solicitacoesCte?.length ?? 0})`} />
            <Section>
                <TableCte />
            </Section>
        </>

    )

}

export default SolicitacaoCteLista