import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import FormViagemFields from '@modules/viagem/components/FormViagemFields'
import { formViagemSchema } from '@modules/viagem/components/FormViagemFields/config'
import { useAppSelector } from '@redux/hooks'
import { formViagemAtivaValues } from './config'

const FormViagem: React.FC = () => {

    const { viagem } = useAppSelector(s => s.viagem)

    return(

        <>
            <Formik
                initialValues = {formViagemAtivaValues(viagem!)}
                onSubmit = {() => {}}
            >
                {() => (
                    <FormikForm>
                        <FormViagemFields
                            readonly = {true}
                        />
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormViagem