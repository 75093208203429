import React, { useState } from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { mdiAccountPlus, mdiClose, mdiMagnify, mdiPlus } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import selectEscolta from '@constants/select/escolta'
import { selectRastreador1, selectRastreador2 } from '@constants/select/rastreador'
import ModalTomador from '@modules/viagem/components/Search/SearchTomador/Modal'
import ModalMotorista from '@modules/viagem/components/Search/SearchMotorista/Modal'
import ModalEmbarcador from '@modules/viagem/components/Search/SearchEmbarcador/Modal'
import ModalDestinatario from '@modules/viagem/components/Search/SearchDestinatario/Modal'
import { searchVeiculo } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'
import ModalCidadesPassagem from '../CidadesPassagem/Modal'
import { formSolicitacaoValues } from './config'
import './styles.scss'
import ModalCreateMotorista from '@modules/solicitacao/pages/SolicitacaoCriar/components/ModalCreateMotorista'
import ModalCavalo from '@modules/viagem/components/Search/SearchCavalo/Modal'
import ModalCreateCavalo from '@modules/solicitacao/pages/SolicitacaoCriar/components/ModalCreateCavalo'
import ModalCreateCarreta from '@modules/solicitacao/pages/SolicitacaoCriar/components/ModalCreateCarreta'
import ModalCarreta from '@modules/viagem/components/Search/SearchCarreta/Modal'
import Icon from '@mdi/react'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'
import { useLocation } from 'react-router'
import { FormSolicitacaoFieldsProps } from './type'

const FormSolicitacaoFields: React.FC<FormSolicitacaoFieldsProps> = ({ readonly = false }) => {

    const { filiais, tiposOperacao } = useAppSelector(s => s.core)

    const [openModalTomador, setOpenModalTomador] = useState(false)
    const [openModalEmbarcador, setOpenModalEmbarcador] = useState(false)
    const [openModalDestinatario, setOpenModalDestinatario] = useState(false)
    const [openModalCidades, setOpenModalCidades] = useState(false)

    const [openModalMotorista, setOpenModalMotorista] = useState(false)
    const [openModalCreateMotorista, setOpenModalCreateMotorista] = useState(false)

    const [openModalCavalo, setOpenModalCavalo] = useState(false)
    const [openModalCreateCavalo, setOpenModalCreateCavalo] = useState(false)

    const [openModalCarreta, setOpenModalCarreta] = useState(false)
    const [openModalCreateCarreta, setOpenModalCreateCarreta] = useState(false)

    const { values, errors, setFieldValue } = useFormikContext<typeof formSolicitacaoValues>()
    const dispatch = useAppDispatch()

    const { pathname } = useLocation()

    const module = pathname.split('/')[1]

    return (

        <>
            <div className="pageGrid">
                <SectionBox
                    title="Geral"
                >
                    <Form.Section>
                        <Form.Row columns={module === 'viagem' ? 5 : 3}>
                            <Select
                                type="outline"
                                label="Filial"
                                fieldName="idFilial"
                                value={values.idFilial}
                                error={!!errors.idFilial}
                                items={createSelectItems(filiais, 'descricao', 'id')}
                                setFieldValue={setFieldValue}
                                readonly={readonly}
                            />
                            <Select
                                type="outline"
                                label="Tipo de operação"
                                fieldName="idTipoOperacao"
                                value={values.idTipoOperacao}
                                items={createSelectItems(tiposOperacao, 'descricao', 'id')}
                                setFieldValue={setFieldValue}
                                readonly={readonly}
                            />

                            <Select
                                type="outline"
                                label="Escolta"
                                fieldName="flagEscolta"
                                value={values.flagEscolta}
                                items={selectEscolta}
                                setFieldValue={setFieldValue}
                                readonly={readonly}
                            />


                            {
                                module === 'viagem' && (
                                    <>
                                        <Form.Group
                                            label="Opentech AE"
                                            inputID="AE"
                                            inputName="AE"
                                            readonly={readonly}
                                        />
                                        <Form.Group
                                            label="Trafegus SM"
                                            inputID="SM"
                                            inputName="SM"
                                            readonly={readonly}
                                        />
                                    </>
                                )
                            }
                        </Form.Row>

                        <Form.Row columns={1}>
                            <Form.Group
                                label="Observações"
                                inputID="obs"
                                inputName="obs"
                                inputType="textarea"
                                inputRows={6}
                                readonly={readonly}
                            />
                        </Form.Row>
                    </Form.Section>
                </SectionBox>

                <SectionBox
                    title="Embarcador"
                    right={
                        <>
                            {!readonly && (
                                <Button
                                    icon={mdiPlus}
                                    status="success"
                                    onClick={() => {
                                        setOpenModalEmbarcador(true)
                                    }}
                                />
                            )}
                        </>
                    }
                >
                    <Form.Row columns={5} grid="1fr 1fr 1fr 60px auto">
                        <Form.Group
                            label="Documento"
                            inputID="remetenteDoc"
                            inputName="remetenteDoc"
                            readonly={true}
                        />

                        <Form.Group
                            label="Nome"
                            inputID="remetenteNome"
                            inputName="remetenteNome"
                            readonly={true}
                        />

                        <Form.Group
                            label="Cidade"
                            inputID="remetenteCidade"
                            inputName="remetenteCidade"
                            readonly={true}
                        />

                        <Form.Group
                            label="UF"
                            inputID="remetenteUf"
                            inputName="remetenteUf"
                            readonly={true}
                        />

                        {!readonly && (
                            <Button
                                icon={mdiClose}
                                status="error"
                                onClick={() => {
                                    ['remetenteDoc', 'remetenteNome', 'remetenteCidade', 'remetenteUf']
                                        .forEach(field => setFieldValue(field, ''))
                                }}
                            />
                        )}
                    </Form.Row>
                </SectionBox>

                <SectionBox
                    title="Destinatário"
                    right={
                        <>
                            {!readonly && (
                                <ButtonGroup>
                                    <Button
                                        icon={mdiPlus}
                                        label={`Cidades de Passagem (${values.dadosCidade.length})`}
                                        status="success"
                                        onClick={() => {
                                            setOpenModalCidades(true)
                                        }}
                                    />
                                    <Button
                                        icon={mdiPlus}
                                        status="success"
                                        onClick={() => {
                                            setOpenModalDestinatario(true)
                                        }}
                                    />
                                </ButtonGroup>
                            )}
                        </>
                    }
                >
                    <Form.Row columns={5} grid="1fr 1fr 1fr 60px auto">
                        <Form.Group
                            label="Documento"
                            inputID="destinatarioDoc"
                            inputName="destinatarioDoc"
                            readonly={true}
                        />

                        <Form.Group
                            label="Nome"
                            inputID="destinatarioNome"
                            inputName="destinatarioNome"
                            readonly={true}
                        />

                        <Form.Group
                            label="Cidade"
                            inputID="destinatarioCidade"
                            inputName="destinatarioCidade"
                            readonly={true}
                        />

                        <Form.Group
                            label="UF"
                            inputID="destinatarioUf"
                            inputName="destinatarioUf"
                            readonly={true}
                        />

                        {!readonly && (
                            <Button
                                icon={mdiClose}
                                status="error"
                                onClick={() => {
                                    ['destinatarioDoc', 'destinatarioNome', 'destinatarioCidade', 'destinatarioUf']
                                        .forEach(field => setFieldValue(field, ''))
                                }}
                            />
                        )}
                    </Form.Row>
                </SectionBox>

                <SectionBox
                    title="Tomador"
                    right={
                        <>
                            {!readonly && (
                                <Button
                                    icon={mdiPlus}
                                    status="success"
                                    onClick={() => {
                                        setOpenModalTomador(true)
                                    }}
                                />
                            )}
                        </>
                    }
                >
                    <FieldArray name="dadosTomador">
                        {({ push, remove }) => (
                            <>
                                {values.dadosTomador.map((item, index) => (
                                    <Form.Row
                                        key={index}
                                        columns={5}
                                        grid="1fr 1fr 1fr 60px auto"
                                    >
                                        <Form.Group
                                            label="Documento"
                                            inputID={`dadosTomador.${index}.documentoTomador`}
                                            inputName={`dadosTomador.${index}.documentoTomador`}
                                            readonly={true}
                                        />

                                        <Form.Group
                                            label="Nome"
                                            inputID={`dadosTomador.${index}.nomeTomador`}
                                            inputName={`dadosTomador.${index}.nomeTomador`}
                                            readonly={true}
                                        />

                                        <Form.Group
                                            label="Cidade"
                                            inputID={`dadosTomador.${index}.cidadeTomador`}
                                            inputName={`dadosTomador.${index}.cidadeTomador`}
                                            readonly={true}
                                        />

                                        <Form.Group
                                            label="UF"
                                            inputID={`dadosTomador.${index}.estadoTomador`}
                                            inputName={`dadosTomador.${index}.estadoTomador`}
                                            readonly={true}
                                        />

                                        {!readonly && (
                                            <Button
                                                icon={mdiClose}
                                                status="error"
                                                onClick={() => {
                                                    remove(index)
                                                }}
                                            />
                                        )}
                                    </Form.Row>
                                ))}

                                <ModalTomador
                                    open={openModalTomador}
                                    setOpen={setOpenModalTomador}
                                    push={push}
                                    remove={remove}
                                />
                            </>
                        )}
                    </FieldArray>
                </SectionBox>

                <SectionBox
                    title={`Motorista ${module !== 'viagem' ? '(Opcional)' : ''}`}
                    right={
                        <>
                            {!readonly && (
                                <>
                                    <Button
                                        label='Novo'
                                        status="success"
                                        onClick={() => {
                                            setOpenModalCreateMotorista(true)
                                        }}
                                    />
                                    <Button
                                        icon={mdiPlus}
                                        status="success"
                                        onClick={() => {
                                            setOpenModalMotorista(true)
                                        }}
                                    />
                                </>
                            )}
                        </>
                    }
                >
                    <Form.Row columns={3}>
                        <Form.Group
                            label="Documento"
                            inputID="motoristaDoc"
                            inputName="motoristaDoc"
                            readonly
                        />
                        <Form.Group
                            label="Nome"
                            inputID="motoristaNome"
                            inputName="motoristaNome"
                            readonly
                        />
                        <Form.Group
                            label="Contratante"
                            inputID="motoristaContratante"
                            inputName="motoristaContratante"
                            readonly
                        />
                    </Form.Row>
                </SectionBox>

                <SectionBox
                    title={`Cavalo ${module !== 'viagem' ? '(Opcional)' : ''}`}
                    right={
                        <>
                            {!readonly && (
                                <>
                                    <Button
                                        label='Novo'
                                        status="success"
                                        onClick={() => {
                                            setOpenModalCreateCavalo(true)
                                        }}
                                    />
                                    <Button
                                        icon={mdiPlus}
                                        status="success"
                                        onClick={() => {
                                            setOpenModalCavalo(true)
                                        }}
                                    />
                                </>
                            )}
                        </>
                    }
                >
                    <Form.Row columns={3}>
                        <Form.Group
                            label="Placa"
                            inputID="veiculoPlaca"
                            inputName="veiculoPlaca"
                            readonly
                        />
                        <Form.Group
                            label="Rastreador"
                            inputID="rastreadorCavalo"
                            inputName="rastreadorCavalo"
                            readonly
                        />
                        <Form.Group
                            label="Bloqueador"
                            inputID="bloqueadorCavalo"
                            inputName="bloqueadorCavalo"
                            readonly
                        />
                    </Form.Row>
                </SectionBox>
                <SectionBox
                    title={`Carretas ${module !== 'viagem' ? '(Opcional)' : ''}`}
                    right={
                        <>
                            {!readonly && (
                                <>
                                    <Button
                                        label='Nova'
                                        status="success"
                                        onClick={() => {
                                            if (!values.veiculoPlaca1 || !values.veiculoPlaca2 || !values.veiculoPlaca3) {
                                                return setOpenModalCreateCarreta(true)
                                            }

                                            dispatch(setAlert(createAlert('warning', "Só são permitidas três carratas")))
                                        }}
                                    />
                                    <Button
                                        icon={mdiPlus}
                                        status="success"
                                        onClick={() => {
                                            if (!values.veiculoPlaca1 || !values.veiculoPlaca2 || !values.veiculoPlaca3) {
                                                return setOpenModalCarreta(true)
                                            }

                                            dispatch(setAlert(createAlert('warning', "Só são permitidas três carratas")))
                                        }}
                                    />
                                </>
                            )}
                        </>
                    }
                >
                    <Form.Row columns={3}>
                        <Form.Group
                            label="Placa 1"
                            inputID="veiculoPlaca1"
                            inputName="veiculoPlaca1"
                            readonly
                            clearInputValue={(!readonly && values.veiculoPlaca1) ? setFieldValue : undefined}
                        />

                        <Form.Group
                            label="Placa 2"
                            inputID="veiculoPlaca2"
                            inputName="veiculoPlaca2"
                            readonly
                            clearInputValue={(!readonly && values.veiculoPlaca2) ? setFieldValue : undefined}

                        />
                        <Form.Group
                            label="Placa 3"
                            inputID="veiculoPlaca3"
                            inputName="veiculoPlaca3"
                            readonly
                            clearInputValue={(!readonly && values.veiculoPlaca3) ? setFieldValue : undefined}
                        />
                    </Form.Row>
                </SectionBox>

                <SectionBox
                    title={`Valores ${module !== 'viagem' ? '(Opcional)' : ''}`}
                    >
                    <Form.Row columns={2} grid="repeat(2, 1fr)">
                        <Form.Currency
                            label="Valor mercadoria"
                            name="valorMercadoria"
                            value={values.valorMercadoria}
                            readonly={readonly}

                        />
                        <Form.Currency
                            label="Valor contrato"
                            name="valorFrete"
                            value={values.valorFrete}
                            readonly={readonly}
                        />
                    </Form.Row>
                </SectionBox>
            </div>

            <ModalEmbarcador
                open={openModalEmbarcador}
                setOpen={setOpenModalEmbarcador}
            />

            <ModalDestinatario
                open={openModalDestinatario}
                setOpen={setOpenModalDestinatario}
            />

            <ModalCavalo
                open={openModalCavalo}
                setOpen={setOpenModalCavalo}
            />
            <ModalCreateCavalo
                open={openModalCreateCavalo}
                setOpen={setOpenModalCreateCavalo}
            />

            <ModalCarreta
                open={openModalCarreta}
                setOpen={setOpenModalCarreta}
            />
            <ModalCreateCarreta
                open={openModalCreateCarreta}
                setOpen={setOpenModalCreateCarreta}
            />

            <ModalMotorista
                open={openModalMotorista}
                setOpen={setOpenModalMotorista}
            />
            <ModalCreateMotorista
                open={openModalCreateMotorista}
                setOpen={setOpenModalCreateMotorista}
            />

            <ModalCidadesPassagem
                open={openModalCidades}
                setOpen={setOpenModalCidades}
            />
        </>

    )

}

export default FormSolicitacaoFields