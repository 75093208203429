import React, { useEffect } from 'react'
import Modal from '@components/Common/Modal'
import { ModalProps } from '@interfaces/Modal'
import { getPernoite } from '@modules/viagem/controllers/viagemController'
import conditionaRender from '@utils/conditionalRender'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import MapPernoite from '../Map'
import TablePernoite from '../Table'

const ModalPernoite: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { viagemPernoite, currentViagemAtiva } = useAppSelector(s => s.viagem)
    const { requestGetPernoite } = useAppSelector(s => s.requestViagem)

    const CR_PERNOITE = conditionaRender(requestGetPernoite, viagemPernoite)

    useEffect(() => {
        if(!!currentViagemAtiva){
            getPernoite(dispatch, currentViagemAtiva.id.toString())
        }
    }, [dispatch, currentViagemAtiva])

    return(

        <>
            <Modal
                {...props}
                title = "Pernoite"
                width = "100%"
            >
                {CR_PERNOITE.DATA && <MapPernoite />}
                <TablePernoite />
            </Modal>
        </>

    )

}

export default ModalPernoite