import ENV from "@env/index"
import { UserData } from "@modules/auth/interfaces/UserData"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { jsonToParams } from "@utils/jsonToParams"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Solicitacao } from "../interfaces/Solicitacao"
import { formSolicitacaoValues } from "../components/FormSolicitacaoFields/config"
import { formSolicitacaoEditValues } from "../pages/SolicitacaoEditar/components/FormSolicitacaoEdit/config"
import { setSolicitacao, setSolicitacoes, setSolicitacoesCte } from "../reducers/solicitacaoReducer"
import { requestCreateSolicitacaoActions, requestDeleteSolicitacaoActions, requestGetSolicitacaoActions, requestGetSolicitacoesActions, requestGetSolicitacoesCteActions, requestUpdateSolicitacaoActions } from "../reducers/requestSolicitacaoReducer"
import { setAlert } from "@modules/app/reducers/alertReducer"
import createAlert from "@modules/app/scripts/alert/createAlert"
import { SolicitacaoCte } from "../interfaces/SolicitacaoCte"

export async function createSolicitacao(
    dispatch: DispatchType,
    user: UserData,
    values: typeof formSolicitacaoValues,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    if (!values.idFilial) {
        return dispatch(setAlert(createAlert('warning', "Informe a filial")))
    }

    if (!values.idTipoOperacao) {
        return dispatch(setAlert(createAlert('warning', "Informe o tipo de operação")))
    }

    if (!values.remetenteDoc) {
        return dispatch(setAlert(createAlert('warning', "Informe o embarcador")))
    }

    if (!values.destinatarioDoc) {
        return dispatch(setAlert(createAlert('warning', "Informe o destinatário")))
    }

    if (!values.dadosTomador[0]) {
        return dispatch(setAlert(createAlert('warning', "Informe no mínimo um tomador")))
    }

    initRequest(dispatch, requestCreateSolicitacaoActions)


    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/salvar-viagem`
    const [rastreadorPrincipal, idRastreadorPrincipal] = values.rastreadorCavalo.split(" - ")
    const [bloqueador, idBloqueador] = values.bloqueadorCavalo.split(" - ")

    const carretas = [values.veiculoPlaca1, values.veiculoPlaca2, values.veiculoPlaca3]
        .filter(placa => placa !== "")
        .sort();

    const body = {
        ...values,
        veiculoRastreadorCodigo: rastreadorPrincipal,
        idVeiculoRastreador: idRastreadorPrincipal,
        veiculoBloqueadorCodigo: bloqueador,
        idVeiculoBloqueador: idBloqueador,
        valorMercadoria: `${values.valorMercadoria}`,
        valorFrete: `${values.valorFrete}`,

        veiculoReboque1: carretas[0],
        veiculoReboque2: carretas[1],
        veiculoReboque3: carretas[2],

        idUsuario: user.id,
        flagCargaMista: false,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createSolicitacao', dispatch, response, requestCreateSolicitacaoActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function updateSolicitacao(
    dispatch: DispatchType,
    user: UserData,
    values: ReturnType<typeof formSolicitacaoEditValues>,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    if (!values.idFilial) {
        return dispatch(setAlert(createAlert('warning', "Informe a filial")))
    }

    if (!values.idTipoOperacao) {
        return dispatch(setAlert(createAlert('warning', "Informe o tipo de operação")))
    }

    if (!values.remetenteDoc) {
        return dispatch(setAlert(createAlert('warning', "Informe o embarcador")))
    }

    if (!values.destinatarioDoc) {
        return dispatch(setAlert(createAlert('warning', "Informe o destinatário")))
    }

    if (!values.dadosTomador[0]) {
        return dispatch(setAlert(createAlert('warning', "Informe no mínimo um tomador")))
    }

    initRequest(dispatch, requestUpdateSolicitacaoActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/salvar-viagem`

    const [rastreadorPrincipal, idRastreadorPrincipal] = values.rastreadorCavalo.split(" - ")
    const [bloqueador, idBloqueador] = values.bloqueadorCavalo.split(" - ")

    const carretas = [values.veiculoPlaca1, values.veiculoPlaca2, values.veiculoPlaca3]
        .filter(placa => placa !== "")
        .sort();

    const body = {
        ...values,
        veiculoRastreadorCodigo: rastreadorPrincipal,
        idVeiculoRastreador: idRastreadorPrincipal,
        veiculoBloqueadorCodigo: bloqueador,
        idVeiculoBloqueador: idBloqueador,
        valorMercadoria: `${values.valorMercadoria}`,
        valorFrete: `${values.valorFrete}`,

        veiculoReboque1: carretas[0],
        veiculoReboque2: carretas[1],
        veiculoReboque3: carretas[2],

        idUsuario: user.id,
        flagCargaMista: false,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateSolicitacao', dispatch, response, requestUpdateSolicitacaoActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function deleteSolicitacao(
    dispatch: DispatchType,
    user: UserData, demanda: Solicitacao,
    setOpenModal: Function
) {
    initRequest(dispatch, requestDeleteSolicitacaoActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/excluir`
    const body = {
        id: demanda.id,
        idUsuario: user.id
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteSolicitacao', dispatch, response, requestDeleteSolicitacaoActions, options)
        .then(() => {
            setOpenModal(false)
            getSolicitacoes(dispatch)
        })
}

export async function getSolicitacoes(dispatch: DispatchType, filterValues?: any) {
    initRequest(dispatch, requestGetSolicitacoesActions)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/listar-todas`
    const params = `?tipoLista=1&${jsonToParams(filterValues)}`
    const response = await request.get<ResponsePattern<Solicitacao[]>>({ endpoint, params })

    handleResponse('getSolicitacoes', dispatch, response, requestGetSolicitacoesActions, options)
        .then(data => {
            dispatch(setSolicitacoes(data))
        })
}

export async function getSolicitacao(dispatch: DispatchType, idSolicitacao: string) {
    initRequest(dispatch, requestGetSolicitacaoActions, setSolicitacao)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/getone`
    const params = `/${idSolicitacao}`
    const response = await request.get<ResponsePattern<Solicitacao>>({ endpoint, params })

    handleResponse('getSolicitacao', dispatch, response, requestGetSolicitacaoActions, options)
        .then(data => {
            const rastreadorCavalo = (data.rastreadorPrincipal && data.idRastreadorPrincipal)
                ? `${data.rastreadorPrincipal} - ${data.idRastreadorPrincipal}`
                : '';
            const bloqueadorCavalo = (data.bloqueador && data.idBloqueador)
                ? `${data.bloqueador} - ${data.idBloqueador}`
                : '';

            dispatch(setSolicitacao({
                ...data,
                rastreadorCavalo,
                bloqueadorCavalo,
            }))
        })
}

export async function getSolicitacoesCte(dispatch: DispatchType, filterValues?: any) {
    initRequest(dispatch, requestGetSolicitacoesCteActions)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/solicitacao/listar`
    const params = `?${jsonToParams(filterValues)}`
    const response = await request.get<ResponsePattern<SolicitacaoCte[]>>({ endpoint, params })

    handleResponse('getSolicitacoesCte', dispatch, response, requestGetSolicitacoesCteActions, options)
        .then(data => {
            dispatch(setSolicitacoesCte(data))
        })
}