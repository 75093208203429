import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useFormikContext } from 'formik'
import { FormCurrencyProps } from '@components/Common/Form/types'
import createClassName from '@utils/createClassName'

const FormCurrency: React.FC <FormCurrencyProps> = ({ label, name, disabled, error, readonly, value }) => {

    const { setFieldValue } = useFormikContext<any>()

    const inputClasses = createClassName('formGroup', [error && 'error'])

    return(

        <div className = {inputClasses}>
            {!!label && <label htmlFor = "valorFrete">{label}</label>}
            <div className = "inputWrapper">  
                <CurrencyInput
                    id = {name}
                    name = {name}
                    disabled = {disabled}
                    readOnly = {readonly}
                    decimalsLimit = {2}
                    maxLength={11}
                    intlConfig = {{ locale: 'pt-BR', currency: 'BRL' }}
                    value = {value}
                    onValueChange = {(value, name) => {
                        setFieldValue(name!, value)
                    }}
                />
            </div>
        </div>

    )

}

export default FormCurrency