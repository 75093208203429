import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { mdiContentSave } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'
import FormSolicitacaoFields from '@modules/solicitacao/components/FormSolicitacaoFields'
import { updateConferencia } from '@modules/conferencia/controllers/conferenciaController'
import { formSolicitacaoSchema } from '@modules/solicitacao/components/FormSolicitacaoFields/config'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formConferenciaEditValues } from './config'

const FormConferenciaEdit: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { conferencia } = useAppSelector(s => s.conferencia)
    const { requestUpdateConferencia } = useAppSelector(s => s.requestConferencia)

    return(

        <>
            <Formik
                initialValues = {formConferenciaEditValues(conferencia!)}
                validationSchema = {formSolicitacaoSchema}
                onSubmit = {v => {
                    updateConferencia(dispatch, userData!, removePesquisaFields(v))
                }}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <FormSolicitacaoFields />

                            <div className = "formFooter">
                                <ButtonGroup>
                                    <Button
                                        icon = {mdiContentSave}
                                        loading = {requestUpdateConferencia.loading}
                                        label = "Salvar"
                                        status = "success"
                                        type = "submit"
                                    />
                                </ButtonGroup>
                            </div>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormConferenciaEdit