import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Tab from '@components/Common/Tab'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import Checkbox from '@components/Common/Checkbox'
import ButtonGroup from '@components/Common/Button/Group'
import TabSalarial from '../tabs/Salarial'
import TabDadosPessoais from '../tabs/DadosPessoais'
import TabDocumentacao1 from '../tabs/Documentacao1'
import TabDocumentacao2 from '../tabs/Documentacao2'

const FormMotorista: React.FC = () => {

    const tabs = [
        {title: 'Dados pessoais', content: <TabDadosPessoais />},
        {title: 'Documentação 1', content: <TabDocumentacao1 />},
        {title: 'Documentação 2', content: <TabDocumentacao2 />},
        {title: 'Salarial', content: <TabSalarial />},
    ]

    return(

        <>
            <Formik
                initialValues = {{}}
                // validationSchema = {{}}
                onSubmit = {() => {}}
            >
                {({ values, setFieldValue, handleChange }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Section>
                                <Form.Row columns = {2}>
                                    <Select
                                        label = "Empresa"
                                        fieldName = "nomeEmpresa"
                                        value = ""
                                        setFieldValue = {setFieldValue}
                                        items = {[]}
                                    />
                                    
                                    <Select
                                        label = "Filial"
                                        fieldName = "idFilial"
                                        value = ""
                                        setFieldValue = {setFieldValue}
                                        items = {[]}
                                    />
                                </Form.Row>

                                <Form.Row columns = {2} grid = "100px 1fr">
                                    <Form.Group
                                        label = "Código"
                                        inputID = "codigo"
                                        inputName = "codigo"
                                    />

                                    <Form.Group
                                        label = "Nome"
                                        inputID = "nome"
                                        inputName = "nome"
                                    />
                                </Form.Row>

                                <Form.Row columns = {2} grid = "100px 1fr">
                                    <Checkbox
                                        label = "Ativo"
                                        name = "flagAtivo"
                                    />

                                    <Select
                                        label = "Tipo"
                                        fieldName = "idTipo"
                                        value = ""
                                        setFieldValue = {setFieldValue}
                                        items = {[]}
                                    />
                                </Form.Row>
                            </Form.Section>

                            <Tab tabs = {tabs} />

                            <div className = "mt-[24px]" />

                            <ButtonGroup>
                                <Button
                                    icon = {mdiContentSave}
                                    label = "Salvar"
                                    status = "success"
                                    type = "submit"
                                />
                            </ButtonGroup>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormMotorista