import React from 'react'
import { ErrorMessage, Field } from 'formik'
import createClassName from '@utils/createClassName'
import { CheckboxProps } from './types'

const Checkbox: React.FC <CheckboxProps> = ({ label, name, error }) => {

    const inputClasses = createClassName(['formCheckbox', name], [error && 'error'])

    return(

        <>
            <div className = {inputClasses}>
                <label htmlFor = {name}>
                    <Field
                        type = "checkbox"
                        name = {name}
                    />

                    {label}
                </label>

                <ErrorMessage name = {name} className = "error" component = "h6" />
            </div>
        </>

    )

}

export default Checkbox