import ENV from "@env/index"
import { ModalProps } from "@interfaces/Modal"
import { UserData } from "@modules/auth/interfaces/UserData"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Operacao } from "../interfaces/Operacao"
import { formOperacaoValues } from "../pages/OperacaoLista/components/FormOperacaoFields/config"
import { setOperacao, setOperacoes } from "../reducers/operacaoReducer"
import { requestDeleteOperacaoActions, requestGetOperacaoActions, requestGetOperacoesActions, requestCreateOperacaoActions, requestUpdateOperacaoActions } from "../reducers/requestOperacaoReducer"

export async function getOperacoes(dispatch: DispatchType){
    initRequest(dispatch, requestGetOperacoesActions)

    const options = { messages: {errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/tipo-operacao`
    const response = await request.get<ResponsePattern<Operacao[]>>({ endpoint })

    handleResponse('getOperacoes', dispatch, response, requestGetOperacoesActions, options)
    .then(data => {
        dispatch(setOperacoes(data))
    })
}

export async function getOperacao(dispatch: DispatchType, idOperacao: number){
    initRequest(dispatch, requestGetOperacaoActions)

    const options = { messages: {errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/tipo-operacao/getone`
    const params = `/${idOperacao}`
    const response = await request.get<ResponsePattern<Operacao>>({ endpoint, params })

    handleResponse('getOperacao', dispatch, response, requestGetOperacaoActions, options)
    .then(data => {
        dispatch(setOperacao(data))
    })
}

export async function deleteOperacao(
    dispatch: DispatchType,
    user: UserData,
    operacao: Operacao | null,
    modal: ModalProps
){
    initRequest(dispatch, requestDeleteOperacaoActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/tipo-operacao`
    const body = {
        idTipoOperacao: operacao!.id,
        idUsuarioExclusao: user.id,
        motivoExclusao: '',
    }
    const response = await request.delete<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteOperacao', dispatch, response, requestDeleteOperacaoActions, options)
    .then(() => {
        getOperacoes(dispatch)

        modal.setOpen(false)
    })
}

export async function createOperacao(
    dispatch: DispatchType,
    user: UserData,
    values: typeof formOperacaoValues,
    modal: ModalProps
){
    initRequest(dispatch, requestCreateOperacaoActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/tipo-operacao`
    const body = {
        ...values,
        idUsuarioCadastro: user.id,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createOperacao', dispatch, response, requestCreateOperacaoActions, options)
    .then(() => {
        getOperacoes(dispatch)

        modal.setOpen(false)
    })
}

export async function updateOperacao(
    dispatch: DispatchType,
    user: UserData,
    values: typeof formOperacaoValues,
    modal: ModalProps
){
    initRequest(dispatch, requestUpdateOperacaoActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/tipo-operacao`
    const body = {
        ...values,
        idUsuarioAlteracao: user.id,
    }
    const response = await request.put<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateOperacao', dispatch, response, requestUpdateOperacaoActions, options)
    .then(() => {
        getOperacoes(dispatch)

        modal.setOpen(false)
    })
}