import { RouteType } from "@routes/interfaces"

const pernoiteRoutes: RouteType[] = [
    {
        key: 'pernoite',
        path: 'pernoite',
        title: 'Pernoite',
        userVerification: true,
        subRoutes: [
            { key: 'pernoites', path: '', title: 'Pernoites', userVerification: true },
            { key: 'pernoite', path: ':idViagem', title: 'Pernoite', userVerification: true },
        ]
    }
]

export default pernoiteRoutes