import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Form as FormikForm, Formik, useFormikContext } from 'formik'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import Form from '@components/Common/Form'
import { ModalProps } from '@interfaces/Modal'
import { formCreateCavaloSchema, formCreateCavaloValues } from '@modules/solicitacao/components/FormCreateCavaloFields/config'
import FormCreateCarretaFields from '@modules/solicitacao/components/FormCreateCarretaFields'
import { formSolicitacaoValues } from '@modules/solicitacao/components/FormSolicitacaoFields/config'

const ModalCreateCarreta: React.FC<ModalProps> = props => {

    const { values, setFieldValue } = useFormikContext<typeof formSolicitacaoValues>()

    return (

        <>
            <Modal
                {...props}
                title="Solicitar criação de carreta"
            >
                <Formik
                    initialValues={formCreateCavaloValues}
                    onSubmit={(v) => {
                        const { veiculoPlaca1, veiculoPlaca2, veiculoPlaca3 } = values

                        const veiculoPlacas = [veiculoPlaca1, veiculoPlaca2, veiculoPlaca3];


                        const indexCarretaVazia = veiculoPlacas.findIndex(placa => !placa);

                        if (indexCarretaVazia !== -1) {
                            setFieldValue(`veiculoPlaca${indexCarretaVazia + 1}`, v.placa.toUpperCase());
                        }

                        props.setOpen(false)
                    }}
                >
                    {({ handleChange }) => (
                        <FormikForm>
                            <Form.Container padding={false}>
                                <FormCreateCarretaFields handleChange={handleChange} />

                                <ModalActions>
                                    <Button
                                        type="reset"
                                        status="error"
                                        label="Cancelar"
                                        onClick={() => props.setOpen(false)}
                                    />
                                    <Button
                                        type="submit"
                                        icon={mdiContentSave}
                                        label="Salvar"
                                        status="success"
                                    />
                                </ModalActions>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </>

    )

}

export default ModalCreateCarreta