import React, { useState } from 'react'
import { Navigate } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import FormViagemFields from '@modules/viagem/components/DEPRACTED_FormViagemFields'
import { createViagem } from '@modules/viagem/controllers/viagemController'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'
import { viagemFormCreateValues } from '@modules/viagem/components/DEPRACTED_FormViagemFields/config'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const FormCreateViagem: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestCreateViagem } = useAppSelector(s => s.requestViagem)
    const [redirect, setRedirect] = useState(false)

    return(

        <>
            {redirect && <Navigate to = {`/viagem`} />}
            <Formik
                initialValues = {{
                    ...viagemFormCreateValues,
                    idUsuarioCadastro: userData!.id
                }}
                onSubmit = {v => {
                    createViagem(dispatch, removePesquisaFields(v), () => setRedirect(true))
                }}
            >
                {({ values, setFieldValue, handleChange }) => (
                    <FormikForm>
                        <Section>
                            <SectionBox>
                                <Form.Container padding = {false}>
                                    <FormViagemFields
                                        values = {values}
                                        showSectionRastreadores = {true}
                                        setFieldValue = {setFieldValue}
                                        handleChange = {handleChange}
                                        hideFields = {['gerenciadoraRastreio', 'idViagem']}
                                    />

                                    <ButtonGroup>
                                        <Button
                                            loading = {requestCreateViagem.loading}
                                            label = "Salvar"
                                            status = "success"
                                            type = "submit"
                                        />
                                    </ButtonGroup>
                                </Form.Container>
                            </SectionBox>
                        </Section>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormCreateViagem