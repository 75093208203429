import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Filial } from "../interfaces/Filial"

interface State {
    requestGetFilial: ResponseDefault<Filial>
    requestGetFiliais: ResponseDefault<Filial[]>
    requestCreateFilial: ResponseDefault<any>
    requestUpdateFilial: ResponseDefault<any>
    requestDeleteFilial: ResponseDefault<any>
}

const initialState: State = {
    requestGetFilial: responseInitialValues,
    requestGetFiliais: responseInitialValues,
    requestCreateFilial: responseInitialValues,
    requestUpdateFilial: responseInitialValues,
    requestDeleteFilial: responseInitialValues,
}

const requestFilialSlice = createSlice({
    name: 'requestFilial',
    initialState,
    reducers: {
        setRequestGetFilialData: (state, action: PayloadAction<ResponsePattern<Filial>>) => {
            state.requestGetFilial.data = action.payload
            state.requestGetFilial.loading = false
            state.requestGetFilial.error = false
        },
        setRequestGetFilialLoading: (state) => {
            state.requestGetFilial.loading = true
            state.requestGetFilial.error = false
        },
        setRequestGetFilialError: (state) => {
            state.requestGetFilial.loading = false
            state.requestGetFilial.error = true
        },
        setRequestGetFilialMessage: (state, action: PayloadAction<string>) => {
            state.requestGetFilial.message = action.payload
        },
        resetRequestGetFilial: (state) => {
            state.requestGetFilial = {...responseInitialValues}
        },

        setRequestGetFiliaisData: (state, action: PayloadAction<ResponsePattern<Filial[]>>) => {
            state.requestGetFiliais.data = action.payload
            state.requestGetFiliais.loading = false
            state.requestGetFiliais.error = false
        },
        setRequestGetFiliaisLoading: (state) => {
            state.requestGetFiliais.loading = true
            state.requestGetFiliais.error = false
        },
        setRequestGetFiliaisError: (state) => {
            state.requestGetFiliais.loading = false
            state.requestGetFiliais.error = true
        },
        setRequestGetFiliaisMessage: (state, action: PayloadAction<string>) => {
            state.requestGetFiliais.message = action.payload
        },
        resetRequestGetFiliais: (state) => {
            state.requestGetFiliais = {...responseInitialValues}
        },

        setRequestCreateFilialData: (state, action: PayloadAction<any>) => {
            state.requestCreateFilial.data = action.payload
            state.requestCreateFilial.loading = false
            state.requestCreateFilial.error = false
        },
        setRequestCreateFilialLoading: (state) => {
            state.requestCreateFilial.loading = true
            state.requestCreateFilial.error = false
        },
        setRequestCreateFilialError: (state) => {
            state.requestCreateFilial.loading = false
            state.requestCreateFilial.error = true
        },
        setRequestCreateFilialMessage: (state, action: PayloadAction<string>) => {
            state.requestCreateFilial.message = action.payload
        },
        resetRequestCreateFilial: (state) => {
            state.requestCreateFilial = {...responseInitialValues}
        },

        setRequestUpdateFilialData: (state, action: PayloadAction<any>) => {
            state.requestUpdateFilial.data = action.payload
            state.requestUpdateFilial.loading = false
            state.requestUpdateFilial.error = false
        },
        setRequestUpdateFilialLoading: (state) => {
            state.requestUpdateFilial.loading = true
            state.requestUpdateFilial.error = false
        },
        setRequestUpdateFilialError: (state) => {
            state.requestUpdateFilial.loading = false
            state.requestUpdateFilial.error = true
        },
        setRequestUpdateFilialMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateFilial.message = action.payload
        },
        resetRequestUpdateFilial: (state) => {
            state.requestUpdateFilial = {...responseInitialValues}
        },

        setRequestDeleteFilialData: (state, action: PayloadAction<any>) => {
            state.requestDeleteFilial.data = action.payload
            state.requestDeleteFilial.loading = false
            state.requestDeleteFilial.error = false
        },
        setRequestDeleteFilialLoading: (state) => {
            state.requestDeleteFilial.loading = true
            state.requestDeleteFilial.error = false
        },
        setRequestDeleteFilialError: (state) => {
            state.requestDeleteFilial.loading = false
            state.requestDeleteFilial.error = true
        },
        setRequestDeleteFilialMessage: (state, action: PayloadAction<string>) => {
            state.requestDeleteFilial.message = action.payload
        },
        resetRequestDeleteFilial: (state) => {
            state.requestDeleteFilial = {...responseInitialValues}
        },
    }
})

const actions = requestFilialSlice.actions

export const requestGetFilialActions = {
    data: actions.setRequestGetFilialData,
    loading: actions.setRequestGetFilialLoading,
    error: actions.setRequestGetFilialError,
    message: actions.setRequestGetFilialMessage,
    reset: actions.resetRequestGetFilial,
}

export const requestGetFiliaisActions = {
    data: actions.setRequestGetFiliaisData,
    loading: actions.setRequestGetFiliaisLoading,
    error: actions.setRequestGetFiliaisError,
    message: actions.setRequestGetFiliaisMessage,
    reset: actions.resetRequestGetFiliais,
}

export const requestCreateFilialActions = {
    data: actions.setRequestCreateFilialData,
    loading: actions.setRequestCreateFilialLoading,
    error: actions.setRequestCreateFilialError,
    message: actions.setRequestCreateFilialMessage,
    reset: actions.resetRequestCreateFilial,
}

export const requestUpdateFilialActions = {
    data: actions.setRequestUpdateFilialData,
    loading: actions.setRequestUpdateFilialLoading,
    error: actions.setRequestUpdateFilialError,
    message: actions.setRequestUpdateFilialMessage,
    reset: actions.resetRequestUpdateFilial,
}

export const requestDeleteFilialActions = {
    data: actions.setRequestDeleteFilialData,
    loading: actions.setRequestDeleteFilialLoading,
    error: actions.setRequestDeleteFilialError,
    message: actions.setRequestDeleteFilialMessage,
    reset: actions.resetRequestDeleteFilial,
}

export default requestFilialSlice.reducer