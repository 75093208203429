import React, { useEffect, useState } from 'react'
import { mdiPlus } from '@mdi/js'
import { useFormikContext } from 'formik'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { ModalProps } from '@interfaces/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatDocumento } from '@utils/format'
import FormSearchMotorista from '@modules/motorista/pages/MotoristaLista/components/FormSearchMotorista'
import { getMotoristas } from '@modules/motorista/controllers/motoristaController'

const TableMotorista: React.FC <ModalProps> = ({ setOpen }) => {

    const dispatch = useAppDispatch()
    const { motoristas } = useAppSelector(s => s.motorista)
    const { requestGetMotoristas } = useAppSelector(s => s.requestMotorista)

    const [tableConfig] = useState(createTableConfig(
        'searchMotorista',
        ["Documento", "Nome", ""]
    ))
    
    const { setFieldValue } = useFormikContext()

    const CR = conditionaRender(requestGetMotoristas, motoristas)

    useEffect(() => {
        getMotoristas(dispatch)
    }, [dispatch])

    return(

        <>
            <TablePaginator
                data = {motoristas}
                conditionalRender = {CR}
                config = {tableConfig}
                options = {
                    <>
                        <div />
                        <FormSearchMotorista />
                    </>
                }
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn width = {160}>{formatDocumento(item.nrDocumento)}</TableColumn>
                        <TableColumn>{item.nome}</TableColumn>
                        <TableColumn>
                            <TableOption
                                icon = {mdiPlus}
                                status = "success"
                                onClick = {() => {
                                    const { nrDocumento, nome, endereco, numero, bairro, cidade, uf, cep, telefone } = item

                                    setFieldValue('motoristaNome', nome)
                                    setFieldValue('motoristaDoc', nrDocumento)
                                    setFieldValue('motoristaEndereco', endereco)
                                    setFieldValue('motoristaNumero', numero)
                                    setFieldValue('motoristaBairro', bairro)
                                    setFieldValue('motoristaCidade', cidade)
                                    setFieldValue('motoristaUf', uf)
                                    setFieldValue('motoristaCep', cep)
                                    setFieldValue('motoristaTelefone', telefone)

                                    setOpen(false)
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />
        </>

    )

}

export default TableMotorista