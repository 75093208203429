import { requestGet, requestPost, requestDelete, requestPut, requestPatch } from "./methods"

const request = {
    get: requestGet,
    post: requestPost,
    delete: requestDelete,
    put: requestPut,
    patch: requestPatch,
}

export default request
export { requestGet, requestPost, requestDelete, requestPut, requestPatch }