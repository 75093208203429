import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { RomaneioSolicitacao } from "../interfaces/RomaneioSolicitacao"
import { Romaneio } from "../interfaces/Romaneio"

interface State {
    requestGetRomaneios: ResponseDefault<Romaneio[]>
    requestGetRomaneioSolicitacoes: ResponseDefault<RomaneioSolicitacao[]>
    requestCreateRomaneio: ResponseDefault<any>
}

const initialState: State = {
    requestGetRomaneios: responseInitialValues,
    requestGetRomaneioSolicitacoes: responseInitialValues,
    requestCreateRomaneio: responseInitialValues,
}

const requestRomaneioSlice = createSlice({
    name: 'requestRomaneio',
    initialState,
    reducers: {
        setRequestGetRomaneiosData: (state: State, action: PayloadAction<ResponsePattern<Romaneio[]>>) => {
            state.requestGetRomaneios.data = action.payload
            state.requestGetRomaneios.loading = false
            state.requestGetRomaneios.error = false
        },
        setRequestGetRomaneiosLoading: (state: State) => {
            state.requestGetRomaneios.loading = true
            state.requestGetRomaneios.error = false
        },
        setRequestGetRomaneiosError: (state: State) => {
            state.requestGetRomaneios.loading = false
            state.requestGetRomaneios.error = true
        },
        setRequestGetRomaneiosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetRomaneios.message = action.payload
        },
        resetRequestGetRomaneios: (state: State) => {
            state.requestGetRomaneios = {...responseInitialValues}
        },

        setRequestGetRomaneioSolicitacoesData: (state: State, action: PayloadAction<ResponsePattern<RomaneioSolicitacao[]>>) => {
            state.requestGetRomaneioSolicitacoes.data = action.payload
            state.requestGetRomaneioSolicitacoes.loading = false
            state.requestGetRomaneioSolicitacoes.error = false
        },
        setRequestGetRomaneioSolicitacoesLoading: (state: State) => {
            state.requestGetRomaneioSolicitacoes.loading = true
            state.requestGetRomaneioSolicitacoes.error = false
        },
        setRequestGetRomaneioSolicitacoesError: (state: State) => {
            state.requestGetRomaneioSolicitacoes.loading = false
            state.requestGetRomaneioSolicitacoes.error = true
        },
        setRequestGetRomaneioSolicitacoesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetRomaneioSolicitacoes.message = action.payload
        },
        resetRequestGetRomaneioSolicitacoes: (state: State) => {
            state.requestGetRomaneioSolicitacoes = {...responseInitialValues}
        },

        setRequestCreateRomaneioData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateRomaneio.data = action.payload
            state.requestCreateRomaneio.loading = false
            state.requestCreateRomaneio.error = false
        },
        setRequestCreateRomaneioLoading: (state: State) => {
            state.requestCreateRomaneio.loading = true
            state.requestCreateRomaneio.error = false
        },
        setRequestCreateRomaneioError: (state: State) => {
            state.requestCreateRomaneio.loading = false
            state.requestCreateRomaneio.error = true
        },
        setRequestCreateRomaneioMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateRomaneio.message = action.payload
        },
        resetRequestCreateRomaneio: (state: State) => {
            state.requestCreateRomaneio = {...responseInitialValues}
        },
    }
})

const { actions } = requestRomaneioSlice

export const requestGetRomaneiosActions = {
    data: actions.setRequestGetRomaneiosData,
    loading: actions.setRequestGetRomaneiosLoading,
    error: actions.setRequestGetRomaneiosError,
    message: actions.setRequestGetRomaneiosMessage,
    reset: actions.resetRequestGetRomaneios,
}

export const requestGetRomaneioSolicitacoesActions = {
    data: actions.setRequestGetRomaneioSolicitacoesData,
    loading: actions.setRequestGetRomaneioSolicitacoesLoading,
    error: actions.setRequestGetRomaneioSolicitacoesError,
    message: actions.setRequestGetRomaneioSolicitacoesMessage,
    reset: actions.resetRequestGetRomaneioSolicitacoes,
}

export const requestCreateRomaneioActions = {
    data: actions.setRequestCreateRomaneioData,
    loading: actions.setRequestCreateRomaneioLoading,
    error: actions.setRequestCreateRomaneioError,
    message: actions.setRequestCreateRomaneioMessage,
    reset: actions.resetRequestCreateRomaneio,
}

export default requestRomaneioSlice.reducer