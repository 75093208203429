import ENV from '@env/index'
import { ModalProps } from '@interfaces/Modal'
import { UserData } from '@modules/auth/interfaces/UserData'
import { getRomaneios } from '@modules/romaneio/controllers/romaneioController'
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { jsonToParams } from '@utils/jsonToParams'
import { ResponsePattern } from "@utils/response/types"
import { formatPunctuationFromCNPJ } from '@utils/format'
import { handleResponse, handleResponseError, initRequest, toResponsePattern } from "@utils/response"
import { setFiliais, setFilial } from '../reducers/filialReducer'
import { requestCreateFilialActions, requestDeleteFilialActions, requestGetFilialActions, requestGetFiliaisActions, requestUpdateFilialActions } from "../reducers/requestFilialReducer"
import { Filial } from '../interfaces/Filial'
import { formFilialValues } from '../components/FormFilialFields/config'
import FormFilialFields from '../components/FormFilialFields'
import { useFormikContext } from 'formik'

export async function getAllFiliais(dispatch: DispatchType, filterValues?: any) {
    initRequest(dispatch, requestGetFiliaisActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/listar-todas`
    const params = `/?${jsonToParams(filterValues)}`
    const response = await request.get<ResponsePattern<Filial[]>>({ endpoint, params })

    handleResponse('getAllFiliais', dispatch, response, requestGetFiliaisActions)
        .then(data => {
            dispatch(setFiliais(data))
        })
}

export async function getFiliais(dispatch: DispatchType) {
    initRequest(dispatch, requestGetFiliaisActions)

    const endpoint = `${ENV.APP_ENDPOINT}/filial`
    const response = await request.get<ResponsePattern<Filial[]>>({ endpoint })

    handleResponse('getFiliais', dispatch, response, requestGetFiliaisActions)
        .then(data => {
            dispatch(setFiliais(data))
        })
}

export async function getFilial(dispatch: DispatchType, idFilial: string) {
    initRequest(dispatch, requestGetFilialActions, setFilial)
    
    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/filial/getone`
    const params = `/${idFilial}`
    const response = await request.get<ResponsePattern<Filial>>({ endpoint, params })

    handleResponse('getFilial', dispatch, response, requestGetFilialActions, options)
    .then(data => {
        dispatch(setFilial(data))
    })
}

export async function createFilial(
    dispatch: DispatchType,
    user: UserData,
    values: typeof formFilialValues,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    initRequest(dispatch, requestCreateFilialActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/filial`
    const body = {
        descricao: values.apelidoFilial,
        nomeFantasia: values.nomeFilial,
        cep: values.cepFilial,
        endereco: values.enderecoFilial,
        numero: `${values.numeroFilial}`,
        bairro: values.bairroFilial,
        cidade: values.cidadeFilial,
        uf: values.ufFilial,
        idUsuarioCadastro: user.id,
        iss: values.issFilial,
        flagAtivo: true,
        nroDocumento: values.documentoFilial
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createFilial', dispatch, response, requestCreateFilialActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function updateFilial(
    dispatch: DispatchType,
    user: UserData,
    values: typeof formFilialValues,
    idFilial: string,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    initRequest(dispatch, requestUpdateFilialActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/filial`
    const body = {
        idFilial: Number(idFilial),
        descricao: values.apelidoFilial,
        nomeFantasia: values.nomeFilial,
        cep: values.cepFilial,
        endereco: values.enderecoFilial,
        numero: `${values.numeroFilial}`,
        bairro: values.bairroFilial,
        cidade: values.cidadeFilial,
        uf: values.ufFilial,
        idUsuarioAlteracao: user.id,
        iss: values.issFilial,
        flagAtivo: true,
        nroDocumento: values.documentoFilial
    }
    const response = await request.put<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateFilial', dispatch, response, requestUpdateFilialActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function deleteFilial(
    dispatch: DispatchType,
    user: UserData,
    filial: Filial,
    setOpenModal: Function
) {
    initRequest(dispatch, requestDeleteFilialActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/filial`
    const body = {
        idFilial: filial.id,
        idUsuarioExclusao: user.id
    }
    const response = await request.delete<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteFilial', dispatch, response, requestDeleteFilialActions, options)
        .then(() => {
            setOpenModal(false)
            getFiliais(dispatch)
        })
}
