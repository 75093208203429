import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import setLogin from '@modules/auth/controllers/authController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formLoginSchema, formLoginValues } from './config'

const FormLogin: React.FC = () => {

    const dispatch = useAppDispatch()
    const { routeIndex } = useAppSelector(s => s.auth)
    const { requestSendAuthLogin } = useAppSelector(s => s.requestAuth)
    const [redirect, setRedirect] = useState(false)

    return(

        <>
            {redirect && <Navigate to = {routeIndex} />}
            <Formik
                initialValues = {formLoginValues}
                validationSchema = {formLoginSchema}
                onSubmit = {v => setLogin(dispatch, setRedirect, v)}
            >
                {({ errors }) => (
                    <FormikForm style = {{display: 'flex', flexDirection: 'column'}}>
                        <Form.Container padding = {false}>
                            <div id = "formContainer" className = "light-mode">
                                <div className = "logoContainer">
                                    <span>Login</span>
                                </div>
                                <Form.Row columns = {1}>
                                    <Form.Group
                                        label = "Login"
                                        inputID = "login"
                                        inputName = "login"
                                        error = {!!errors.login}
                                    />
                                    <Form.Group
                                        label = "Senha"
                                        inputID = "senha"
                                        inputName = "senha"
                                        inputType = "password"
                                        error = {!!errors.senha}
                                    />
                                </Form.Row>
                                {/* <div>
                                    <Link to = "/solicitacao-acesso">Solicitar acesso</Link>
                                </div> */}
                                <Button
                                    label = "Entrar"
                                    type = "submit"
                                    loading = {requestSendAuthLogin.loading}
                                />
                            </div>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormLogin