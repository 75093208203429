import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { mdiCheckCircle, mdiPlusCircle } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { RomaneioSolicitacao } from '@modules/romaneio/interfaces/RomaneioSolicitacao'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatCurrency } from '@utils/format'
import FormSearch from '../FormSearch'
import { formRomaneioCreateValues } from '../FormCreate/config'
import { ModalSearchSolicitacaoProps } from '../ModalSolicitacoes/types'

const TableSolicitacao: React.FC<ModalSearchSolicitacaoProps> = ({ push, remove }) => {

    const { romaneioSolicitacoes } = useAppSelector(s => s.romaneio)
    const { requestGetRomaneioSolicitacoes } = useAppSelector(s => s.requestRomaneio)

    const [tableConfig] = useState(createTableConfig(
        'romaneioSolicitacoes',
        ['CTE', 'Tomador', 'Destinatário', 'Destino', 'Mercadoria', 'Frete', 'D. Entrega', 'D. Armazém', 'Peso', 'Adicionado']
    ))

    const { values, setFieldValue } = useFormikContext<typeof formRomaneioCreateValues>()

    const CR = conditionaRender(requestGetRomaneioSolicitacoes, romaneioSolicitacoes, true)

    const handleUpdateValues = (item: RomaneioSolicitacao) => {
        setFieldValue('valorMercadoria', Number(values.valorMercadoria) + item.valorMercadoria)
        setFieldValue('valorFreteTotal', Number(values.valorFreteTotal) + item.valorFreteCobrado)

        setFieldValue('destinatarioDoc', item.docDestinatario)
        setFieldValue('destinatarioNome', item.nmDestinatario)
        setFieldValue('destinatarioCidade', item.cidadeDestino)
        setFieldValue('destinatarioUf', item.ufDestino)
    }

    return (

        <>
            <TablePaginator
                options={<FormSearch />}
                data={romaneioSolicitacoes}
                config={tableConfig}
                conditionalRender={CR}
                renderItem={(item, index) => {
                    const added = values.solicitacoes.some(s => s.id === item.id)
                    return (
                        <TableRow key={index}>
                            <TableColumn>{item.nrCto}</TableColumn>
                            <TableColumn>{ }</TableColumn>
                            <TableColumn>{item.nmDestinatario}</TableColumn>
                            <TableColumn>{`${item.cidadeDestino} - ${item.ufDestino}`}</TableColumn>
                            <TableColumn>{formatCurrency(Number(item.valorMercadoria))}</TableColumn>
                            <TableColumn>{formatCurrency(Number(item.valorFreteCobrado))}</TableColumn>
                            <TableColumn>{item.diasParaEntrega}</TableColumn>
                            <TableColumn>{item.qtdeDiasArmazem}</TableColumn>
                            <TableColumn>{item.pesoMercadoria}</TableColumn>

                            <TableColumn>
                                <TableOption
                                    icon={added ? mdiCheckCircle : mdiPlusCircle}
                                    status={added ? 'success' : undefined}
                                    onClick={() => {
                                        if (!added) {
                                            push(item)
                                            handleUpdateValues(item)
                                        } else {
                                            const indexSolicitacao = values.solicitacoes.findIndex(s => s.nrCto === item.nrCto)

                                            remove(indexSolicitacao)

                                            setFieldValue('valorMercadoria', Number(values.valorMercadoria) - item.valorMercadoria)
                                            setFieldValue('valorFreteTotal', Number(values.valorFreteTotal) - item.valorFreteCobrado)
                                        }
                                    }}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />
        </>

    )

}

export default TableSolicitacao