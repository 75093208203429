import React, { useEffect } from 'react'
import ScrollReset from '@components/Base/ScrollReset'
import SnackbarAlert from '@components/Feedback/SnackbarAlert'
import { useAppSelector } from '@redux/hooks'
import RenderRoutePage from '@routes/components/RenderRoutePage'
import createClassName from '@utils/createClassName'
import Layout from '../Layout'
import './styles.scss'
import { PageRenderProps } from './types'

const PageRender: React.FC <PageRenderProps> = ({ children, pageKey, ...props }) => {

    const { sidebarOpen } = useAppSelector(s => s.app)

    const wrapperClasses = createClassName([props.type ?? 'default'], [sidebarOpen && 'sidebar-open'])

    useEffect(() => {
        document.title = props.title ?? 'Title Not Provided'
    }, [props.title, props.path])

    return(

        <>
            {(props.type === 'pdf' && <RenderRoutePage name = {pageKey!} />) || (
                <div id = "wrapper" className = {wrapperClasses}>
                    <ScrollReset />
                    
                    {(['center', 'error', 'form'].includes(props.type ?? 'default') && (
                        <Layout.Center name = {pageKey ?? ''}>
                            {children ?? <RenderRoutePage name = {pageKey!} />}
                        </Layout.Center>
                    )) || (
                        <Layout.Default name = {pageKey ?? ''}>
                            {children ?? <RenderRoutePage name = {pageKey!} />}
                        </Layout.Default>
                    )}

                    <SnackbarAlert />
                </div>
            )}
        </>

    )

}

export default React.memo(PageRender, (prevProps, nextProps) => {
    return (
        prevProps.path === nextProps.path &&
        prevProps.type === nextProps.type &&
        prevProps.userVerification === nextProps.userVerification &&
        prevProps.authVerification === nextProps.authVerification
    )
})