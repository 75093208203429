import React from 'react'
import SectionBox from '@components/Page/Section/box'
import SectionBoxData from '@components/Page/Section/data'
import FormVeiculo from './components/FormVeiculo'
import FormMotorista from './components/FormMotorista'

const TabGlobus: React.FC = () => {

    return(

        <>
            <SectionBox title = "Globus">
                <SectionBoxData title = "Motorista">
                    <FormMotorista />
                </SectionBoxData>
                
                <SectionBoxData title = "Veículo">
                    <FormVeiculo />
                </SectionBoxData>
            </SectionBox>
        </>

    )

}

export default TabGlobus