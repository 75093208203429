import { ViagemOne } from "@modules/viagem/interfaces/ViagemOne"

export const romaneioFormValues = (viagem: ViagemOne) => {
    return {
        id: viagem.id,

        idFilial: viagem.idFilial,
        idTipoOperacao: viagem.idTipoOperacao,

        remetenteDoc: viagem.remetenteDoc,
        remetenteNome: viagem.remetenteNome,
        remetenteCep: viagem.remetenteCep,
        remetenteEndereco: viagem.remetenteEndereco,
        remetenteNumero: viagem.remetenteNumero,
        remetenteComplemento: viagem.remetenteComplemento,
        remetenteBairro: viagem.remetenteBairro,
        remetenteCidade: viagem.remetenteCidade,
        remetenteUf: viagem.remetenteUf,
        remetenteResponsavel: viagem.remetenteResponsavel,
        remetenteTelefone: viagem.remetenteTelefone,

        motoristaNome: viagem.motoristaNome,
        motoristaDoc: viagem.motoristaDoc,
        motoristaCep: viagem.motoristaCep,
        motoristaEndereco: viagem.motoristaEndereco,
        motoristaNumero: viagem.motoristaNumero,
        motoristaComplemento: viagem.motoristaComplemento,
        motoristaBairro: viagem.motoristaBairro,
        motoristaCidade: viagem.motoristaCidade,
        motoristaUf: viagem.motoristaUf,
        motoristaTelefone: viagem.motoristaTelefone,

        idTipoVeiculo: viagem.idTipoVeiculo,
        veiculoPlaca: viagem.veiculoPlaca,
        veiculoPlaca2: viagem.veiculoPlaca2,
        veiculoPlaca3: viagem.veiculoPlaca3,

        valorMercadoria: viagem.valorMercadoria,
        valorFrete: viagem.valorFrete,

        obs: viagem.obs,

        idUsuarioCadastro: viagem.idUsuarioCadastro,
        idUsuarioAlteracao: viagem.idUsuarioCadastro,
    }
}