import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableFiliais from './components/TableFiliais'
import { getFiliais } from '@modules/filial/controllers/filialController'

const FilialLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { filiais } = useAppSelector(s => s.filial)

    useEffect(() => {
        getFiliais(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Filiais (${filiais?.length ?? 0})`} />
            <Section>
                <TableFiliais />
            </Section>
        </>

    )

}

export default FilialLista