import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Filial } from "../interfaces/Filial"

interface State {
    filiais: Filial[] | null
    filial: Filial | null

    currentFilial: Filial | null
}

const initialState: State = {
    filiais: null,
    filial: null,

    currentFilial: null
}

const filialSlice = createSlice({
    name: 'filial',
    initialState,
    reducers: {
        setFiliais: (state, action: PayloadAction<Filial[]>) => {
            state.filiais = action.payload
        },
        setFilial: (state, action: PayloadAction<Filial| null>) => {
            state.filial = action.payload
        },

        setCurrentFilial: (state, action: PayloadAction<Filial | null>) => {
            state.currentFilial = action.payload
        },
    }
})

export const { setFiliais, setFilial, setCurrentFilial } = filialSlice.actions
export default filialSlice.reducer