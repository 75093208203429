import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { jsonToParams } from "@utils/jsonToParams"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Romaneio } from "../interfaces/Romaneio"
import { RomaneioSolicitacao } from "../interfaces/RomaneioSolicitacao"
import { setRomaneioSolicitacoes, setRomaneios } from "../reducers/romaneioReducer"
import { requestCreateRomaneioActions, requestGetRomaneioSolicitacoesActions, requestGetRomaneiosActions } from "../reducers/requestRomaneioReducer"
import { formRomaneioCreateValues } from "../pages/RomaneioCriar/components/FormCreate/config"
import { UserData } from "@modules/auth/interfaces/UserData"
import { setAlert } from "@modules/app/reducers/alertReducer"
import createAlert from "@modules/app/scripts/alert/createAlert"

export async function getRomaneios(dispatch: DispatchType){
    initRequest(dispatch, requestGetRomaneiosActions)

    const endpoint = `${ENV.APP_ENDPOINT}/demanda/listar-todas`
    const params = `/?flagRomaneio=1`
    const response = await request.get<ResponsePattern<Romaneio[]>>({ endpoint, params })

    handleResponse('getRomaneios', dispatch, response, requestGetRomaneiosActions)
    .then(data => {
        dispatch(setRomaneios(data))
    })
}

export async function getRomaneioSolicitacoes(dispatch: DispatchType, searchromaneio?: any){
    initRequest(dispatch, requestGetRomaneioSolicitacoesActions)

    const endpoint = `${ENV.APP_ENDPOINT}/solicitacao/tms-listar`
    const body = {
        query: `&${jsonToParams(searchromaneio)}`
    }
    const response = await request.post<ResponsePattern<RomaneioSolicitacao[]>>({ endpoint, body })

    handleResponse('getRomaneioSolicitacoes', dispatch, response, requestGetRomaneioSolicitacoesActions)
    .then(data => {
        dispatch(setRomaneioSolicitacoes(data))
    })
}

export async function createRomaneio(
    dispatch: DispatchType,
    user: UserData,
    values: typeof formRomaneioCreateValues,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenModalParticularidades: React.Dispatch<React.SetStateAction<boolean>>
){    
    if (!values.idTipoOperacao) {
        return dispatch(setAlert(createAlert('warning', "Informe o tipo de operação")))
    }
    
    if (!values.idFilial) {
        return dispatch(setAlert(createAlert('warning', "Informe a filial")))
    }

    if (!values.solicitacoes[0]) {
        return dispatch(setAlert(createAlert('warning', "Informe no mínimo uma solicitação")))
    }

    if (!values.destinatarioDoc) {
        return dispatch(setAlert(createAlert('warning', "Informe o destinatário")))
    }

    if (values.particularidades) {
        return setOpenModalParticularidades(true)
    }

    initRequest(dispatch, requestCreateRomaneioActions)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/lista/salvar-romaneio`

    const [rastreadorPrincipal, idRastreadorPrincipal] = values.rastreadorCavalo.split(" - ")
    const [bloqueador, idBloqueador] = values.bloqueadorCavalo.split(" - ")

    const carretas = [values.veiculoPlaca1, values.veiculoPlaca2, values.veiculoPlaca3]
        .filter(placa => placa !== "")
        .sort();

    const body = {
        ...values,
        veiculoRastreadorCodigo: rastreadorPrincipal,
        idVeiculoRastreador: idRastreadorPrincipal,
        veiculoBloqueadorCodigo: bloqueador,
        idVeiculoBloqueador: idBloqueador,
        valorMercadoria: `${values.valorMercadoria}`,
        valorFrete: `${values.valorFrete}`,

        veiculoReboque1: carretas[0],
        veiculoReboque2: carretas[1],
        veiculoReboque3: carretas[2],

        idUsuario: user.id,
        flagCargaMista: false,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createRomaneio', dispatch, response, requestCreateRomaneioActions, options)
    .then(() => {
        setRedirect(true)
    })
}