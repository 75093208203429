import React from 'react'
import { mdiBed, mdiCheckCircle, mdiClockAlert, mdiMinusCircle, mdiShieldCheck, mdiTruckFast } from '@mdi/js'
import Container from '@components/Page/Container'
import SectionBox from '@components/Page/Section/box'
import TextContainer from '@components/Common/TextContainer'
import Loader, { LoaderContainer } from '@components/Feedback/Loader'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const ChartEmViagem: React.FC = () => {

    const { viagensGraficos } = useAppSelector(s => s.viagem)
    const { requestGetViagensAtivas } = useAppSelector(s => s.requestViagem)

    const CR = conditionaRender(requestGetViagensAtivas, viagensGraficos)

    return(

        <>
            {CR.LOADING && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            {CR.DATA && (
                <Container
                    width = "100%"
                    direction = "row"
                    justify = "between"
                    padding = {false}
                    gap = {48}
                >
                    <SectionBox title = "Viagens" padding = {false}>
                        <div style = {{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', gap: 32, padding: 10}}>
                            <TextContainer
                                icon = {mdiTruckFast}
                                title = {viagensGraficos!.viagens}
                                text = "Total"
                                className = "status info"
                                titleStyle = {{fontSize: 32}}
                                textStyle = {{fontSize: 18}}
                            />
                            <TextContainer
                                icon = {mdiShieldCheck}
                                title = {viagensGraficos!.escoltas}
                                text = "Escoltas"
                                className = "status success"
                                titleStyle = {{fontSize: 24}}
                                textStyle = {{fontSize: 18}}
                            />
                            <TextContainer
                                icon = {mdiMinusCircle}
                                title = {viagensGraficos!.bloqueadas}
                                text = "Bloqueados"
                                className = "status error"
                                titleStyle = {{fontSize: 24}}
                                textStyle = {{fontSize: 18}}
                            />
                        </div>
                    </SectionBox>

                    <SectionBox title = "Pernoites" padding = {false}>
                        <div style = {{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', gap: 32, padding: 10}}>
                            <TextContainer
                                icon = {mdiBed}
                                title = {viagensGraficos!.totalPernoites}
                                text = "Total"
                                className = "status info"
                                titleStyle = {{fontSize: 32}}
                                textStyle = {{fontSize: 18}}
                            />
                            <TextContainer
                                icon = {mdiCheckCircle}
                                title = {viagensGraficos!.totalPernoitesAceitas}
                                text = "Aceitas"
                                className = "status success"
                                titleStyle = {{fontSize: 24}}
                                textStyle = {{fontSize: 18}}
                            />
                            <TextContainer
                                icon = {mdiClockAlert}
                                title = {viagensGraficos!.totalPernoitesNaoAceitas}
                                text = "Pendentes"
                                className = "status warning"
                                titleStyle = {{fontSize: 24}}
                                textStyle = {{fontSize: 18}}
                            />
                        </div>
                    </SectionBox>
                </Container>
            )}
        </>

    )

}

export default ChartEmViagem