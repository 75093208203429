import React from 'react'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { useAppDispatch } from '@redux/hooks'
import { getMotoristas } from '@modules/motorista/controllers/motoristaController'

const FormSearchMotorista: React.FC = () => {

    const dispatch = useAppDispatch()

    return(

        <>
            <Formik
                initialValues = {{
                    valorBusca: '',
                }}
                onSubmit = {v => {
                    getMotoristas(dispatch, v.valorBusca)
                }}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {2} grid = "auto auto">
                                <Form.Group
                                    label = "Pesquisar"
                                    inputPlaceholder = "Digite placa ou nome do condutor"
                                    inputName = "valorBusca"
                                    inputID = "valorBusca"
                                />

                                <ButtonGroup>
                                    <Button
                                        icon = {mdiMagnify}
                                        status = "success"
                                        type = "submit"
                                    />
                                    <Button
                                        icon = {mdiClose}
                                        type = "reset"
                                        status = "error"
                                    />
                                </ButtonGroup>
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSearchMotorista