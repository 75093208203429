import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { Viagem } from '@modules/viagem/interfaces/Viagem'
import { formatCurrency } from '@utils/format'

const Column: React.FC <{width: string, text: string | number}> = ({ width, text }) => {

    const styles = StyleSheet.create({
        column: {
            width,
        },
        text: {
            fontSize: 6,
        }
    })

    return(

        <View style = {styles.column}>
            <Text style = {styles.text}>{text}</Text>
        </View>

    )

}

const PdfViagemProtocoloFooter: React.FC <Viagem> = data => {

    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingVertical: 4,
            borderBottom: "1px solid black",
        },
    })

    return(

        <View style = {styles.row}>
            <Column
                width = "10%"
                text = "Total geral"
            />
            <Column
                width = "56%"
                text = {`${data.solicitacoes.length} Processo(s)`}
            />
            <Column
                width = "10%"
                text = {data.solicitacoes.reduce((acc, cur) => acc + Number(cur.pesoMercadoria), 0)}
            />
            <Column
                width = "12%"
                text = {formatCurrency(data.solicitacoes.reduce((acc, cur) => acc + Number(cur.valorMercadoria), 0))}
            />
            <Column
                width = "12%"
                text = {formatCurrency(data.solicitacoes.reduce((acc, cur) => acc + Number(cur.valorFreteCobrado), 0))}
            />
        </View>

    )

}

export default PdfViagemProtocoloFooter