import React from 'react'
import { mdiDelete } from '@mdi/js'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import ModalMessage from '@components/Common/Modal/Message'
import { deleteSolicitacao } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { ModalDeleteSolicitacaoProps } from './types'

const ModalDeleteSolicitacao: React.FC <ModalDeleteSolicitacaoProps> = modalProps => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentSolicitacao } = useAppSelector(s => s.solicitacao)
    const { requestDeleteSolicitacao } = useAppSelector(s => s.requestSolicitacao)

    const { setOpen } = modalProps

    return(

        <Modal {...modalProps} title = "Deletar solicitação">
            <ModalMessage message = "Deseja deletar essa solicitacao?" />
            <ModalActions>
                <Button
                    label = "Cancelar"
                    onClick = {() => setOpen(false)}
                />
                <Button
                    icon = {mdiDelete}
                    label = "Deletar"
                    status = "error"
                    loading = {requestDeleteSolicitacao.loading}
                    onClick = {() => {
                        deleteSolicitacao(dispatch, userData!, currentSolicitacao!, setOpen)
                    }}
                />
            </ModalActions>
        </Modal>

    )

}

export default ModalDeleteSolicitacao