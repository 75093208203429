import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Form as FormikForm, Formik, useFormikContext } from 'formik'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import Form from '@components/Common/Form'
import { ModalProps } from '@interfaces/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formCreateCavaloValues } from '@modules/solicitacao/components/FormCreateCavaloFields/config'
import FormCreateCavaloFields from '@modules/solicitacao/components/FormCreateCavaloFields'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'

const ModalCreateCavalo: React.FC<ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    const { setFieldValue } = useFormikContext()

    return (

        <>
            <Modal
                {...props}
                title="Solicitar criação de cavalo"
            >
                <Formik
                    initialValues={formCreateCavaloValues}
                    onSubmit={(v) => {
                        if (!v.placa || v.placa.length !== 8) {
                            dispatch(setAlert(createAlert('warning', `A Placa é obrigatória!`)))
                            return
                        }

                        if (!v.rastreadorTipo) {
                            dispatch(setAlert(createAlert('warning', `O Tipo do Rastreador é obrigatório!`)))
                            return
                        }

                        if (!v.rastreadorID) {
                            dispatch(setAlert(createAlert('warning', `O ID do Rastreador é obrigatório!`)))
                            return
                        }

                        if (v.bloqueadorTipo && !v.bloqueadorID) {
                            dispatch(setAlert(createAlert('warning', `O ID do Bloqueador é obrigatório!`)))
                            return
                        }

                        if (!v.bloqueadorTipo && v.bloqueadorID) {
                            dispatch(setAlert(createAlert('warning', `O Tipo do Bloqueador é obrigatório!`)))
                            return
                        }

                        setFieldValue('veiculoPlaca', v.placa.toUpperCase())
                        setFieldValue('rastreadorCavalo', `${v.rastreadorTipo} - ${v.rastreadorID}`)

                        if (v.bloqueadorID && v.bloqueadorTipo) {
                            setFieldValue('bloqueadorCavalo', `${v.bloqueadorTipo} - ${v.bloqueadorID}`)
                        }

                        props.setOpen(false)
                    }}
                >
                    {({ setFieldValue, handleChange, values }) => (
                        <FormikForm>
                            <Form.Container padding={false}>
                                <FormCreateCavaloFields handleChange={handleChange} setFieldValue={setFieldValue} values={values} />

                                <ModalActions>
                                    <Button
                                        type="reset"
                                        status="error"
                                        label="Cancelar"
                                        onClick={() => props.setOpen(false)}
                                    />
                                    <Button
                                        type="submit"
                                        icon={mdiContentSave}
                                        label="Salvar"
                                        status="success"
                                    />
                                </ModalActions>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </>

    )

}

export default ModalCreateCavalo