import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Romaneio } from "../interfaces/Romaneio"
import { RomaneioSolicitacao } from "../interfaces/RomaneioSolicitacao"

interface State {
    romaneios: Romaneio[] | null
    romaneioSolicitacoes: RomaneioSolicitacao[] | null
}

const initialState: State = {
    romaneios: null,
    romaneioSolicitacoes: null,
}

const romaneioSlice = createSlice({
    name: 'romaneio',
    initialState,
    reducers: {
        setRomaneios: (state, action: PayloadAction<Romaneio[] | null>) => {
            state.romaneios = action.payload
        },

        setRomaneioSolicitacoes: (state, action: PayloadAction<RomaneioSolicitacao[] | null>) => {
            state.romaneioSolicitacoes = action.payload
        },
    }
})

export const { setRomaneios, setRomaneioSolicitacoes } = romaneioSlice.actions
export default romaneioSlice.reducer