import React, { useState } from 'react'
import { mdiContentSave } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'
import { Navigate, useParams } from 'react-router'
import { iniciateViagem } from '@modules/conferencia/controllers/conferenciaController'

const ModalIniciarViagem: React.FC<ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { requestUpdateConferenciaValidacao } = useAppSelector(s => s.requestConferencia)
    const [redirect, setRedirect] = useState(false)


    const { idConferencia } = useParams()


    return (

        <>
            {redirect && <Navigate to={`/conferencia`} />}

            <Modal
                {...props}
                title="Iniciar Viagem"
            >
                <Formik
                    initialValues={{
                        id: idConferencia,
                        ae: '',
                        sm: '',
                    }}
                    onSubmit={v => {
                        if (!v.ae) {
                            dispatch(setAlert(createAlert('warning', "Informe o Opentech AE")))
                            return
                        }
                        if (!v.sm) {
                            dispatch(setAlert(createAlert('warning', "Informe o Trafegus SM")))
                            return
                        }

                        iniciateViagem(dispatch, v, props.setOpen, setRedirect)
                    }}
                >
                    <FormikForm>
                        <Form.Container padding={false}>
                            <Form.Row columns={2}>
                                <Form.Group
                                    label="Opentech AE"
                                    inputID="ae"
                                    inputName="ae"
                                />
                                <Form.Group
                                    label="Trafegus SM"
                                    inputID="sm"
                                    inputName="sm"
                                />
                            </Form.Row>

                            <ModalActions>
                                <Button
                                    label="Fechar"
                                    onClick={() => props.setOpen(false)}
                                />
                                <Button
                                    icon={mdiContentSave}
                                    loading={requestUpdateConferenciaValidacao.loading}
                                    type="submit"
                                    label="Salvar"
                                    status="success"
                                />
                            </ModalActions>
                        </Form.Container>
                    </FormikForm>
                </Formik>
            </Modal>
        </>

    )

}

export default ModalIniciarViagem