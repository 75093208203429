import React, { useEffect, useState } from 'react'
import { mdiPlus } from '@mdi/js'
import { useFormikContext } from 'formik'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import setClienteValues from '@modules/viagem/utils/setClienteValues'
import getEnderecoSearchCliente from '@modules/viagem/utils/getEnderecoSearchCliente'
import { tableViagemSearhClientColumns } from '@modules/viagem/constants/tableSearch'
import { searchEmbarcador as requestSearch, searchCliente } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatDocumento } from '@utils/format'
import { ModalSearchClienteProps } from '../../../../interfaces/SearchClienteModal'
import FormSearchCliente from '../../FormSearchCliente'

const TableEmbarcador: React.FC<ModalSearchClienteProps> = ({ setOpen, type }) => {

    const dispatch = useAppDispatch()
    const { searchEmbarcador } = useAppSelector(s => s.viagem)
    const { requestSearchEmbarcador, requestSearchCliente } = useAppSelector(s => s.requestViagem)

    const [tableConfig] = useState(createTableConfig(
        'searchEmbarcador',
        tableViagemSearhClientColumns
    ))

    const { setFieldValue } = useFormikContext()

    useEffect(() => {
        requestSearch(dispatch, '', setFieldValue, false)
    }, [])

    return (

        <>
            <TablePaginator
                data={searchEmbarcador}
                loading={requestSearchEmbarcador.loading || requestSearchCliente.loading}
                config={tableConfig}
                options={
                    <>
                        <div />
                        <FormSearchCliente
                            type={type}
                            handleSearchSistema={v => {
                                requestSearch(dispatch, v, setFieldValue, false)
                            }}
                            handleSearchDocumento={v => {
                                searchCliente(dispatch, v, setFieldValue, false, 'remetente')
                            }}
                        />
                    </>
                }
                renderItem={(item, index) => (
                    <TableRow key={index}>
                        <TableColumn>{item.nome}</TableColumn>
                        <TableColumn width={160}>{formatDocumento(item.nroDocumento)}</TableColumn>
                        <TableColumn>{getEnderecoSearchCliente(item)}</TableColumn>
                        <TableColumn>
                            <TableOption
                                icon={mdiPlus}
                                status="success"
                                onClick={() => {
                                    setClienteValues('remetente', item, setFieldValue)

                                    setOpen(false)
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />
        </>

    )

}

export default TableEmbarcador