import { SelectItem } from "@components/Common/Select/types"

export const selectRastreador1: SelectItem[] = [
    { label: 'Onixsat', value: 'Onixsat' },
    { label: 'Sascar', value: 'Sascar' },
    { label: 'Autotrack', value: 'Autotrack' },
    { label: 'Ominilink', value: 'Ominilink' },
]

export const selectRastreador2: SelectItem[] = [
    { label: 'Nenhum', value: null },
    { label: 'T4S', value: 'T4S' },
    { label: '3S', value: '3S' },
]