import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { Solicitacao } from '@modules/viagem/interfaces/Viagem'

const Column: React.FC <{width: string, text: string | number}> = ({ width, text }) => {

    const styles = StyleSheet.create({
        column: {
            width,
        },
        text: {
            fontSize: 6,
        }
    })

    return(

        <View style = {styles.column}>
            <Text style = {styles.text}>{text}</Text>
        </View>

    )

}

const PdfViagemProtocoloItem: React.FC <Solicitacao> = data => {

    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingVertical: 4,
            borderBottom: "1px solid black",
        },
    })

    return(

        <View style = {styles.row}>
            <Column
                width = "10%"
                text = {data.nrNf}
            />
            <Column
                width = "23%"
                text = {data.nmEmbarcador}
            />
            <Column
                width = "23"
                text = {data.nmDestinatario}
            />
            <Column
                width = "10%"
                text = {data.nrCto}
            />
            <Column
                width = "10%"
                text = {data.pesoMercadoria}
            />
            <Column
                width = "12%"
                text = {data.valorMercadoria}
            />
            <Column
                width = "12%"
                text = {data.valorFreteCobrado}
            />
        </View>

    )

}

export default PdfViagemProtocoloItem