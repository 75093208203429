import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getConferencias } from '@modules/conferencia/controllers/conferenciaController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableConferencia from './components/TableConferencia'

const ConferenciaLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { conferencias } = useAppSelector(s => s.conferencia)

    useEffect(() => {
        getConferencias(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Conferências (${conferencias?.length ?? 0})`} />
            <Section>
                <TableConferencia />
            </Section>
        </>

    )

}

export default ConferenciaLista