import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getNotifications } from '@modules/notification/controllers/notificationController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableNotifications from './components/Table'

const Notifications: React.FC = () => {

    const dispatch = useAppDispatch()
    const { notifications } = useAppSelector(s => s.notification)

    useEffect(() => {
        getNotifications(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle title = {`Notificações (${notifications?.length ?? 0})`} />
            <Section>
                <TableNotifications />
            </Section>
        </>

    )

}

export default Notifications