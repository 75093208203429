export default function removePesquisaFields<T>(fields: T){
    const fieldsCopy: any = { ...fields }

    delete fieldsCopy.remententePesquisa
    delete fieldsCopy.destinatarioPesquisa
    delete fieldsCopy.motoristaPesquisa
    delete fieldsCopy.veiculoPesquisa
    delete fieldsCopy.pesquisaPassagemCidade
    delete fieldsCopy.pesquisaPassagemUf
    delete fieldsCopy.valorFreteTotal

    return fieldsCopy as T
}