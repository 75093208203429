import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Loader from '@components/Feedback/Loader/components/Loader'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getDocumento } from '@modules/documento/controllers/documentoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormEditDocumento from './components/FormEditDocumento'
import { getConferencia } from '@modules/conferencia/controllers/conferenciaController'

const DocumentoEditar: React.FC = () => {

    const dispatch = useAppDispatch()
    const { documento } = useAppSelector(s => s.documento)
    const { requestGetDocumento } = useAppSelector(s => s.requestDocumento)

    const { idDocumento } = useParams()

    const CR = conditionaRender(requestGetDocumento, documento)

    useEffect(() => {
        if(!!idDocumento){
            getDocumento(dispatch, idDocumento)
            getConferencia(dispatch, idDocumento)
        }
    }, [dispatch, idDocumento])
    
    return(

        <>
            <PageTitle title = "Editar Documentação" />
            {CR.LOADING && (
                <Section align = "center">
                    <Loader />
                </Section>
            )}
            {CR.DATA && (
                <Section>
                    <FormEditDocumento />
                </Section>
            )}
        </>

    )

}

export default DocumentoEditar