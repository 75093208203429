import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Cliente } from "../interfaces/Cliente"

interface State {
    clientes: Cliente[] | null
    cliente: Cliente | null

    currentCliente: Cliente | null
}

const initialState: State = {
    clientes: null,
    cliente: null,

    currentCliente: null
}

const clienteSlice = createSlice({
    name: 'cliente',
    initialState,
    reducers: {
        setClientes: (state, action: PayloadAction<Cliente[]>) => {
            state.clientes = action.payload
        },
        setCliente: (state, action: PayloadAction<Cliente | null>) => {
            state.cliente = action.payload
        },

        setCurrentCliente: (state, action: PayloadAction<Cliente | null>) => {
            state.currentCliente = action.payload
        },
    }
})

export const { setClientes, setCliente, setCurrentCliente } = clienteSlice.actions
export default clienteSlice.reducer