import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Loader from '@components/Feedback/Loader'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import SectionBox from '@components/Page/Section/box'
import { getViagem } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormRomaneio from './components/FormRomaneio'
import TableRomaneioSolicitacoes from './components/TableRomaneioSolicitacoes'
import './styles.scss'


const RomaneioEditar: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagem } = useAppSelector(s => s.viagem)
    const { requestGetViagem } = useAppSelector(s => s.requestViagem)

    const { idRomaneio } = useParams()

    const CR = conditionaRender(requestGetViagem, viagem)

    useEffect(() => {
        if (!!idRomaneio) {
            getViagem(dispatch, idRomaneio)
        }
    }, [dispatch, idRomaneio])

    return (

        <>
            <PageTitle title="Editar Romaneio" />
            {CR.LOADING && (
                <Section align="center">
                    <Loader />
                </Section>
            )}
            {CR.DATA && (
                <Section>
                    <FormRomaneio />
                </Section>
            )}
        </>

    )

}

export default RomaneioEditar