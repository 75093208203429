import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Form as FormikForm, Formik } from 'formik'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import Form from '@components/Common/Form'
import { ModalProps } from '@interfaces/Modal'
import { updateOperacao } from '@modules/operacao/controllers/operacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormOperacaoFields from '../FormOperacaoFields'
import { formOperacaoSchema } from '../FormOperacaoFields/config'

const ModalEditOperacao: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentOperacao } = useAppSelector(s => s.operacao)
    const { requestUpdateOperacao } = useAppSelector(s => s.requestOperacao)
    
    return(

        <>
            <Modal
                {...props}
                title = "Editar Tipo de Operação"
            >
                <Formik
                    initialValues = {{
                        idTipoOperacao: currentOperacao?.id ?? '',
                        codigoTipoOperacao: currentOperacao?.codigoTipoOperacao ?? '',
                        descricao: currentOperacao?.descricao ?? '',
                        flagAtivo: currentOperacao?.flagAtivo ?? true,
                    }}
                    validationSchema = {formOperacaoSchema}
                    onSubmit = {v => {
                        updateOperacao(dispatch, userData!, v, props)
                    }}
                >
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <FormOperacaoFields />

                            <ModalActions>
                                <Button
                                    type = "reset"
                                    status = "error"
                                    label = "Cancelar"
                                    onClick = {() => props.setOpen(false)}
                                />
                                <Button
                                    type = "submit"
                                    icon = {mdiContentSave}
                                    loading = {requestUpdateOperacao.loading}
                                    label = "Salvar"
                                    status = "success"
                                />
                            </ModalActions>
                        </Form.Container>
                    </FormikForm>
                </Formik>
            </Modal>
        </>

    )

}

export default ModalEditOperacao