import React, { useEffect, useState } from 'react'
import { FieldArray, Formik, Form as FormikForm } from 'formik'
import { mdiClose, mdiFlag, mdiPencil, mdiPlus } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import sleep from '@utils/sleep'
import { romaneioFormValues } from './config'
import ModalCavalo from '@modules/viagem/components/Search/SearchCavalo/Modal'
import ModalCreateCavalo from '@modules/solicitacao/pages/SolicitacaoCriar/components/ModalCreateCavalo'
import ModalCarreta from '@modules/viagem/components/Search/SearchCarreta/Modal'
import ModalCreateCarreta from '@modules/solicitacao/pages/SolicitacaoCriar/components/ModalCreateCarreta'
import ModalMotorista from '@modules/viagem/components/Search/SearchMotorista/Modal'
import ModalCreateMotorista from '@modules/solicitacao/pages/SolicitacaoCriar/components/ModalCreateMotorista'
import ModalSolicitacao from '@modules/romaneio/pages/RomaneioCriar/components/ModalSolicitacoes'
import SectionBox from '@components/Page/Section/box'
import { RomaneioSolicitacao } from '@modules/romaneio/interfaces/RomaneioSolicitacao'
import Select from '@components/Common/Select'
import createSelectItems from '@utils/createSelectItems'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'
import FormCurrency from '@components/Common/Form/components/currency'
import resetClienteValues from '@modules/viagem/utils/resetClienteValues'
import { Navigate, useLocation, useParams } from 'react-router'
import { createRomaneio } from '@modules/romaneio/controllers/romaneioController'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'
import { searchClienteSistema as requestSearch } from '@modules/viagem/controllers/viagemController'
import ModalConfirm from '@components/Feedback/ModalConfirm'
import { requestCreateRomaneioActions } from '@modules/romaneio/reducers/requestRomaneioReducer'
import { FormRomaneioProps } from './type'

const FormRomaneio: React.FC<FormRomaneioProps> = ({ readonly = false }) => {

    const dispatch = useAppDispatch()
    const { viagem } = useAppSelector(s => s.viagem)
    const [formValues, setFormValues] = useState<ReturnType<typeof romaneioFormValues>>()
    const { searchClienteSistema } = useAppSelector(s => s.viagem)
    const { requestCreateRomaneio } = useAppSelector(s => s.requestRomaneio)
    const [openModalParticularidades, setOpenModalParticularidades] = useState(false)

    const [openModalMotorista, setOpenModalMotorista] = useState(false)
    const [openModalCreateMotorista, setOpenModalCreateMotorista] = useState(false)

    const [openModalCavalo, setOpenModalCavalo] = useState(false)
    const [openModalCreateCavalo, setOpenModalCreateCavalo] = useState(false)

    const [openModalCarreta, setOpenModalCarreta] = useState(false)
    const [openModalCreateCarreta, setOpenModalCreateCarreta] = useState(false)

    const [solicitacaoDestinatario, setSolicitacaoDestinatario] = useState<RomaneioSolicitacao | null>()
    const [openModalSolcitacao, setOpenModalSolcitacao] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const { filiais, tiposOperacao } = useAppSelector(s => s.core)

    const { userData } = useAppSelector(s => s.auth)

    const { pathname } = useLocation()

    const module = pathname.split('/')[1]

    const handleSetDestinatario = (item: RomaneioSolicitacao | null, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        if (item) {
            setSolicitacaoDestinatario(item)
            setFieldValue('destinatarioDoc', item.docDestinatario)
            setFieldValue('destinatarioNome', item.nmDestinatario)
            setFieldValue('destinatarioCidade', item.cidadeDestino)
            setFieldValue('destinatarioUf', item.ufDestino)

            requestSearch(dispatch, item.docDestinatario, setFieldValue, false)

            if (!!searchClienteSistema && !!searchClienteSistema[0]) {
                setFieldValue('particularidades', searchClienteSistema[0].particularidades)
            }
        } else {
            setSolicitacaoDestinatario(null)
            resetClienteValues('destinatario', setFieldValue)
            setFieldValue('particularidades', '')
        }
    }

    useEffect(() => {
        (async () => {
            setFormValues(undefined)
            if (!!viagem) {
                await sleep(200)
                const romaneioValues = romaneioFormValues(viagem)

                setFormValues(romaneioValues)
                const initialSolicitacaoDestinatario = romaneioValues.solicitacoes.find(s => s.docDestinatario === romaneioValues.destinatarioDoc)

                if (initialSolicitacaoDestinatario) {
                    setSolicitacaoDestinatario(initialSolicitacaoDestinatario)
                }
            }
        })()
    }, [viagem])


    return (

        <>
            {redirect && <Navigate to={`/${module}`} />}

            {!!formValues && (
                <Formik
                    initialValues={formValues}
                    onSubmit={v => {
                        createRomaneio(dispatch, userData!, removePesquisaFields(v), setRedirect, setOpenModalParticularidades)
                    }}
                >
                    {({ values, setFieldValue }) => {
                        return (
                            <FormikForm>
                                <Form.Container padding={false}>
                                    <div className="formGrid">
                                        <SectionBox
                                            title="Geral"
                                        >
                                            <Form.Section>
                                                <Form.Row columns={module === 'viagem' ? 4 : 2}>
                                                    <Select
                                                        type="outline"
                                                        label="Tipo de operação"
                                                        fieldName="idTipoOperacao"
                                                        value={values.idTipoOperacao}
                                                        items={createSelectItems(tiposOperacao, 'descricao', 'id')}
                                                        setFieldValue={setFieldValue}
                                                        readonly={readonly}
                                                    />

                                                    <Select
                                                        type="outline"
                                                        label="Embarcador/Filial"
                                                        fieldName="idFilial"
                                                        value={values.idFilial}
                                                        items={createSelectItems(filiais, 'descricao', 'id')}
                                                        setFieldValue={setFieldValue}
                                                        readonly={readonly}
                                                    />

                                                    {
                                                        module === 'viagem' && (
                                                            <>
                                                                <Form.Group
                                                                    label="Opentech AE"
                                                                    inputID="AE"
                                                                    inputName="AE"
                                                                    readonly={readonly}
                                                                />
                                                                <Form.Group
                                                                    label="Trafegus SM"
                                                                    inputID="SM"
                                                                    inputName="SM"
                                                                    readonly={readonly}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                </Form.Row>

                                                <Form.Row columns={1}>
                                                    <Form.Group
                                                        label="Observações"
                                                        inputID="obs"
                                                        inputName="obs"
                                                        inputType="textarea"
                                                        inputRows={6}
                                                        readonly={readonly}
                                                    />
                                                </Form.Row>
                                            </Form.Section>
                                        </SectionBox>

                                        <SectionBox
                                            title="Motorista (Opcional)"
                                            right={
                                                <>
                                                    {!readonly && (
                                                        <>
                                                            <Button
                                                                label='Novo'
                                                                status="success"
                                                                onClick={() => {
                                                                    setOpenModalCreateMotorista(true)
                                                                }}
                                                            />
                                                            <Button
                                                                icon={mdiPlus}
                                                                status="success"
                                                                onClick={() => {
                                                                    setOpenModalMotorista(true)
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            }
                                        >
                                            <Form.Row columns={3}>
                                                <Form.Group
                                                    label="Documento"
                                                    inputID="motoristaDoc"
                                                    inputName="motoristaDoc"
                                                    readonly
                                                />
                                                <Form.Group
                                                    label="Nome"
                                                    inputID="motoristaNome"
                                                    inputName="motoristaNome"
                                                    readonly
                                                />
                                                <Form.Group
                                                    label="Contratante"
                                                    inputID="motoristaContratante"
                                                    inputName="motoristaContratante"
                                                    readonly
                                                />
                                            </Form.Row>
                                        </SectionBox>

                                        <SectionBox
                                            title="Cavalo (Opcional)"
                                            right={
                                                <>
                                                    {!readonly && (
                                                        <>
                                                            <Button
                                                                label='Novo'
                                                                status="success"
                                                                onClick={() => {
                                                                    setOpenModalCreateCavalo(true)
                                                                }}
                                                            />
                                                            <Button
                                                                icon={mdiPlus}
                                                                status="success"
                                                                onClick={() => {
                                                                    setOpenModalCavalo(true)
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            }
                                        >
                                            <Form.Row columns={3}>
                                                <Form.Group
                                                    label="Placa"
                                                    inputID="veiculoPlaca"
                                                    inputName="veiculoPlaca"
                                                    readonly
                                                />
                                                <Form.Group
                                                    label="Rastreador"
                                                    inputID="rastreadorCavalo"
                                                    inputName="rastreadorCavalo"
                                                    readonly
                                                />
                                                <Form.Group
                                                    label="Bloqueador"
                                                    inputID="bloqueadorCavalo"
                                                    inputName="bloqueadorCavalo"
                                                    readonly
                                                />
                                            </Form.Row>
                                        </SectionBox>
                                        <SectionBox
                                            title="Carretas (Opcional)"
                                            right={
                                                <>
                                                    {!readonly && (
                                                        <>
                                                            <Button
                                                                label='Nova'
                                                                status="success"
                                                                onClick={() => {
                                                                    if (!values.veiculoPlaca1 || !values.veiculoPlaca2 || !values.veiculoPlaca3) {
                                                                        return setOpenModalCreateCarreta(true)
                                                                    }

                                                                    dispatch(setAlert(createAlert('warning', "Só são permitidas três carratas")))
                                                                }}
                                                            />
                                                            <Button
                                                                icon={mdiPlus}
                                                                status="success"
                                                                onClick={() => {
                                                                    if (!values.veiculoPlaca1 || !values.veiculoPlaca2 || !values.veiculoPlaca3) {
                                                                        return setOpenModalCarreta(true)
                                                                    }

                                                                    dispatch(setAlert(createAlert('warning', "Só são permitidas três carratas")))
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            }
                                        >
                                            <Form.Row columns={3}>
                                                <Form.Group
                                                    label="Placa 1"
                                                    inputID="veiculoPlaca1"
                                                    inputName="veiculoPlaca1"
                                                    readonly
                                                    clearInputValue={(!readonly && values.veiculoPlaca1) ? setFieldValue : undefined}

                                                />

                                                <Form.Group
                                                    label="Placa 2"
                                                    inputID="veiculoPlaca2"
                                                    inputName="veiculoPlaca2"
                                                    readonly
                                                    clearInputValue={(!readonly && values.veiculoPlaca2) ? setFieldValue : undefined}
                                                />
                                                <Form.Group
                                                    label="Placa 3"
                                                    inputID="veiculoPlaca3"
                                                    inputName="veiculoPlaca3"
                                                    readonly
                                                    clearInputValue={(!readonly && values.veiculoPlaca3) ? setFieldValue : undefined}
                                                />
                                            </Form.Row>
                                        </SectionBox>

                                        <SectionBox
                                            title="Valores (Opcional)"
                                        >
                                            <Form.Row columns={3}>
                                                <Form.Currency
                                                    label="Valor mercadoria"
                                                    name="valorMercadoria"
                                                    value={values.valorMercadoria}
                                                    readonly
                                                />
                                                <Form.Currency
                                                    label="Valor frete"
                                                    name="valorFreteTotal"
                                                    value={values.valorFreteTotal}
                                                    readonly
                                                />
                                                <Form.Currency
                                                    label="Valor contrato"
                                                    name="valorFrete"
                                                    value={values.valorFrete}
                                                />
                                            </Form.Row>
                                        </SectionBox>

                                        <SectionBox
                                            title={`Solicitações Adicionadas (${values.solicitacoes.length})`}
                                            right={
                                                <>
                                                    {!readonly && (
                                                        <Button
                                                            icon={mdiPlus}
                                                            status="success"
                                                            onClick={() => {
                                                                setOpenModalSolcitacao(true)
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            }
                                        >
                                            <FieldArray name="solicitacoes">
                                                {({ push, remove }) => {

                                                    return (
                                                        <>
                                                            {values.solicitacoes.map((item, index) => {
                                                                const hasDestinatario = values.solicitacoes.some(s => s.nrCto === solicitacaoDestinatario?.nrCto)

                                                                if (!hasDestinatario) {
                                                                    handleSetDestinatario(item, setFieldValue)
                                                                }

                                                                const isDestinatario = solicitacaoDestinatario?.id === item.id

                                                                return (
                                                                    <Form.Row
                                                                        key={index}
                                                                        columns={8}
                                                                        grid="1fr 1fr 1fr 40px 1fr 1fr 1fr auto"
                                                                    >
                                                                        <Form.Group
                                                                            label="CTE"
                                                                            inputID={`solicitacoes.${index}.nrCto`}
                                                                            inputName={`solicitacoes.${index}.nrCto`}
                                                                            readonly
                                                                        />

                                                                        <Form.Group
                                                                            label="Tomador"
                                                                            inputID={`teste`}
                                                                            inputName={`teste`}
                                                                            readonly
                                                                        />

                                                                        <Form.Group
                                                                            label="Cidade"
                                                                            inputID={`solicitacoes.${index}.cidadeDestino`}
                                                                            inputName={`solicitacoes.${index}.cidadeDestino`}
                                                                            readonly
                                                                        />

                                                                        <Form.Group
                                                                            label="UF"
                                                                            inputID={`solicitacoes.${index}.ufDestino`}
                                                                            inputName={`solicitacoes.${index}.ufDestino`}
                                                                            readonly
                                                                        />

                                                                        <FormCurrency
                                                                            label="Mercadoria"
                                                                            name={`solicitacoes.${index}.valorMercadoria`}
                                                                            value={item.valorMercadoria}
                                                                            readonly
                                                                        />

                                                                        <FormCurrency
                                                                            label="Frete"
                                                                            name={`solicitacoes.${index}.valorFreteCobrado`}
                                                                            value={item.valorFreteCobrado}
                                                                            readonly
                                                                        />

                                                                        <Form.Group
                                                                            label="Peso"
                                                                            inputID={`solicitacoes.${index}.pesoMercadoria`}
                                                                            inputName={`solicitacoes.${index}.pesoMercadoria`}
                                                                            readonly
                                                                        />

                                                                        <ButtonGroup>
                                                                            <Button
                                                                                icon={mdiFlag}
                                                                                theme={isDestinatario ? 'default' : 'outline'}
                                                                                status={isDestinatario ? 'success' : undefined}
                                                                                onClick={() => {
                                                                                    if (readonly) return

                                                                                    !isDestinatario && handleSetDestinatario(item, setFieldValue)
                                                                                }}
                                                                            />

                                                                            {!readonly && (
                                                                                <Button
                                                                                    icon={mdiClose}
                                                                                    status="error"
                                                                                    onClick={() => {
                                                                                        setFieldValue('valorMercadoria', Number(values.valorMercadoria) - item.valorMercadoria)
                                                                                        setFieldValue('valorFreteTotal', Number(values.valorFreteTotal) - item.valorFreteCobrado)
                                                                                        remove(index)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </ButtonGroup>
                                                                    </Form.Row>
                                                                )
                                                            })}

                                                            <ModalSolicitacao
                                                                open={openModalSolcitacao}
                                                                setOpen={setOpenModalSolcitacao}
                                                                push={push}
                                                                remove={remove}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            </FieldArray>
                                        </SectionBox>
                                    </div>

                                    {!readonly && (
                                        <div className="formFooter">
                                            <Button
                                                type="submit"
                                                status="success"
                                                loading={requestCreateRomaneio.loading}
                                                label="Salvar Edição"
                                            />
                                        </div>
                                    )}
                                    <ModalConfirm
                                        title='O destinatário possui particularidades'
                                        message={values.particularidades}
                                        onConfirm={() => [
                                            createRomaneio(dispatch, userData!, removePesquisaFields({ ...values, particularidades: '' }), setRedirect, setOpenModalParticularidades)
                                        ]}
                                        open={openModalParticularidades}
                                        setOpen={setOpenModalParticularidades}
                                    />
                                </Form.Container>


                                <ModalCavalo
                                    open={openModalCavalo}
                                    setOpen={setOpenModalCavalo}
                                />
                                <ModalCreateCavalo
                                    open={openModalCreateCavalo}
                                    setOpen={setOpenModalCreateCavalo}
                                />

                                <ModalCarreta
                                    open={openModalCarreta}
                                    setOpen={setOpenModalCarreta}
                                />
                                <ModalCreateCarreta
                                    open={openModalCreateCarreta}
                                    setOpen={setOpenModalCreateCarreta}
                                />

                                <ModalMotorista
                                    open={openModalMotorista}
                                    setOpen={setOpenModalMotorista}
                                />
                                <ModalCreateMotorista
                                    open={openModalCreateMotorista}
                                    setOpen={setOpenModalCreateMotorista}
                                />
                            </FormikForm>
                        )
                    }}
                </Formik>
            )}
        </>

    )

}

export default FormRomaneio