import React from 'react'
import Modal from '@components/Common/Modal'
import { ModalProps } from '@interfaces/Modal'
import FormPernoiteParada from '../Form'

const ModalPernoiteParada: React.FC <ModalProps> = props => {

    return(

        <Modal title = "Parada" {...props}>
            <FormPernoiteParada {...props} />
        </Modal>

    )

}

export default ModalPernoiteParada