function createFieldKey(key: string, field: string) {
    return `${key}${field}`
}

export default function resetClienteValues(
    key: 'remetente' | 'destinatario' | 'tomador' | 'remetente' | 'cliente',
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
){
    ['Nome', 'Doc', 'Endereco', 'Numero', 'Complemento', 'Cep', 'Bairro', 'Cidade', 'Uf'].forEach(field => {
        setFieldValue(createFieldKey(key, field), '')
    })
}