import React from 'react'
import Form from '@components/Common/Form'
import Select from '@components/Common/Select'
import selectBoolean from '@constants/select/boolean'
import { useFormikContext } from 'formik'
import { formOperacaoValues } from './config'

const FormOperacaoFields: React.FC = () => {

    const { values, errors, setFieldValue } = useFormikContext<typeof formOperacaoValues>()

    return(

        <>
            <Form.Row columns = {1}>
                <Form.Group inputID = "codigoTipoOperacao" inputName = "codigoTipoOperacao" label = "ID" />
            </Form.Row>
            <Form.Row columns = {1}>
                <Form.Group inputID = "descricao" inputName = "descricao" label = "Tipo de Operação" />
            </Form.Row>
            <Form.Row columns = {1}>
                <Select
                    type = "outline"
                    label = "Ativo"
                    fieldName = "flagAtivo"
                    error = {!!errors.flagAtivo}
                    value = {values.flagAtivo}
                    setFieldValue = {setFieldValue}
                    items = {selectBoolean}
                />
            </Form.Row>
        </>

    )

}

export default FormOperacaoFields