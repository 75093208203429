import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { mdiCheckCircle, mdiCloseCircle, mdiPlusCircle, mdiText } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableBody, TableColumn, TableHeader, TableLoader, TableMessage, TableRow, TableWrapper, TableOption } from '@components/Common/Table'
import { getViagemValidacoes } from '@modules/viagem/controllers/viagemController'
import { setCurrentViagemValidacao } from '@modules/viagem/reducers/viagemReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatDate } from '@utils/format'
import conditionaRender from '@utils/conditionalRender'
import ModalValidacao from '../ModalValidacoes'
import { TableValidacoesProps } from './types'
import { ConferenciaValidacao } from '@modules/conferencia/interfaces/ConferenciaValidacao'

const TableValidacoes: React.FC<TableValidacoesProps> = ({ onlyView = false }) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { viagemValidacoes } = useAppSelector(s => s.viagem)
    const { requestGetViagemValidacoes } = useAppSelector(s => s.requestViagem)

    const [openModalValidacao, setOpenModalValidacao] = useState(false)
    const [tableConfig] = useState(createTableConfig(
        'viagemValidacoes',
        ["Tipo", "Usuário", "Validado em", ""]
    ))

    const { idViagem } = useParams()

    const CR = conditionaRender(requestGetViagemValidacoes, viagemValidacoes)

    useEffect(() => {
        if (!!idViagem) {
            getViagemValidacoes(dispatch, idViagem)
        }
    }, [dispatch, idViagem])

    let validacoesUnicas: ConferenciaValidacao[] = []

    return (

        <>
            <TableWrapper config={tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {CR.LOADING && <TableLoader />}
                        {CR.NODATA && <TableMessage text="Nenhuma validação encontrada!" />}
                        {CR.DATA && viagemValidacoes
                            ?.filter(item => {
                                if (validacoesUnicas.some(v => v.descricao.toLocaleLowerCase() === item.descricao.toLocaleLowerCase())) return false

                                validacoesUnicas.push(item)
                                return true
                            })
                            ?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableColumn>{item.descricao}</TableColumn>
                                    <TableColumn>{!!item.usuarioValidacao ? item.usuarioValidacao.nome : ''}</TableColumn>
                                    <TableColumn>{formatDate(item.dtValidado)}</TableColumn>
                                    <TableColumn width="80px">
                                        <TableOption
                                            icon={mdiText}
                                            onClick={() => {
                                                if (!onlyView) {
                                                    dispatch(setCurrentViagemValidacao(item))

                                                    setOpenModalValidacao(true)
                                                }
                                            }}
                                        />
                                        <TableOption
                                            icon={item.flagValidado ? mdiCheckCircle : onlyView ? mdiCloseCircle : mdiPlusCircle}
                                            status={item.flagValidado ? 'success' : undefined}
                                            onClick={() => {
                                                if (!onlyView) {

                                                }
                                            }}
                                        />
                                    </TableColumn>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableWrapper>

            <ModalValidacao
                open={openModalValidacao}
                setOpen={setOpenModalValidacao}
            />
        </>

    )

}

export default TableValidacoes