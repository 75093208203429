import { configureStore } from '@reduxjs/toolkit'
import appReducer from '@modules/app/reducers/appReducer'
import authReducer from '@modules/auth/reducers/authReducer'
import alertReducer from '@modules/app/reducers/alertReducer'
import themeReducer from '@modules/theme/reducers/themeReducer'
import romaneioReducer from '@modules/romaneio/reducers/romaneioReducer'
import dashboardReducer from '@modules/dashboard/reducers/dashboardReducer'
import requestAuthReducer from '@modules/auth/reducers/requestAuthReducer'
import conferenciaReducer from '@modules/conferencia/reducers/conferenciaReducer'
import romaneioCriarReducer from '@modules/romaneio/pages/RomaneioCriar/reducers/romaneioCriarReducer'
import requestRomaneioReducer from '@modules/romaneio/reducers/requestRomaneioReducer'
import requestViagemReducer from '@modules/viagem/reducers/requestViagemReducer'
import coreReducer from '@modules/core/reducers/coreReducer'
import viagemReducer from '@modules/viagem/reducers/viagemReducer'
import requestCoreReducer from '@modules/core/reducers/requestCoreReducer'
import requestDashboardReducer from '@modules/dashboard/reducers/requestDashboardReducer'
import requestConferenciaReducer from '@modules/conferencia/reducers/requestConferenciaReducer'
import acessoReducer from '@modules/acesso/reducers/acessoReducer'
import requestAcessoReducer from '@modules/acesso/reducers/requestAcessoReducer'
import acessoGrupoReducer from '@modules/acesso/reducers/grupo/acessoGrupoReducer'
import requestAcessoGrupoReducer from '@modules/acesso/reducers/grupo/requestAcessoGrupoReducer'
import usuarioReducer from '@modules/acesso/reducers/usuario/usuarioReducer'
import requestUsuarioReducer from '@modules/acesso/reducers/usuario/requestUsuarioReducer'
import acessoModuloReducer from '@modules/acesso/reducers/modulo/acessoModuloReducer'
import requestAcessoModuloReducer from '@modules/acesso/reducers/modulo/requestAcessoModuloReducer'
import moduloReducer from '@modules/modulos/reducers/modulo/moduloReducer'
import requestModuloReducer from '@modules/modulos/reducers/modulo/requestModuloReducer'
import menuReducer from '@modules/modulos/reducers/menu/menuReducer'
import requestMenuReducer from '@modules/modulos/reducers/menu/requestMenuReducer'
import pernoiteReducer from '@modules/pernoite/reducers/pernoiteReducer'
import requestPernoiteReducer from '@modules/pernoite/reducers/requestPernoiteReducer'
import notificationReducer from '@modules/notification/reducers/notificationReducer'
import requestNotificationReducer from '@modules/notification/reducers/requestNotificationReducer'
import documentoReducer from '@modules/documento/reducers/documentoReducer'
import requestDocumentoReducer from '@modules/documento/reducers/requestDocumentoReducer'
import solicitacaoReducer from '@modules/solicitacao/reducers/solicitacaoReducer'
import requestSolicitacaoReducer from '@modules/solicitacao/reducers/requestSolicitacaoReducer'
import clienteReducer from '@modules/cliente/reducers/clienteReducer'
import requestClienteReducer from '@modules/cliente/reducers/requestClienteReducer'
import requestFilialReducer from '@modules/filial/reducers/requestFilialReducer'
import filialReducer from '@modules/filial/reducers/filialReducer'
import operacaoReducer from '@modules/operacao/reducers/operacaoReducer'
import requestOperacaoReducer from '@modules/operacao/reducers/requestOperacaoReducer'
import motoristaReducer from '@modules/motorista/reducers/motoristaReducer'
import requestMotoristaReducer from '@modules/motorista/reducers/requestMotoristaReducer'
import veiculoReducer from '@modules/veiculo/reducers/veiculoReducer'
import requestVeiculoReducer from '@modules/veiculo/reducers/requestVeiculoReducer'

const store = configureStore({
    reducer: {
        app: appReducer,
        alert: alertReducer,
        theme: themeReducer,

        auth: authReducer,
        requestAuth: requestAuthReducer,

        core: coreReducer,
        requestCore: requestCoreReducer,

        notification: notificationReducer,
        requestNotification: requestNotificationReducer,

        dashboard: dashboardReducer,
        requestDashboard: requestDashboardReducer,

        conferencia: conferenciaReducer,
        requestConferencia: requestConferenciaReducer,

        romaneio: romaneioReducer,
        requestRomaneio: requestRomaneioReducer,

        romaneioCriar: romaneioCriarReducer,

        viagem: viagemReducer,
        requestViagem: requestViagemReducer,

        pernoite: pernoiteReducer,
        requestPernoite: requestPernoiteReducer,

        documento: documentoReducer,
        requestDocumento: requestDocumentoReducer,

        solicitacao: solicitacaoReducer,
        requestSolicitacao: requestSolicitacaoReducer,

        cliente: clienteReducer,
        requestCliente: requestClienteReducer,

        filial: filialReducer,
        requestFilial: requestFilialReducer,
        
        motorista: motoristaReducer,
        requestMotorista: requestMotoristaReducer,

        veiculo: veiculoReducer,
        requestVeiculo: requestVeiculoReducer,
      
        operacao: operacaoReducer,
        requestOperacao: requestOperacaoReducer,

        acesso: acessoReducer,
        requestAcesso: requestAcessoReducer,

        acessoGrupo: acessoGrupoReducer,
        requestAcessGrupo: requestAcessoGrupoReducer,

        acessoUsuario: usuarioReducer,
        requestAcessoUsuario: requestUsuarioReducer,

        acessoModulo: acessoModuloReducer,
        requestAcessoModulo: requestAcessoModuloReducer,

        modulo: moduloReducer,
        requestModulo: requestModuloReducer,

        menu: menuReducer,
        requestMenu: requestMenuReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store