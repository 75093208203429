import * as yup from 'yup'
import { FormCidade } from '@modules/solicitacao/interfaces/Cidade'
import { FormTomador } from '@modules/solicitacao/interfaces/Tomador'

export const formSolicitacaoValues = {
    veiculoPesquisa: '',

    idFilial: null,
    idTipoOperacao: null,
    flagEscolta: false,
    obs: '',
    
    remetenteDoc: '',
    remetenteNome: '',
    remetenteCidade: '',
    remetenteUf: '',

    destinatarioDoc: '',
    destinatarioNome: '',
    destinatarioCidade: '',
    destinatarioUf: '',

    dadosCidade: [] as FormCidade[],
    pesquisaPassagemCidade: '',
    pesquisaPassagemUf: '',

    dadosTomador: [] as FormTomador[],

    motoristaDoc: '',
    motoristaNome: '',
    motoristaContratante: '',

    veiculoPlaca:'',
    veiculoPlaca1:'',
    veiculoPlaca2:'',
    veiculoPlaca3:'',
    bloqueadorCavalo:'',
    rastreadorCavalo:'',

    valorMercadoria: '',
    valorFrete: '',
    particularidades: ''
}

export const formSolicitacaoSchema = yup.object().shape({
    idFilial: yup.number().typeError('Campo obrigatório'),
    idTipoOperacao: yup.number().typeError('Campo obrigatório'),
    flagEscolta: yup.boolean().required('Campo obrigatório'),
    obs: yup.string(),

    remetenteDoc: yup.string().required('Campo obrigatório'),
    remetenteNome: yup.string().required('Campo obrigatório'),
    remetenteCidade: yup.string().required('Campo obrigatório'),
    remetenteUf: yup.string().required('Campo obrigatório'),

    destinatarioDoc: yup.string().required('Campo obrigatório'),
    destinatarioNome: yup.string().required('Campo obrigatório'),
    destinatarioCidade: yup.string().required('Campo obrigatório'),
    destinatarioUf: yup.string().required('Campo obrigatório'),

    // dadosTomador: yup.array().min(1).of(yup.object().shape({
    //     idTipoTomador: yup.number().required('Campo obrigatório'),
    //     docTomador: yup.string().required('Campo obrigatório'),
    //     nomeTomador: yup.string().required('Campo obrigatório'),
    //     cidadeTomador: yup.string().required('Campo obrigatório'),
    //     ufTomador: yup.string().required('Campo obrigatório'),
    // })),

    motoristaDoc: yup.string(),
    motoristaNome: yup.string(),
    motoristaContratante: yup.string(),

    veiculoPlaca: yup.string(),
    veiculoPlaca1: yup.string(),
    veiculoPlaca2: yup.string(),
    veiculoPlaca3: yup.string(),
    bloqueadorCavalo: yup.string(),
    rastreadorCavalo: yup.string(),

    valorMercadoria: yup.string(),
    valorFrete: yup.string(),

    flagCargaMista: yup.boolean(),
})