import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Checkbox from '@components/Common/Checkbox'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import { maskPlaca } from '@constants/masks'

const TabOpen: React.FC = () => {

    return(

        <>
            <SectionBox title = "Open">
                <Formik
                    initialValues = {{}}
                    // validationSchema = {{}}
                    onSubmit = {() => {}}
                >
                    {({ values, setFieldValue, handleChange }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Section
                                    title = "Dados Pessoais"
                                >
                                    <Form.Row columns = {5}>
                                        <Form.Group
                                            label = "País origem doc."
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "CPF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Nome"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Nacionalidade"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Rádio"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                    <Form.Row columns = {8} grid = "140px 1fr 40px 140px 40px 140px 40px 1fr">
                                        <Form.Group
                                            label = "Data nacimento"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Cidade"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "DDD"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Tel. Fixo"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "DDD"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Tel. Celular"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "DDD"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Fax"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Documentos"
                                >
                                    <Form.Row columns = {6} grid = "1fr 1fr 140px 1fr 140px 1fr">
                                        <Form.Group
                                            label = "RG"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Orgão expedidor"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Data emissão"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Vínculo"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Data contratação"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Número da matricula"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                    <Form.Row columns = {2} grid = "1fr 1fr">
                                        <Form.Group
                                            label = "Profissão"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <li />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Carteira de Habilitação"
                                >
                                    <Form.Row columns = {6} grid = "1fr 1fr 60px 140px 140px 1fr">
                                        <Form.Group
                                            label = "Número documento"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Número registro"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "UF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Data emissão"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Data vencimento"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Categoria"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "MOPP - Movimentação Operacional de Produtos Perigosos"
                                >
                                    <Form.Row columns = {3} grid = "140px 1fr 1fr">
                                        <Form.Group
                                            label = "Data Vencimento"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Número Registro"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Número Registro"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Dados do Veículo"
                                >
                                    <Form.Row columns = {5}>
                                        <Form.Group
                                            label = "País de origem"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Placa"
                                            inputID = "teste"
                                            inputName = "teste"
                                            mask = {maskPlaca}
                                            handleChange = {handleChange}
                                        />
                                        <Form.Group
                                            label = "Frota"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Nº do chassi"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Nº do renavam"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                    <Form.Row columns = {5} >
                                        <Form.Group
                                            label = "Nº do certificado"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Marca"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Modelo"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Vínculo"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Tipo de veículo"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                    <Form.Row columns = {3} >
                                        <Form.Group
                                            label = "Cor predominante"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Tipo carroceria"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Combutível"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Licenciamento"
                                >
                                    <Form.Row columns = {5} grid = "140px 140px 140px 100px 100px">
                                        <Form.Group
                                            label = "Data emissão"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Data transferência"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Data do vencimento"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />
                                        <Form.Group
                                            label = "Ano modelo"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Ano fabricação"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Rastreador"
                                >
                                    <Form.Row columns = {4} >
                                        <Form.Group
                                            label = "Tecnologia autorizada p/ rastreador principal"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "ID rasterador principal"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Teconologia autorizada p/ rastreador secundário"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "ID rasterador secundário"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                    <Form.Row columns = {5} >
                                        <Form.Group
                                            label = "Tecnologia imobilizador"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "ID imobilizador"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Site"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Usuário"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                        <Form.Group
                                            label = "Senha"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Tipo de Rastreador 1"
                                >
                                    <Form.Row columns = {3} grid = "80px 80px 1fr">
                                        <Checkbox
                                            label = "Satelital"
                                            name = "satelital"
                                        />
                                        <Checkbox
                                            label = "GPRS"
                                            name = "gprs"
                                        />

                                        <li />
                                    </Form.Row>
                                </Form.Section>

                                <ButtonGroup>
                                    <Button
                                        label = "Salvar"
                                        status = "success"
                                        type = "submit"
                                    />
                                </ButtonGroup>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </SectionBox>
        </>

    )

}

export default TabOpen