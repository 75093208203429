import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getPernoites } from '@modules/pernoite/controllers/pernoiteController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TablePernoites from './components/TablePernoites'

const Pernoites: React.FC = () => {

    const dispatch = useAppDispatch()
    const { pernoites } = useAppSelector(s => s.pernoite)

    useEffect(() => {
        getPernoites(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle title = {`Pernoites (${pernoites?.length ?? 0})`} />
            <Section>
                <TablePernoites />
            </Section>
        </>

    )

}

export default Pernoites