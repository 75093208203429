import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RomaneioSolicitacao } from "@modules/romaneio/interfaces/RomaneioSolicitacao"

interface State {
    solicitacaoDestinatario: RomaneioSolicitacao | null
}

const initialState: State = {
    solicitacaoDestinatario: null
}

const romaneioCriarSlice = createSlice({
    name: 'romaneioCriar',
    initialState,
    reducers: {
        setRomaneioSolicitacaoDestinatario: (state, action: PayloadAction<RomaneioSolicitacao | null>) => {
            state.solicitacaoDestinatario = action.payload
        },
    }
})

export const { setRomaneioSolicitacaoDestinatario } = romaneioCriarSlice.actions
export default romaneioCriarSlice.reducer