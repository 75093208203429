import React from 'react'
import { mdiTableCog, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Select from '@components/Common/Select'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import Form, { FormGroup } from '@components/Common/Form'
import { getRomaneioSolicitacoes } from '@modules/romaneio/controllers/romaneioController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'
import { romaneioCriarFormSearchValues } from './config'

const FormSearch: React.FC = () => {

    const dispatch = useAppDispatch()
    const { filiais } = useAppSelector(s => s.core)

    return(

        <Formik
            initialValues = {romaneioCriarFormSearchValues}
            onSubmit = {v => {
                getRomaneioSolicitacoes(dispatch, v)
            }}
        >
            {({ values, setFieldValue }) => (
                <FormikForm>
                    <Form.Container padding = {false}>
                        <Form.Row columns = {5} grid = "repeat(5, auto) auto">
                            <FormGroup
                                label = "Embarcador"
                                inputID = "valorBuscaEmbarcador"
                                inputName = "valorBuscaEmbarcador"
                            />
                            <FormGroup
                                label = "Destinatário"
                                inputID = "valorBuscaDestinatario"
                                inputName = "valorBuscaDestinatario"
                            />
                            <FormGroup
                                label = "CTE"
                                inputID = "nrCto"
                                inputName = "nrCto"
                            />
                            <Select
                                type = "outline"
                                label = "Filial"
                                fieldName = "filialOrigem"
                                setFieldValue = {setFieldValue}
                                value = {values.filialOrigem}
                                items = {createSelectItems(filiais, 'descricao', 'id')}
                            />
                            <ButtonGroup>
                                <Button
                                    icon = {mdiMagnify}
                                    status = "success"
                                    type = "submit"
                                />
                            </ButtonGroup>
                        </Form.Row>
                    </Form.Container>
                </FormikForm>
            )}
        </Formik>

    )

}

export default FormSearch