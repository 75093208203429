import ENV from "@env/index"
import { ModalProps } from "@interfaces/Modal"
import { UserData } from "@modules/auth/interfaces/UserData"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Cliente } from "../interfaces/Cliente"
import { formClienteValues } from "../components/FormClienteFields/config"
import { formClienteEditValues } from "../pages/ClienteEditar/components/FormEdit/config"
import { setCliente, setClientes } from "../reducers/clienteReducer"
import { requestCreateClienteActions, requestDeleteClienteActions, requestGetClienteActions, requestGetClientesActions, requestUpdateClienteActions } from "../reducers/requestClienteReducer"

export async function getClientes(dispatch: DispatchType, search: string = '') {
    initRequest(dispatch, requestGetClientesActions)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/clientes/listar`
    const params = `?q=${search?.toUpperCase()}`
    const response = await request.get<ResponsePattern<Cliente[]>>({ endpoint, params })

    handleResponse('getClientes', dispatch, response, requestGetClientesActions, options)
        .then(data => {
            dispatch(setClientes(data))
        })
}

export async function getCliente(dispatch: DispatchType, idCliente: string) {
    initRequest(dispatch, requestGetClienteActions, setCliente)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/cliente`
    const params = `/${idCliente}`
    const response = await request.get<ResponsePattern<Cliente>>({ endpoint, params })

    handleResponse('getCliente', dispatch, response, requestGetClienteActions, options)
        .then(data => {
            dispatch(setCliente(data))
        })
}

export async function createCliente(
    dispatch: DispatchType,
    user: UserData,
    values: typeof formClienteValues,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    initRequest(dispatch, requestCreateClienteActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/clientes/salvar`
    const body = {
        nome: values.nomeCliente,
        valorPeso: values.valorTonelada,
        razaoSocial: values.razaoSocial,
        flagPagador: values.flagPagador,
        particularidades: values.particularidades,
        nroDocumento: values.documentoCliente,
        cidade: values.cidadeCliente,
        uf: values.estadoCliente,
        idUsuario: user.id,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createCliente', dispatch, response, requestCreateClienteActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function updateCliente(
    dispatch: DispatchType,
    user: UserData,
    values: ReturnType<typeof formClienteEditValues>,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    initRequest(dispatch, requestUpdateClienteActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/clientes/salvar`
    const body = {
        id: values.id,
        nome: values.nomeCliente,
        valorPeso: values.valorTonelada,
        razaoSocial: values.razaoSocial,
        flagPagador: values.flagPagador,
        particularidades: values.particularidades,
        nroDocumento: values.documentoCliente,
        cidade: values.cidadeCliente,
        uf: values.estadoCliente,
        idUsuario: user.id,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateCliente', dispatch, response, requestUpdateClienteActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function deleteCliente(
    dispatch: DispatchType,
    user: UserData,
    cliente: Cliente,
    modal: ModalProps
) {
    initRequest(dispatch, requestDeleteClienteActions)

    const options = { messages: { successMessage: {}, errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/clientes/deletar`
    const body = {
        id: cliente.id,
        idUsuario: user.id,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteCliente', dispatch, response, requestDeleteClienteActions, options)
        .then(() => {
            getClientes(dispatch)

            modal.setOpen(false)
        })
}