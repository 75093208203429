import React, { useState } from 'react'
import { mdiCheckCircle, mdiPlusCircle } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableBody, TableColumn, TableHeader, TableLoader, TableMessage, TableOption, TableRow, TableWrapper } from '@components/Common/Table'
import { setCurrentParada } from '@modules/viagem/reducers/viagemReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatDate } from '@utils/format'
import conditionaRender from '@utils/conditionalRender'
import ModalPernoiteParada from '../../PernoiteParada/Modal'

const TablePernoite: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagemPernoite } = useAppSelector(s => s.viagem)
    const { requestGetPernoite } = useAppSelector(s => s.requestViagem)

    const [openModalParada, setOpenModalParada] = useState(false)
    const [tableConfig] = useState(createTableConfig(
        'emviagemPernoite',
        ['Pernoite', 'KM', 'Distância', 'Status', 'Responsável', 'Data de Alteração', 'Opções']
    ))

    const CR_PERNOITE = conditionaRender(requestGetPernoite, viagemPernoite)

    return(

        <>
            <TableWrapper config = {tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {CR_PERNOITE.LOADING && <TableLoader />}
                        {CR_PERNOITE.NODATA && <TableMessage text = "Nenhuma pernoite encontrada!" />}
                        {CR_PERNOITE.DATA && viagemPernoite!.paradas.map((item, index) => (
                            <TableRow key = {index}>
                                <TableColumn>{item.descricao}</TableColumn>
                                <TableColumn>{item.km}</TableColumn>
                                <TableColumn>{item.distancia}</TableColumn>
                                <TableColumn>{item.status}</TableColumn>
                                <TableColumn>{item.responsavel}</TableColumn>
                                <TableColumn>{!!item.alteracao ? formatDate(item.alteracao) : ''}</TableColumn>
                                <TableColumn>
                                    <TableOption
                                        icon = {item.alteracao ? mdiCheckCircle : mdiPlusCircle}
                                        status = {item.flagPernoite ? 'success' : !!item.alteracao ? 'info' : undefined}
                                        onClick = {() => {
                                            dispatch(setCurrentParada(item))

                                            setOpenModalParada(true)
                                        }}
                                    />
                                </TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>

            <ModalPernoiteParada
                open = {openModalParada}
                setOpen = {setOpenModalParada}
            />
        </>

    )

}

export default TablePernoite