import React, { Fragment } from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import PageRender from '@components/Page/Render'
import Error from '@modules/error/pages/Error'
import errorRoutes from '@modules/error/routes'
import { useAppSelector } from '@redux/hooks'
import { routes } from './routes'
import ProtectedRoute from './components/ProtectedRoute'

const AppRoutes: React.FC = () => {

    const { routeIndex } = useAppSelector(s => s.auth)
    
    return(

        <BrowserRouter>
            <Routes>
                <Route index element = {<Navigate to = {routeIndex} />} />
                {errorRoutes.map((route, index) => (
                    <Route key = {index} path = {route.path}>
                        {route.subRoutes?.map((subRoute, subIndex) => (
                            <Route
                                key = {subIndex}
                                path = {subRoute.path}
                                element = {<Error idError = {subRoute.path} />}
                            />
                        ))}
                    </Route>
                ))}
                {routes.map((route, index) => (
                    <Fragment key = {index}>
                        {(route.subRoutes && (
                            <Route path = {route.path}>
                                {route.subRoutes.map((subRoute, subIndex) => (
                                    <Route key = {subIndex} path = {subRoute.path} element = {(() => {
                                        if(subRoute.userVerification) return <ProtectedRoute><PageRender {...subRoute} pageKey = {subRoute.key} /></ProtectedRoute>
                                        return <PageRender {...subRoute} pageKey = {subRoute.key} />
                                    })()} />
                                ))}
                            </Route>
                        )) || (
                            <Route path = {route.path} element = {(() => {
                                if(route.userVerification) return <ProtectedRoute><PageRender {...route} pageKey = {route.key} /></ProtectedRoute>
                                return <PageRender {...route} pageKey = {route.key} />
                            })()} />
                        )}
                    </Fragment>
                ))}
                <Route
                    path = "*"
                    element = {<Error idError = "http-404" />}
                />
            </Routes>
        </BrowserRouter>

    )

}

export default AppRoutes