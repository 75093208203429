import React, { useState } from 'react'
import { Navigate } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import { mdiContentSave } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import FormClienteFields from '@modules/cliente/components/FormClienteFields'
import { createCliente } from '@modules/cliente/controllers/clienteController'
import { formClienteSchema, formClienteValues } from '@modules/cliente/components/FormClienteFields/config'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const FormCreate: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestCreateCliente } = useAppSelector(s => s.requestCliente)

    const [redirect, setRedirect] = useState(false)

    return(

        <>
            {redirect && <Navigate to = "/cadastro/cliente" />}
            <Formik
                initialValues = {formClienteValues}
                validationSchema = {formClienteSchema}
                onSubmit = {v => {
                    createCliente(dispatch, userData!, v, setRedirect)
                }}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <FormClienteFields />

                            <div className = "formFooter">
                                <Button
                                    icon = {mdiContentSave}
                                    loading = {requestCreateCliente.loading}
                                    label = "Salvar"
                                    status = "success"
                                    type = "submit"
                                />
                            </div>
                        </Form.Container>
                    </FormikForm>
                
                )}
            </Formik>
        </>

    )

}

export default FormCreate