import React from "react"
import { FormFile } from "@components/Common/File/interfaces/File"
import ENV from "@env/index"
import { ModalProps } from "@interfaces/Modal"
import { UserData } from "@modules/auth/interfaces/UserData"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { jsonToParams } from "@utils/jsonToParams"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, handleResponseError, initRequest } from "@utils/response"
import { Documento } from "../interfaces/Documento"
import { DocumentoViagem } from "../interfaces/DocumentoViagem"
import { formEditDocumentoValues } from "../pages/DocumentoEditar/components/FormEditDocumento/config"
import { setDocumento, setDocumentosFinanceiro, setDocumentosExpedicao, setOpenModalStatus } from "../reducers/documentoReducer"
import { requestCreateDocumentoActions, requestDeleteDocumentoActions, requestGetDocumentoActions, requestGetDocumentosExpedicaoActions, requestGetDocumentosFinanceiroActions, requestSendDocumentoFileActions, requestUpdateDocumentoActions, requestUpdateDocumentoStatusActions } from "../reducers/requestDocumentoReducer"
import { Conferencia } from "@modules/conferencia/interfaces/Conferencia"

export async function getDocumentosFinanceiro(dispatch: DispatchType, searchValues?: any) {
    initRequest(dispatch, requestGetDocumentosFinanceiroActions)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem`
    const params = `?fullFilled=true&${jsonToParams(searchValues)}`
    const response = await request.get<ResponsePattern<Documento[]>>({ endpoint, params })

    handleResponse('getDocumentosFinanceiro', dispatch, response, requestGetDocumentosFinanceiroActions, options)
        .then(data => {
            dispatch(setDocumentosFinanceiro(data))
        })
}

export async function getDocumentosExpedicao(dispatch: DispatchType, searchValues?: any) {
    initRequest(dispatch, requestGetDocumentosExpedicaoActions)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem`
    const params = `?${jsonToParams(searchValues)}`
    const response = await request.get<ResponsePattern<Documento[]>>({ endpoint, params })

    handleResponse('getDocumentosExpedicao', dispatch, response, requestGetDocumentosExpedicaoActions, options)
        .then(data => {
            dispatch(setDocumentosExpedicao(data))
        })
}

async function handleSendFiles(
    dispatch: DispatchType,
    files: FormFile[],
    keys: DocumentoViagem[]
) {

    for (const file of files) {
        if (typeof file.file === 'string' && file.file.includes('http')) {
            keys.push({ number: file.id, url: (file as any).file })
        } else {
            if (!file.file) return
            
            const response = await sendDocumentoFile(dispatch, file.file as File)

            if (response) {
                keys.push({ number: file.id, url: response })
            }
        }
    }
}

export async function createDocumento(
    dispatch: DispatchType,
    user: UserData,
    values: Conferencia & {
        contrato: FormFile[];
        CTS: FormFile[];
        MDF: FormFile[];
    },
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    let fileError = false

    initRequest(dispatch, requestCreateDocumentoActions)

    const keysContrato: DocumentoViagem[] = []
    const keysMdfe: DocumentoViagem[] = []
    const keysCts: DocumentoViagem[] = []
    
    await Promise.all([
        handleSendFiles(dispatch, values.contrato, keysContrato),
        handleSendFiles(dispatch, values.MDF, keysMdfe),
        handleSendFiles(dispatch, values.CTS, keysCts),
    ]).catch(() => {
        dispatch(requestCreateDocumentoActions.error())
        fileError = true
    })

    if (fileError) return
    
    const options = { messages: { errorMessage: {}, successMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem`

    const body = {
        idFilial: values.filial.id,
        idLista: values.id,
        idResponsavel: user.id,
        contrato: keysContrato,
        MDF: keysMdfe,
        CTS: keysCts,
        documentoMotorista: values.motoristaDoc,
        nomeMotorista: values.motoristaNome,
        idDocumentacoesViagem: values.id
    }

    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createDocumento', dispatch, response, requestCreateDocumentoActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function updateDocumento(
    dispatch: DispatchType,
    user: UserData,
    values: ReturnType<typeof formEditDocumentoValues>,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    let fileError = false
    initRequest(dispatch, requestUpdateDocumentoActions)

    const keysContrato: DocumentoViagem[] = []
    const keysMdfe: DocumentoViagem[] = []
    const keysCts: DocumentoViagem[] = []

    await Promise.all([
        handleSendFiles(dispatch, values.contrato, keysContrato),
        handleSendFiles(dispatch, values.MDF, keysMdfe),
        handleSendFiles(dispatch, values.CTS, keysCts),
    ]).catch(() => {
        dispatch(requestUpdateDocumentoActions.error())
        fileError = true
    })

    if (fileError) return

    const options = { messages: { errorMessage: {}, successMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem`
    const body = {
        ...values,
        contrato: keysContrato,
        MDF: keysMdfe,
        CTS: keysCts,
        idUsuarioAlteracao: user.id,
    }
    const response = await request.put<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateDocumento', dispatch, response, requestUpdateDocumentoActions, options)
        .then(() => {
            setRedirect(true)
        })
}

export async function sendDocumentoFile(dispatch: DispatchType, file: File) {
    initRequest(dispatch, requestSendDocumentoFileActions)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem/upload`
    const formData = new FormData()

    formData.append('Documento', file)

    const response = await fetch(endpoint, { method: 'POST', body: formData })
    const data: ResponsePattern<any> = await response.json()

    handleResponse('sendDocumentoFile', dispatch, data, requestSendDocumentoFileActions, options)

    if (!!data && data.flagErro === false) {
        return data.listaResultados.filePath
    }

    return null
}

export async function updateDocumentoStatus(dispatch: DispatchType, documento: Documento, status: string) {
    initRequest(dispatch, requestUpdateDocumentoStatusActions)

    const options = { messages: { errorMessage: {}, successMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem`
    const body = {
        idDocumentacoesViagem: documento.id,
        status,
    }
    const response = await request.put<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateDocumentoStatus', dispatch, response, requestUpdateDocumentoStatusActions, options)
        .then(() => {
            dispatch(setOpenModalStatus(false))

            getDocumentosFinanceiro(dispatch)
        })
}

export async function deleteDocumento(dispatch: DispatchType, user: UserData, documento: Documento, modal: ModalProps) {
    initRequest(dispatch, requestDeleteDocumentoActions)

    const options = { messages: { errorMessage: {}, successMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem`
    const body = {
        idUsuarioExclusao: user.id,
        idDocumentacoesViagem: documento.id,
    }
    const response = await request.delete<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteDocumento', dispatch, response, requestDeleteDocumentoActions, options)
        .then(() => {
            modal.setOpen(false)

            getDocumentosFinanceiro(dispatch)
        })
}

export async function getDocumento(dispatch: DispatchType, id: string) {
    initRequest(dispatch, requestGetDocumentoActions, setDocumento)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem/lista`
    const params = `/${id}`
    const response = await request.get<ResponsePattern<Documento>>({ endpoint, params })

    handleResponse('getDocumento', dispatch, response, requestGetDocumentoActions, options)
        .then(data => {
            dispatch(setDocumento((data as any)[0] || {}))
        })
}