import { RoutePageType } from "@routes/interfaces"
import ClienteLista from "./pages/ClienteLista"
import ClienteCriar from "./pages/ClienteCriar"
import ClienteEditar from "./pages/ClienteEditar"

const clientePages: RoutePageType[] = [
    {key: 'cadastroClienteLista', element: ClienteLista},
    {key: 'cadastroClienteCriar', element: ClienteCriar},
    {key: 'cadastroClienteEditar', element: ClienteEditar},
]

export default clientePages