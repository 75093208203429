import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getViagensAplicativo } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch } from '@redux/hooks'
import TableViagemAplicativo from './components/TableViagemAplicativo'
import ChartViagemAplicativo from './components/ChartViagemAplicativo'

const ViagemAplicativoDashboard: React.FC = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        getViagensAplicativo(dispatch, new Date().toISOString().slice(0, 10))
    }, [dispatch])

    return(

        <>
            <PageTitle title = "Acompanhamento aplicativo" />
            <Section>
                <ChartViagemAplicativo />
            </Section>
            <Section>
                <TableViagemAplicativo />
            </Section>
        </>

    )

}

export default ViagemAplicativoDashboard