import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { handleResponse, initRequest } from "@utils/response"
import { ResponsePattern } from "@utils/response/types"
import { Notificacao } from "../interfaces/Notificacao"
import { setNotifications } from "../reducers/notificationReducer"
import { requestGetNotificationsActions } from "../reducers/requestNotificationReducer"

export async function getNotifications(dispatch: DispatchType){
    initRequest(dispatch, requestGetNotificationsActions)

    const endpoint = `${ENV.APP_ENDPOINT}/notificacoes/listar`
    const response = await request.get<ResponsePattern<Notificacao[]>>({ endpoint })

    handleResponse('getNotifications', dispatch, response, requestGetNotificationsActions)
    .then(data => {
        dispatch(setNotifications(data))
    })
}