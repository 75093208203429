//import MOCK_FORM_VIAGEM from "@mock/form/formViagem"

//export const viagemFormCreateValues = MOCK_FORM_VIAGEM

export const viagemFormCreateValues = {
    idFilial: null,
    idTipoOperacao: null,

    tomadorDoc: '',

    remententePesquisa: '',

    remetenteDoc: '',
    remetenteNome: '',
    remetenteCep: '',
    remetenteEndereco: '',
    remetenteNumero: '',
    remetenteComplemento: '',
    remetenteBairro: '',
    remetenteCidade: '',
    remetenteUf: '',
    remetenteResponsavel: '',
    remetenteTelefone: '',

    destinatarioPesquisa: '',

    destinatarioDoc: '',
    destinatarioNome: '',
    destinatarioCep: '',
    destinatarioEndereco: '',
    destinatarioNumero: '',
    destinatarioComplemento: '',
    destinatarioBairro: '',
    destinatarioCidade: '',
    destinatarioUf: '',
    destinatarioResponsavel: '',
    destinatarioTelefone: '',

    motoristaPesquisa: '',

    motoristaNome: '',
    motoristaDoc: '',
    motoristaCep: '',
    motoristaEndereco: '',
    motoristaNumero: '',
    motoristaComplemento: '',
    motoristaBairro: '',
    motoristaCidade: '',
    motoristaUf: '',
    motoristaTelefone: '',

    veiculoPesquisa: '',

    idTipoVeiculo: null,
    veiculoPlaca: '',
    veiculoPlaca2: '',
    veiculoPlaca3: '',

    valorMercadoria: 0,
    valorFrete: 0,

    obs: '',

    rastreadorPrincipal: '',
    idRasteadorPrincipal: '',
    bloqueador: '',
    idBloqueador: '',
}

export const remententeFields = ['remetenteNome', 'remetenteDoc', 'remetenteCep', 'remetenteEndereco', 'remetenteNumero', 'remetenteComplemento', 'remetenteBairro', 'remetenteCidade', 'remetenteUf', 'remetenteTelefone']

export const destinatarioFields = ['destinatarioNome', 'destinatarioDoc', 'destinatarioCep', 'destinatarioEndereco', 'destinatarioNumero', 'destinatarioComplemento', 'destinatarioBairro', 'destinatarioCidade', 'destinatarioUf', 'destinatarioTelefone']

export const motoristaFields = ['motoristaNome', 'motoristaDoc', 'motoristaCep', 'motoristaEndereco', 'motoristaNumero', 'motoristaBairro', 'motoristaCidade', 'motoristaUf', 'motoristaTelefone']