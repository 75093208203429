import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableDocumentos from './components/TableDocumentos'
import { getConferencias } from '@modules/conferencia/controllers/conferenciaController'

const DocumentosListaExpedicao: React.FC = () => {

    const dispatch = useAppDispatch()
    const { conferencias } = useAppSelector(s => s.conferencia)

    useEffect(() => {
        getConferencias(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Documentações (${conferencias?.length ?? 0})`} />
            <Section>
                <TableDocumentos />
            </Section>
        </>

    )

}

export default DocumentosListaExpedicao