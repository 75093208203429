import React from 'react'
import { mdiFileUpload, mdiMagnify, mdiSend, mdiSync } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Checkbox from '@components/Common/Checkbox'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import RadioButton from '@components/Common/RadioButton'
import RadioButtonGroup from '@components/Common/RadioButton/RadioButtonGroup'

const TabGuep: React.FC = () => {

    return(

        <>
            <SectionBox title = "GUEP">
                <Formik
                    initialValues = {{}}
                    // validationSchema = {{}}
                    onSubmit = {() => {}}
                >
                    {({ values, setFieldValue, handleChange }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Section>
                                    <Form.Row columns = {4} grid = "100px auto 1fr 60px 1fr">
                                        <Form.Group
                                            label = "Placa"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <ButtonGroup>
                                            <Button
                                                icon = {mdiSync}
                                                label = "Verifcar"
                                            />
                                            <Button
                                                icon = {mdiMagnify}
                                                label = "Buscar Consulta"
                                            />
                                        </ButtonGroup>

                                        <Form.Group
                                            label = "Renavam"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "UF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <li />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Dados do proprietário"
                                >
                                    <Form.Row columns = {3} grid = "120px 200px 1fr">
                                        <RadioButtonGroup
                                            label = "Tipo"
                                        >
                                            <RadioButton
                                                label = "PF"
                                                name = "tipoPessoa"
                                                value = "pf"
                                            />

                                            <RadioButton
                                                label = "PJ"
                                                name = "tipoPessoa"
                                                value = "pj"
                                            />
                                        </RadioButtonGroup>

                                        <Form.Group
                                            label = "CPF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "Nome"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />
                                    </Form.Row>

                                    <Form.Row columns = {2} grid = "400px 1fr">
                                        <Checkbox
                                            label = "Solicitação de análise de veículo na ANTT"
                                            name = "antt"
                                        />

                                        <li />
                                    </Form.Row>
                                    <Form.Row columns = {3} grid = "120px 200px 1fr">
                                        <RadioButtonGroup
                                            label = "Tipo de proprietário"
                                        >
                                            <RadioButton
                                                label = "PF"
                                                name = "tipoProprietario"
                                                value = "pf"
                                            />

                                            <RadioButton
                                                label = "PJ"
                                                name = "tipoProprietario"
                                                value = "pj"
                                            />
                                        </RadioButtonGroup>

                                        <Form.Group
                                            label = "CPF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <li />
                                    </Form.Row>

                                    <Form.Row columns = {3} grid = "auto 1fr">
                                        <ButtonGroup>
                                            <Button
                                                icon = {mdiFileUpload}
                                                label = "Selecionar arquivos"
                                            />
                                            <Button
                                                icon = {mdiSend}
                                                label = "Enviar"
                                                status = "success"
                                            />
                                        </ButtonGroup>

                                        <li />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Dados do condutor"
                                >
                                    <Form.Row columns = {2} grid = "auto 1fr">
                                        <Checkbox
                                            label = "Consultar fonte creditícia"
                                            name = "check"
                                        />

                                        <li />
                                    </Form.Row>

                                    <Form.Row columns = {3} grid = "200px auto 1fr">
                                        <Form.Group
                                            label = "CPF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <ButtonGroup>
                                            <Button
                                                icon = {mdiSync}
                                                label = "Verifcar"
                                            />
                                            <Button
                                                icon = {mdiMagnify}
                                                label = "Buscar Consulta"
                                            />
                                        </ButtonGroup>

                                        <li />
                                    </Form.Row>
                                    <Form.Row columns = {4} grid = "1fr auto 140px 1fr">
                                        <Form.Group
                                            label = "Nome"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <RadioButtonGroup
                                            label = "Sexo"
                                        >
                                            <RadioButton
                                                label = "Masculino"
                                                name = "sexo"
                                                value = "masculino"
                                            />

                                            <RadioButton
                                                label = "Feminino"
                                                name = "sexo"
                                                value = "feminino"
                                            />
                                        </RadioButtonGroup>

                                        <Form.Group
                                            label = "Data de nascimento"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />

                                        <li />
                                    </Form.Row>
                                    <Form.Row columns = {3} >
                                        <Form.Group
                                            label = "Nome da mãe"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "Nome do pai"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <li />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Carteira de identidade (RG)"
                                >
                                    <Form.Row columns = {4} grid = "1fr 60px 140px 1fr">
                                        <Form.Group
                                            label = "RG"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "UF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "Data de emissão"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />

                                        <li />
                                    </Form.Row>
                                </Form.Section>

                                <Form.Section
                                    title = "Carteira nacional de habilitação (CNH)"
                                >
                                    <Form.Row columns = {5} grid = "1fr 60px 140px 140px 1fr">
                                        <Form.Group
                                            label = "CNH"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "UF"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "Data da 1ª habilitação"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />

                                        <Form.Group
                                            label = "Data de validade"
                                            inputID = "teste"
                                            inputName = "teste"
                                            inputType = "date"
                                        />

                                        <li />
                                    </Form.Row>
                                    <Form.Row columns = {3} >
                                        <Form.Group
                                            label = "Código de segurança"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <Form.Group
                                            label = "Categoria"
                                            inputID = "teste"
                                            inputName = "teste"
                                        />

                                        <li />
                                    </Form.Row>

                                    <Form.Row columns = {3} grid = "auto 1fr">
                                        <ButtonGroup>
                                            <Button
                                                icon = {mdiFileUpload}
                                                label = "Selecionar arquivos"
                                            />
                                            <Button
                                                icon = {mdiSend}
                                                label = "Enviar"
                                                status = "success"
                                            />
                                        </ButtonGroup>

                                        <li />
                                    </Form.Row>
                                </Form.Section>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </SectionBox>
        </>

    )

}

export default TabGuep