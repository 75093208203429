import { ViagemAplicativo } from "@modules/viagem/interfaces/ViagemAplicativo"
import { ViagemAplicativoMotorista } from "@modules/viagem/interfaces/ViagemAplicativoMotorista"

export function createChartViagemAplicativo(viagensAplicativo: ViagemAplicativo[]){
    const totalAberto = viagensAplicativo.map(driver => driver.totalEmAberto).reduce((acc, current) => acc + current)
    const totalInsucesso = viagensAplicativo.map(driver => driver.totalInsucesso).reduce((acc, current) => acc + current)
    const totalSucesso = viagensAplicativo.map(driver => driver.totalSucesso).reduce((acc, current) => acc + current)
    const total = totalAberto + totalInsucesso + totalSucesso
    
    const percentAberto = ((totalAberto * 100) / total).toFixed(1)
    const percentInsucesso = ((totalInsucesso * 100) / total).toFixed(1)
    const percentSucesso = ((totalSucesso * 100) / total).toFixed(1)

    return {
        totalViagens: total.toString(),
        totalViagensAtiva: `${percentAberto}% (${totalAberto})`,
        totalViagensSucesso: `${percentSucesso}% (${totalSucesso})`,
        totalViagensOcorrencia: `${percentInsucesso}% (${totalInsucesso})`,
    }
}

export function createChartViagemMotorista(viagemMotorista: ViagemAplicativoMotorista){
    const totalAberto = viagemMotorista.destinatarios.filter(dest => dest.notas.filter(note => note.situacao === 'Em aberto').length > 0).length
    const totalInsucesso = viagemMotorista.destinatarios.filter(dest => dest.notas.filter(note => note.situacao === 'Ocorrencia').length > 0).length
    const totalSucesso = viagemMotorista.destinatarios.filter(dest => dest.notas.filter(note => note.situacao === 'Sucesso').length > 0).length
    const total = totalAberto + totalInsucesso + totalSucesso
    
    const percentAberto = ((totalAberto * 100) / total).toFixed(1)
    const percentInsucesso = ((totalInsucesso * 100) / total).toFixed(1)
    const percentSucesso = ((totalSucesso * 100) / total).toFixed(1)

    return {
        totalViagens: total.toString(),
        totalViagensAtiva: `${percentAberto}% (${totalAberto})`,
        totalViagensSucesso: `${percentSucesso}% (${totalSucesso})`,
        totalViagensOcorrencia: `${percentInsucesso}% (${totalInsucesso})`,
    }
}