import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Veiculo } from "../interfaces/Veiculo"

interface State {
    veiculos: Veiculo[] | null
    veiculo: Veiculo | null

    currentVeiculo: Veiculo | null
}

const initialState: State = {
    veiculos: null,
    veiculo: null,

    currentVeiculo: null
}

const veiculoSlice = createSlice({
    name: 'veiculo',
    initialState,
    reducers: {
        setVeiculos: (state, action: PayloadAction<Veiculo[]>) => {
            state.veiculos = action.payload
        },
        setVeiculo: (state, action: PayloadAction<Veiculo| null>) => {
            state.veiculo = action.payload
        },

        setCurrentVeiculo: (state, action: PayloadAction<Veiculo | null>) => {
            state.currentVeiculo = action.payload
        },
    }
})

export const { setVeiculos, setVeiculo, setCurrentVeiculo } = veiculoSlice.actions
export default veiculoSlice.reducer