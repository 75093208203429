import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getClientes } from '@modules/cliente/controllers/clienteController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableClientes from './components/TableClientes'

const ClienteLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { clientes } = useAppSelector(s => s.cliente)

    useEffect(() => {
        getClientes(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Clientes (${clientes?.length ?? 0})`} />
            <Section>
                <TableClientes />
            </Section>
        </>

    )

}

export default ClienteLista