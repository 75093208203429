import { SearchCliente } from "@modules/viagem/interfaces/SearchCliente"

function createFieldKey(key: string, field: string) {
    return `${key}${field}`
}

export default function setClienteValues(
    key: 'remetente' | 'destinatario' | 'tomador' | 'remetente' | 'cliente',
    values: SearchCliente,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
){
    const { nroDocumento, nome, cidade, uf } = values

    setFieldValue(createFieldKey(key, 'Nome'), nome)
    setFieldValue(createFieldKey(key, 'Doc'), nroDocumento)
    setFieldValue(createFieldKey(key, 'Cidade'), cidade)
    setFieldValue(createFieldKey(key, 'Uf'), uf)
}