import React, { useEffect, useState } from 'react'
import { mdiCheckCircle, mdiPlus, mdiPlusCircle } from '@mdi/js'
import { useFormikContext } from 'formik'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import getEnderecoSearchCliente from '@modules/viagem/utils/getEnderecoSearchCliente'
import { tableViagemSearhClientColumns } from '@modules/viagem/constants/tableSearch'
import { searchTomador as requestSearch, searchCliente } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatDocumento } from '@utils/format'
import FormSearchCliente from '../../FormSearchCliente'
import { ModalSearchTomadorProps } from '../types'
import { formSolicitacaoValues } from '@modules/solicitacao/components/FormSolicitacaoFields/config'

const TableTomador: React.FC<ModalSearchTomadorProps> = ({ remove, push }) => {

    const dispatch = useAppDispatch()
    const { searchTomador } = useAppSelector(s => s.viagem)
    const { requestSearchTomador, requestSearchCliente } = useAppSelector(s => s.requestViagem)

    const [tableConfig] = useState(createTableConfig(
        'searchTomador',
        tableViagemSearhClientColumns
    ))

    const { values, setFieldValue } = useFormikContext<typeof formSolicitacaoValues>()


    useEffect(() => {
        requestSearch(dispatch, '', setFieldValue, false)
    }, [])

    return (

        <>
            <TablePaginator
                data={searchTomador}
                loading={requestSearchTomador.loading || requestSearchCliente.loading}
                config={tableConfig}
                options={
                    <>
                        <div />
                        <FormSearchCliente
                            handleSearchSistema={v => {
                                requestSearch(dispatch, v, setFieldValue, false)
                            }}
                            handleSearchDocumento={v => {
                                searchCliente(dispatch, v, setFieldValue, false, 'tomador')
                            }}
                        />
                    </>
                }
                renderItem={(item, index) => {
                    const { nroDocumento, nome, cidade, uf } = item

                    const { dadosTomador } = values
                    const added = dadosTomador.some(p => p.documentoTomador === nroDocumento)

                    return (
                        <TableRow key={index}>
                            <TableColumn>{nome}</TableColumn>
                            <TableColumn width={160}>{formatDocumento(nroDocumento)}</TableColumn>
                            <TableColumn>{getEnderecoSearchCliente(item)}</TableColumn>
                            <TableColumn>
                                <TableOption
                                    icon={added ? mdiCheckCircle : mdiPlusCircle}
                                    status={added ? 'success' : undefined}
                                    onClick={() => {
                                        if (!added) {
                                            push({
                                                documentoTomador: nroDocumento,
                                                nomeTomador: nome,
                                                cidadeTomador: cidade,
                                                estadoTomador: uf,
                                            })
                                        } else {
                                            const indexTomador = dadosTomador.findIndex(tomadador => tomadador.documentoTomador === nroDocumento);

                                            remove(indexTomador)
                                        }
                                    }}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />
        </>

    )

}

export default TableTomador