import { Navigate } from "react-router-dom"
import { useAppSelector } from "@redux/hooks"

const ProtectedRoute: React.FC<any> = ({ children }) => {
    const { userData, loading } = useAppSelector(s => s.auth)
    
    if(!loading){
        if(!!userData){
            //const userRoutes = authRoutes.map(modulo => modulo.rotas.map(rota => `${modulo.modulo.path}${rota.path ?? ''}`))

            return children
        }
        return <Navigate to = "/login" />
    }
    return null
}

export default ProtectedRoute