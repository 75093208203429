
import DocumentoEditar from "@modules/documento/pages/DocumentoEditar"
import DocumentosListaExpedicao from "@modules/documento/pages/DocumentosListaExpedicao"
import { RoutePageType } from "@routes/interfaces"
import ConferenciaLista from "./pages/ConferenciaLista"
import ConferenciaEditar from "./pages/ConferenciaEditar"
import ConferenciaValidar from "./pages/ConferenciaValidar"
import RomaneioEditar from "@modules/romaneio/pages/RomaneioEditar"
import SolicitacaoEditar from "@modules/solicitacao/pages/SolicitacaoEditar"

const conferenciaPages: RoutePageType[] = [
    {key: 'conferenciaLista', element: ConferenciaLista},
    {key: 'conferenciaEditar', element: ConferenciaEditar},
    {key: 'conferenciaValidar', element: ConferenciaValidar},
    
    {key: 'conferenciaDocumentacaoExpedicao', element: DocumentosListaExpedicao},
    {key: 'conferenciaEditarDocumentacao', element: DocumentoEditar},

    { key: 'solicitacaoRomaneioEditar', element: RomaneioEditar },
    {key: 'solicitacaoEditar', element: SolicitacaoEditar},
]

export default conferenciaPages