import React, { useState } from 'react'
import { mdiPlus } from '@mdi/js'
import { useFormikContext } from 'formik'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import setClienteValues from '@modules/viagem/utils/setClienteValues'
import getEnderecoSearchCliente from '@modules/viagem/utils/getEnderecoSearchCliente'
import { tableViagemSearhClientColumns } from '@modules/viagem/constants/tableSearch'
import { searchClienteSistema as requestSearch, searchCliente } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatDocumento } from '@utils/format'
import { ModalSearchClienteProps } from '../../../../interfaces/SearchClienteModal'
import FormSearchCliente from '../../FormSearchCliente'

const TableCliente: React.FC <ModalSearchClienteProps> = ({ setOpen, type }) => {

    const dispatch = useAppDispatch()
    const { searchClienteSistema} = useAppSelector(s => s.viagem)
    const { requestSearchClienteSistema, requestSearchCliente } = useAppSelector(s => s.requestViagem)

    const [tableConfig] = useState(createTableConfig(
        'searchCliente',
        tableViagemSearhClientColumns
    ))
    
    const { setFieldValue } = useFormikContext()

    return(

        <>
            <TablePaginator
                data = {searchClienteSistema}
                loading = {requestSearchCliente.loading || requestSearchClienteSistema.loading}
                config = {tableConfig}
                options = {
                    <>
                        <div />
                        <FormSearchCliente
                            type={type}
                            handleSearchSistema = {v => {
                                requestSearch(dispatch, v, setFieldValue, false)
                            }}
                            handleSearchDocumento = {v => {
                                searchCliente(dispatch, v, setFieldValue, false, 'cliente')
                            }}
                        />
                    </>
                }
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.nome}</TableColumn>
                        <TableColumn width = {160}>{formatDocumento(item.nroDocumento)}</TableColumn>
                        <TableColumn>{getEnderecoSearchCliente(item)}</TableColumn>
                        <TableColumn>
                            <TableOption
                                icon = {mdiPlus}
                                status = "success"
                                onClick = {() => {
                                    setFieldValue('documentoCliente', item.nroDocumento)
                                    setFieldValue('nomeCliente', item.nome)
                                    setFieldValue('razaoSocial', item.razaoSocial)
                                    setFieldValue('cidadeCliente', item.cidade)
                                    setFieldValue('estadoCliente', item.uf)

                                    setOpen(false)
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />
        </>

    )

}

export default TableCliente