import React from 'react'
import Modal from '@components/Common/Modal'
import { ModalProps } from '@interfaces/Modal'
import FormVeiculoBloqueado from '../Form'

const ModalVeiculoBloqueado: React.FC <ModalProps> = props => {

    return(

        <Modal title = "Bloqueio T4/3S" {...props}>
            <FormVeiculoBloqueado {...props} />
        </Modal>

    )

}

export default ModalVeiculoBloqueado