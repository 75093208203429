import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { mdiContentSave } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'
import FormSolicitacaoFields from '@modules/solicitacao/components/FormSolicitacaoFields'
import { updateSolicitacao } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formSolicitacaoEditValues } from './config'
import { Navigate, useLocation } from 'react-router'
import ModalConfirm from '@components/Feedback/ModalConfirm'
import { FormSolicitacaoEditProps } from './type'

const FormSolicitacaoEdit: React.FC<FormSolicitacaoEditProps> = ({ readonly = false }) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { solicitacao } = useAppSelector(s => s.solicitacao)
    const { requestUpdateSolicitacao } = useAppSelector(s => s.requestSolicitacao)

    const [redirect, setRedirect] = useState(false)
    const [openModalParticularidades, setOpenModalParticularidades] = useState(false)

    const { pathname } = useLocation()

    const module = pathname.split('/')[1]


    return (

        <>
            {redirect && <Navigate to={`/${module}`} />}

            <Formik
                initialValues={formSolicitacaoEditValues(solicitacao!)}
                onSubmit={v => {
                    if (v.particularidades) {
                        setOpenModalParticularidades(true)
                    } else {
                        updateSolicitacao(dispatch, userData!, removePesquisaFields(v), setRedirect)
                    }
                }}
            >
                {({ values }) => (
                    <FormikForm>
                        <Form.Container padding={false}>
                            <FormSolicitacaoFields readonly={readonly} />

                            {!readonly && (
                                <div className="formFooter">
                                    <Button
                                        icon={mdiContentSave}
                                        loading={requestUpdateSolicitacao.loading}
                                        label="Salvar"
                                        status="success"
                                        type="submit"
                                    />
                                </div>
                            )}
                        </Form.Container>
                        <ModalConfirm
                            title='O destinatário possui particularidades'
                            message={values.particularidades}
                            onConfirm={() => [
                                updateSolicitacao(dispatch, userData!, removePesquisaFields(values), setRedirect)
                            ]}
                            open={openModalParticularidades}
                            setOpen={setOpenModalParticularidades}
                        />
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSolicitacaoEdit