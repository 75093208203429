import React, { useState } from 'react'
import { Chip } from '@mui/material'
import { mdiEye, mdiTableCog, mdiPlus, mdiMicrosoftExcel } from '@mdi/js'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableConfig, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatCurrency, formatDate } from '@utils/format'
import FormSearch from '../FormSearch'

const TableCte: React.FC = () => {

    const dispatch = useAppDispatch()
    const { solicitacoesCte } = useAppSelector(s => s.solicitacao)
    const { requestGetSolicitacoesCte } = useAppSelector(s => s.requestSolicitacao)

    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'ctes',
        ['CT-e', 'ID Romaneio', 'Usuário', 'Criado em', 'Embarcador/Filial', 'Tomador', 'Destinatário', 'Origem', 'Destino', 'Mercadoria', 'Peso', 'Frete', 'D. Entrega', 'D. Armazém']
    ))

    const CR = conditionaRender(requestGetSolicitacoesCte, solicitacoesCte, true)

    return(

        <>
            <TablePaginator
                data = {solicitacoesCte}
                config = {tableConfig}
                conditionalRender = {CR}
                options = {
                    <>
                        <ButtonGroup>
                            <Button
                                icon = {mdiTableCog}
                                onClick = {() => setOpenTableConfig(true)}
                            />
                            <Button
                                icon = {mdiMicrosoftExcel}
                                status = "success"
                                onClick = {() => {}}
                            />
                            <Button
                                icon = {mdiPlus}
                                status = "success"
                                label = "Criar Romaneio"
                                link = "/solicitacao/romaneio/criar"
                            />
                        </ButtonGroup>

                        <FormSearch />
                    </>
                }
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.cte}</TableColumn>
                        <TableColumn>{item.idRomaneio}</TableColumn>
                        <TableColumn>{item.usuario}</TableColumn>
                        <TableColumn>{formatDate(item.dtCadastro)}</TableColumn>
                        <TableColumn>{item.descFilialOrigemCto}</TableColumn>
                        <TableColumn>{item.nmTomador}</TableColumn>
                        <TableColumn>{item.nmDestinatario}</TableColumn>
                        <TableColumn>{item.filialOrigemCto}</TableColumn>
                        <TableColumn>{item.nmDestinatario}</TableColumn>
                        <TableColumn>{item.mercadoria}</TableColumn>
                        <TableColumn>{item.peso}</TableColumn>
                        <TableColumn>{formatCurrency(item.valorFreteCobrado)}</TableColumn>
                        <TableColumn>{item.diasParaEntrega}</TableColumn>
                        <TableColumn>{item.qtdeDiasArmazem}</TableColumn>
                    </TableRow>
                )}
            />

            <TableConfig
                openModal = {openTableConfig}
                setOpenModal = {setOpenTableConfig}
                tableConfig = {tableConfig}
                setTableConfig = {setTableConfig}
            />
        </>

    )

}

export default TableCte