import { SelectItem } from "@components/Common/Select/types"

const selectStatusDocumento: SelectItem[] = [
    { label: 'Todos', value: '' },
    { label: 'Parcial', value: 'Parcial' },
    { label: 'Pago', value: 'Pago' },
    { label: 'Pendente', value: 'Pendente' },
    { label: 'Cancelado', value: 'Cancelado' },
]

export default selectStatusDocumento