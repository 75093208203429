import { Filial } from "@modules/filial/interfaces/Filial"

export default function getEnderecoFilial(filial: Filial){
    if(!!filial.endereco){
        const endereco = `${filial.endereco}, ${filial.numero} - ${filial.bairro}, ${filial.cidade} - ${filial.uf} - ${filial.cep}`
            .replaceAll('null', '')
            .replaceAll(', NaN', '')
    
        return endereco
    }

    return ''    
}