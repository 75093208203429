import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { AcessoSolicitacao } from "../interfaces/AcessoSolicitacao"

interface State {
    requestGetAcessoSolicitacoes: ResponseDefault<AcessoSolicitacao[]>
    requestDeleteAcessoSolicitacao: ResponseDefault<any>
}

const initialState: State = {
    requestGetAcessoSolicitacoes: responseInitialValues,
    requestDeleteAcessoSolicitacao: responseInitialValues,
}

const requestAcessoSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        setRequestGetAcessoSolicitacoesData: (state: State, action: PayloadAction<ResponsePattern<AcessoSolicitacao[]>>) => {
            state.requestGetAcessoSolicitacoes.data = action.payload
            state.requestGetAcessoSolicitacoes.loading = false
            state.requestGetAcessoSolicitacoes.error = false
        },
        setRequestGetAcessoSolicitacoesLoading: (state: State) => {
            state.requestGetAcessoSolicitacoes.loading = true
            state.requestGetAcessoSolicitacoes.error = false
        },
        setRequestGetAcessoSolicitacoesError: (state: State) => {
            state.requestGetAcessoSolicitacoes.loading = false
            state.requestGetAcessoSolicitacoes.error = true
        },
        setRequestGetAcessoSolicitacoesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetAcessoSolicitacoes.message = action.payload
        },
        resetRequestGetAcessoSolicitacoes: (state: State) => {
            state.requestGetAcessoSolicitacoes = {...responseInitialValues}
        },

        setRequestDeleteAcessoSolicitacoesData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteAcessoSolicitacao.data = action.payload
            state.requestDeleteAcessoSolicitacao.loading = false
            state.requestDeleteAcessoSolicitacao.error = false
        },
        setRequestDeleteAcessoSolicitacaoLoading: (state: State) => {
            state.requestDeleteAcessoSolicitacao.loading = true
            state.requestDeleteAcessoSolicitacao.error = false
        },
        setRequestDeleteAcessoSolicitacaoError: (state: State) => {
            state.requestDeleteAcessoSolicitacao.loading = false
            state.requestDeleteAcessoSolicitacao.error = true
        },
        setRequestDeleteAcessoSolicitacaoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestDeleteAcessoSolicitacao.message = action.payload
        },
        resetRequestDeleteAcessoSolicitacao: (state: State) => {
            state.requestDeleteAcessoSolicitacao = {...responseInitialValues}
        },
    }
})

const actions = requestAcessoSlice.actions

export const requestGetAcessoSolicitacoes = {
    data: actions.setRequestGetAcessoSolicitacoesData,
    loading: actions.setRequestGetAcessoSolicitacoesLoading,
    error: actions.setRequestGetAcessoSolicitacoesError,
    message: actions.setRequestGetAcessoSolicitacoesMessage,
    reset: actions.resetRequestGetAcessoSolicitacoes,
}

export const requestDeleteAcessoSolicitacao = {
    data: actions.setRequestDeleteAcessoSolicitacoesData,
    loading: actions.setRequestDeleteAcessoSolicitacaoLoading,
    error: actions.setRequestDeleteAcessoSolicitacaoError,
    message: actions.setRequestDeleteAcessoSolicitacaoMessage,
    reset: actions.resetRequestDeleteAcessoSolicitacao,
}

export default requestAcessoSlice.reducer