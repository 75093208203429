import React, { useState } from 'react'
import { mdiEye } from '@mdi/js'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableBody, TableColumn, TableHeader, TableMessage, TableOption, TableRow, TableWrapper } from '@components/Common/Table'
import { setOpenModalDocumentos } from '@modules/documento/reducers/documentoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatFilenameByURL } from '@utils/format'

const ModalDocumentos: React.FC = () => {

    const dispatch = useAppDispatch()
    const { openModalDocumentos, currentDocumentos } = useAppSelector(s => s.documento)

    const [tableConfig] = useState(createTableConfig(
        'documentosFiles',
        ["Tipo", "Titulo", "Opções"]
    ))


    return (

        <>
            <Modal
                open={openModalDocumentos}
                setOpen={setOpenModalDocumentos}
                title="Documentos"
                width="100%"
            >
                <TableWrapper config={tableConfig}>
                    <Table>
                        <TableHeader />
                        <TableBody>
                            {!!!currentDocumentos?.length && <TableMessage text="Nenhum documento adicionado!" />}

                            {currentDocumentos?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableColumn>{item.tipo}</TableColumn>
                                    <TableColumn>{formatFilenameByURL(item.url)}</TableColumn>
                                    <TableColumn width="100px">
                                        <TableOption
                                            icon={mdiEye}
                                            target="_blank"
                                            link={item.url}
                                        />
                                    </TableColumn>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableWrapper>
                <div className='ml-auto mt-4'>
                    <Button
                        label="Fechar"
                        onClick={() => dispatch(setOpenModalDocumentos(false))}
                    />
                </div>
            </Modal>
        </>

    )

}

export default ModalDocumentos