import { Solicitacao } from '@modules/solicitacao/interfaces/Solicitacao'

export const formSolicitacaoEditValues = (data: Solicitacao) => {
    return {
        veiculoPesquisa: '',
    
        id: data.id,
        AE: data.AE,
        SM: data.SM,

        idFilial: data.filial.id,
        idTipoOperacao: data.tipo_operacao.id,
        flagEscolta: !!data.flagEscolta,
        obs: data.obs,
        
        remetenteDoc: data.remetenteDoc,
        remetenteNome: data.remetenteNome,
        remetenteCidade: data.remetenteCidade,
        remetenteUf: data.remetenteUf,
    
        destinatarioDoc: data.destinatarioDoc,
        destinatarioNome: data.destinatarioNome,
        destinatarioCidade: data.destinatarioCidade,
        destinatarioUf: data.destinatarioUf,
    
        dadosTomador: data.lista_tomador.map(tomador => ({
            documentoTomador: tomador.documentoTomador,
            nomeTomador: tomador.nomeTomador,
            cidadeTomador: tomador.cidadeTomador,
            estadoTomador: tomador.estadoTomador,
        })),

        dadosCidade: data.lista_passagem_cidade.map(cidade => ({
            cidade: cidade.cidade,
            estado: cidade.estado,
        })),
    
        motoristaDoc: data.motoristaDoc,
        motoristaNome: data.motoristaNome,
        motoristaContratante: data.motoristaContratante,
    
        veiculoPlaca: data.veiculoPlaca,
        veiculoPlaca1: data.veiculoPlaca1,
        veiculoPlaca2: data.veiculoPlaca2,
        veiculoPlaca3: data.veiculoPlaca3,
        rastreadorCavalo: data.rastreadorCavalo,
        bloqueadorCavalo: data.bloqueadorCavalo,
    
        valorMercadoria: data.valorMercadoria,
        valorFrete: data.valorFrete,
        particularidades: ''
    }
}