import React from 'react'
import PdfRomaneioProtocolo from './components/PDF'


const RomaneioProtocolo: React.FC = () => {

    return <PdfRomaneioProtocolo />

}

export default RomaneioProtocolo