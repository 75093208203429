import React, { useState } from 'react'
import { Chip } from '@mui/material'
import { mdiDelete, mdiFilePdfBox, mdiPencil } from '@mdi/js'
import ModalConfirm from '@components/Feedback/ModalConfirm'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import ModalStatus from '@modules/documento/components/ModalStatus'
import { Documento } from '@modules/documento/interfaces/Documento'
import ModalDocumentos from '@modules/documento/components/ModalDocumentos'
import formatDocumentos from '@modules/documento/scripts/formatDocumentos'
import { deleteDocumento } from '@modules/documento/controllers/documentoController'
import { setCurrentDocumento, setCurrentDocumentos, setOpenModalDocumentos, setOpenModalStatus } from '@modules/documento/reducers/documentoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatDate } from '@utils/format'
import conditionaRender from '@utils/conditionalRender'
import FormSearchDocument from '../FormSearchDocument'
import { defaultTableColumns, financialTableColumns } from './config'

const TableDocumentos: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { documentosFinanceiro, currentDocumento } = useAppSelector(s => s.documento)
    const { requestGetDocumentosFinanceiro, requestDeleteDocumento } = useAppSelector(s => s.requestDocumento)

    const grupoAcessoStatusFinanceiro = [10, 1].includes(userData!.grupoAcesso.id)

    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [tableConfig] = useState(createTableConfig(
        'documentosFinanceiro',
        grupoAcessoStatusFinanceiro ? financialTableColumns : [...defaultTableColumns, "Opções"]
    ))

    const CR = conditionaRender(requestGetDocumentosFinanceiro, documentosFinanceiro, true)

    const handleOpenDocumentos = (documento: Documento) => {
        dispatch(setCurrentDocumentos(formatDocumentos(documento)))
        dispatch(setOpenModalDocumentos(true))
    }

    return(

        <>
            <TablePaginator
                data = {documentosFinanceiro}
                conditionalRender = {CR}
                config = {tableConfig}
                options = {
                    <>
                        <div />

                        <FormSearchDocument />
                    </>
                }
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.lista.id}</TableColumn>
                        <TableColumn>{item.nomeMotorista}</TableColumn>
                        <TableColumn>{item.usuario.nome}</TableColumn>
                        <TableColumn>{item.filial.descricao}</TableColumn>
                        <TableColumn>{formatDate(item.dtCadastro)}</TableColumn>
                        {grupoAcessoStatusFinanceiro && (
                            <TableColumn>
                                <Chip
                                    label = {item.status}
                                    color = {item.status === "Pago" ? "success" : item.status === "Pendente" ? "warning" : item.status === "Parcial" ? "info" : undefined}
                                    onClick = {() => {
                                        dispatch(setCurrentDocumento(item))

                                        dispatch(setOpenModalStatus(true))
                                    }}
                                />
                            </TableColumn>
                        )}
                        <TableColumn width = "116px">
                            <TableOption
                                icon = {mdiFilePdfBox}
                                onClick = {() => {
                                    handleOpenDocumentos(item)
                                }}
                            />
                            {item.status !== "Pago" && (
                                <>
                                    <TableOption
                                        icon = {mdiPencil}
                                        link = {`${item.lista.id}`}
                                    />
                                </>
                            )}
                        </TableColumn>
                    </TableRow>
                )}
            />

            <ModalDocumentos />
            <ModalStatus />
            <ModalConfirm
                open = {openModalDelete}
                setOpen = {setOpenModalDelete}
                title = "Excluir essa documentação"
                message = "Deseja realmente excluir essa documentação?"
                loading = {requestDeleteDocumento.loading}
                onConfirm = {props => {
                    deleteDocumento(dispatch, userData!, currentDocumento!, props)
                }}
            />
        </>

    )

}

export default TableDocumentos