import React, { useState } from 'react'
import { mdiEye, mdiTableCog } from '@mdi/js'
import Button from '@components/Common/Button'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableConfig, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const TablePernoites: React.FC = () => {

    const { pernoites } = useAppSelector(s => s.pernoite)
    const { requestGetPernoites } = useAppSelector(s => s.requestPernoite)

    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'pernoites',
        ["ID", "Origem", "Destino", "Motorista", "Mercadoria", "Frete", "Escolta", "Tipo", "Obs", ""]
    ))

    const CR = conditionaRender(requestGetPernoites, pernoites, true)

    return(

        <>
            <TablePaginator
                data = {pernoites}
                config = {tableConfig}
                conditionalRender = {CR}
                options = {
                    <Button
                        icon = {mdiTableCog}
                        onClick = {() => setOpenTableConfig(true)}
                    />
                }
                renderItem = {(item, index) => {
                    return(
                        <TableRow key = {index}>
                            <TableColumn>
                                <TableOption
                                    icon = {mdiEye}
                                    link = {`$`}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />

            <TableConfig
                openModal = {openTableConfig}
                setOpenModal = {setOpenTableConfig}
                tableConfig = {tableConfig}
                setTableConfig = {setTableConfig}
            />
        </>

    )

}

export default TablePernoites