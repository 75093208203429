import React from 'react'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import ModalMessage from '@components/Common/Modal/Message'
import { ModalConfirmProps } from './types'

const ModalConfirm: React.FC <ModalConfirmProps> = ({ onConfirm, onCancel, loading, message, title = 'Confirmar', ...props }) => {

    return(

        <>
            <Modal {...props} title = {title}>
                <ModalMessage message = {message} />
                <ModalActions>
                    <Button
                        label = "Cancelar"
                        status = "error"
                        onClick = {() => {
                            onCancel && onCancel()
                            props.setOpen(false)
                        }}
                    />
                    <Button
                        label = "Confirmar"
                        status = "success"
                        loading = {loading}
                        onClick = {() => {
                            onConfirm(props)
                            props.setOpen(false)
                        }}
                    />
                </ModalActions>
            </Modal>
        </>

    )

}

export default ModalConfirm