import React, { useLayoutEffect, useState } from 'react'
import { Document, PDFViewer, Text } from '@react-pdf/renderer'
import PDF from '@components/Common/PDF'
import { Viagem } from '@modules/viagem/interfaces/Viagem'
import storage from '@utils/storage'
import { formatCurrency, formatDate } from '@utils/format'
import PdfViagemProtocoloItem from './components/List/Item'
import PdfViagemProtocoloHeader from './components/List/Header'
import PdfViagemProtocoloFooter from './components/List/Footer'

const PdfViagemProtocolo: React.FC = () => {

    const [data, setData] = useState<Viagem | null>(null)

    useLayoutEffect(() => {
        const localData = storage.getObject<Viagem>('pdfViagem')

        if(!!localData){
            setData(localData)
        }
    }, [])

    return(

        <>
            {!!data && (
                <PDFViewer style = {{ width: '100vw', height: '100vh' }}>
                    <Document>
                        <PDF.Page>
                            <PDF.Header>
                                <PDF.Container style = {{width: '300px'}}>
                                    <PDF.Container style = {{marginBottom: 4}}>
                                        <Text style = {{marginBottom: 2, fontSize: 10, textAlign: 'right'}}>COMANDO LOG E TRANSPORTES LTDA</Text>
                                        <Text style = {{marginBottom: 2, fontSize: 10, textAlign: 'right'}}>Viagem DE CARREGAMENTO</Text>
                                        <Text style = {{marginBottom: 2, fontSize: 10, textAlign: 'right'}}>{`Viagem Nro. ${data.id}`}</Text>
                                    </PDF.Container>
                                    <PDF.Container style = {{flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <PDF.TextContainer
                                            title = "Emitido em"
                                            text = {formatDate(data.dtCadastro)}
                                            titleWidth = "60px"
                                        />
                                    </PDF.Container>
                                    <PDF.Container style = {{flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <PDF.TextContainer
                                            title = "Iniciado em"
                                            text = {data.lista_processo_validacao.every(e => e.dtValidado !== null) ? formatDate(data.lista_processo_validacao.at(-1)!.dtValidado) : ''}
                                            titleWidth = "60px"
                                        />
                                        <PDF.TextContainer
                                            title = "Concluído em"
                                            text = {formatDate(data.dtBaixa)}
                                            titleWidth = "60px"
                                        />
                                    </PDF.Container>
                                </PDF.Container>
                            </PDF.Header>

                            <PDF.Line />

                            <PDF.Section style = {{flexDirection: 'row', marginBottom: 12}}>
                                <PDF.Container style = {{width: '70%'}}>
                                    <PDF.TextContainer
                                        title = "Unid. Destino"
                                        text = {""}
                                        titleWidth = "80px"
                                    />
                                    <PDF.TextContainer
                                        title = "Motorista"
                                        text = {data.motoristaNome}
                                        titleWidth = "80px"
                                    />
                                    <PDF.TextContainer
                                        title = "Frete Combinado"
                                        text = {formatCurrency(data.valorFrete)}
                                        titleWidth = "80px"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.TextContainer
                                        title = "Cavalo"
                                        text = {data.veiculoPlaca}
                                        titleWidth = "70px"
                                    />
                                    <PDF.TextContainer
                                        title = "Reboque 1"
                                        text = {data.veiculoPlaca2}
                                        titleWidth = "70px"
                                    />
                                    <PDF.TextContainer
                                        title = "Reboque 2"
                                        text = {data.veiculoPlaca3}
                                        titleWidth = "70px"
                                    />
                                </PDF.Container>
                            </PDF.Section>

                            <PDF.Section>
                                <PdfViagemProtocoloHeader />

                                {data.solicitacoes.map((item, index) => (
                                    <PdfViagemProtocoloItem
                                        key = {index}
                                        {...item}
                                    />
                                ))}


                                <PdfViagemProtocoloFooter {...data} />
                            </PDF.Section>

                            <PDF.Section style = {{marginTop: 12}}>
                                <PDF.TextContainer
                                    text = "Observações"
                                />
                            </PDF.Section>
                            <PDF.Section style = {{marginTop: 4}}>
                                <PDF.TextContainer
                                    text = {data.obs}
                                />
                            </PDF.Section>

                            <PDF.Section style = {{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 100}}>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.Signature
                                        label = "EXPEDIÇÃO"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.Signature
                                        label = "OPERAÇÃO"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%'}}>
                                    <PDF.Signature
                                        label = "RASTREAMENTO"
                                    />
                                </PDF.Container>
                                <PDF.Container style = {{width: '30%', marginTop: 32}}>
                                    <PDF.Signature
                                        label = "2ª CONFERÊNCIA"
                                    />
                                </PDF.Container>
                            </PDF.Section>
                        </PDF.Page>
                    </Document>
                </PDFViewer>
            )}
        </>

    )

}

export default PdfViagemProtocolo