import React, { useState } from 'react'
import { mdiEye } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { useAppSelector } from '@redux/hooks'
import { formatDate } from '@utils/format'
import conditionaRender from '@utils/conditionalRender'
import SearchFilter from '../SearchFilter'

const TableViagemAplicativo: React.FC = () => {

    const { viagensAplicativo } = useAppSelector(s => s.viagem)
    const { requestGetViagensAplicativo } = useAppSelector(s => s.requestViagem)

    const [tableConfig] = useState(createTableConfig(
        'viagemAplicativo',
        ["ID", "Nome", "Baixado às", "Solicitações", "Em aberto", "Sucessos", "Insucessos", "Versão", ""]
    ))

    const CR = conditionaRender(requestGetViagensAplicativo, viagensAplicativo, true)

    return(

        <>
            <TablePaginator
                data = {viagensAplicativo}
                config = {tableConfig}
                conditionalRender = {CR}
                options = {<SearchFilter />}
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.id}</TableColumn>
                        <TableColumn>{item.nome}</TableColumn>
                        <TableColumn>{formatDate(item.dtDownload)}</TableColumn>
                        <TableColumn>{item.totalSolicitacoes}</TableColumn>
                        <TableColumn status = "info">{item.totalEmAberto}</TableColumn>
                        <TableColumn status = "success">{item.totalSucesso}</TableColumn>
                        <TableColumn status = "error">{item.totalInsucesso}</TableColumn>
                        <TableColumn status = {item.flagNovoApp ? 'success': 'error'}>{item.versao}</TableColumn>
                        <TableColumn>
                            <TableOption
                                icon = {mdiEye}
                                link = {`/viagem/aplicativo/motorista/${item.id}/${item.nome}`}
                                onClick = {() => {

                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />
        </>

    )

}

export default TableViagemAplicativo