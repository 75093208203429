import { useAppSelector } from '@redux/hooks'
import React from 'react'

const MapPernoite: React.FC = () => {

    const { viagemPernoite } = useAppSelector(s => s.viagem)

    return(

        <iframe
            src = {viagemPernoite!.linkMapa}
            title = "Mapa"
            style = {{height: 400, borderRadius: 16, marginBottom: 24}}
        />

    )

}

export default MapPernoite