import React, { useEffect, useState } from 'react'
import { mdiCheckCircle, mdiPlusCircle } from '@mdi/js'
import { useFormikContext } from 'formik'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { ModalProps } from '@interfaces/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formSolicitacaoValues } from '@modules/solicitacao/components/FormSolicitacaoFields/config'
import FormSearchMotorista from '@modules/motorista/pages/MotoristaLista/components/FormSearchMotorista'
import { getMotoristas } from '@modules/motorista/controllers/motoristaController'
import FormSearchVeiculos from '@modules/veiculo/pages/VeiculoLista/components/FormSearchVeiculos'
import { getVeiculos } from '@modules/veiculo/controllers/veiculoController'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'

const TableCarreta: React.FC<ModalProps> = ({ setOpen }) => {

    const dispatch = useAppDispatch()
    const { veiculos } = useAppSelector(s => s.veiculo)
    const { requestGetVeiculos } = useAppSelector(s => s.requestVeiculo)

    const [tableConfig] = useState(createTableConfig(
        'searchMotorista',
        ["Placa", ""]
    ))

    const { values, setFieldValue } = useFormikContext<typeof formSolicitacaoValues>()

    const CR = conditionaRender(requestGetVeiculos, veiculos)

    useEffect(() => {
        getVeiculos(dispatch, '', 'carreta')
    }, [])

    return (

        <>
            <TablePaginator
                data={veiculos}
                conditionalRender={CR}
                config={tableConfig}
                options={
                    <>
                        <div />
                        <FormSearchVeiculos type='carreta' />
                    </>
                }
                renderItem={(item, index) => {
                    const { placa } = item

                    const { veiculoPlaca1, veiculoPlaca2, veiculoPlaca3 } = values
                    const veiculoPlacas = [veiculoPlaca1, veiculoPlaca2, veiculoPlaca3];
                    const added = veiculoPlacas.some(p => p === placa)

                    return (
                        <TableRow key={index}>
                            <TableColumn>{placa}</TableColumn>
                            <TableColumn width={22}>
                                <TableOption
                                    icon={added ? mdiCheckCircle : mdiPlusCircle}
                                    status={added ? 'success' : undefined}
                                    onClick={() => {
                                        const indexCarretaVazia = veiculoPlacas.findIndex(placa => !placa);

                                        if (!added) {

                                            if (indexCarretaVazia === -1) {
                                                return dispatch(setAlert(createAlert('warning', "Só são permitidas três carratas")))
                                            }

                                            if (indexCarretaVazia !== -1) {
                                                setFieldValue(`veiculoPlaca${indexCarretaVazia + 1}`, placa);
                                            }
                                        } else {
                                            const indexCarrreta = veiculoPlacas.findIndex(p => p === placa)

                                            if (indexCarrreta !== -1) {
                                                setFieldValue(`veiculoPlaca${indexCarrreta + 1}`, '');
                                            }
                                        }
                                    }}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />
        </>

    )

}

export default TableCarreta