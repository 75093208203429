import authRoutes from "@modules/auth/routes"
import viagemRoutes from "@modules/viagem/routes"
import acessoRoutes from "@modules/acesso/routes"
import modulosRoutes from "@modules/modulos/routes"
import pernoiteRoutes from "@modules/pernoite/routes"
import cadastroRoutes from "@modules/cadastro/routes"
import conferenciaRoutes from "@modules/conferencia/routes"
import solicitacaoRoutes from "@modules/solicitacao/routes"
import notificationRoutes from "@modules/notification/routes"
import { RouteType } from "./interfaces"

const routes: RouteType[] = [
    ...authRoutes,
    ...conferenciaRoutes,
    ...viagemRoutes,
    ...pernoiteRoutes,
    ...acessoRoutes,
    ...modulosRoutes,
    ...notificationRoutes,
    ...cadastroRoutes,
    ...solicitacaoRoutes,
]

const routesKeys: string[] = routes.reduce((acc: string[], route) => {
    acc.push(route.key)
    if (route.subRoutes) route.subRoutes.forEach(subRoute => acc.push(subRoute.key))
    return acc
}, [])

const notShowRoutesKeys: string[] = [
    ...authRoutes.map(i => i.key),
]

export { routes, routesKeys, notShowRoutesKeys }