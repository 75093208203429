import React, { useState } from 'react'
import { Navigate } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import { mdiContentSave } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formFilialSchema, formFilialValues } from '@modules/filial/components/FormFilialFields/config'
import FormFilialFields from '@modules/filial/components/FormFilialFields'
import { createFilial } from '@modules/filial/controllers/filialController'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'

const FormCreate: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestCreateFilial } = useAppSelector(s => s.requestFilial)

    const [redirect, setRedirect] = useState(false)

    return(

        <>
            {redirect && <Navigate to = "/cadastro/filial" />}
            <Formik
                initialValues = {formFilialValues}
                validationSchema = {formFilialSchema}
                onSubmit = {v => {
                    createFilial(dispatch, userData!, removePesquisaFields(v), setRedirect)
                }}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <FormFilialFields />

                            <div className = "formFooter">
                                <Button
                                    icon = {mdiContentSave}
                                    loading = {requestCreateFilial.loading}
                                    label = "Salvar"
                                    status = "success"
                                    type = "submit"
                                />
                            </div>
                        </Form.Container>
                    </FormikForm>
                
                )}
            </Formik>
        </>

    )

}

export default FormCreate