import React from 'react'
import Form from '@components/Common/Form'
import Select from '@components/Common/Select'
import { selectRastreador1, selectRastreador2 } from '@constants/select/rastreador'
import { formCreateCavaloValues } from './config'
import { maskPlaca } from '@constants/masks'
import { FormCreateCavaloProps } from './types'

const FormCreateCavaloFields: React.FC<FormCreateCavaloProps> = ({ handleChange, setFieldValue, values }) => {

    return (

        <>
            <Form.Row columns={1}>
                <Form.Group
                    inputID="placa"
                    inputName="placa"
                    label="Placa"
                    mask={maskPlaca}
                    handleChange={handleChange}
                />
            </Form.Row>
            <Form.Row columns={2}>
                <Select
                    type="outline"
                    label="Rastreador principal"
                    fieldName="rastreadorTipo"
                    value={values.rastreadorTipo}
                    items={selectRastreador1}
                    setFieldValue={setFieldValue}
                />
                <Form.Group
                    inputID="rastreadorID"
                    inputName="rastreadorID"
                    label="ID Rastreador"
                    inputType='number'
                />
            </Form.Row>
            <Form.Row columns={2}>
                <Select
                    type="outline"
                    label="Bloqueador secundário"
                    fieldName="bloqueadorTipo"
                    value={values.bloqueadorTipo}
                    items={selectRastreador2}
                    setFieldValue={setFieldValue}
                />
                <Form.Group inputID="bloqueadorID" inputName="bloqueadorID" label="ID Bloqueador" />
            </Form.Row>
        </>

    )

}

export default FormCreateCavaloFields