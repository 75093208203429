import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getRomaneioSolicitacoes } from '@modules/romaneio/controllers/romaneioController'
import { useAppDispatch } from '@redux/hooks'
import FormCreateRomaneio from './components/FormCreate'
import { setRomaneioSolicitacaoDestinatario } from './reducers/romaneioCriarReducer'
import './styles.scss'

const RomaneioCriar: React.FC = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        getRomaneioSolicitacoes(dispatch)
    }, [dispatch])

    useEffect(() => {
        return () => {
            dispatch(setRomaneioSolicitacaoDestinatario(null))
        }
    }, [dispatch])

    return(

        <>
            <PageTitle title = "Criar Romaneio" />
            <Section>
                <FormCreateRomaneio />
            </Section>
        </>

    )

}

export default RomaneioCriar