import React from 'react'
import Modal from '@components/Common/Modal'
import { ModalSearchSolicitacaoProps } from './types'
import TableSolicitacao from '../TableSolicitacao'
import Button from '@components/Common/Button'

const ModalSolicitacao: React.FC<ModalSearchSolicitacaoProps> = props => {

    return (

        <Modal
            {...props}
            title="Adicionar Solicitações"
            width="100%"
        >
            <TableSolicitacao {...props} />
            <div className='ml-auto mt-4'>
                <Button
                    label="Fechar"
                    onClick={() => props.setOpen(false)}
                />
            </div>
        </Modal>

    )

}

export default ModalSolicitacao