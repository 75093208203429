import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { mdiContentSave } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormClienteFields from '@modules/cliente/components/FormClienteFields'
import { formClienteSchema, formClienteValues } from '@modules/cliente/components/FormClienteFields/config'
import { updateCliente } from '@modules/cliente/controllers/clienteController'
import { formClienteEditValues } from './config'
import { Navigate } from 'react-router'

const FormEdit: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { cliente } = useAppSelector(s => s.cliente)
    const { requestUpdateCliente } = useAppSelector(s => s.requestCliente)
    const [redirect, setRedirect] = useState(false)


    return (

        <>
            {redirect && <Navigate to={`/cadastro/cliente`} />}
            <Formik
                initialValues={formClienteEditValues(cliente!)}
                validationSchema={formClienteSchema}
                onSubmit={v => {
                    updateCliente(dispatch, userData!, v, setRedirect)
                }}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding={false}>
                            <FormClienteFields />

                            <div className="formFooter">
                                <Button
                                    icon={mdiContentSave}
                                    loading={requestUpdateCliente.loading}
                                    label="Salvar"
                                    status="success"
                                    type="submit"
                                />
                            </div>
                        </Form.Container>
                    </FormikForm>

                )}
            </Formik>
        </>

    )

}

export default FormEdit