
import { RoutePageType } from "@routes/interfaces"
import Pernoite from "./pages/Pernoite"
import Pernoites from "./pages/Pernoites"

const pernoitePages: RoutePageType[] = [
    {key: 'pernoites', element: Pernoites},
    {key: 'pernoite', element: Pernoite},
]

export default pernoitePages