import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Pernoite } from "../interfaces/Pernoite"

interface State {
    pernoites: Pernoite[] | null
    pernoite: Pernoite | null
}

const initialState: State = {
    pernoites: null,
    pernoite: null,
}

const pernoiteSlice = createSlice({
    name: 'pernoite',
    initialState,
    reducers: {
        setPernoites: (state, action: PayloadAction<Pernoite[] | null>) => {
            state.pernoites = action.payload
        },
        setPernoite: (state, action: PayloadAction<Pernoite | null>) => {
            state.pernoite = action.payload
        },
    }
})

export const { setPernoites, setPernoite } = pernoiteSlice.actions
export default pernoiteSlice.reducer