import React from 'react'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { FormSearchClienteProps } from './types'

const FormSearchCliente: React.FC<FormSearchClienteProps> = ({ handleSearchDocumento, handleSearchSistema, type = 'select' }) => {

    return (

        <>
            <Formik
                initialValues={{
                    valorBuscaSistema: '',
                    valorBuscaDocumento: ''
                }}
                onSubmit={() => { }}
            >
                {({ setFieldValue, values }) => (
                    <FormikForm>
                        <Form.Container padding={false}>
                            <Form.Row columns={2} grid={type === 'select' ? "200px auto" : "200px auto auto"}>
                                {!!handleSearchSistema && type === 'select' && (
                                    <>
                                        <Form.Group
                                            label="Pesquisar no sistema"
                                            inputName="valorBuscaSistema"
                                            inputID="valorBuscaSistema"
                                        />
                                        <ButtonGroup>
                                            <Button
                                                icon={mdiMagnify}
                                                status="success"
                                                onClick={() => {
                                                    handleSearchSistema(values.valorBuscaSistema)
                                                }}
                                            />
                                            {!handleSearchDocumento && (
                                                <Button
                                                    icon={mdiClose}
                                                    type="reset"
                                                    status="error"
                                                    onClick={() => {
                                                        setFieldValue('valorBuscaSistema', '')
                                                        setFieldValue('valorBuscaDocumento', '')
                                                        handleSearchSistema('')
                                                    }}
                                                />
                                            )}
                                        </ButtonGroup>
                                    </>
                                )}

                                {!!handleSearchDocumento && type === 'create' && (
                                    <>
                                        <Form.Group
                                            label="Pesquisar por CNPJ"
                                            inputName="valorBuscaDocumento"
                                            inputID="valorBuscaDocumento"
                                        />
                                        <ButtonGroup>
                                            <Button
                                                icon={mdiMagnify}
                                                status="success"
                                                onClick={() => {
                                                    handleSearchDocumento(values.valorBuscaDocumento)
                                                }}
                                            />
                                        </ButtonGroup>

                                        <ButtonGroup>
                                            <Button
                                                icon={mdiClose}
                                                type="reset"
                                                status="error"
                                                onClick={() => {
                                                    setFieldValue('valorBuscaSistema', '')
                                                    setFieldValue('valorBuscaDocumento', '')
                                                }}
                                            />
                                        </ButtonGroup>
                                    </>
                                )}
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSearchCliente