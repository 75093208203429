import React from 'react'
import Form from '@components/Common/Form'
import { maskPlaca } from '@constants/masks'
import { FormCreateCarretaProps } from './types'

const FormCreateCarretaFields: React.FC<FormCreateCarretaProps> = ({ handleChange }) => {

    return (

        <>
            <Form.Row columns={1}>
                <Form.Group
                    inputID="placa"
                    inputName="placa"
                    label="Placa"
                    mask={maskPlaca}
                    handleChange={handleChange}
                />
            </Form.Row>
        </>

    )

}

export default FormCreateCarretaFields