import React from 'react'
import Form from '@components/Common/Form'
import Section from '@components/Page/Section'
import Container from '@components/Page/Container'
import TableValidacoes from '../Validacoes/TableValidacoes'
import TableDocumentos from '../Documento/TableDocumentos'
import { FormViagemFieldsProps } from './types'
import './styles.scss'
import FormRomaneio from '@modules/romaneio/pages/RomaneioEditar/components/FormRomaneio'
import { useAppSelector } from '@redux/hooks'
import FormSolicitacaoEdit from '@modules/solicitacao/pages/SolicitacaoEditar/components/FormSolicitacaoEdit'

const FormViagemFields: React.FC<FormViagemFieldsProps> = ({
    readonlyValidacoes = true
}) => {
    const { viagem } = useAppSelector(s => s.viagem)
    const { solicitacao } = useAppSelector(s => s.solicitacao)

    return (
        <>
            <Form.Container padding={false}>
                <Section>
                    {viagem?.flagRomaneio && <FormRomaneio readonly />}
                    {!solicitacao?.flagRomaneio && <FormSolicitacaoEdit readonly />}
                </Section>


                <Section>
                    <Container direction="row" align="start" justify="between" gap={24} padding={false}>
                        <TableDocumentos />
                        <TableValidacoes onlyView={readonlyValidacoes} />
                    </Container>
                </Section>
            </Form.Container>
        </>

    )

}

export default FormViagemFields