import React, { useState } from 'react'
import { Navigate } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import { mdiContentSave } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import { formSolicitacaoValues } from '@modules/solicitacao/components/FormSolicitacaoFields/config'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'
import FormSolicitacaoFields from '@modules/solicitacao/components/FormSolicitacaoFields'
import { createSolicitacao } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import ModalConfirm from '@components/Feedback/ModalConfirm'

const FormCreate: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestCreateSolicitacao } = useAppSelector(s => s.requestSolicitacao)

    const [redirect, setRedirect] = useState(false)
    const [openModalParticularidades, setOpenModalParticularidades] = useState(false)

    return (

        <>
            {redirect && <Navigate to="/solicitacao" />}
            <Formik
                initialValues={formSolicitacaoValues}
                onSubmit={v => {
                    if (v.particularidades) {
                        setOpenModalParticularidades(true)
                    } else {
                        createSolicitacao(dispatch, userData!, removePesquisaFields(v), setRedirect)
                    }
                }}
            >
                {({values}) => (
                    <FormikForm>
                        <Form.Container padding={false}>
                            <FormSolicitacaoFields />

                            <div className="formFooter">
                                <Button
                                    icon={mdiContentSave}
                                    loading={requestCreateSolicitacao.loading}
                                    label="Salvar"
                                    status="success"
                                    type="submit"
                                />
                            </div>
                        </Form.Container>
                        <ModalConfirm
                            title='O destinatário possui particularidades'
                            message={values.particularidades}
                            onConfirm={() => [
                                createSolicitacao(dispatch, userData!, removePesquisaFields(values), setRedirect)
                            ]}
                            open={openModalParticularidades}
                            setOpen={setOpenModalParticularidades}
                        />
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormCreate
