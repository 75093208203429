import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableVeiculos from './components/TableVeiculos'
import { getVeiculos } from '@modules/veiculo/controllers/veiculoController'

const VeiculoLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { veiculos } = useAppSelector(s => s.veiculo)

    useEffect(() => {
        getVeiculos(dispatch)
    }, [dispatch])
    
    return(

        <>
            <PageTitle goBack={false} title = {`Veículos (${veiculos?.length ?? 0})`} />
            <Section>
                <TableVeiculos />
            </Section>
        </>

    )

}

export default VeiculoLista