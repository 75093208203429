import React from 'react'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import ButtonGroup from '@components/Common/Button/Group'
import selectStatusDocumento from '@constants/select/statusDocumento'
import { getSolicitacoesCte } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'

const FormSearch: React.FC = () => {

    const dispatch = useAppDispatch()
    const { filiais } = useAppSelector(s => s.core)

    return(

        <>
            <Formik
                initialValues = {{
                    idFilial: 0,
                    status: '',
                    valorBusca: '',
                    dtInicio: '',
                    dtFim: ''
                }}
                onSubmit = {v => {
                    getSolicitacoesCte(dispatch, v)
                }}
            >
                {({ values, setFieldValue }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {4} grid = "auto auto auto auto auto auto">
                                <Select
                                    type = "outline"
                                    label = "Filial"
                                    fieldName = "idFilial"
                                    setFieldValue = {setFieldValue}
                                    value = {values.idFilial}
                                    items = {createSelectItems(filiais, 'descricao', 'id')}
                                />

                                <Select
                                    label = "Status"
                                    fieldName = "status"
                                    value = {values.status}
                                    items = {selectStatusDocumento}
                                    setFieldValue = {setFieldValue}
                                />

                                <Form.Group
                                    label = "Pesquisar"
                                    inputName = "valorBusca"
                                    inputID = "valorBusca"
                                />

                                <Form.Group
                                    label = "Data de início"
                                    inputName = "dtInicio"
                                    inputID = "dtInicio"
                                    inputType = "date"
                                />
                                <Form.Group
                                    label = "Data de fim"
                                    inputName = "dtFim"
                                    inputID = "dtFim"
                                    inputType = "date"
                                />

                                <ButtonGroup>
                                    <Button
                                        icon = {mdiMagnify}
                                        status = "success"
                                        type = "submit"
                                    />
                                    <Button
                                        icon = {mdiClose}
                                        type = "reset"
                                        status = "error"
                                    />
                                </ButtonGroup>
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSearch