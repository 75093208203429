import React from 'react'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { getSolicitacoes } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch } from '@redux/hooks'
import { FormSearchSolicitacaoProps } from './types'

const FormSearchSolicitacao: React.FC <FormSearchSolicitacaoProps> = ({ handleSearch, handleReset }) => {

    const dispatch = useAppDispatch()

    return(

        <>
            <Formik
                initialValues = {{
                    valorBusca: '',
                    dtInicio: '',
                    dtFim: ''
                }}
                onSubmit = {v => {
                    const { valorBusca, ...data } = v

                    getSolicitacoes(dispatch, data)
                }}
            >
                {({ values }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {4} grid = "auto auto auto auto">
                                <Form.Group
                                    label = "Pesquisar"
                                    inputName = "valorBusca"
                                    inputID = "valorBusca"
                                    onChange = {() => {
                                        if(values.valorBusca !== ''){
                                            handleSearch(values.valorBusca)
                                        }
                                    }}
                                />
                                <Form.Group
                                    label = "Data de início"
                                    inputName = "dtInicio"
                                    inputID = "dtInicio"
                                    inputType = "date"
                                />
                                <Form.Group
                                    label = "Data de fim"
                                    inputName = "dtFim"
                                    inputID = "dtFim"
                                    inputType = "date"
                                />

                                <ButtonGroup>
                                    <Button
                                        icon = {mdiMagnify}
                                        status = "success"
                                        type = "submit"
                                    />
                                    <Button
                                        icon = {mdiClose}
                                        type = "reset"
                                        status = "error"
                                        onClick = {handleReset}
                                    />
                                </ButtonGroup>
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSearchSolicitacao