import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Loader from '@components/Feedback/Loader'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import { getPernoite } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import MapPernoite from './components/MapPernoite'
import TableParadas from './components/TableParadas'

const Pernoite: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagemPernoite } = useAppSelector(s => s.viagem)
    const { requestGetPernoite } = useAppSelector(s => s.requestViagem)

    const { idViagem } = useParams()

    const CR_PERNOITE = conditionaRender(requestGetPernoite, viagemPernoite)

    useEffect(() => {
        if(!!idViagem){
            getPernoite(dispatch, idViagem)
        }
    }, [dispatch, idViagem])

    return(

        <>
            {CR_PERNOITE.LOADING && (
                <Section align = "center">
                    <Loader />
                </Section>
            )}
            {CR_PERNOITE.DATA && (
                <>
                    <Section>
                        <SectionBox title = "Localização" padding = {false}>
                            <MapPernoite />
                        </SectionBox>
                    </Section>
                    <Section>
                        <TableParadas />
                    </Section>                
                </>
            )}
        </>

    )

}

export default Pernoite