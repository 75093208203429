import { RoutePageType } from "@routes/interfaces"
import FilialLista from "./pages/FilialLista"
import FilialCriar from "./pages/FilialCriar"
import FilialEditar from "./pages/FilialEditar"

const filialPages: RoutePageType[] = [
    {key: 'cadastroFilialLista', element: FilialLista},
    {key: 'cadastroFilialCriar', element: FilialCriar},
    {key: 'cadastroFilialEditar', element: FilialEditar},
]

export default filialPages