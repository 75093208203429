import { ViagemOne } from "@modules/viagem/interfaces/ViagemOne"

export const formViagemAtivaValues = (data: ViagemOne) => {
    return {
        veiculoPesquisa: '',
    
        id: data.id,

        idFilial: data.idFilial,
        idTipoOperacao: data.idTipoOperacao,
        flagEscolta: !!data.flagEscolta,
        obs: data.obs,
        
        remetenteDoc: data.documentoEmbarcador,
        remetenteNome: data.remetenteNome,
        remetenteCidade: data.cidadeEmbarcador,
        remetenteUf: data.estadoEmbarcador,
    
        destinatarioDoc: data.destinatarioDoc,
        destinatarioNome: data.destinatarioNome,
        destinatarioCidade: data.destinatarioCidade,
        destinatarioUf: data.destinatarioUf,

        dadosTomador: [],
        dadosCidade: [],
    
        // dadosTomador: data.lista_tomador.map((tomador: any) => ({
        //     documentoTomador: tomador.documentoTomador,
        //     nomeTomador: tomador.nomeTomador,
        //     cidadeTomador: tomador.cidadeTomador,
        //     estadoTomador: tomador.estadoTomador,
        // })),

        // dadosCidade: data.lista_passagem_cidade.map((cidade: any) => ({
        //     cidade: cidade.cidade,
        //     estado: cidade.estado,
        // })),
    
        motoristaDoc: data.motoristaDoc,
        motoristaNome: data.motoristaNome,
        motoristaContratante: data.motoristaContratante,
    
        veiculoPlaca: data.veiculoPlaca,
        veiculoPlaca1: data.veiculoPlaca1,
        veiculoPlaca2: data.veiculoPlaca2,
        veiculoPlaca3: data.veiculoPlaca3,
        idRastreadorPrincipal: data.idRastreadorPrincipal,
        rastreadorPrincipal: data.rastreadorPrincipal,
        idVeiculoBloqueador: data.idBloqueador,
        veiculoBloqueadorCodigo: data.bloqueador,
    
        valorMercadoria: data.valorMercadoria,
        valorFrete: data.valorFrete,
    }
}