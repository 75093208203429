import React from 'react'
import 'moment/locale/pt-br'
import { ToastContainer } from 'react-toastify'
import { Provider as ReduxProvider } from 'react-redux'
import 'overlayscrollbars/overlayscrollbars.css'
import 'react-toastify/dist/ReactToastify.css'
import AppTimers from '@modules/app/components/providers/AppTimers'
import AppProvider from '@modules/app/components/providers/AppProvider'
import store from '@redux/store'
import '@styles/vars.scss'
import '@styles/app.scss'
import '@styles/common.scss'
import AppRoutes from './routes'

const App: React.FC = () => {

    return(

        <ReduxProvider store = {store}>
            <AppRoutes />
            
            <AppProvider />
            <AppTimers />

            <ToastContainer
                position = "bottom-left"
                autoClose = {5000}
                hideProgressBar = {false}
                newestOnTop
                closeOnClick
                rtl = {false}
                pauseOnFocusLoss
                pauseOnHover
                theme = "colored"
            />
        </ReduxProvider>

    )

}

export default App