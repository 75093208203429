import React from 'react'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { useAppDispatch } from '@redux/hooks'
import { getVeiculos } from '@modules/veiculo/controllers/veiculoController'
import { FormSearchVeiculosProps } from './FormSearchVeiculos'

const FormSearchVeiculos: React.FC<FormSearchVeiculosProps> = ({ type }) => {

    const dispatch = useAppDispatch()

    return (

        <>
            <Formik
                initialValues={{
                    valorBusca: '',
                }}
                onSubmit={(v) =>
                    getVeiculos(dispatch, v.valorBusca, type)
                }
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding={false}>
                            <Form.Row columns={2} grid="auto auto">
                                <Form.Group
                                    label="Pesquisar"
                                    inputPlaceholder="Pesquisar veículo"
                                    inputName="valorBusca"
                                    inputID="valorBusca"
                                />

                                <ButtonGroup>
                                    <Button
                                        icon={mdiMagnify}
                                        status="success"
                                        type="submit"
                                    />
                                    <Button
                                        icon={mdiClose}
                                        type="reset"
                                        status="error"
                                        onClick={() => getVeiculos(dispatch, '', type)}
                                    />
                                </ButtonGroup>
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSearchVeiculos