import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

const Column: React.FC <{width: string, text: string | number}> = ({ width, text }) => {

    const styles = StyleSheet.create({
        column: {
            width,
            paddingHorizontal: 4,
        },
        text: {
            fontSize: 6,
        }
    })

    return(

        <View style = {styles.column}>
            <Text style = {styles.text}>{text}</Text>
        </View>

    )

}

const PdfRomaneioProtocoloHeader: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingVertical: 4,
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
        },
    })

    return(

        <View style = {styles.row}>
            <Column
                width = "10%"
                text = {"Nota Fiscal"}
            />
            <Column
                width = "23%"
                text = {"Embarcador"}
            />
            <Column
                width = "23%"
                text = {"Destinatário"}
            />
            <Column
                width = "10%"
                text = {"Documento"}
            />
            <Column
                width = "10%"
                text = {"Peso"}
            />
            <Column
                width = "12%"
                text = {"Vlr. Mercadoria"}
            />
            <Column
                width = "12%"
                text = {"Frt. Cobrado"}
            />
        </View>

    )

}

export default PdfRomaneioProtocoloHeader