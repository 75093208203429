import React from 'react'
import Form from '@components/Common/Form'

const TabDocumentacao1: React.FC = () => {

    return(

        <>
            <Form.Section>
                <Form.Row columns = {2} grid = "1fr 1fr">
                    <Form.Group
                        label = "CPF"
                        inputID = "cpf"
                        inputName = "cpf"
                    />

                    <Form.Group
                        label = "INSS"
                        inputID = "inss"
                        inputName = "inss"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section title = "OC">
                <Form.Row columns = {2} grid = "1fr 1fr">
                    <Form.Group
                        label = "Número"
                        inputID = "nroOC"
                        inputName = "nroOC"
                    />

                    <Form.Group
                        label = "Emissor"
                        inputID = "emissorOC"
                        inputName = "emissorOC"
                    />
                    
                    <Form.Group
                        label = "Expedição"
                        inputID = "dtExpedicaoOC"
                        inputName = "dtExpedicaoOC"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "Validade"
                        inputID = "dtValidadeOC"
                        inputName = "dtValidadeOC"
                        inputType = "date"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section title = "RG">
                <Form.Row columns = {3}>
                    <Form.Group
                        label = "Número"
                        inputID = "nroRG"
                        inputName = "nroRG"
                    />

                    <Form.Group
                        label = "Emissor"
                        inputID = "emissorRG"
                        inputName = "emissorRG"
                    />
                    
                    <Form.Group
                        label = "Expedição"
                        inputID = "dtExpedicaoRG"
                        inputName = "dtExpedicaoRG"
                        inputType = "date"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section title = "CTPS">
                <Form.Row columns = {3}>
                    <Form.Group
                        label = "Número"
                        inputID = "nroCTPS"
                        inputName = "nroCTPS"
                    />

                    <Form.Group
                        label = "Série"
                        inputID = "serieCTPS"
                        inputName = "serieCTPS"
                    />

                    <Form.Group
                        label = "UF"
                        inputID = "ufCTPS"
                        inputName = "ufCTPS"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section title = "RIC">
                <Form.Row columns = {3}>
                    <Form.Group
                        label = "Número"
                        inputID = "nroRIC"
                        inputName = "nroRIC"
                    />

                    <Form.Group
                        label = "Emissor"
                        inputID = "emissorRIC"
                        inputName = "emissorRIC"
                    />
                    
                    <Form.Group
                        label = "Expedição"
                        inputID = "dtExpedicaoRIC"
                        inputName = "dtExpedicaoRIC"
                        inputType = "date"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section title = "RNE">
                <Form.Row columns = {3}>
                    <Form.Group
                        label = "Número"
                        inputID = "nroRNE"
                        inputName = "nroRNE"
                    />

                    <Form.Group
                        label = "Emissor"
                        inputID = "emissorRNE"
                        inputName = "emissorRNE"
                    />
                    
                    <Form.Group
                        label = "Expedição"
                        inputID = "dtExpedicaoRNE"
                        inputName = "dtExpedicaoRNE"
                        inputType = "date"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section title = "CNH">
                <Form.Row columns = {4}>
                    <Form.Group
                        label = "Número"
                        inputID = "nroCNH"
                        inputName = "nroCNH"
                    />

                    <Form.Group
                        label = "Categoria"
                        inputID = "categoriaCNH"
                        inputName = "categoriaCNH"
                    />

                    <Form.Group
                        label = "Expedição"
                        inputID = "dtExpedicaoCNH"
                        inputName = "dtExpedicaoCNH"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "Expedição"
                        inputID = "dtExpedicaoCNH"
                        inputName = "dtExpedicaoCNH"
                        inputType = "date"
                    />
                </Form.Row>

                <Form.Row columns = {3}>
                    <Form.Group
                        label = "1ª habilitação"
                        inputID = "dt1HabilitacaoCNH"
                        inputName = "dt1HabilitacaoCNH"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "UF"
                        inputID = "ufCNH"
                        inputName = "ufCNH"
                    />

                    <Form.Group
                        label = "Registro"
                        inputID = "registroCNH"
                        inputName = "registroCNH"
                    />
                </Form.Row>
            </Form.Section>
        </>
        
    )
}

export default TabDocumentacao1