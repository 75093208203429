import React, { useEffect, useState } from 'react'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableConfig, TablePaginator, TableRow } from '@components/Common/Table'
import FormSearchMotorista from '../FormSearchMotorista'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatDocumento } from '@utils/format'
import { getMotoristas } from '@modules/motorista/controllers/motoristaController'
import { mdiTableCog } from '@mdi/js'
import ButtonGroup from '@components/Common/Button/Group'
import Button from '@components/Common/Button'

const TableMotoristas: React.FC = () => {

    const dispatch = useAppDispatch()
    const { motoristas } = useAppSelector(s => s.motorista)
    const { requestGetMotoristas } = useAppSelector(s => s.requestMotorista)

    const [data, setData] = useState(motoristas)
    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'motoristas',
        ["Documento", "Nome"]
    ))

    const CR = conditionaRender(requestGetMotoristas, motoristas, true)

    useEffect(() => {
        if (!!motoristas) {
            setData(motoristas)
        }
    }, [motoristas])

    useEffect(() => {
        getMotoristas(dispatch)
    }, [dispatch])

    return (

        <>
            <TablePaginator
                data={data}
                config={tableConfig}
                conditionalRender={CR}
                options={
                    <>
                        <ButtonGroup>
                            <Button
                                icon={mdiTableCog}
                                onClick={() => setOpenTableConfig(true)}
                            />
                
                        </ButtonGroup>

                        <FormSearchMotorista />
                    </>
                }
                renderItem={(item, index) => (
                    <TableRow key={index}>
                        <TableColumn width={180}>{formatDocumento(item.nrDocumento) || "-"}</TableColumn>
                        <TableColumn>{item.nome || "-"}</TableColumn>
                    </TableRow>
                )}
            />

            <TableConfig
                openModal={openTableConfig}
                setOpenModal={setOpenTableConfig}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
            />
        </>

    )

}

export default TableMotoristas