import * as yup from 'yup'

export const formFilialValues = {
    idFilial: 0,
    apelidoFilial: '',
    issFilial: '',

    nomeFilial: '',
    documentoFilial: '',

    cidadeFilial: '',
    ufFilial: '',
    enderecoFilial: '',
    numeroFilial: '',
    bairroFilial: '',
    cepFilial: '',
}

export const formFilialSchema = yup.object().shape({
    idFilial: yup.number(),
    apelidoFilial: yup.string().required('Campo obrigatório'),
    issFilial: yup.number().required('Campo obrigatório'),
    
    nomeFilial: yup.string().required('Campo obrigatório'),
    documentoFilial: yup.string().required('Campo obrigatório'),

    cidadeFilial: yup.string().required('Campo obrigatório'),
    ufFilial: yup.string().required('Campo obrigatório'),
    enderecoFilial: yup.string().required('Campo obrigatório'),
    numeroFilial: yup.string().required('Campo obrigatório'),
    bairroFilial: yup.string().required('Campo obrigatório'),
    cepFilial: yup.string().required('Campo obrigatório'),
})