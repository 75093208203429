import React, { useEffect, useState } from 'react'
import { mdiDelete, mdiEye, mdiFilePdfBox, mdiMapMarkerMultiple, mdiMicrosoftExcel, mdiPencil, mdiTableCog, mdiViewGridPlus } from '@mdi/js'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TableConfig, TablePaginator, TableRow } from '@components/Common/Table'
import filterViagem from '@modules/viagem/utils/filterViagem'
import FormSearchSolicitacao from '@modules/solicitacao/components/FormSearchSolicitacao'
import ModalDeleteSolicitacao from '@modules/solicitacao/components/ModalDeleteViagem'
import { setCurrentSolicitacao, setOpenModalSolicitacaoListaView } from '@modules/solicitacao/reducers/solicitacaoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import storage from '@utils/storage'
import conditionaRender from '@utils/conditionalRender'
import { formatCurrency, formatDate } from '@utils/format'
import createExcel from '../../scripts/createExcel'
import ModalSolicitacaoView from '../ModalView'

const TableSolicitacao: React.FC = () => {

    const dispatch = useAppDispatch()
    const { solicitacoes } = useAppSelector(s => s.solicitacao)
    const { requestGetSolicitacoes } = useAppSelector(s => s.requestSolicitacao)

    const [data, setData] = useState(solicitacoes)
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'solicitacaoLista',
        ['ID', 'Usuário', 'Criado em', 'Filial', 'Tipo', 'Romaneio', 'Embarcador', 'Carga Mista', 'C. Passagem', 'Tomador', 'Destinatário', 'Origem', 'Destino', 'Mercadoria', 'Contrato', 'Opções']
    ))

    const CR = conditionaRender(requestGetSolicitacoes, solicitacoes, true)

    const handleSearch = (search: string) => {
        if (!!solicitacoes) {
            setData(solicitacoes!.filter(v => filterViagem(v, search)))
        }
    }

    useEffect(() => {
        if (!!solicitacoes) {
            setData(solicitacoes)
        }
    }, [solicitacoes])

    return (

        <>
            <TablePaginator
                data={data}
                config={tableConfig}
                conditionalRender={CR}
                options={
                    <>
                        <ButtonGroup>
                            <Button
                                icon={mdiTableCog}
                                onClick={() => setOpenTableConfig(true)}
                            />
                            <Button
                                icon={mdiMicrosoftExcel}
                                status="success"
                                onClick={() => createExcel(data!)}
                            />
                            <Button
                                icon={mdiViewGridPlus}
                                label="Criar solicitação"
                                status="success"
                                link="criar"
                            />
                            <Button
                                icon={mdiViewGridPlus}
                                label="Criar romaneio"
                                status="success"
                                link="romaneio/criar"
                            />
                        </ButtonGroup>

                        <FormSearchSolicitacao
                            handleSearch={handleSearch}
                            handleReset={() => setData(solicitacoes)}
                        />
                    </>
                }
                renderItem={(item, index) => (
                    <TableRow key={index}>
                        <TableColumn>{item.id}</TableColumn>
                        <TableColumn>{item.usuario.nome}</TableColumn>
                        <TableColumn>{formatDate(item.dtCadastro)}</TableColumn>
                        <TableColumn>{item.filial.descricao}</TableColumn>
                        <TableColumn>{item.tipo_operacao.descricao}</TableColumn>
                        <TableColumn>{item.flagRomaneio ? 'Sim' : 'Não'}</TableColumn>

                        <TableColumn>{item.remetenteNome}</TableColumn>
                        <TableColumn>{item.flagCargaMista ? 'Sim' : 'Não'}</TableColumn>
                        <TableColumn>{item.flagRomaneio ? item.solicitacoes.length : item.lista_passagem_cidade.length}</TableColumn>
                        <TableColumn>{item.lista_tomador.map(i => i.nomeTomador)}</TableColumn>
                        <TableColumn>{item.destinatarioNome}</TableColumn>
                        <TableColumn>{`${item.remetenteEndereco} - ${item.remetenteUf}`}</TableColumn>
                        <TableColumn>{`${item.destinatarioCidade} - ${item.destinatarioUf}`}</TableColumn>

                        <TableColumn>{formatCurrency(Number(item.valorMercadoria))}</TableColumn>
                        <TableColumn>{formatCurrency(Number(item.valorFrete))}</TableColumn>

                        <TableColumn>
                            {item.flagRomaneio ? (
                                <TableOption
                                    icon={mdiFilePdfBox}
                                    link={`romaneio/protocolo/${item.id}`}
                                    target='_blank'
                                    onClick={() => {
                                        storage.setObject('pdfRomaneio', item)
                                    }}

                                />
                            ) : <div className='w-full' />}
                            <TableOption
                                icon={mdiMapMarkerMultiple}
                                onClick={() => {
                                    dispatch(setCurrentSolicitacao(item))
                                    dispatch(setOpenModalSolicitacaoListaView(true))
                                }}
                            />
                            <TableOption
                                icon={mdiPencil}
                                link={`${item.flagRomaneio ? 'romaneio/' : ''}editar/${item.id}`}
                            />
                            <TableOption
                                icon={mdiDelete}
                                status="error"
                                onClick={() => {
                                    dispatch(setCurrentSolicitacao(item))
                                    setOpenModalDelete(true)
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />

            <TableConfig
                openModal={openTableConfig}
                setOpenModal={setOpenTableConfig}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
            />
            <ModalDeleteSolicitacao
                open={openModalDelete}
                setOpen={setOpenModalDelete}
            />
            <ModalSolicitacaoView />
        </>

    )

}

export default TableSolicitacao