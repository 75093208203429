import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { DashboardChart } from "../interfaces/DashboardChart"

interface State {
    requestGetChartTransferencias: ResponseDefault<DashboardChart>
    requestGetChartColetas: ResponseDefault<DashboardChart>
    requestGetChartDistribuicoes: ResponseDefault<DashboardChart>
    requestGetChartLotacao: ResponseDefault<DashboardChart>
    requestGetChartPluma: ResponseDefault<DashboardChart>
}

const initialState: State = {
    requestGetChartTransferencias: responseInitialValues,
    requestGetChartColetas: responseInitialValues,
    requestGetChartDistribuicoes: responseInitialValues,
    requestGetChartLotacao: responseInitialValues,
    requestGetChartPluma: responseInitialValues,
}

const requestDashboardSlice = createSlice({
    name: 'requestDashboard',
    initialState,
    reducers: {
        setRequestGetChartTransferenciasData: (state: State, action: PayloadAction<ResponsePattern<DashboardChart>>) => {
            state.requestGetChartTransferencias.data = action.payload
            state.requestGetChartTransferencias.loading = false
            state.requestGetChartTransferencias.error = false
        },
        setRequestGetChartTransferenciasLoading: (state: State) => {
            state.requestGetChartTransferencias.loading = true
            state.requestGetChartTransferencias.error = false
        },
        setRequestGetChartTransferenciasError: (state: State) => {
            state.requestGetChartTransferencias.loading = false
            state.requestGetChartTransferencias.error = true
        },
        setRequestGetChartTransferenciasMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetChartTransferencias.message = action.payload
        },
        resetRequestGetChartTransferencias: (state: State) => {
            state.requestGetChartTransferencias = {...responseInitialValues}
        },

        setRequestGetChartColetasData: (state: State, action: PayloadAction<ResponsePattern<DashboardChart>>) => {
            state.requestGetChartColetas.data = action.payload
            state.requestGetChartColetas.loading = false
            state.requestGetChartColetas.error = false
        },
        setRequestGetChartColetasLoading: (state: State) => {
            state.requestGetChartColetas.loading = true
            state.requestGetChartColetas.error = false
        },
        setRequestGetChartColetasError: (state: State) => {
            state.requestGetChartColetas.loading = false
            state.requestGetChartColetas.error = true
        },
        setRequestGetChartColetasMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetChartColetas.message = action.payload
        },
        resetRequestGetChartColetas: (state: State) => {
            state.requestGetChartColetas = {...responseInitialValues}
        },

        setRequestGetChartDistribuicoesData: (state: State, action: PayloadAction<ResponsePattern<DashboardChart>>) => {
            state.requestGetChartDistribuicoes.data = action.payload
            state.requestGetChartDistribuicoes.loading = false
            state.requestGetChartDistribuicoes.error = false
        },
        setRequestGetChartDistribuicoesLoading: (state: State) => {
            state.requestGetChartDistribuicoes.loading = true
            state.requestGetChartDistribuicoes.error = false
        },
        setRequestGetChartDistribuicoesError: (state: State) => {
            state.requestGetChartDistribuicoes.loading = false
            state.requestGetChartDistribuicoes.error = true
        },
        setRequestGetChartDistribuicoesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetChartDistribuicoes.message = action.payload
        },
        resetRequestGetChartDistribuicoes: (state: State) => {
            state.requestGetChartDistribuicoes = {...responseInitialValues}
        },

        setRequestGetChartLotacaoData: (state: State, action: PayloadAction<ResponsePattern<DashboardChart>>) => {
            state.requestGetChartLotacao.data = action.payload
            state.requestGetChartLotacao.loading = false
            state.requestGetChartLotacao.error = false
        },
        setRequestGetChartLotacaoLoading: (state: State) => {
            state.requestGetChartLotacao.loading = true
            state.requestGetChartLotacao.error = false
        },
        setRequestGetChartLotacaoError: (state: State) => {
            state.requestGetChartLotacao.loading = false
            state.requestGetChartLotacao.error = true
        },
        setRequestGetChartLotacaoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetChartLotacao.message = action.payload
        },
        resetRequestGetChartLotacao: (state: State) => {
            state.requestGetChartLotacao = {...responseInitialValues}
        },

        setRequestGetChartPlumaData: (state: State, action: PayloadAction<ResponsePattern<DashboardChart>>) => {
            state.requestGetChartPluma.data = action.payload
            state.requestGetChartPluma.loading = false
            state.requestGetChartPluma.error = false
        },
        setRequestGetChartPlumaLoading: (state: State) => {
            state.requestGetChartPluma.loading = true
            state.requestGetChartPluma.error = false
        },
        setRequestGetChartPlumaError: (state: State) => {
            state.requestGetChartPluma.loading = false
            state.requestGetChartPluma.error = true
        },
        setRequestGetChartPlumaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetChartPluma.message = action.payload
        },
        resetRequestGetChartPluma: (state: State) => {
            state.requestGetChartPluma = {...responseInitialValues}
        },
    }
})

const { actions } = requestDashboardSlice

export const requestGetChartTransferenciasActions = {
    data: actions.setRequestGetChartTransferenciasData,
    loading: actions.setRequestGetChartTransferenciasLoading,
    error: actions.setRequestGetChartTransferenciasError,
    message: actions.setRequestGetChartTransferenciasMessage,
    reset: actions.resetRequestGetChartTransferencias,
}

export const requestGetChartColetasActions = {
    data: actions.setRequestGetChartColetasData,
    loading: actions.setRequestGetChartColetasLoading,
    error: actions.setRequestGetChartColetasError,
    message: actions.setRequestGetChartColetasMessage,
    reset: actions.resetRequestGetChartColetas,
}

export const requestGetChartDistribuicoesActions = {
    data: actions.setRequestGetChartDistribuicoesData,
    loading: actions.setRequestGetChartDistribuicoesLoading,
    error: actions.setRequestGetChartDistribuicoesError,
    message: actions.setRequestGetChartDistribuicoesMessage,
    reset: actions.resetRequestGetChartDistribuicoes,
}

export const requestGetChartLotacaoActions = {
    data: actions.setRequestGetChartLotacaoData,
    loading: actions.setRequestGetChartLotacaoLoading,
    error: actions.setRequestGetChartLotacaoError,
    message: actions.setRequestGetChartLotacaoMessage,
    reset: actions.resetRequestGetChartLotacao,
}

export const requestGetChartPlumaActions = {
    data: actions.setRequestGetChartPlumaData,
    loading: actions.setRequestGetChartPlumaLoading,
    error: actions.setRequestGetChartPlumaError,
    message: actions.setRequestGetChartPlumaMessage,
    reset: actions.resetRequestGetChartPluma,
}

export default requestDashboardSlice.reducer