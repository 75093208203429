import React, { useState } from 'react'
import moment from 'moment'
import { mdiCheckCircle, mdiMicrosoftExcel, mdiPlusCircle, mdiTableCog } from '@mdi/js'
import Button from '@components/Common/Button'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableWrapper, TableColumn, TableConfig, TableHeader, TableBody, TableRow, TableOption } from '@components/Common/Table'
import { setCurrentParada } from '@modules/viagem/reducers/viagemReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import ModalParada from '../ModalParada'
import createExcel from '../../scripts/createExcel'

const TableParadas: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagemPernoite } = useAppSelector(s => s.viagem)
    const [openModalParada, setOpenModalParada] = useState(false)
    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'viagemPernoiteParadas',
        ['Sequência', 'Local', 'Tipo', 'Pernoite', 'Data prevista', 'Data da parada', '']
    ))

    return(

        <SectionBox
            title = "Paradas"
            padding = {false}
            right = {
                <ButtonGroup>
                    <Button
                        icon = {mdiTableCog}
                        onClick = {() => setOpenTableConfig(true)}
                    />
                    <Button
                        icon = {mdiMicrosoftExcel}
                        status = "success"
                        onClick = {() => createExcel(viagemPernoite!)}
                    />
                </ButtonGroup>
            }
        >
            <TableWrapper config = {tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {viagemPernoite!.paradas.map((item, index) => (
                            <TableRow key = {index}>
                                <TableColumn>{item.sequenciaParada}</TableColumn>
                                <TableColumn>{item.descricao}</TableColumn>
                                <TableColumn>{item.tipoParada}</TableColumn>
                                <TableColumn>{item.flagPernoite ? 'Sim' : 'Não'}</TableColumn>
                                <TableColumn>{!!item.dtPrevisaoParada ? moment(item.dtPrevisaoParada).format('DD/MM/YYYY HH:mm:ss') : ''}</TableColumn>
                                <TableColumn>{!!item.dtParada ? moment(item.dtParada).format('DD/MM/YYYY HH:mm:ss') : ''}</TableColumn>
                                <TableColumn>
                                    <TableOption
                                        icon = {item.dtParada ? mdiCheckCircle : mdiPlusCircle}
                                        status = {item.flagPernoite ? 'success' : !!item.dtParada ? 'info' : undefined}
                                        onClick = {() => {
                                            dispatch(setCurrentParada(item))

                                            setOpenModalParada(true)
                                        }}
                                    />
                                </TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>

            <TableConfig
                openModal = {openTableConfig}
                setOpenModal = {setOpenTableConfig}
                tableConfig = {tableConfig}
                setTableConfig = {setTableConfig}
            />
            <ModalParada
                open = {openModalParada}
                setOpen = {setOpenModalParada}
            />
        </SectionBox>

    )

}

export default TableParadas