import React, { useEffect, useLayoutEffect } from 'react'
import configSidebar from '@components/Page/Sidebar/scripts/configSidebar'
import getUser from '@modules/auth/scripts/getUser'
import configTheme from '@modules/theme/scripts/configTheme'
import { getFiliais, getTiposOperacao, getTiposVeiculo } from '@modules/core/controllers/coreController'
import { useAppDispatch } from '@redux/hooks'

const AppProvider: React.FC = () => {

    const dispatch = useAppDispatch()

    useLayoutEffect(() => {
        configTheme(dispatch, true)
        configSidebar(dispatch)
        getUser(dispatch)
    }, [dispatch])

    useEffect(() => {
        getFiliais(dispatch)
        getTiposOperacao(dispatch)
        getTiposVeiculo(dispatch)
    }, [dispatch])

    return null

}

export default AppProvider