import React from 'react'
import { mdiDelete } from '@mdi/js'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import ModalMessage from '@components/Common/Modal/Message'
import { deleteConferencia } from '@modules/conferencia/controllers/conferenciaController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { ModalDeleteSolicitacaoProps } from './types'

const ModalDeleteConferencia: React.FC <ModalDeleteSolicitacaoProps> = modalProps => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentConferencia } = useAppSelector(s => s.conferencia)
    const { requestDeleteConferencia } = useAppSelector(s => s.requestConferencia)

    const { setOpen } = modalProps

    return(

        <Modal {...modalProps} title = "Deletar solicitação">
            <ModalMessage message = "Deseja deletar essa conferencia?" />
            <ModalActions>
                <Button
                    label = "Cancelar"
                    onClick = {() => setOpen(false)}
                />
                <Button
                    icon = {mdiDelete}
                    label = "Deletar"
                    status = "error"
                    loading = {requestDeleteConferencia.loading}
                    onClick = {() => {
                        deleteConferencia(dispatch, userData!, currentConferencia?.id!, setOpen)
                    }}
                />
            </ModalActions>
        </Modal>

    )

}

export default ModalDeleteConferencia