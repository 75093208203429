import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getViagensFinalizadas } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableViagensFinalizadas from './components/Table'

const ViagemFinalizadaLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagensFinalizadas } = useAppSelector(s => s.viagem)

    useEffect(() => {
        getViagensFinalizadas(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Viagens Finalizadas (${viagensFinalizadas?.length ?? 0})`} />
            <Section>
                <TableViagensFinalizadas />
            </Section>
        </>

    )

}

export default ViagemFinalizadaLista