import ENV from '@env/index'
import { UserData } from '@modules/auth/interfaces/UserData'
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { setMotoristas, setMotorista } from '../reducers/motoristaReducer'
import { requestGetMotoristaActions, requestGetMotoristasActions } from "../reducers/requestMotoristaReducer"
import { Motorista } from '../interfaces/Motorista'

export async function getMotoristas(dispatch: DispatchType, search: string = '') {
    initRequest(dispatch, requestGetMotoristasActions)

    const endpoint = `${ENV.APP_ENDPOINT}/motorista`
    const params = `?q=${search}`
    const response = await request.get<ResponsePattern<Motorista[]>>({ endpoint, params })

    handleResponse('getMotoristas', dispatch, response, requestGetMotoristasActions)
    .then(data => {
        dispatch(setMotoristas(data))
    })
}

export async function getMotorista(dispatch: DispatchType, idMotorista: string) {
    initRequest(dispatch, requestGetMotoristaActions, setMotorista)
    
    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/motorista/getone`
    const params = `/${idMotorista}`
    const response = await request.get<ResponsePattern<Motorista>>({ endpoint, params })

    handleResponse('getMotorista', dispatch, response, requestGetMotoristaActions, options)
    .then(data => {
        dispatch(setMotorista(data))
    })
}

export async function createMotorista(
    dispatch: DispatchType,
    user: UserData,
    values: any,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    
}

export async function updateMotorista(
    dispatch: DispatchType,
    user: UserData,
    values: any,
    idMotorista: string,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {
    
}

export async function deleteMotorista(
    dispatch: DispatchType,
    user: UserData,
    motorista: Motorista,
    setOpenModal: Function
) {
    
}
