import Table from "./components/Container/Table"
import TableRow from "./components/Container/Row"
import TableBody from "./components/Container/Body"
import TableHeader from "./components/Container/Header"
import TableColumn from "./components/Container/Column"
import TableConfig from "./components/Config"
import TableLoader from "./components/Loader"
import TableOption from "./components/Option"
import TableMessage from "./components/Message"
import TableWrapper from "./components/Container/Wrapper"
import TableOptions from "./components/Container/Options"
import TablePaginator from "./components/Paginator"

export default Table
export {
    TablePaginator,
    TableConfig,
    TableWrapper,
    Table,
    TableOptions,
    TableHeader,
    TableBody,
    TableRow,
    TableColumn,
    TableOption,
    TableMessage,
    TableLoader,
}