import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import PageTitle from '@components/Page/Title'
import Loader from '@components/Feedback/Loader'
import { getViagem, getViagemDocumentos, getViagemFinalizada } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormViagem from './components/FormViagem'
import Section from '@components/Page/Section'
import { getSolicitacao } from '@modules/solicitacao/controllers/solicitacaoController'

const ViagemAtiva: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagem } = useAppSelector(s => s.viagem)
    const { solicitacao } = useAppSelector(s => s.solicitacao)
    const { requestGetViagem } = useAppSelector(s => s.requestViagem)
    const { requestGetSolicitacao } = useAppSelector(s => s.requestSolicitacao)

    const { idViagem } = useParams()

    const CRViagem = conditionaRender(requestGetViagem, viagem)
    const CRSolicitacao = conditionaRender(requestGetSolicitacao, solicitacao)
    

    useEffect(() => {
        if(!!idViagem){
            getViagem(dispatch, idViagem)
            getViagemDocumentos(dispatch, idViagem)
            getViagemFinalizada(dispatch, idViagem)
            getSolicitacao(dispatch, idViagem)
            // getViagem(dispatch, idViagem)
            getViagemDocumentos(dispatch, idViagem)
        }
    }, [dispatch, idViagem])

    return(

        <>
            <PageTitle title = {`Detalhes da Viagem ${idViagem}`} />
            {(CRViagem.LOADING || CRSolicitacao.LOADING) && (
                <Section align="center">
                    <Loader />
                </Section>
            )}
            {(CRViagem.DATA && CRSolicitacao.DATA) && (
                <FormViagem />
            )}
        </>

    )
}

export default ViagemAtiva