import React, { useEffect, useState } from 'react'
import { TableColumn, TableConfig, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import FormSearchVeiculos from '../FormSearchVeiculos'
import { mdiRefresh, mdiTableCog } from '@mdi/js'
import { Chip } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import ButtonGroup from '@components/Common/Button/Group'
import Button from '@components/Common/Button'

const veiculos = [
  {
    placa: "EW0-2528",
    tipo: "Carreta",
    rastreador: null,
    bloqueador: null,
    requisitante: "ANTONIO ROBERTO",
    status: [
      { tipo: "Solicitado", data: "24/04/2024" },
      { tipo: "Expirado", data: "24/04/2024" },
      { tipo: "Inválido", data: "24/04/2024" }
    ]
  },
  {
    placa: "EW0-2528",
    tipo: "Cavalo",
    rastreador: "Sascar - 2349rfnn",
    bloqueador: "T4S - sddcswerfef",
    requisitante: "ANTONIO ROBERTO",
    status: [
      { tipo: "Válido", data: "24/04/2024" },
      { tipo: "Solicitado", data: "24/04/2024" },
      { tipo: "Solicitado", data: "24/04/2024" }
    ]
  }
];


const TableVeiculos: React.FC = () => {

  const dispatch = useAppDispatch()
  const { veiculos } = useAppSelector(s => s.veiculo)
  const { requestGetVeiculos } = useAppSelector(s => s.requestVeiculo)

  const [data, setData] = useState(veiculos)
  const [openTableConfig, setOpenTableConfig] = useState(false)


  const [tableConfig, setTableConfig] = useState(createTableConfig(
    'veiculos',
    ["Placa", "Tipo", "Rastreador Principal", "Bloqueador Secundário"]
  ))


  const CR = conditionaRender(requestGetVeiculos, veiculos, true)

  useEffect(() => {
    if (!!veiculos) {
      setData(veiculos)
    }
  }, [veiculos])


  return (

    <>
      <TablePaginator
        data={data}
        config={tableConfig}
        conditionalRender={CR}
        options={
          <>
            <ButtonGroup>
              <Button
                icon={mdiTableCog}
                onClick={() => setOpenTableConfig(true)}
              />
            </ButtonGroup>

            <FormSearchVeiculos />
          </>
        }
        renderItem={(item, index) => {
          const {placa, tipo, rastreador, bloqueador} = item

          return (
            <TableRow key={index}>
              <TableColumn>{placa || "-"}</TableColumn>
              <TableColumn className='capitalize'>{tipo || "-"}</TableColumn>
              <TableColumn>{rastreador || "-"}</TableColumn>
              <TableColumn>{bloqueador || "-"}</TableColumn>
            </TableRow>
          )
        }}
      />

      <TableConfig
        openModal={openTableConfig}
        setOpenModal={setOpenTableConfig}
        tableConfig={tableConfig}
        setTableConfig={setTableConfig}
      />
    </>

  )

}

export default TableVeiculos