import moment from "moment"
import { ViagemPernoite } from "@modules/viagem/interfaces/ViagemPernoite"
import saveExcel from "@utils/saveExcel"

export default function createExcel(viagem: ViagemPernoite){
    const header = ['Sequência', 'Local', 'Tipo', 'Pernoite', 'Data prevista', 'Data da parada', 'Local para pernoite?', 'Aceita pelo motorista?', 'Observações']
    const values = viagem.paradas.map(parada => {
        return {
            'Sequência': parada.sequenciaParada,
            'Local': parada.descricao,
            'Tipo': parada.tipoParada,
            'Pernoite': parada.flagPernoite ? 'Sim' : 'Não',
            'Data prevista': !!parada.dtPrevisaoParada ? moment(parada.dtPrevisaoParada).format('DD/MM/YYYY HH:mm:ss') : '',
            'Data da parada': !!parada.dtParada ? moment(parada.dtParada).format('DD/MM/YYYY HH:mm:ss') : '',
            'Local para pernoite?' : parada.flagPernoite ? 'Sim' : 'Não',
            'Aceita pelo motorista?' : parada.flagPernoiteAceita ? 'Sim' : 'Não',
            'Observações' : parada.obsParada,
        }
    })

    saveExcel(`paradas-viagem.xlsx`, header, values)
}