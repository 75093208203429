import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getSolicitacoes } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableSolicitacao from './components/TableSolicitacao'

const SolicitacaoLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { solicitacoes } = useAppSelector(s => s.solicitacao)

    useEffect(() => {
        getSolicitacoes(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Solicitações (${solicitacoes?.length ?? 0})`} />
            <Section>
                <TableSolicitacao />
            </Section>
        </>

    )

}

export default SolicitacaoLista