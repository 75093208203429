export function jsonToParams(obj: { [key: string]: string | number }, schema: boolean = false){
    if(!!obj){
        let params = ''

        if(!!schema){
            params = '&'
        }

        for (let arr of Object.entries(obj)){
            if(!!arr[1]){
                params += `${arr[0]}=${arr[1]}&`
            }
        }
        return params.slice(0, -1)
    }
    return ''
}