import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Loader, { LoaderContainer } from '@components/Feedback/Loader'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getConferencia } from '@modules/conferencia/controllers/conferenciaController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormConferenciaEdit from './components/FormConferenciaEdit'

const ConferenciaEditar: React.FC = () => {

    const dispatch = useAppDispatch()
    const { conferencia } = useAppSelector(s => s.conferencia)
    const { requestGetConferencia } = useAppSelector(s => s.requestConferencia)
    
    const { idConferencia } = useParams()

    const CR = conditionaRender(requestGetConferencia, conferencia)

    useEffect(() => {
        if(!!idConferencia){
            getConferencia(dispatch, idConferencia)
        }
    }, [dispatch, idConferencia])

    return(

        <>
            <PageTitle title = "Editar Conferência" />
            {CR.LOADING && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            {CR.DATA && (
                <Section>
                    <FormConferenciaEdit />
                </Section>
            )}
        </>

    )

}

export default ConferenciaEditar