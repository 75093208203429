import React, { useState } from 'react'
import { mdiFlag, mdiHome, mdiMapMarker } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableWrapper, TableColumn, TableHeader, TableBody, TableMessage, TableRow, TableOption } from '@components/Common/Table'
import { useAppSelector } from '@redux/hooks'

const TableRoteiro: React.FC = () => {

    const { currentSolicitacao } = useAppSelector(s => s.solicitacao)

    const [tableConfig] = useState(createTableConfig(
        'solicitacaoRoteiro',
        ["#", "Roteiro de Viagem",]
    ))

    return (

        <>
            <TableWrapper config={tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        <TableRow>
                            <TableColumn width="40px">
                                <TableOption
                                    icon={mdiHome}
                                />
                            </TableColumn>
                            <TableColumn className='uppercase'>{`${currentSolicitacao?.remetenteCidade} - ${currentSolicitacao?.remetenteUf}`}</TableColumn>
                        </TableRow>

                        {currentSolicitacao?.lista_passagem_cidade.map((item, index) => (
                            <TableRow key={index}>
                                <TableColumn width="40px">
                                    <TableOption
                                        icon={mdiMapMarker}
                                    />
                                </TableColumn>
                                <TableColumn className='uppercase'>{`${item.cidade} - ${item.estado}`} </TableColumn>
                            </TableRow>
                        ))}

                        {currentSolicitacao?.solicitacoes.map((item, index) => (
                            <TableRow key={index}>
                                <TableColumn width="40px">
                                    <TableOption
                                        icon={mdiMapMarker}
                                    />
                                </TableColumn>
                                <TableColumn className='uppercase'>{`${item.cidadeDestino} - ${item.ufDestino}`} </TableColumn>
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableColumn width="40px">
                                <TableOption
                                    icon={mdiFlag}
                                />
                            </TableColumn>
                            <TableColumn className='uppercase'>{`${currentSolicitacao?.destinatarioCidade} - ${currentSolicitacao?.destinatarioUf}`}</TableColumn>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableWrapper>
        </>

    )

}

export default TableRoteiro