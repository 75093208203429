import React, { useState } from 'react'
import { mdiCheckCircle, mdiFilePdfBox, mdiPlusCircle, mdiText } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableBody, TableColumn, TableHeader, TableLoader, TableMessage, TableRow, TableWrapper, TableOption } from '@components/Common/Table'
import { setCurrentConferenciaValidacao } from '@modules/conferencia/reducers/conferenciaReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatDate } from '@utils/format'
import conditionaRender from '@utils/conditionalRender'
import ModalValidacao from '../ModalValidacao'
import { invalidateConferencia, validateConferencia } from '@modules/conferencia/controllers/conferenciaController'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'
import { setCurrentDocumentos, setOpenModalDocumentos } from '@modules/documento/reducers/documentoReducer'
import ModalDocumentos from '@modules/documento/components/ModalDocumentos'
import formatDocumentos from '@modules/documento/scripts/formatDocumentos'
import { ConferenciaValidacao } from '@modules/conferencia/interfaces/ConferenciaValidacao'

const TableValidacoes: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { conferenciaValidacoes, conferencia } = useAppSelector(s => s.conferencia)
    const { requestGetConferenciaValidacoes } = useAppSelector(s => s.requestConferencia)
    const { documento } = useAppSelector(s => s.documento)

    const [openModalValidacao, setOpenModalValidacao] = useState(false)
    const [tableConfig] = useState(createTableConfig(
        'conferenciaValidacoes',
        ["Tipo", "Usuário", "Validado em", ""]
    ))

    const CR = conditionaRender(requestGetConferenciaValidacoes, conferenciaValidacoes)

    let validacoesUnicas: ConferenciaValidacao[] = []

    return (

        <>
            <TableWrapper config={tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {CR.LOADING && <TableLoader />}
                        {CR.NODATA && <TableMessage text="Nenhuma validação encontrada!" />}
                        {CR.DATA && conferenciaValidacoes
                            ?.filter(item => {
                                if (validacoesUnicas.some(v => v.descricao.toLocaleLowerCase() === item.descricao.toLocaleLowerCase())) return false

                                validacoesUnicas.push(item)
                                return true
                            })
                            ?.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableColumn>{item.descricao}</TableColumn>
                                        <TableColumn>{!!item.usuarioValidacao ? item.usuarioValidacao.nome : ''}</TableColumn>
                                        <TableColumn>{formatDate(item.dtValidado)}</TableColumn>
                                        <TableColumn width="120px" className='justify-end flex'>
                                            {item.descricao === 'Documentação' && (
                                                <TableOption
                                                    icon={mdiFilePdfBox}
                                                    onClick={() => {
                                                        dispatch(setCurrentDocumentos(formatDocumentos(!!documento ? documento : [] as any)))

                                                        dispatch(setOpenModalDocumentos(true))
                                                    }}
                                                />
                                            )}
                                            <TableOption
                                                icon={mdiText}
                                                onClick={() => {
                                                    dispatch(setCurrentConferenciaValidacao(item))

                                                    setOpenModalValidacao(true)
                                                }}
                                            />
                                            <TableOption
                                                icon={item.flagValidado ? mdiCheckCircle : mdiPlusCircle}
                                                status={item.flagValidado ? 'success' : undefined}
                                                onClick={() => {
                                                    if (item.descricao === 'Documentação') {
                                                        return dispatch(setAlert(createAlert('warning', "Este campo é válidado de maneira automática")))
                                                    }

                                                    conferenciaValidacoes.forEach(v => {
                                                        if (v.descricao.toLocaleLowerCase() !== item.descricao.toLocaleLowerCase()) return

                                                        v.flagValidado ? invalidateConferencia(dispatch, userData!, conferencia!, v) : validateConferencia(dispatch, userData!, conferencia!, v)
                                                    })
                                                }}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableWrapper>

            <ModalValidacao
                open={openModalValidacao}
                setOpen={setOpenModalValidacao}
            />
            <ModalDocumentos />

        </>

    )

}

export default TableValidacoes