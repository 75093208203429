import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Notificacao } from "../interfaces/Notificacao"

interface State {
    requestGetNotifications: ResponseDefault<Notificacao[]>
}

const initialState: State = {
    requestGetNotifications: responseInitialValues
}

const requestNotificationSlice = createSlice({
    name: 'requestNotification',
    initialState,
    reducers: {
        setRequestGetNotificationsData: (state: State, action: PayloadAction<ResponsePattern<Notificacao[]>>) => {
            state.requestGetNotifications.data = action.payload
            state.requestGetNotifications.loading = false
            state.requestGetNotifications.error = false
        },
        setRequestGetNotificationsLoading: (state: State) => {
            state.requestGetNotifications.loading = true
            state.requestGetNotifications.error = false
        },
        setRequestGetNotificationsError: (state: State) => {
            state.requestGetNotifications.loading = false
            state.requestGetNotifications.error = true
        },
        setRequestGetNotificationsMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetNotifications.message = action.payload
        },
        resetRequestGetNotifications: (state: State) => {
            state.requestGetNotifications = {...responseInitialValues}
        },
    }
})

const { actions } = requestNotificationSlice

export const requestGetNotificationsActions = {
    data: actions.setRequestGetNotificationsData,
    loading: actions.setRequestGetNotificationsLoading,
    error: actions.setRequestGetNotificationsError,
    message: actions.setRequestGetNotificationsMessage,
    reset: actions.resetRequestGetNotifications,
}

export default requestNotificationSlice.reducer