import { Solicitacao } from "@modules/solicitacao/interfaces/Solicitacao"
import saveExcel from "@utils/saveExcel"
import { formatCurrency, formatDate } from "@utils/format"

export default function createExcel(solicitacoes: Solicitacao[]){
    const header = ['ID', 'Usuário', 'Criado em', 'Filial', 'Tipo', 'Embarcador', 'Carga Mista', 'C. Passagem', 'Tomador', 'Destinatário', 'Origem', 'Destino', 'Mercadoria', 'Contrato']
    const values = solicitacoes.map(item => {
        return {
            'ID': item.id,
            'Usuário': item.usuario.nome,
            'Criado em': formatDate(item.dtCadastro),
            'Filial': item.filial.descricao,
            'Tipo': item.tipo_operacao.descricao,
            'Embarcador': item.remetenteNome,
            'Carga Mista': item.flagCargaMista ? 'Sim' : 'Não',
            'C. Passagem': '',
            'Tomador': item.tomadorNome,
            'Destinatário': item.destinatarioNome,
            'Origem': `${item.remetenteEndereco} - ${item.remetenteUf}`,
            'Destino': `${item.destinatarioCidade} - ${item.destinatarioUf}`,
            'Mercadoria': formatCurrency(item.valorMercadoria),
            'Frete': formatCurrency(item.valorFrete),
        }
    })

    saveExcel(`solicitacoes.xlsx`, header, values)
}