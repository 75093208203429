import * as yup from 'yup'

export const formOperacaoValues = {
    codigoTipoOperacao: '',
    descricao: '',
    flagAtivo: true,
}

export const formOperacaoSchema = yup.object().shape({
    codigoTipoOperacao: yup.string().required('ID é obrigatório'),
    descricao: yup.string().required('Tipo de Operação é obrigatório'),
    flagAtivo: yup.boolean().required('Ativo é obrigatório'),
})