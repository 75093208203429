import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Veiculo } from "../interfaces/Veiculo"

interface State {
    requestGetVeiculo: ResponseDefault<Veiculo>
    requestGetVeiculos: ResponseDefault<Veiculo[]>
    requestCreateVeiculo: ResponseDefault<any>
    requestUpdateVeiculo: ResponseDefault<any>
    requestDeleteVeiculo: ResponseDefault<any>
}

const initialState: State = {
    requestGetVeiculo: responseInitialValues,
    requestGetVeiculos: responseInitialValues,
    requestCreateVeiculo: responseInitialValues,
    requestUpdateVeiculo: responseInitialValues,
    requestDeleteVeiculo: responseInitialValues,
}

const requestVeiculoSlice = createSlice({
    name: 'requestVeiculo',
    initialState,
    reducers: {
        setRequestGetVeiculoData: (state, action: PayloadAction<ResponsePattern<Veiculo>>) => {
            state.requestGetVeiculo.data = action.payload
            state.requestGetVeiculo.loading = false
            state.requestGetVeiculo.error = false
        },
        setRequestGetVeiculoLoading: (state) => {
            state.requestGetVeiculo.loading = true
            state.requestGetVeiculo.error = false
        },
        setRequestGetVeiculoError: (state) => {
            state.requestGetVeiculo.loading = false
            state.requestGetVeiculo.error = true
        },
        setRequestGetVeiculoMessage: (state, action: PayloadAction<string>) => {
            state.requestGetVeiculo.message = action.payload
        },
        resetRequestGetVeiculo: (state) => {
            state.requestGetVeiculo = {...responseInitialValues}
        },

        setRequestGetVeiculosData: (state, action: PayloadAction<ResponsePattern<Veiculo[]>>) => {
            state.requestGetVeiculos.data = action.payload
            state.requestGetVeiculos.loading = false
            state.requestGetVeiculos.error = false
        },
        setRequestGetVeiculosLoading: (state) => {
            state.requestGetVeiculos.loading = true
            state.requestGetVeiculos.error = false
        },
        setRequestGetVeiculosError: (state) => {
            state.requestGetVeiculos.loading = false
            state.requestGetVeiculos.error = true
        },
        setRequestGetVeiculosMessage: (state, action: PayloadAction<string>) => {
            state.requestGetVeiculos.message = action.payload
        },
        resetRequestGetVeiculos: (state) => {
            state.requestGetVeiculos = {...responseInitialValues}
        },

        setRequestCreateVeiculoData: (state, action: PayloadAction<any>) => {
            state.requestCreateVeiculo.data = action.payload
            state.requestCreateVeiculo.loading = false
            state.requestCreateVeiculo.error = false
        },
        setRequestCreateVeiculoLoading: (state) => {
            state.requestCreateVeiculo.loading = true
            state.requestCreateVeiculo.error = false
        },
        setRequestCreateVeiculoError: (state) => {
            state.requestCreateVeiculo.loading = false
            state.requestCreateVeiculo.error = true
        },
        setRequestCreateVeiculoMessage: (state, action: PayloadAction<string>) => {
            state.requestCreateVeiculo.message = action.payload
        },
        resetRequestCreateVeiculo: (state) => {
            state.requestCreateVeiculo = {...responseInitialValues}
        },

        setRequestUpdateVeiculoData: (state, action: PayloadAction<any>) => {
            state.requestUpdateVeiculo.data = action.payload
            state.requestUpdateVeiculo.loading = false
            state.requestUpdateVeiculo.error = false
        },
        setRequestUpdateVeiculoLoading: (state) => {
            state.requestUpdateVeiculo.loading = true
            state.requestUpdateVeiculo.error = false
        },
        setRequestUpdateVeiculoError: (state) => {
            state.requestUpdateVeiculo.loading = false
            state.requestUpdateVeiculo.error = true
        },
        setRequestUpdateVeiculoMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateVeiculo.message = action.payload
        },
        resetRequestUpdateVeiculo: (state) => {
            state.requestUpdateVeiculo = {...responseInitialValues}
        },

        setRequestDeleteVeiculoData: (state, action: PayloadAction<any>) => {
            state.requestDeleteVeiculo.data = action.payload
            state.requestDeleteVeiculo.loading = false
            state.requestDeleteVeiculo.error = false
        },
        setRequestDeleteVeiculoLoading: (state) => {
            state.requestDeleteVeiculo.loading = true
            state.requestDeleteVeiculo.error = false
        },
        setRequestDeleteVeiculoError: (state) => {
            state.requestDeleteVeiculo.loading = false
            state.requestDeleteVeiculo.error = true
        },
        setRequestDeleteVeiculoMessage: (state, action: PayloadAction<string>) => {
            state.requestDeleteVeiculo.message = action.payload
        },
        resetRequestDeleteVeiculo: (state) => {
            state.requestDeleteVeiculo = {...responseInitialValues}
        },
    }
})

const actions = requestVeiculoSlice.actions

export const requestGetVeiculoActions = {
    data: actions.setRequestGetVeiculoData,
    loading: actions.setRequestGetVeiculoLoading,
    error: actions.setRequestGetVeiculoError,
    message: actions.setRequestGetVeiculoMessage,
    reset: actions.resetRequestGetVeiculo,
}

export const requestGetVeiculosActions = {
    data: actions.setRequestGetVeiculosData,
    loading: actions.setRequestGetVeiculosLoading,
    error: actions.setRequestGetVeiculosError,
    message: actions.setRequestGetVeiculosMessage,
    reset: actions.resetRequestGetVeiculos,
}

export const requestCreateVeiculoActions = {
    data: actions.setRequestCreateVeiculoData,
    loading: actions.setRequestCreateVeiculoLoading,
    error: actions.setRequestCreateVeiculoError,
    message: actions.setRequestCreateVeiculoMessage,
    reset: actions.resetRequestCreateVeiculo,
}

export const requestUpdateVeiculoActions = {
    data: actions.setRequestUpdateVeiculoData,
    loading: actions.setRequestUpdateVeiculoLoading,
    error: actions.setRequestUpdateVeiculoError,
    message: actions.setRequestUpdateVeiculoMessage,
    reset: actions.resetRequestUpdateVeiculo,
}

export const requestDeleteVeiculoActions = {
    data: actions.setRequestDeleteVeiculoData,
    loading: actions.setRequestDeleteVeiculoLoading,
    error: actions.setRequestDeleteVeiculoError,
    message: actions.setRequestDeleteVeiculoMessage,
    reset: actions.resetRequestDeleteVeiculo,
}

export default requestVeiculoSlice.reducer