import React from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { ListProps } from '../types'
import '../styles.scss'
import { firstLetterToUpperCase } from '../../../../utils/format'

const List: React.FC <ListProps> = ({ children, name }) => {

    return(

        <OverlayScrollbarsComponent defer>
            <div id = {`list${firstLetterToUpperCase(name)}`} className = "listContainer overlayScroll">{children}</div>
        </OverlayScrollbarsComponent>

    )

}

export default List