import React from 'react'
import { useAppSelector } from '@redux/hooks'

const MapPernoite: React.FC = () => {

    const { viagemPernoite } = useAppSelector(s => s.viagem)

    return(

        <iframe src = {viagemPernoite!.linkMapa} title = "Mapa" style = {{height: 400}} />

    )

}

export default MapPernoite