import DocumentoCriar from "@modules/documento/pages/DocumentoCriar"
import DocumentoEditar from "@modules/documento/pages/DocumentoEditar"
import DocumentosListaFinanceiro from "@modules/documento/pages/DocumentosListaFinanceiro"
import { RoutePageType } from "@routes/interfaces"
import Pernoite from "./pages/Pernoite"
import ViagemCriar from "./pages/ViagemCriar"
import ViagemAtiva from "./pages/ViagemAtiva"
import ViagemProtocolo from "./pages/ViagemProtocolo"
import ViagemAtivaLista from "./pages/ViagemAtivaLista"
import ViagemFinalizada from "./pages/ViagemFinalizada"
import ViagemAtivaEditar from "./pages/ViagemAtivaEditar"
import ViagemFinalizadaLista from "./pages/ViagemFinalizadaLista"
import ViagemAplicativoDashboard from "./pages/ViagemAplicativoDashboard"
import ViagemAplicativoMotorista from "./pages/ViagemAplicativoMotorista"
import SolicitacaoEditar from "@modules/solicitacao/pages/SolicitacaoEditar"
import RomaneioEditar from "@modules/romaneio/pages/RomaneioEditar"

const viagemPages: RoutePageType[] = [
    {key: 'ViagemAtivaLista', element: ViagemAtivaLista},
    {key: 'ViagemAtiva', element: ViagemAtiva},
    {key: 'ViagemAtivaEditar', element: SolicitacaoEditar},
    {key: 'ViagemAtivaRomaneioEditar', element: RomaneioEditar},
    {key: 'viagemCriar', element: ViagemCriar},
    {key: 'viagemPernoite', element: Pernoite},
    {key: 'viagemFinalizadaLista', element: ViagemFinalizadaLista},
    {key: 'viagemFinalizada', element: ViagemFinalizada},
    {key: 'viagemProtocolo', element: ViagemProtocolo},

    {key: 'viagemAplicativoDashboard', element: ViagemAplicativoDashboard},
    {key: 'viagemAplicativoMotorista', element: ViagemAplicativoMotorista},

    {key: 'viagemDocumentacaoFinanceiro', element: DocumentosListaFinanceiro},
    {key: 'viagemEditarDocumentacao', element: DocumentoEditar},
]

export default viagemPages