import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Filial } from "../interfaces/Filial"
import { TipoVeiculo } from "../interfaces/TipoVeiculo"
import { TipoOperacao } from "../interfaces/TipoOperacao"

interface State {
    tiposVeiculo: TipoVeiculo[] | null
    tiposOperacao: TipoOperacao[] | null
    filiais: Filial[] | null
}

const initialState: State = {
    tiposVeiculo: null,
    tiposOperacao: null,
    filiais: null,
}

const coreSlice = createSlice({
    name: 'core',
    initialState,
    reducers: {
        setTiposVeiculo: (state, action: PayloadAction<TipoVeiculo[] | null>) => {
            state.tiposVeiculo = action.payload
        },
        setTiposOperacao: (state, action: PayloadAction<TipoOperacao[] | null>) => {
            state.tiposOperacao = action.payload
        },
        setFiliais: (state, action: PayloadAction<Filial[] | null>) => {
            state.filiais = action.payload
        },
    }
})

export const { setTiposVeiculo, setTiposOperacao, setFiliais } = coreSlice.actions
export default coreSlice.reducer