import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { updateConferenciaValidacao } from '@modules/conferencia/controllers/conferenciaController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const ModalValidacao: React.FC<ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { currentConferenciaValidacao, conferencia } = useAppSelector(s => s.conferencia)
    const { requestUpdateConferenciaValidacao } = useAppSelector(s => s.requestConferencia)

    return (

        <>
            <Modal
                {...props}
                title="Conferência"
            >
                <Formik
                    initialValues={{
                        id: currentConferenciaValidacao?.id,
                        obs: currentConferenciaValidacao?.obs ?? '',
                    }}
                    onSubmit={v => {
                        updateConferenciaValidacao(dispatch, conferencia!, v, props.setOpen)
                    }}
                >
                    <FormikForm>
                        <Form.Container padding={false}>
                            <Form.Row columns={1}>
                                <Form.Group
                                    label="Observações"
                                    inputID="obs"
                                    inputName="obs"
                                    inputType="textarea"
                                />
                            </Form.Row>

                            <ModalActions>
                                <Button
                                    label="Fechar"
                                    onClick={() => props.setOpen(false)}
                                />
                                <Button
                                    icon={mdiContentSave}
                                    loading={requestUpdateConferenciaValidacao.loading}
                                    type="submit"
                                    label="Salvar"
                                    status="success"
                                />
                            </ModalActions>
                        </Form.Container>
                    </FormikForm>
                </Formik>
            </Modal>
        </>

    )

}

export default ModalValidacao