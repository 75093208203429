import React, { useEffect, useState } from 'react'
import { mdiPlus } from '@mdi/js'
import { useFormikContext } from 'formik'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { ModalProps } from '@interfaces/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { getVeiculos } from '@modules/veiculo/controllers/veiculoController'
import FormSearchVeiculos from '@modules/veiculo/pages/VeiculoLista/components/FormSearchVeiculos'

const TableCavalo: React.FC<ModalProps> = ({ setOpen }) => {

    const dispatch = useAppDispatch()
    const { veiculos } = useAppSelector(s => s.veiculo)
    const { requestGetVeiculos } = useAppSelector(s => s.requestVeiculo)

    const [tableConfig] = useState(createTableConfig(
        'searchMotorista',
        ["Placa", "Rastreador Primário", "Bloqueador Secundário", ""]
    ))

    const { setFieldValue } = useFormikContext()

    const CR = conditionaRender(requestGetVeiculos, veiculos)

    useEffect(() => {
        getVeiculos(dispatch, '', 'cavalo')
    }, [])

    return (

        <>
            <TablePaginator
                data={veiculos}
                conditionalRender={CR}
                config={tableConfig}
                options={
                    <>
                        <div />
                        <FormSearchVeiculos type='cavalo' />
                    </>
                }
                renderItem={(item, index) => {
                    const { placa, rastreador, bloqueador } = item

                    return (
                        <TableRow key={index}>
                            <TableColumn>{placa}</TableColumn>
                            <TableColumn>{rastreador}</TableColumn>
                            <TableColumn>{bloqueador}</TableColumn>
                            <TableColumn>
                                <TableOption
                                    icon={mdiPlus}
                                    status="success"
                                    onClick={() => {
                                        setFieldValue('veiculoPlaca', placa)
                                        setFieldValue('rastreadorCavalo', rastreador)
                                        setFieldValue('bloqueadorCavalo', bloqueador)

                                        setOpen(false)
                                    }}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />
        </>

    )

}

export default TableCavalo