import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Notificacao } from "../interfaces/Notificacao"

interface State {
    notifications: Notificacao[] | null
}

const initialState: State = {
    notifications: null
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<Notificacao[] | null>) => {
            state.notifications = action.payload
        }
    }
})

export const { setNotifications } = notificationSlice.actions
export default notificationSlice.reducer