import React from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import FormCreate from './components/FormCreate'

const ClienteCriar: React.FC = () => {

    return(

        <>
            <PageTitle title = "Criar Cliente" />
            <Section>
                <FormCreate />
            </Section>
        </>

    )

}

export default ClienteCriar