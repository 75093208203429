import React, { useState } from 'react'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { useAppSelector } from '@redux/hooks'
import Table, { TableBody, TableColumn, TableHeader, TableOption, TableRow, TableWrapper } from '@components/Common/Table'
import { formatDate } from '@utils/format'
import { mdiImage, mdiMapMarker } from '@mdi/js'

const TableViagemAplicativoNotas: React.FC = () => {

    const { currentDestinatario } = useAppSelector(s => s.viagem)

    const [tableConfig] = useState(createTableConfig(
        'viagemAplicativoNotas',
        ["ID", "Situação", "Previsão", "Data da baixa",  ""]
    ))

    return(

        <>
            <TableWrapper config = {tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {currentDestinatario?.notas.map((item, index) => (
                            <TableRow key = {index} status = {item.situacao === 'Em aberto' ? 'info' : item.situacao === 'Ocorrencia' ? 'error' : 'success'}>
                                <TableColumn>{item.nrNota}</TableColumn>
                                <TableColumn>{item.situacao === 'Ocorrencia' ? `${item.notaOcorrencias[0].descricaoTipoOcorrencia}` : `${item.situacao}`}</TableColumn>
                                <TableColumn>{!!item.previsaoEntrega ? formatDate(new Date(item.previsaoEntrega!)) : 'Sem previsão de entrega'}</TableColumn>
                                <TableColumn>
                                {(item.situacao === 'Sucesso' && (
                                    !!item.baixaDtEntrega ? (new Date(item.baixaDtEntrega!)).toLocaleString() : 'Sem data de baixa'
                                )) || (item.situacao === 'Ocorrencia' && (
                                    !!item.notaOcorrencias[0].createdAt ? (new Date(item.notaOcorrencias[0].createdAt)).toLocaleString() : 'Ainda não baixado'
                                ))}
                                </TableColumn>
                                <TableColumn>
                                    {item.situacao === 'Sucesso' && (
                                        <>
                                            <TableOption
                                                icon = {mdiMapMarker}
                                                link = {`https://www.google.com.br/maps/search/${item.baixaLatitude},${item.baixaLongitude}/@${item.baixaLatitude},${item.baixaLongitude},14z`}
                                                target = "_blank"
                                            />
                                            <TableOption
                                                icon = {mdiImage}
                                                link = {item.foto}
                                                target = "_blank"
                                            />
                                        </>
                                    )}
                                    {item.situacao === 'Ocorrencia' && (
                                        <>
                                            <TableOption
                                                icon = {mdiMapMarker}
                                                link = {`https://www.google.com.br/maps/search/${item.notaOcorrencias[0].latitude!},${item.notaOcorrencias[0].longitude!}/@${item.notaOcorrencias[0].latitude!},${item.notaOcorrencias[0].longitude!},14z`}
                                                target = "_blank"
                                            />
                                            <TableOption
                                                icon = {mdiImage}
                                                link = {item.notaOcorrencias[0].foto}
                                                target = "_blank"
                                            />
                                        </>
                                    )}
                                </TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
        </>

    )

}

export default TableViagemAplicativoNotas