import React from 'react'
import Icon from '@mdi/react'
import MaskedInput from "react-text-mask"
import { mdiClose } from '@mdi/js'
import { ErrorMessage, Field } from 'formik'
import { FormGroupProps } from '@components/Common/Form/types'
import createClassName from '@utils/createClassName'

const FormGroup: React.FC<FormGroupProps> = ({ children, error, inputID, inputRows, inputName, inputType, inputPlaceholder, disabled, label, readonly, onChange, right, mask, clearInputValue, handleChange }) => {

    const inputRef = React.createRef<HTMLInputElement>()

    const inputClasses = createClassName(['formGroup', inputName], [readonly && 'status disabled', error && 'error'])

    const inputProps = {
        id: inputID,
        name: inputName,
        type: inputType ?? 'text',
        placeholder: inputPlaceholder,
        disabled,
        readOnly: readonly,
    }

    // React.useEffect(() => {
    //     if(inputRef.current && readonly) inputRef.current.setAttribute('readonly', 'readonly')
    // }, [inputRef, readonly])

    return (

        <div className={inputClasses}>
            {!!label && <label htmlFor={inputID}>{label}</label> || <div className="h-[18px]" />}
            <div className="inputWrapper">
                {(inputType === 'textarea' && <Field {...inputProps} innerRef={inputRef} rows={inputRows} component="textarea" onKeyUp={onChange} />) || (
                    (!!mask && (
                        <Field
                            name={inputName}
                            innerRef={inputRef}
                            onKeyUp={onChange}
                            readOnly={readonly}
                            render={({ field }: any) => (
                                <MaskedInput
                                    {...field}
                                    readOnly={readonly}
                                    mask={mask}
                                    id={inputID}
                                    type={inputType ?? 'text'}
                                    placeholder={inputPlaceholder}
                                    onChange={handleChange}
                                />
                            )}
                        />
                    )) || (
                        <Field {...inputProps} innerRef={inputRef} onKeyUp={onChange} />
                    )
                )}
                {right}
                {clearInputValue && (
                    <button
                        className="absolute bg-red-500 top-3 p-1 right-2 rounded-md"
                        onClick={() => {
                            clearInputValue(inputID, "")
                        }}
                    >
                        <Icon path={mdiClose} size="12px" color="white" />
                    </button>
                )}
            </div>
            <ErrorMessage name={inputName} className="error" component="h6" />
            {children}
        </div>

    )

}

export default FormGroup