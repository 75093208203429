import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Form as FormikForm, Formik } from 'formik'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import Form from '@components/Common/Form'
import { ModalProps } from '@interfaces/Modal'
import { createOperacao } from '@modules/operacao/controllers/operacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormOperacaoFields from '../FormOperacaoFields'
import { formOperacaoSchema, formOperacaoValues } from '../FormOperacaoFields/config'

const ModalCreateOperacao: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestCreateOperacao } = useAppSelector(s => s.requestOperacao)

    return(

        <>
            <Modal
                {...props}
                title = "Criar Tipo de Operação"
            >
                <Formik
                    initialValues = {formOperacaoValues}
                    validationSchema = {formOperacaoSchema}
                    onSubmit = {v => {
                        createOperacao(dispatch, userData!, v, props)
                    }}
                >
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <FormOperacaoFields />

                            <ModalActions>
                                <Button
                                    type = "reset"
                                    status = "error"
                                    label = "Cancelar"
                                    onClick = {() => props.setOpen(false)}
                                />
                                <Button
                                    type = "submit"
                                    icon = {mdiContentSave}
                                    loading = {requestCreateOperacao.loading}
                                    label = "Salvar"
                                    status = "success"
                                />
                            </ModalActions>
                        </Form.Container>
                    </FormikForm>
                </Formik>
            </Modal>
        </>

    )

}

export default ModalCreateOperacao