import { CNPJAResponse } from "@modules/viagem/interfaces/CNPJAResponse"
import { SearchCliente } from "@modules/viagem/interfaces/SearchCliente"

export default function formatCNPJA(response: CNPJAResponse | null){
    if(!response) return {} as SearchCliente

    const data: SearchCliente = {
        nroDocumento: response.taxId,
        razaoSocial: response.alias,
        nome: response.company.name,
        cidade: response.address.city,
        uf: response.address.state
    }

    return data
}