import React, { useState } from 'react'
import Table, { TableWrapper, TableColumn, TableHeader, TableBody, TableRow, TableLoader } from '@components/Common/Table'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { useAppSelector } from '@redux/hooks'
import { formatDate } from '@utils/format'
import conditionaRender from '@utils/conditionalRender'

const TableNotifications: React.FC = () => {

    const { notifications } = useAppSelector(s => s.notification)
    const { requestGetNotifications } = useAppSelector(s => s.requestNotification)

    const [tableConfig] = useState(createTableConfig(
        'notificacoes', 
        ["Titulo", "Descrição", "Tipo", "Data"]
    ))

    const CR = conditionaRender(requestGetNotifications, notifications, true)

    return(

        <>
            <TableWrapper config = {tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {CR.LOADING && <TableLoader />}
                        {CR.DATA && notifications?.map((item, index) => (
                            <TableRow key = {index}>
                                <TableColumn width = {200}>{item.titulo}</TableColumn>
                                <TableColumn>{item.descricao}</TableColumn>
                                <TableColumn>{item.tipoAcao}</TableColumn>
                                <TableColumn width = {140}>{formatDate(item.dtOcorrencia)}</TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
        </>

    )

}

export default TableNotifications