import React, { useState } from 'react'
import { mdiFilePdfBox, mdiPencil } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { DocumentoFile } from '@modules/documento/interfaces/Documento'
import ModalDocumentos from '@modules/documento/components/ModalDocumentos'
import formatDocumentos from '@modules/documento/scripts/formatDocumentos'
import { setCurrentDocumentos, setOpenModalDocumentos } from '@modules/documento/reducers/documentoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatCurrency, formatDate } from '@utils/format'
import FormSearchDocument from '../FormSearchDocument'
import { Conferencia } from '@modules/conferencia/interfaces/Conferencia'
import FormSearchViagem from '@modules/viagem/components/FormSearchViagem'
import filterViagem from '@modules/viagem/utils/filterViagem'

const TableDocumentos: React.FC = () => {

    const dispatch = useAppDispatch()
    const { conferencias } = useAppSelector(s => s.conferencia)
    const { requestGetConferencias } = useAppSelector(s => s.requestConferencia)
    const [data, setData] = useState(conferencias)

    const [tableConfig] = useState(createTableConfig(
        'documentosExpedicao',
        ['ID', 'Usuário', 'Criado em', 'Motorista', 'Filial', 'Tipo', 'Embarcador', 'Carga Mista', 'C. Passagem', 'Tomador', 'Destinatário', 'Origem', 'Destino', 'Mercadoria', 'Contrato', 'Opções']
    ))

    const CR = conditionaRender(requestGetConferencias, conferencias, true)

    const handleOpenDocumentos = (conferencia: Conferencia) => {
        const { documentacoes_viagem } = conferencia

        const documentos = documentacoes_viagem[0];

        const documentosFormatados = documentos
            ? {
                CTS: documentos.CTS ? JSON.parse(documentos.CTS) as DocumentoFile[] : null,
                contrato: documentos.contrato ? JSON.parse(documentos.contrato) as DocumentoFile[] : null,
                MDF: documentos.MDF ? JSON.parse(documentos.MDF) as DocumentoFile[] : null,
            }
            : { CTS: null, contrato: null, MDF: null };

        dispatch(setCurrentDocumentos(formatDocumentos(documentosFormatados)));

        dispatch(setOpenModalDocumentos(true))
    }

    const handleSearch = (search: string) => {
        if (!!conferencias) {
            setData(conferencias!.filter(v => filterViagem(v, search)))
        }
    }

    return (

        <>
            <TablePaginator
                data={data}
                conditionalRender={CR}
                config={tableConfig}
                options={
                    <>
                        <div />
                        <FormSearchViagem
                            handleSearch={handleSearch}
                            handleReset = {() => setData(conferencias)}
                        />
                    </>
                }
                renderItem={(item, index) => (
                    <TableRow key={index}>
                        <TableColumn>{item.id}</TableColumn>
                        <TableColumn>{item.usuario.nome}</TableColumn>
                        <TableColumn>{formatDate(item.dtCadastro)}</TableColumn>
                        <TableColumn>{item.motoristaNome}</TableColumn>
                        <TableColumn>{item.filial.descricao}</TableColumn>
                        <TableColumn>{item.tipo_operacao.descricao}</TableColumn>

                        <TableColumn>{item.remetenteNome}</TableColumn>
                        <TableColumn>{item.flagCargaMista ? 'Sim' : 'Não'}</TableColumn>
                        <TableColumn>{item.lista_passagem_cidade.length}</TableColumn>
                        <TableColumn>{item.tomadorNome}</TableColumn>
                        <TableColumn>{item.destinatarioNome}</TableColumn>
                        <TableColumn>{`${item.remetenteCidade} - ${item.remetenteUf}`}</TableColumn>
                        <TableColumn>{`${item.destinatarioCidade} - ${item.destinatarioUf}`}</TableColumn>

                        <TableColumn>{formatCurrency(Number(item.valorMercadoria))}</TableColumn>
                        <TableColumn>{formatCurrency(Number(item.valorFrete))}</TableColumn>

                        <TableColumn>
                            <TableOption
                                icon={mdiFilePdfBox}
                                onClick={() => {
                                    handleOpenDocumentos(item)
                                }}
                            />
                            <TableOption
                                icon={mdiPencil}
                                link={`${item.id}`}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />

            <ModalDocumentos />
        </>

    )

}

export default TableDocumentos