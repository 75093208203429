import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Loader from '@components/Feedback/Loader'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import SectionBox from '@components/Page/Section/box'
import { getViagem } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormRomaneio from './components/FormRomaneio'
import TableRomaneioSolicitacoes from './components/TableRomaneioSolicitacoes'

const Romaneio: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagem } = useAppSelector(s => s.viagem)
    const { requestGetViagem } = useAppSelector(s => s.requestViagem)
    
    const { id } = useParams()

    const CR = conditionaRender(requestGetViagem, viagem)

    useEffect(() => {
        if(!!id){
            getViagem(dispatch, id)
        }
    }, [dispatch, id])

    return(

        <>
            <PageTitle title = "Romaneio" />
            {CR.LOADING && (
                <Section align = "center">
                    <Loader />
                </Section>
            )}
            {CR.DATA && (
                <>
                    <Section>
                        <SectionBox>
                            <FormRomaneio />
                        </SectionBox>
                    </Section>
                    <Section>
                        <TableRomaneioSolicitacoes />
                    </Section>
                </>
            )}
        </>

    )

}

export default Romaneio