import React, { useEffect, useState } from 'react'
import { mdiDelete, mdiPencil, mdiPlus } from '@mdi/js'
import Button from '@components/Common/Button'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import ModalConfirm from '@components/Feedback/ModalConfirm'
import List, { ListColumn, ListLoader, ListMessage, ListOption, ListRow, ListSection } from '@components/Common/List'
import { getAcessoGrupos } from '@modules/acesso/controllers/grupoController'
import { setCurrentAcessoUsuario } from '@modules/acesso/reducers/usuario/usuarioReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { deleteAcessoUsuario, getAcessoUsuarios } from '../../controllers/usuarioController'
import ModalEdit from './components/ModalEdit'
import ModalCreate from './components/ModalCreate'

const AcessoUsuarios: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { acessoUsuarios, currentAcessoUsuario } = useAppSelector(s => s.acessoUsuario)
    const { requestGetUsuarios, requestRemoveUsuario } = useAppSelector(s => s.requestAcessoUsuario)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalEdit, setOpenModalEdit] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false)

    const listGrid = "40px repeat(3, 1fr) 70px"

    const CR = conditionaRender(requestGetUsuarios, acessoUsuarios, true)

    useEffect(() => {
        if(!!userData){
            getAcessoUsuarios(dispatch, userData)
            getAcessoGrupos(dispatch, userData)
        }
    }, [dispatch, userData])

    return(

        <>
            <Section name = "usuarios">
                <SectionBox 
                    title = "Usuários" 
                    padding = {false} 
                    right = {
                        <Button
                            icon = {mdiPlus}
                            label = "Criar usuário"
                            status = "success"
                            onClick = {() => setOpenModalCreate(true)}
                        />
                    }
                >
                    <List name = "usuarios">
                        <ListSection type = "header">
                            <ListRow grid = {listGrid}>
                                {["ID", "Nome", "Login", "Grupo de acesso", "Opções"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                            </ListRow>
                        </ListSection>
                        <ListSection>
                            {CR.NODATA && <ListMessage text = "Nenhum usuário encontrado" />}
                            {CR.LOADING && <ListLoader />}
                            {CR.DATA && acessoUsuarios!.map((item, index) => (
                                <ListRow key = {index} grid = {listGrid} zebra = {index % 2 === 0}>
                                    <ListColumn>{item.id}</ListColumn>
                                    <ListColumn>{item.nome}</ListColumn>
                                    <ListColumn>{item.login}</ListColumn>
                                    <ListColumn>{item.grupoAcesso?.nome}</ListColumn>
                                    <ListColumn>
                                        <ListOption
                                            icon = {mdiPencil}
                                            onClick = {() => {
                                                dispatch(setCurrentAcessoUsuario(item))
                                                setOpenModalEdit(true)
                                            }}
                                        />
                                        <ListOption
                                            icon = {mdiDelete}
                                            status = "error"
                                            onClick = {() => {
                                                dispatch(setCurrentAcessoUsuario(item))
                                                setOpenModalDelete(true)
                                            }}
                                        />
                                    </ListColumn>

                                    
                                </ListRow>
                            ))}
                        </ListSection>
                    </List>
                </SectionBox>
            </Section>

            <ModalCreate open = {openModalCreate} setOpen = {setOpenModalCreate} />
            <ModalEdit open = {openModalEdit} setOpen = {setOpenModalEdit} />
            <ModalConfirm
                open = {openModalDelete}
                setOpen = {setOpenModalDelete}
                title = "Excluir usuário"
                message = "Deseja realmente excluir este usuário?"
                loading = {requestRemoveUsuario.loading}
                onConfirm = {() => {
                    deleteAcessoUsuario(dispatch, userData!, { idUsuario: currentAcessoUsuario?.id, idUsuarioExclusao: userData!.id })
                }}
            />
        </>

    )

}

export default AcessoUsuarios