import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getViagensAtivas } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableEmViagem from './components/TableEmViagem'
import ChartEmViagem from './components/ChartEmViagem'
import './styles.scss'

const ViagemAtivaLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagensAtivas } = useAppSelector(s => s.viagem)

    useEffect(() => {
        getViagensAtivas(dispatch)
    }, [dispatch])

    return(

        <>
            <PageTitle goBack={false} title = {`Em viagem (${viagensAtivas?.length ?? 0})`} />
            <Section>
                <ChartEmViagem />
            </Section>
            <Section>
                <TableEmViagem />
            </Section>
        </>

    )

}

export default ViagemAtivaLista