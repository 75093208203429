import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableMotoristas from './components/TableMotoristas'
import { getMotoristas } from '@modules/motorista/controllers/motoristaController'

const MotoristaLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { motoristas } = useAppSelector(s => s.motorista)

    useEffect(() => {
        getMotoristas(dispatch)
    }, [dispatch])
    
    return(

        <>
            <PageTitle goBack={false} title = {`Motoristas (${motoristas?.length ?? 0})`} />
            <Section>
                <TableMotoristas />
            </Section>
        </>

    )

}

export default MotoristaLista