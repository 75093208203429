import React from 'react'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalMessage from '@components/Common/Modal/Message'
import ModalActions from '@components/Common/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { useAppSelector } from '@redux/hooks'

const ModalParticularidadesCliente: React.FC <ModalProps> = props => {

    const { currentCliente } = useAppSelector(s => s.cliente)

    return(

        <Modal
            {...props}
            title = "Particularidades"
        >
            <ModalMessage message = {currentCliente?.particularidades || ''} />
            <ModalActions>
                <Button
                    label = "Fechar"
                    onClick = {() => props.setOpen(false)}
                />
            </ModalActions>
        </Modal>

    )

}

export default ModalParticularidadesCliente