import React from 'react'
import { mdiAccessPoint, mdiAlertCircleOutline, mdiCheckCircleOutline, mdiTruckFastOutline } from '@mdi/js'
import Box from '@components/Common/Box'
import Loader from '@components/Feedback/Loader'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const ChartViagemMotorista: React.FC = () => {

    const { viagemAplicativoMotorista, chartViagemMotorista } = useAppSelector(s => s.viagem)
    const { requestGetViagemAplicativoMotorista } = useAppSelector(s => s.requestViagem)

    const CR = conditionaRender(requestGetViagemAplicativoMotorista, viagemAplicativoMotorista)

    return(

        <>
            {CR.LOADING && <Loader />}
            {CR.DATA && (
                <Box.Group columns = {4}>
                    <Box.Item
                        statusTitle = "Total"
                        theme = "status info"
                        padding = "32px 24px"
                    >
                        <Box.Section>
                            <Box.Icon
                                icon = {mdiAccessPoint}
                                title = {chartViagemMotorista?.totalViagens}
                                size = "32px"
                            />
                        </Box.Section>
                    </Box.Item>

                    <Box.Item
                        statusTitle = "Em aberto"
                        theme = "status warning"
                        padding = "32px 24px"
                    >
                        <Box.Section>
                            <Box.Icon
                                icon = {mdiTruckFastOutline}
                                title = {chartViagemMotorista?.totalViagensAtiva}
                                size = "32px"
                            />
                        </Box.Section>
                    </Box.Item>

                    <Box.Item
                        statusTitle = "Sucesso"
                        theme = "status success"
                        padding = "32px 24px"
                    >
                        <Box.Section>
                            <Box.Icon
                                icon = {mdiCheckCircleOutline}
                                title = {chartViagemMotorista?.totalViagensSucesso}
                                size = "32px"
                            />
                        </Box.Section>
                    </Box.Item>

                    <Box.Item
                        statusTitle = "Insucesso"
                        theme = "status error"
                        padding = "32px 24px"
                    >
                        <Box.Section>
                            <Box.Icon
                                icon = {mdiAlertCircleOutline}
                                title = {chartViagemMotorista?.totalViagensOcorrencia}
                                size = "32px"
                            />
                        </Box.Section>
                    </Box.Item>
                </Box.Group>
            )}
        </>

    )

}

export default ChartViagemMotorista