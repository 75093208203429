import { Cidade, IBGECidadesResponse } from "@modules/viagem/interfaces/IBGECidadesResponse"

export default function formatCidades(response: IBGECidadesResponse | null){
    if(!response) return [] as Cidade[]

    const data: Cidade[] = response.map((c) => {
        return {
            id: c.id,
            nome: c.nome
        }
    })

    return data
}