import { RouteType } from "@routes/interfaces"

const cadastroRoutes: RouteType[] = [
    {
        key: 'cadastro',
        path: 'cadastro',
        title: 'Cadastro',
        userVerification: true,
        subRoutes: [
            { key: 'cadastroMotoristaLista', path: 'motorista', title: 'Motoristas', userVerification: true },
            { key: 'cadastroMotoristaCriar', path: 'motorista/criar', title: 'Criar Motorista', userVerification: true },
            
            { key: 'cadastroVeiculoLista', path: 'veiculo', title: 'Veículos', userVerification: true },
            { key: 'cadastroVeiculoCriar', path: 'veiculo/criar', title: 'Criar Veículo', userVerification: true },

            { key: 'cadastroClienteLista', path: 'cliente', title: 'Clientes', userVerification: true },
            { key: 'cadastroClienteCriar', path: 'cliente/criar', title: 'Criar Cliente', userVerification: true },
            { key: 'cadastroClienteEditar', path: 'cliente/:idCliente', title: 'Cliente', userVerification: true },

            { key: 'cadastroFilialLista', path: 'filial', title: 'Filiais', userVerification: true },
            { key: 'cadastroFilialCriar', path: 'filial/criar', title: 'Filiais', userVerification: true },
            { key: 'cadastroFilialEditar', path: 'filial/:idFilial', title: 'Filial', userVerification: true },
            
            { key: 'cadastroTransportadoraLista', path: 'transportadora', title: 'Transportadoras', userVerification: true },
            { key: 'cadastroTransportadoraCriar', path: 'transportadora/criar', title: 'Criar Transportadora', userVerification: true },
            { key: 'cadastroTransportadoraEditar', path: 'transportadora/:idTransportadora', title: 'Transportadora', userVerification: true },
        
            { key: 'cadastroOperacaoLista', path: 'operacao', title: 'Tipos de operação', userVerification: true },
        ]
    }
]

export default cadastroRoutes