import React from 'react'
import Tab from '@components/Common/Tab'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import TabGuep from './components/tabs/Guep'
import TabOpen from './components/tabs/Open'
import TabGlobus from './components/tabs/Globus'

const MotoristaCriar: React.FC = () => {

    const tabs = [
        {title: 'GUEP', content: <TabGuep />},
        {title: 'OPEN', content: <TabOpen />},
        {title: 'GLOBUS', content: <TabGlobus />},
    ]

    return(

        <>
            <PageTitle title = "Criar Motorista" />
            <Section>
                <Tab tabs = {tabs} />
            </Section>
        </>

    )

}

export default MotoristaCriar