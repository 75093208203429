import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Viagem } from "../interfaces/Viagem"
import { ViagemOne } from "../interfaces/ViagemOne"
import { ViagemAtiva } from "../interfaces/ViagemAtiva"
import { ViagemPosicao } from "../interfaces/ViagemPosicao"
import { SearchCliente } from "../interfaces/SearchCliente"
import { ViagemGraficos } from "../interfaces/ViagemGraficos"
import { ViagemValidacao } from "../interfaces/ViagemValidacao"
import { SearchMotorista } from "../interfaces/SearchMotorista"
import { ViagemDocumento } from "../interfaces/ViagemDocumento"
import { ViagemFinalizada } from "../interfaces/ViagemFinalizada"
import { ViagemAplicativo } from "../interfaces/ViagemAplicativo"
import { ChartViagemAplicativo } from "../interfaces/ChartViagemAplicativo"
import { Parada, ViagemPernoite } from "../interfaces/ViagemPernoite"
import { ListaCto, ViagemManifesto } from "../interfaces/ViagemManifesto"
import { Destinatario, ViagemAplicativoMotorista } from "../interfaces/ViagemAplicativoMotorista"
import { createChartViagemAplicativo, createChartViagemMotorista } from "../utils/createChartViagemAplicativo"
import { Cidade } from "../interfaces/IBGECidadesResponse"

interface State {
    allViagens: Viagem[] | null

    viagens: Viagem[] | null
    viagem: ViagemOne | null

    currentViagem: Viagem | null

    viagensAtivas: ViagemAtiva[] | null
    currentViagemAtiva: ViagemAtiva | null

    viagensGraficos: ViagemGraficos | null

    viagemPosicao: ViagemPosicao | null

    viagemPernoite: ViagemPernoite | null
    currentParada: Parada | null

    viagensFinalizadas: ViagemFinalizada[] | null
    viagemFinalizada: ViagemOne | null

    viagemManifestos: ViagemManifesto[] | null

    currentManifesto: ViagemManifesto | null
    selectedManifestos: ViagemManifesto[]

    currentManifestoCto: ListaCto | null

    viagemValidacoes: ViagemValidacao[] | null
    currentViagemValidacao: ViagemValidacao | null

    searchCliente: SearchCliente[] | null
    searchEmbarcador: SearchCliente[] | null
    searchDestinatario: SearchCliente[] | null
    searchMotorista: SearchMotorista[] | null
    searchTomador: SearchCliente[] | null
    searchClienteSistema: SearchCliente[] | null

    viagensAplicativo: ViagemAplicativo[] | null
    viagemAplicativoMotorista: ViagemAplicativoMotorista | null

    chartViagemAplicativo: ChartViagemAplicativo | null
    chartViagemMotorista: ChartViagemAplicativo | null

    currentDestinatario: Destinatario | null
    cidades: Cidade[] | null

    viagemDocumentos: ViagemDocumento[] | null
}

const initialState: State = {
    allViagens: null,

    viagens: null,
    viagem: null,

    currentViagem: null,

    viagensAtivas: null,
    currentViagemAtiva: null,

    viagensGraficos: null,

    viagemPosicao: null,

    viagemPernoite: null,
    currentParada: null,

    viagensFinalizadas: null,
    viagemFinalizada: null,

    viagemManifestos: null,

    currentManifesto: null,
    selectedManifestos: [],

    currentManifestoCto: null,

    viagemValidacoes: null,
    currentViagemValidacao: null,

    searchCliente: null,
    searchEmbarcador: null,
    searchDestinatario: null,
    searchMotorista: null,
    searchTomador: null,
    searchClienteSistema: null,

    viagensAplicativo: null,
    viagemAplicativoMotorista: null,

    chartViagemAplicativo: null,
    chartViagemMotorista: null,

    currentDestinatario: null,
    cidades: null,

    viagemDocumentos: null
}

const viagemSlice = createSlice({
    name: 'viagem',
    initialState,
    reducers: {
        setAllViagens: (state, action: PayloadAction<Viagem[] | null>) => {
            state.allViagens = action.payload
        },

        setViagens: (state, action: PayloadAction<Viagem[] | null>) => {
            state.viagens = action.payload
        },

        setViagem: (state, action: PayloadAction<ViagemOne | null>) => {
            state.viagem = action.payload
        },
        setCurrentViagem: (state, action: PayloadAction<Viagem | null>) => {
            state.currentViagem = action.payload
        },

        setViagensAtivas: (state, action: PayloadAction<ViagemAtiva[] | null>) => {
            state.viagensAtivas = action.payload
        },
        setCurrentViagemAtiva: (state, action: PayloadAction<ViagemAtiva | null>) => {
            state.currentViagemAtiva = action.payload
        },

        setViagensGraficos: (state, action: PayloadAction<ViagemGraficos | null>) => {
            state.viagensGraficos = action.payload
        },

        setViagemPosicao: (state, action: PayloadAction<ViagemPosicao | null>) => {
            state.viagemPosicao = action.payload
        },

        setViagensFinalizadas: (state, action: PayloadAction<ViagemFinalizada[] | null>) => {
            state.viagensFinalizadas = action.payload
        },
        setViagemFinalizada: (state, action: PayloadAction<ViagemOne | null>) => {
            state.viagemFinalizada = action.payload
        },

        setViagemPernoite: (state, action: PayloadAction<ViagemPernoite | null>) => {
            state.viagemPernoite = action.payload
        },
        setCurrentParada: (state, action: PayloadAction<Parada | null>) => {
            state.currentParada = action.payload
        },

        setViagemManifestos: (state, action: PayloadAction<ViagemManifesto[] | null>) => {
            state.viagemManifestos = action.payload
        },

        setCurrentManifesto: (state, action: PayloadAction<ViagemManifesto | null>) => {
            state.currentManifesto = action.payload
        },
        setSelectedManifesto: (state, action: PayloadAction<ViagemManifesto>) => {
            if(state.selectedManifestos.some(item => item.MANIFESTO === action.payload.MANIFESTO)) {
                state.selectedManifestos = state.selectedManifestos.filter(item => item.MANIFESTO !== action.payload.MANIFESTO)
            }else{
                state.selectedManifestos.push(action.payload)
            }
        },

        setCurrentManifestoCto: (state, action: PayloadAction<ListaCto | null>) => {
            state.currentManifestoCto = action.payload
        },

        resetSelectedManifestos: (state) => {
            state.selectedManifestos = []
        },

        setViagemValidacoes: (state, action: PayloadAction<ViagemValidacao[] | null>) => {
            state.viagemValidacoes = action.payload
        },
        setCurrentViagemValidacao: (state, action: PayloadAction<ViagemValidacao | null>) => {
            state.currentViagemValidacao = action.payload
        },

        setSearchCliente: (state, action: PayloadAction<SearchCliente[] | null>) => {
            state.searchCliente = action.payload
        },
        setSearchEmbarcador: (state, action: PayloadAction<SearchCliente[] | null>) => {
            state.searchEmbarcador = action.payload
        },
        setSearchDestinatario: (state, action: PayloadAction<SearchCliente[] | null>) => {
            state.searchDestinatario = action.payload
        },
        setSearchMotorista: (state, action: PayloadAction<SearchMotorista[] | null>) => {
            state.searchMotorista = action.payload
        },
        setSearchTomador: (state, action: PayloadAction<SearchCliente[] | null>) => {
            state.searchTomador = action.payload
        },
        setSearchClienteSistema: (state, action: PayloadAction<SearchCliente[] | null>) => {
            state.searchClienteSistema = action.payload
        },

        setViagensAplicativo: (state, action: PayloadAction<ViagemAplicativo[]>) => {
            state.chartViagemAplicativo = createChartViagemAplicativo(action.payload)
            state.viagensAplicativo = action.payload
        },
        setViagemAplicativoMotorista: (state, action: PayloadAction<ViagemAplicativoMotorista>) => {
            state.chartViagemMotorista = createChartViagemMotorista(action.payload)
            state.viagemAplicativoMotorista = action.payload
        },

        setCurrentDestinatario: (state, action: PayloadAction<Destinatario | null>) => {
            state.currentDestinatario = action.payload
        },

        setCidades: (state, action: PayloadAction<Cidade[] | null>) => {
            state.cidades = action.payload
        },

        setViagemDocumentos: (state, action: PayloadAction<ViagemDocumento[] | null>) => {
            state.viagemDocumentos = action.payload
        },
    }
})

export const {
    setAllViagens,
    setViagens, setViagem, setCurrentViagem,
    setViagensAtivas, setCurrentViagemAtiva,
    setViagensGraficos,
    setViagemPosicao,
    setViagensFinalizadas, setViagemFinalizada,
    setViagemPernoite, setCurrentParada,
    setViagemManifestos,
    setCurrentManifesto, setSelectedManifesto,
    setCurrentManifestoCto,
    resetSelectedManifestos,
    setViagemValidacoes, setCurrentViagemValidacao,
    setSearchCliente, setSearchDestinatario, setSearchMotorista, setSearchTomador, setSearchEmbarcador, setSearchClienteSistema,
    setViagensAplicativo, setViagemAplicativoMotorista,
    setCurrentDestinatario,
    setCidades,
    setViagemDocumentos
} = viagemSlice.actions
export default viagemSlice.reducer