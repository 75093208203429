import * as yup from 'yup'
import { Documento } from '@modules/documento/interfaces/Documento'
import formatFormFile from '@modules/documento/scripts/formatFormFile'

export const formEditDocumentoValues = (documento: Documento) => {
    return {
        idDocumentacoesViagem: documento?.id,
        idFilial: documento?.idFilial,
        idLista: documento?.idLista,
        idResponsavel: documento?.idResponsavel,
        documentoMotorista: documento?.documentoMotorista,
        nomeMotorista: documento?.nomeMotorista,
        status: documento?.status,

        contrato: formatFormFile(documento?.contrato),
        CTS: formatFormFile(documento?.CTS),
        MDF: formatFormFile(documento?.MDF),
    }
}

export const formEditDocumentoSchema = yup.object().shape({
    idFilial: yup.number().typeError('Campo obrigatório'),
    idLista: yup.number().typeError('Campo obrigatório'),
    idResponsavel: yup.number().typeError('Campo obrigatório'),
    documentoMotorista: yup.string().typeError('Campo obrigatório'),
    nomeMotorista: yup.string().typeError('Campo obrigatório'),
    status: yup.string().typeError('Campo obrigatório'),
})
