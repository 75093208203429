import React from 'react'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import { setOpenModalSolicitacaoListaView } from '@modules/solicitacao/reducers/solicitacaoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableRoteiro from './components/TableRoteiro'
import TableTomador from './components/TableTomador'

const ModalSolicitacaoView: React.FC = () => {

    const dispatch = useAppDispatch()
    const { openModalSolicitacaoListaView } = useAppSelector(s => s.solicitacao)

    return (

        <>
            <Modal
                title="Visualizar Solicitação"
                open={openModalSolicitacaoListaView}
                setOpen={setOpenModalSolicitacaoListaView}
                width="70%"
            >
                <TableRoteiro />

                <div className="h-[16px]" />

                <TableTomador />

                <div className='ml-auto mt-4'>
                    <Button
                        label="Fechar"
                        onClick={() => dispatch(setOpenModalSolicitacaoListaView(false))}
                    />
                </div>
            </Modal>
        </>

    )

}

export default ModalSolicitacaoView