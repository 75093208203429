import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Field, Form as FormikForm, Formik } from 'formik'
import { mdiHome, mdiHomeOutline } from '@mdi/js'
import Button from '@components/Common/Button'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import List, { ListColumn, ListOption, ListRow, ListSection } from '@components/Common/List'
import setPermissoes from '@modules/acesso/scripts/setPermissoes'
import { getAcessoModulos } from '@modules/acesso/controllers/moduloController'
import { getAcessoGrupoPermissoes, saveAcessoGrupo, saveAcessoGrupoHome } from '@modules/acesso/controllers/grupoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const AcessoGrupo: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { acessoGrupoPermissoes } = useAppSelector(s => s.acessoGrupo)
    const { acessoModulos } = useAppSelector(s => s.acessoModulo)
    const { requestGetAcessoGrupoPermissoes } = useAppSelector(s => s.requestAcessGrupo)
    const { requestGetAcessoModulo } = useAppSelector(s => s.requestAcessoModulo)

    const [newFields, setNewFields] = useState<any>()
    const [render, setRender] = useState(false)

    const { idGrupo } = useParams<any>()

    const CR_PERMISSOES = conditionaRender(requestGetAcessoGrupoPermissoes, acessoGrupoPermissoes)
    const CR_MODULOS = conditionaRender(requestGetAcessoModulo, acessoModulos)

    const getModulos = useCallback(() => {
        (async() => {
            setRender(false)
            if(!!idGrupo){
                await Promise.all([
                    getAcessoModulos(dispatch),
                    getAcessoGrupoPermissoes(dispatch, Number(idGrupo))
                ])
            }
            setRender(true)
        })()
    }, [dispatch, idGrupo])

    useEffect(() => {
        getModulos()
    }, [dispatch, getModulos, idGrupo])

    useEffect(() => {
        if(!!acessoGrupoPermissoes){
            const fields = {}
            acessoGrupoPermissoes.map(modulo => {
                return modulo.moduloMenus.map(menu => { return (fields as any)[menu.id] = menu.flagPermissao })
            })
            setNewFields(fields)
        }
    }, [acessoGrupoPermissoes])
    
    return(

        <>
            {(CR_MODULOS.DATA && CR_PERMISSOES.DATA && render) && (
                <Formik
                    initialValues = {newFields}
                    onSubmit = {v => {
                        saveAcessoGrupo(dispatch, setPermissoes(v, Number(idGrupo)), getModulos)
                    }}
                >
                    {() => (
                        <FormikForm>
                            {acessoModulos!.map((modulo, moduloIndex) => (
                                <Section key = {moduloIndex}>
                                    <SectionBox
                                        title = {modulo.nome}
                                        padding = {false}
                                    >
                                        <List name = "menu">
                                            <ListSection>
                                                {modulo.moduloMenus.map((menu, menuIndex) => {
                                                    const currentPermissoesMenu = acessoGrupoPermissoes!.find(f => f.moduloMenus.find(m => m.id === menu.id))!.moduloMenus.find(m => m.id === menu.id)!
                                                    
                                                    return(
                                                        <ListRow key = {menuIndex} grid = "auto 1fr auto">
                                                            <ListColumn>
                                                                <Field type = "checkbox" name = {menu.id} />
                                                            </ListColumn>
                                                            <ListColumn>{menu.nome}</ListColumn>
                                                            <ListColumn>
                                                                <ListOption
                                                                    icon = {currentPermissoesMenu.flagHome ? mdiHome : mdiHomeOutline}
                                                                    status = {currentPermissoesMenu.flagHome ? 'success' : undefined}
                                                                    onClick = {() => {
                                                                        saveAcessoGrupoHome(
                                                                            dispatch,
                                                                            userData!,
                                                                            {  
                                                                                operacao: {
                                                                                    idGrupoAcesso: Number(idGrupo),
                                                                                    idModuloMenu: menu.id,
                                                                                    tipo: 'flagHome',
                                                                                },
                                                                                mensagem: ''
                                                                            }, 
                                                                            getModulos
                                                                        )
                                                                    }}
                                                                />
                                                            </ListColumn>
                                                        </ListRow>
                                                    )
                                                })}
                                            </ListSection>
                                        </List>
                                    </SectionBox>
                                </Section>

                            ))}
                            <Section direction = "row" align = "center" padding = {false}>
                                <ButtonGroup>
                                    <Button
                                        type = "submit"
                                        label = "Salvar"
                                        status = "success"
                                    />
                                </ButtonGroup>
                            </Section>
                            <div style = {{marginBottom: 24}} />
                        </FormikForm>
                    )}
                </Formik>
            )}
        </>

    )

}

export default AcessoGrupo
