import React from 'react'
import Modal from '@components/Common/Modal'
import { ModalSearchClienteProps } from '@modules/viagem/interfaces/SearchClienteModal'
import TableEmbarcador from '../Table'
import Button from '@components/Common/Button'

const ModalEmbarcador: React.FC<ModalSearchClienteProps> = props => {

    return (

        <Modal
            {...props}
            title="Buscar embarcador"
            width="100%"
        >
            <TableEmbarcador {...props} />
            <div className='ml-auto mt-4'>
                <Button
                    label="Fechar"
                    onClick={() => props.setOpen(false)}
                />
            </div>
        </Modal>

    )

}

export default ModalEmbarcador