import { ViagemOne } from "@modules/viagem/interfaces/ViagemOne"

export const romaneioFormValues = (viagem: ViagemOne) => {
    const rastreadorCavalo = (viagem.rastreadorPrincipal && viagem.idRastreadorPrincipal)
        ? `${viagem.rastreadorPrincipal} - ${viagem.idRastreadorPrincipal}`
        : '';
    const bloqueadorCavalo = (viagem.bloqueador && viagem.idBloqueador)
        ? `${viagem.bloqueador} - ${viagem.idBloqueador}`
        : '';

    const valorFreteTotal = viagem.solicitacoes.reduce((acc, solicitacao) => acc + Number(solicitacao.valorFreteCobrado), 0)

    return {
        idRomaneio: viagem.id,

        idFilial: viagem.idFilial,
        idTipoOperacao: viagem.idTipoOperacao,
        AE: viagem.AE,
        SM: viagem.SM,

        remetenteDoc: viagem.remetenteDoc,
        remetenteNome: viagem.remetenteNome,
        remetenteCep: viagem.remetenteCep,
        remetenteEndereco: viagem.remetenteEndereco,
        remetenteNumero: viagem.remetenteNumero,
        remetenteComplemento: viagem.remetenteComplemento,
        remetenteBairro: viagem.remetenteBairro,
        remetenteCidade: viagem.remetenteCidade,
        remetenteUf: viagem.remetenteUf,
        remetenteResponsavel: viagem.remetenteResponsavel,
        remetenteTelefone: viagem.remetenteTelefone,

        motoristaNome: viagem.motoristaNome,
        motoristaDoc: viagem.motoristaDoc,
        motoristaCep: viagem.motoristaCep,
        motoristaEndereco: viagem.motoristaEndereco,
        motoristaNumero: viagem.motoristaNumero,
        motoristaComplemento: viagem.motoristaComplemento,
        motoristaBairro: viagem.motoristaBairro,
        motoristaCidade: viagem.motoristaCidade,
        motoristaUf: viagem.motoristaUf,
        motoristaTelefone: viagem.motoristaTelefone,

        idTipoVeiculo: viagem.idTipoVeiculo,
        veiculoPlaca: viagem.veiculoPlaca,
        veiculoPlaca1: viagem.veiculoPlaca1,
        veiculoPlaca2: viagem.veiculoPlaca2,
        veiculoPlaca3: viagem.veiculoPlaca3,

        valorMercadoria: viagem.valorMercadoria,
        valorFrete: viagem.valorFrete,
        valorFreteTotal,

        obs: viagem.obs,

        solicitacoes: viagem.solicitacoes,

        idUsuarioCadastro: viagem.idUsuarioCadastro,
        idUsuarioAlteracao: viagem.idUsuarioCadastro,

        veiculoPesquisa: '',

        flagEscolta: viagem.flagEscolta,

        destinatarioDoc: viagem.destinatarioDoc,
        destinatarioNome: viagem.destinatarioNome,
        destinatarioCidade: viagem.destinatarioCidade,
        destinatarioUf: viagem.destinatarioUf,

        motoristaContratante: viagem.motoristaContratante,

        rastreadorCavalo,
        bloqueadorCavalo,
        particularidades: ''
    }
}