import * as yup from 'yup'

export const formClienteValues = {
    valorTonelada: '',
    flagPagador: false,
    documentoCliente: '',
    nomeCliente: '',
    razaoSocial: '',
    cidadeCliente: '',
    estadoCliente: '',
    particularidades: '',
}

export const formClienteSchema = yup.object().shape({
    valorTonelada: yup.string().required('Campo obrigatório'),
    flagPagador: yup.boolean().required('Campo obrigatório'),
    documentoCliente: yup.string().required('Campo obrigatório'),
    nomeCliente: yup.string().required('Campo obrigatório'),
    cidadeCliente: yup.string().required('Campo obrigatório'),
    estadoCliente: yup.string().required('Campo obrigatório'),
    particularidades: yup.string().notRequired(),
})