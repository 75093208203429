import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Filial } from "../interfaces/Filial"
import { TipoOperacao } from "../interfaces/TipoOperacao"
import { setFiliais, setTiposOperacao, setTiposVeiculo } from "../reducers/coreReducer"
import { requestGetFiliaisActions, requestGetTiposOperacaoActions, requestGetTiposVeiculoActions } from "../reducers/requestCoreReducer"

export async function getFiliais(dispatch: DispatchType){
    initRequest(dispatch, requestGetFiliaisActions)

    const endpoint = `${ENV.APP_ENDPOINT}/filial`
    const response = await request.get<ResponsePattern<Filial[]>>({ endpoint })

    handleResponse('getFiliais', dispatch, response, requestGetFiliaisActions)
    .then(data => {
        dispatch(setFiliais(data))
    })
}

export async function getTiposOperacao(dispatch: DispatchType){
    initRequest(dispatch, requestGetTiposOperacaoActions)

    const endpoint = `${ENV.APP_ENDPOINT}/tipo-operacao`
    const response = await request.get<ResponsePattern<TipoOperacao[]>>({ endpoint })

    handleResponse('getTiposOperacao', dispatch, response, requestGetTiposOperacaoActions)
    .then(data => {
        dispatch(setTiposOperacao(data))
    })
}

export async function getTiposVeiculo(dispatch: DispatchType){
    initRequest(dispatch, requestGetTiposVeiculoActions)

    const endpoint = `${ENV.APP_ENDPOINT}/tipo-veiculo/listar`
    const response = await request.get<ResponsePattern<TipoOperacao[]>>({ endpoint })

    handleResponse('getTiposVeiculo', dispatch, response, requestGetTiposVeiculoActions)
    .then(data => {
        dispatch(setTiposVeiculo(data))
    })
}