import React from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import FormCreate from './components/FormCreate'

const SolicitacaoCriar: React.FC = () => {

    return(

        <>
            <PageTitle title = "Criar solicitação" />
            <Section>
                <FormCreate />
            </Section>
        </>

    )

}

export default SolicitacaoCriar