import { RouteType } from "@routes/interfaces"

const conferenciaRoutes: RouteType[] = [
    {
        key: 'conferencia',
        path: 'conferencia',
        title: 'Conferência',
        userVerification: true,
        subRoutes: [
            { key: 'conferenciaLista', path: '', title: 'Conferências', userVerification: true },
            // { key: 'conferenciaEditar', path: 'editar/:idConferencia', title: 'Conferência', userVerification: true },
            { key: 'conferenciaValidar', path: 'validar/:idConferencia', title: 'Validar Conferência', userVerification: true },

            { key: 'conferenciaDocumentacaoExpedicao', path: 'documento/expedicao', title: 'Documentações de Viagens', userVerification: true },
            { key: 'conferenciaEditarDocumentacao', path: 'documento/expedicao/:idDocumento', title: 'Editar Documentação', userVerification: true },

            { key: 'solicitacaoRomaneioEditar', path: 'editar-romaneio/:idRomaneio', title: 'Editar Romaneio', userVerification: true },
            { key: 'solicitacaoEditar', path: 'editar/:idSolicitacao', title: 'Editar Solicitação', userVerification: true },

        ]
    }
]

export default conferenciaRoutes