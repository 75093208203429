import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { getOperacoes } from '@modules/operacao/controllers/operacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import TableOperacao from './components/TableOperacao'

const OperacaoLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { operacoes } = useAppSelector(s => s.operacao)

    useEffect(() => {
        getOperacoes(dispatch)
    }, [dispatch])

    return (

        <>
            <PageTitle goBack={false} title={`Tipos de Operação (${operacoes?.length ?? 0})`} />

            <Section>
                <TableOperacao />
            </Section>
        </>

    )

}

export default OperacaoLista