import React, { useState } from 'react'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableWrapper, TableColumn, TableHeader, TableBody, TableMessage, TableRow } from '@components/Common/Table'
import { useAppSelector } from '@redux/hooks'

const TableTomador: React.FC = () => {

    const { currentSolicitacao } = useAppSelector(s => s.solicitacao)

    const [tableConfig] = useState(createTableConfig(
        'solicitacaoTomador', 
        ["#", "Tomadores",]
    ))

    return(

        <>
            <TableWrapper config = {tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {currentSolicitacao?.lista_tomador.length === 0 && <TableMessage text = "Nenhum tomador adicionado!" />}
                        {currentSolicitacao?.lista_tomador.map((item, index) => (
                            <TableRow key = {index}>
                                <TableColumn width = "40px">{item.id}</TableColumn>
                                <TableColumn>{item.nomeTomador} </TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
        </>

    )

}

export default TableTomador