import ENV from '@env/index'
import { UserData } from '@modules/auth/interfaces/UserData'
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { setVeiculos, setVeiculo } from '../reducers/veiculoReducer'
import { requestGetVeiculoActions, requestGetVeiculosActions } from "../reducers/requestVeiculoReducer"
import { Veiculo } from '../interfaces/Veiculo'

export async function getVeiculos(dispatch: DispatchType, search: string = '', tipo: 'cavalo' | 'carreta' | '' = '') {
    initRequest(dispatch, requestGetVeiculosActions)

    const endpoint = `${ENV.APP_ENDPOINT}/veiculo`
    const params = `?q=${search}${tipo && `&tipo=${tipo}`}`
    const response = await request.get<ResponsePattern<Veiculo[]>>({ endpoint, params })

    handleResponse('getVeiculos', dispatch, response, requestGetVeiculosActions)
        .then(data => {
            const formattedData = data.map((item) => {
                const rastreador = (item.rastreadorTipo && item.rastreadorId)
                    ? `${item.rastreadorTipo} - ${item.rastreadorId}`
                    : '';
                const bloqueador = (item.bloqueadorTipo && item.bloqueadorId)
                    ? `${item.bloqueadorTipo} - ${item.bloqueadorId}`
                    : '';

                return {
                    ...item,
                    rastreador,
                    bloqueador,
                }
            })

            dispatch(setVeiculos(formattedData))
        })
}

export async function getVeiculo(dispatch: DispatchType, idVeiculo: string) {
    initRequest(dispatch, requestGetVeiculoActions, setVeiculo)

    const options = { messages: { errorMessage: {} } }
    const endpoint = `${ENV.APP_ENDPOINT}/veiculo/getone`
    const params = `/${idVeiculo}`
    const response = await request.get<ResponsePattern<Veiculo>>({ endpoint, params })

    handleResponse('getVeiculo', dispatch, response, requestGetVeiculoActions, options)
        .then(data => {
            dispatch(setVeiculo(data))
        })
}

export async function createVeiculo(
    dispatch: DispatchType,
    user: UserData,
    values: any,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {

}

export async function updateVeiculo(
    dispatch: DispatchType,
    user: UserData,
    values: any,
    idVeiculo: string,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>
) {

}

export async function deleteVeiculo(
    dispatch: DispatchType,
    user: UserData,
    veiculo: Veiculo,
    setOpenModal: Function
) {

}
