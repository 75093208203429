import React, { useEffect, useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { mdiPencil } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import FormViagemFields from '@modules/viagem/components/DEPRACTED_FormViagemFields'
import { updateViagem } from '@modules/viagem/controllers/viagemController'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import sleep from '@utils/sleep'
import { romaneioFormValues } from './config'

const FormRomaneio: React.FC = () => {

    const dispatch = useAppDispatch()
    const { viagem } = useAppSelector(s => s.viagem)
    const { requestUpdateViagem } = useAppSelector(s => s.requestViagem)
    const [formValues, setFormValues] = useState<ReturnType<typeof romaneioFormValues>>()

    useEffect(() => {
        (async() => {
            setFormValues(undefined)
            if(!!viagem){
                await sleep(200)
                setFormValues(romaneioFormValues(viagem))
            }
        })()
    }, [viagem])

    return(

        <>
            {!!formValues && (
                <Formik
                    initialValues = {formValues}
                    onSubmit = {v => {
                        // updateViagem(dispatch, removePesquisaFields(v))
                    }}
                >
                    {({ values, setFieldValue, handleChange }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <FormViagemFields
                                    values = {values}
                                    setFieldValue = {setFieldValue}
                                    handleChange = {handleChange}
                                    showSectionDestinatario = {false}
                                    hideFields = {['tomadorDoc', 'gerenciadoraRastreio', 'idViagem']}
                                />
                                <ButtonGroup>
                                    <Button
                                        type = "submit"
                                        loading = {requestUpdateViagem.loading}
                                        status = "success"
                                        icon = {mdiPencil}
                                        label = "Salvar"
                                    />
                                </ButtonGroup>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </>

    )

}

export default FormRomaneio