import { RouteType } from "@routes/interfaces"

const solicitacaoRoutes: RouteType[] = [
    {
        key: 'solicitacao',
        path: 'solicitacao',
        title: 'Solicitação',
        userVerification: true,
        subRoutes: [
            { key: 'solicitacaoLista', path: '', title: 'Solicitações', userVerification: true },
            { key: 'solicitacaoCriar', path: 'criar', title: 'Criar Solicitação', userVerification: true },
            { key: 'solicitacaoEditar', path: 'editar/:idSolicitacao', title: 'Editar Solicitação', userVerification: true },
            
            { key: 'solicitacaoCteLista', path: 'cte', title: 'Listagem de CT-e', userVerification: true },
            
            { key: 'solicitacaoRomaneioLista', path: 'romaneio', title: 'Romaneios', userVerification: true },
            { key: 'SolicitacaoRomaneio', path: 'romaneio/:id', title: 'Romaneio', userVerification: true },
            { key: 'solicitacaoRomaneioCriar', path: 'romaneio/criar', title: 'Criar Romaneio', userVerification: true },
            { key: 'solicitacaoRomaneioEditar', path: 'romaneio/editar/:idRomaneio', title: 'Editar Romaneio', userVerification: true },
            { key: 'solicitacaoRomaneioProtocolo', path: 'romaneio/protocolo/:id', type: 'pdf', title: 'Protocolo do Romaneio', userVerification: true },
        ]
    }
]

export default solicitacaoRoutes