import { RouteType } from "@routes/interfaces"

const notificationRoutes: RouteType[] = [
    {
        key: 'notification',
        path: 'notificacao',
        title: 'Notificações',
        userVerification: true,
    }
]

export default notificationRoutes