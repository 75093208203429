import React from 'react'
import moment from 'moment'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import ButtonGroup from '@components/Common/Button/Group'
import selectStatusDocumento from '@constants/select/statusDocumento'
import { getDocumentosFinanceiro } from '@modules/documento/controllers/documentoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const FormSearchDocument: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    const grupoAcessoStatusFinanceiro = [10, 1].includes(userData!.grupoAcesso.id)

    const rowConfig = {
        columns: grupoAcessoStatusFinanceiro ? 5 : 4,
        grid: grupoAcessoStatusFinanceiro ? "auto auto auto auto auto" : "auto auto auto auto"
    }

    return(

        <>
            <Formik
                initialValues = {{
                    status: '',
                    search: '',
                    startDate: '',
                    endDate: '',
                }}
                onSubmit = {v => {
                    const startDate = !!v.startDate ? moment(v.startDate).format('L') : ''
                    const endDate = !!v.endDate ? moment(v.endDate).format('L') : ''

                    getDocumentosFinanceiro(dispatch, {...v, startDate, endDate})
                }}
            >
                {({ setFieldValue, values }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row {...rowConfig}>
                                <Form.Group
                                    label = "Pesquisar"
                                    inputName = "search"
                                    inputID = "search"
                                />

                                {grupoAcessoStatusFinanceiro && (
                                    <Select
                                        label = "Status"
                                        fieldName = "status"
                                        value = {values.status}
                                        items = {selectStatusDocumento}
                                        setFieldValue = {setFieldValue}
                                    />
                                )}

                                <Form.Group
                                    label = "Data de início"
                                    inputName = "startDate"
                                    inputID = "startDate"
                                    inputType = "date"
                                />

                                <Form.Group
                                    label = "Data de fim"
                                    inputName = "endDate"
                                    inputID = "endDate"
                                    inputType = "date"
                                />

                                <ButtonGroup>
                                    <Button
                                        icon = {mdiMagnify}
                                        status = "success"
                                        type = "submit"
                                    />
                                    <Button
                                        icon = {mdiClose}
                                        type = "reset"
                                        status = "error"
                                    />
                                </ButtonGroup>
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSearchDocument