import React from 'react'
import PageTitle from '@components/Page/Title'
import FormCreateViagem from './components/FormCreate'

const ViagemCriar: React.FC = () => {

    return(

        <>
            <PageTitle title = "Criar Viagem" />
            <FormCreateViagem />
        </>

    )

}

export default ViagemCriar