import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import { getViagensAplicativoMotorista } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch } from '@redux/hooks'
import ChartViagemMotorista from './components/ChartViagemMotorista'
import TableViagemAplicativoDestinatario from './components/TableViagemAplicativoDestinatario'
import './styles.scss'

const ViagemAplicativoMotorista: React.FC = () => {

    const dispatch = useAppDispatch()

    const { idMotorista, nomeMotorista } = useParams()

    useEffect(() => {
        getViagensAplicativoMotorista(dispatch, Number(idMotorista))
    }, [dispatch, idMotorista])

    return(

        <>
            <Section>
                <ChartViagemMotorista />
            </Section>
            <Section>
                <SectionBox title = {nomeMotorista} padding = {false}>
                    <TableViagemAplicativoDestinatario />
                </SectionBox>
            </Section>
        </>

    )

}

export default ViagemAplicativoMotorista