import React from 'react'
import Icon from '@mdi/react'
import { FieldArray } from 'formik'
import { mdiClose, mdiFilePlus, mdiFileUpload } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import { formatFilenameByURL } from '@utils/format'
import { FileSelectProps } from './types'
import './styles.scss'

const FileSelect: React.FC <FileSelectProps> = ({
    name,
    values,
    accept,
    setFieldValue
}) => {

    return(

        <>
            <div className = "fileListWrapper">
                <FieldArray name = {name}>
                    {({ push, remove }) => (
                        <>
                            <div className = "fileList">
                                {values.map((item, index) => (
                                    <div key = {index} className = "fileListItem">
                                        <div className = "itemContainer">
                                            <Form.Group
                                                label = "Código"
                                                inputID = {`${name}.${index}.id`}
                                                inputName = {`${name}.${index}.id`}
                                                inputType='number'
                                            />
                                        </div>

                                        <div className = "itemContainer">
                                            <span>{typeof item.file === 'string' ? formatFilenameByURL(item.file) : item.file?.name}</span>
                                        </div>

                                        <div className = "itemContainer">
                                            <div className = "btnWrapper">
                                                <label
                                                    className = "btn default"
                                                    htmlFor = {`${name}.${index}`}
                                                >
                                                    <Icon path = {mdiFileUpload} size = "18px" />
                                                    Selecionar arquivo
                                                </label>
                                            </div>
                                            <input
                                                id = {`${name}.${index}`}
                                                name = {`${name}.${index}`}
                                                type = "file"
                                                accept = {accept}
                                                onChange = {event => {
                                                    setFieldValue(`${name}.${index}.file`, event.target.files![0])
                                                }}
                                            />
                                        </div>

                                        <div className = "itemContainer">
                                            <Button
                                                icon = {mdiClose}
                                                status = "error"
                                                onClick = {() => remove(index)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <Button
                                icon = {mdiFilePlus}
                                label = "Adicionar arquivo"
                                status = "success"
                                onClick = {() => push({ id: '', file: null })}
                            />
                        </>
                    )}
                </FieldArray>
            </div>
        </>

    )

}

export default FileSelect