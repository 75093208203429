import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { mdiPlus } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import ModalFilial from '@modules/filial/components/Search/SearchFilial/Modal'
import { formFilialValues } from './config'
import { useAppSelector } from '@redux/hooks'

const FormFilialFields: React.FC = () => {

    const [openModalFilial, setOpenModalFilial] = useState(false)
    const { filial } = useAppSelector(s => s.filial)

    const { values, setFieldValue, handleReset } = useFormikContext<typeof formFilialValues>()

    useEffect(() => {
        if (!!filial) {
            setFieldValue('documentoFilial', filial.nroDocumento)
            setFieldValue('nomeFilial', filial.nomeFantasia)
            setFieldValue('cidadeFilial', filial.cidade)
            setFieldValue('ufFilial', filial.uf)
            setFieldValue('enderecoFilial', filial.endereco)
            setFieldValue('numeroFilial', filial.numero)
            setFieldValue('bairroFilial', filial.bairro)
            setFieldValue('cepFilial', filial.cep)
            setFieldValue('apelidoFilial', filial.descricao)
            setFieldValue('issFilial', filial.iss)
        } else {
            handleReset()
        }
    }, [filial])

    return (

        <>
            <SectionBox
                title="Filial"
                right={
                    <ButtonGroup>
                        <Button
                            icon={mdiPlus}
                            status="success"
                            onClick={() => {
                                setOpenModalFilial(true)
                            }}
                        />
                    </ButtonGroup>
                }
            >
                <Form.Section>
                    <Form.Row columns={4} grid="1fr 1fr 1fr 40px">
                        <Form.Group
                            label="Documento"
                            inputID="documentoFilial"
                            inputName="documentoFilial"
                            readonly
                        />

                        <Form.Group
                            label="Nome"
                            inputID="nomeFilial"
                            inputName="nomeFilial"
                            readonly
                        />

                        <Form.Group
                            label="Cidade"
                            inputID="cidadeFilial"
                            inputName="cidadeFilial"
                            readonly
                        />

                        <Form.Group
                            label="UF"
                            inputID="ufFilial"
                            inputName="ufFilial"
                            readonly
                        />
                    </Form.Row>
                    <div className='hidden'>
                        <Form.Group
                            label="Endereço"
                            inputID="enderecoFilial"
                            inputName="enderecoFilial"
                            readonly
                        />

                        <Form.Group
                            label="Número"
                            inputID="numeroFilial"
                            inputName="numeroFilial"
                            readonly
                        />

                        <Form.Group
                            label="Bairro"
                            inputID="bairroFilial"
                            inputName="bairroFilial"
                            readonly
                        />

                        <Form.Group
                            label="CEP"
                            inputID="cepFilial"
                            inputName="cepFilial"
                            readonly
                        />
                    </div>
                </Form.Section>
            </SectionBox>

            <SectionBox title="Geral">
                <Form.Section>
                    <Form.Row columns={2} grid="1fr 1fr">
                        <Form.Group
                            label="Apelido"
                            inputID="apelidoFilial"
                            inputName="apelidoFilial"
                        />

                        <Form.Group
                            label="ISS (%)"
                            inputID="issFilial"
                            inputName="issFilial"
                            inputType='number'
                        />
                    </Form.Row>
                </Form.Section>
            </SectionBox>

            <ModalFilial
                open={openModalFilial}
                setOpen={setOpenModalFilial}
                type='create'
            />
        </>

    )

}

export default FormFilialFields