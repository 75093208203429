import React from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import SectionBox from '@components/Page/Section/box'
import FormCreateDocumento from './components/FormCreateDocumento'

const DocumentoCriar: React.FC = () => {

    

    return(

        <>
            <PageTitle title = "Nova Documentação" />
            <Section>
                <SectionBox>
                    <FormCreateDocumento />
                </SectionBox>
            </Section>
        </>

    )

}

export default DocumentoCriar