import React, { useContext } from 'react'
import TableContext from '@components/Common/Table/context'
import TableColumn from '../Container/Column'
import { TableMessageProps } from './types'

const TableMessage: React.FC <TableMessageProps> = ({ text }) => {

    const { columns } = useContext(TableContext)

    return(

        <tr>
            <TableColumn className = "center" colSpan = {columns.length}>{text}</TableColumn>
        </tr>

    )

}

export default TableMessage