import React, { useState } from 'react'
import { Navigate } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { addAcessoGrupo } from '@modules/acesso/controllers/grupoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const ModalCriar: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestAddAcessoGrupo } = useAppSelector(s => s.requestAcessGrupo)
    const [redirect, setRedirect] = useState(false)
    const [newGrupo, setNewGrupo] = useState<number>()

    return(

        <>
            {redirect && <Navigate to = {`/acesso/grupos/${newGrupo}`} />}
            <Modal {...props} title = "Criar grupo de acesso">
                <Formik
                    initialValues = {{nome: '', idUsuarioCadastro: userData!.id}}
                    onSubmit = {v => addAcessoGrupo(dispatch, userData!, v, setNewGrupo, props.setOpen, setRedirect)}
                >
                    {() => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                </Form.Row>
                                <ModalActions>
                                    <Button
                                        type = "reset"
                                        label = "Cancelar"
                                        onClick = {() => props.setOpen(false)}
                                    />
                                    <Button
                                        type = "submit"
                                        loading = {requestAddAcessoGrupo.loading}
                                        label = "Salvar"
                                        status = "success"
                                    />
                                </ModalActions>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </>

    )

}

export default ModalCriar