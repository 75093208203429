import React, { useEffect, useState } from 'react'
import { mdiEye, mdiMicrosoftExcel } from '@mdi/js'
import Button from '@components/Common/Button'
import FormSearch from '@components/Common/FormSearch'
import ButtonGroup from '@components/Common/Button/Group'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import filterViagem from '@modules/viagem/utils/filterViagem'
import getEnderecoViagem from '@modules/viagem/utils/getEnderecoViagem'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatCurrency, formatDate } from '@utils/format'
import createExcel from '../../scripts/createExcel'

const TableViagensFinalizadas: React.FC = () => {

    const { viagensFinalizadas } = useAppSelector(s => s.viagem)
    const { requestGetViagensFinalizadas } = useAppSelector(s => s.requestViagem)

    const [data, setData] = useState(viagensFinalizadas)
    const [tableConfig] = useState(createTableConfig(
        'viagensFinalizadas',
        ["ID", "AE", "SM", "Origem", "Destino", "Motorista", "Mercadoria", "Frete", "Escolta", "Romaneio", "Finalizado", "Obs", ""]
    ))

    const CR = conditionaRender(requestGetViagensFinalizadas, viagensFinalizadas, true)

    const handleSearch = (search: string) => {
        if(!!viagensFinalizadas){
            setData(viagensFinalizadas!.filter(v => filterViagem(v, search)))
        }
    }

    useEffect(() => {
        if(!!viagensFinalizadas){
            setData(viagensFinalizadas)
        }
    }, [viagensFinalizadas])

    return(

        <>
            <TablePaginator
                data = {data}
                config = {tableConfig}
                conditionalRender = {CR}
                options = {
                    <>
                        <ButtonGroup>
                            <Button
                                icon = {mdiMicrosoftExcel}
                                status = "success"
                                onClick = {() => createExcel(viagensFinalizadas!)}
                            />
                        </ButtonGroup>

                        <FormSearch
                            getData = {v => {}}
                            handleSearch = {handleSearch}
                            handleReset = {() => setData(viagensFinalizadas)}
                        />
                    </>
                }
                renderItem = {(item, index) => {
                    const { remetenteEndereco, destinatarioEndereco } = getEnderecoViagem(item as any)
                    
                    return(
                        <TableRow key = {index}>
                            <TableColumn>{item.id}</TableColumn>
                            <TableColumn>{item.AE}</TableColumn>
                            <TableColumn>{item.SM}</TableColumn>
                            <TableColumn>
                                {remetenteEndereco}
                            </TableColumn>
                            <TableColumn>
                                {destinatarioEndereco}
                            </TableColumn>

                            <TableColumn>{item.motoristaNome}</TableColumn>

                            <TableColumn>{formatCurrency(Number(item.valorMercadoria))}</TableColumn>
                            <TableColumn>{formatCurrency(Number(item.valorFrete))}</TableColumn>

                            <TableColumn>{item.flagEscolta ? 'Sim' : 'Não'}</TableColumn>
                            <TableColumn>{item.flagRomaneio ? 'Sim' : 'Não'}</TableColumn>
                            <TableColumn>{formatDate(item.dtFinalizado)}</TableColumn>
                            <TableColumn>{item.obs}</TableColumn>

                            <TableColumn>
                                <TableOption
                                    icon = {mdiEye}
                                    link = {`${item.id}`}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />
        </>

    )

}

export default TableViagensFinalizadas