import React from 'react'
import { mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { getViagensAplicativo } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch } from '@redux/hooks'

const SearchFilter: React.FC = () => {

    const dispatch = useAppDispatch()

    return(

        <>
            <div />
            <Formik
                initialValues = {{
                    data: new Date().toISOString().slice(0, 10),
                }}
                onSubmit = {v => {
                    getViagensAplicativo(dispatch, v.data)
                }}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {2} grid = "auto auto">
                                <Form.Group
                                    label = "Data"
                                    inputName = "data"
                                    inputID = "data"
                                    inputType = "date"
                                />
                                <ButtonGroup>
                                    <Button
                                        icon = {mdiMagnify}
                                        status = "success"
                                        type = "submit"
                                    />
                                </ButtonGroup>
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default SearchFilter