import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { useParams } from 'react-router'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import ButtonGroup from '@components/Common/Button/Group'
import selectBoolean from '@constants/select/boolean'
import { ModalProps } from '@interfaces/Modal'
import { saveViagemParada } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const FormPernoiteParada: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentParada } = useAppSelector(s => s.viagem)
    const { requestSaveViagemParada } = useAppSelector(s => s.requestViagem)

    const { idViagem } = useParams()

    return (

        <>
            {!!currentParada && (
                <Formik
                    initialValues={{
                        idLista: Number(idViagem!),
                        idUsuarioAlteracao: userData!.id,
                        idParada: currentParada.id,
                        flagPernoite: currentParada.flagPernoite,
                        flagPernoiteAceita: currentParada.flagPernoiteAceita,
                        obs: currentParada.obsParada,
                    }}
                    onSubmit={v => {
                        saveViagemParada(dispatch, v, props)
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <FormikForm>
                            <Form.Container padding={false}>
                                <Form.Section>
                                    <Form.Row columns={1} grid="1fr">
                                        <Select
                                            type="outline"
                                            label="Local para pernoite?"
                                            fieldName="flagPernoite"
                                            value={values.flagPernoite}
                                            items={selectBoolean}
                                            setFieldValue={setFieldValue}
                                        />
                                        <Select
                                            type="outline"
                                            label="A pernoite foi aceita pelo motorista?"
                                            fieldName="flagPernoiteAceita"
                                            value={values.flagPernoiteAceita}
                                            items={selectBoolean}
                                            setFieldValue={setFieldValue}
                                        />
                                        <Form.Group
                                            label="Observações"
                                            inputType="textarea"
                                            inputID="obs"
                                            inputName="obs"
                                        />
                                    </Form.Row>
                                </Form.Section>
                                <ButtonGroup>
                                    <Button
                                        label="Fechar"
                                        onClick={() => props.setOpen(false)}
                                    />
                                    <Button
                                        type="submit"
                                        status="success"
                                        loading={requestSaveViagemParada.loading}
                                        label="Salvar"
                                    />
                                </ButtonGroup>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </>

    )

}

export default FormPernoiteParada