import React, { useState } from 'react'
import { mdiContentSave } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import FileSelect from '@components/Common/File/components/FileSelect'
import SectionBox from '@components/Page/Section/box'
import ModalMotorista from '@modules/viagem/components/Search/SearchMotorista/Modal'
import { createDocumento, updateDocumento } from '@modules/documento/controllers/documentoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formEditDocumentoValues } from './config'
import { getConferencia } from '@modules/conferencia/controllers/conferenciaController'
import { Navigate, useLocation, useParams } from 'react-router'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'
import { formatFilenameByURL } from '@utils/format'
import { FormFile } from '@components/Common/File/interfaces/File'

const FormEditDocumento: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { documento } = useAppSelector(s => s.documento)
    const { conferencia } = useAppSelector(s => s.conferencia)
    const { requestGetConferencia } = useAppSelector(s => s.requestConferencia)
    const { requestUpdateDocumento, requestCreateDocumento } = useAppSelector(s => s.requestDocumento)

    const [openModalMotorista, setOpenModalMotorista] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const { pathname } = useLocation()
    const urlParts = pathname.split('/');
    urlParts.pop()
    const href = urlParts.join('/');

    const { idDocumento } = useParams()

    const checkFileAndId = (
        item: FormFile,
        type: string,
        onSomeFieldIsMissing: () => void
    ) => {
        if (item.file && !item.id) {
            const fileName = typeof item.file === 'string' ? formatFilenameByURL(item.file) : item.file.name;
            dispatch(setAlert(createAlert('warning', `O arquivo de ${type} ${fileName} não possui um código anexado`)));
            onSomeFieldIsMissing()
        }
        if (!item.file && item.id) {
            dispatch(setAlert(createAlert('warning', `O ${type} de código ${item.id} não possui um PDF anexado`)));
            onSomeFieldIsMissing()
        }
    };

    return (

        <>
            {redirect && <Navigate to={`${href}`} />}

            <Formik
                initialValues={formEditDocumentoValues(documento!)}
                onSubmit={async (v) => {
                    let someFieldIsMissing = false;

                    const onSomeFieldIsMissing = () => { someFieldIsMissing = true }

                    v.contrato.forEach((c) => checkFileAndId(c, 'Contrato', onSomeFieldIsMissing))
                    if (someFieldIsMissing) return;

                    v.MDF.forEach((mdf) => checkFileAndId(mdf, 'MDF', onSomeFieldIsMissing))
                    if (someFieldIsMissing) return;

                    v.CTS.forEach((cts) => checkFileAndId(cts, 'CTE', onSomeFieldIsMissing))
                    if (someFieldIsMissing) return;

                    if (!v.idDocumentacoesViagem) {
                        createDocumento(dispatch, userData!, { ...conferencia!, ...v }, setRedirect);
                    } else {
                        updateDocumento(dispatch, userData!, v, setRedirect);
                    }

                }}
            >
                {({ values, setFieldValue }) => (
                    <FormikForm>
                        <Form.Container padding={false}>
                            <SectionBox title="Doc. Contrato">
                                <Form.Section>
                                    <Form.Row columns={1} grid="80%">
                                        <FileSelect
                                            name="contrato"
                                            values={values.contrato}
                                            accept=".pdf"
                                            setFieldValue={setFieldValue}
                                        />
                                    </Form.Row>
                                </Form.Section>
                            </SectionBox>

                            <SectionBox title="Doc. MDF-e">
                                <Form.Section>
                                    <Form.Row columns={1} grid="80%">
                                        <FileSelect
                                            name="MDF"
                                            values={values.MDF}
                                            accept=".pdf"
                                            setFieldValue={setFieldValue}
                                        />
                                    </Form.Row>
                                </Form.Section>
                            </SectionBox>

                            <SectionBox title="Doc. CTE">
                                <Form.Section>
                                    <Form.Row columns={1} grid="80%">
                                        <FileSelect
                                            name="CTS"
                                            values={values.CTS}
                                            accept=".pdf"
                                            setFieldValue={setFieldValue}
                                        />
                                    </Form.Row>
                                </Form.Section>
                            </SectionBox>

                            <Form.Row columns={2} grid="1fr auto">
                                <div />

                                <Button
                                    loading={requestCreateDocumento.loading || requestUpdateDocumento.loading}
                                    icon={mdiContentSave}
                                    label="Salvar"
                                    status="success"
                                    type="submit"
                                />
                            </Form.Row>

                            <ModalMotorista
                                open={openModalMotorista}
                                setOpen={setOpenModalMotorista}
                            />
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormEditDocumento