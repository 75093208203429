import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Form as FormikForm, Formik, useFormikContext } from 'formik'
import Modal from '@components/Common/Modal'
import Button from '@components/Common/Button'
import ModalActions from '@components/Common/Modal/Actions'
import Form, { FormRender } from '@components/Common/Form'
import { ModalProps } from '@interfaces/Modal'
import { createOperacao } from '@modules/operacao/controllers/operacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormCreateMotoristasFields from '@modules/solicitacao/components/FormCreateMotoristasFields'
import { formCreateMotoristasSchema, formCreateMotoristasValues } from '@modules/solicitacao/components/FormCreateMotoristasFields/config'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'

const ModalCreateMotorista: React.FC<ModalProps> = props => {

    const dispatch = useAppDispatch()


    const { userData } = useAppSelector(s => s.auth)
    const { setFieldValue } = useFormikContext()

    return (

        <>
            <Modal
                {...props}
                title="Solicitar criação de motorista"
            >
                <Formik
                    initialValues={formCreateMotoristasValues}
                    onSubmit={(v) => {
                        if (v.documento.replaceAll('_', '').replaceAll('-', '').replaceAll('.', '').length !== 11) {
                            dispatch(setAlert(createAlert('warning', "Informe o Documento Completo")))
                            return
                        }

                        if (!v.nome) {
                            dispatch(setAlert(createAlert('warning', "Informe um Nome")))
                            return
                        }
                        
                        setFieldValue('motoristaDoc', v.documento)
                        setFieldValue('motoristaNome', v.nome)
                        setFieldValue('motoristaContratante', userData?.nome)

                        props.setOpen(false)
                    }}
                >
                    {({ handleChange }) => (
                        <FormikForm>
                            <Form.Container padding={false}>
                                <FormCreateMotoristasFields handleChange={handleChange} />

                                <ModalActions>
                                    <Button
                                        type="reset"
                                        status="error"
                                        label="Cancelar"
                                        onClick={() => props.setOpen(false)}
                                    />
                                    <Button
                                        type="submit"
                                        icon={mdiContentSave}
                                        label="Salvar"
                                        status="success"
                                    />
                                </ModalActions>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </>

    )

}

export default ModalCreateMotorista