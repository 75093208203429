import getEnderecoViagem from "@modules/viagem/utils/getEnderecoViagem"
import { ViagemFinalizada } from "@modules/viagem/interfaces/ViagemFinalizada"
import saveExcel from "@utils/saveExcel"
import { formatCurrency, formatDate } from "@utils/format"

export default function createExcel(viagens: ViagemFinalizada[]){
    const header = ["ID", "AE/SM", "Origem", "Destino", "Motorista", "Mercadoria", "Frete", "Escolta", "Tipo", "Finalizado", "Obs", ""]
    const values = viagens.map(viagem => {
        const { remetenteEndereco, destinatarioEndereco } = getEnderecoViagem(viagem as any)

        return {
            "ID": viagem.id,
            "AE/SM": viagem.idViagem,
            "Origem": remetenteEndereco,
            "Destino": destinatarioEndereco,
            "Motorista": viagem.motoristaNome,
            "Mercadoria": formatCurrency(Number(viagem.valorMercadoria)),
            "Frete": formatCurrency(Number(viagem.valorFrete)),
            "Escolta": viagem.flagEscolta ? 'Sim' : 'Não',
            "Tipo": viagem.flagRomaneio ? 'Romaneio' : 'Demanda',
            "Finalizado": formatDate(viagem.dtFinalizado),
            "Obs": viagem.obs,
        }
    })

    saveExcel(`viagens_finalizadas.xlsx`, header, values)
}