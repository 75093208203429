import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { mdiDelete, mdiFilePdfBox, mdiPencil, mdiTableCog } from '@mdi/js'
import { Box, CircularProgress, Typography } from '@mui/material'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TableConfig, TablePaginator, TableRow } from '@components/Common/Table'
import filterViagem from '@modules/viagem/utils/filterViagem'
import FormSearchSolicitacao from '@modules/solicitacao/components/FormSearchSolicitacao'
import { setCurrentConferencia } from '@modules/conferencia/reducers/conferenciaReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatCurrency, formatDate } from '@utils/format'
import determinePercentualTheme from './scripts/determinePercentualTheme'
import ModalDeleteConferencia from '../ModalDeleteConferência'
import storage from '@utils/storage'

const TableConferencia: React.FC = () => {

    const dispatch = useAppDispatch()
    const { conferencias } = useAppSelector(s => s.conferencia)
    const { requestGetConferencias } = useAppSelector(s => s.requestConferencia)
        
    const [data, setData] = useState(conferencias)
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'conferencias',
        ['ID', 'Usuário', 'Criado em', 'Motorista', 'Filial', 'Tipo', 'Romaneio', 'Embarcador', 'Carga Mista', 'C. Passagem' ,'Tomador', 'Destinatário', 'Origem', 'Destino', 'Mercadoria', 'Contrato', 'Opções']
    ))

    const CR = conditionaRender(requestGetConferencias, conferencias, true)

    const handleSearch = (search: string) => {
        if(!!conferencias){
            setData(conferencias!.filter(v => filterViagem(v, search)))
        }
    }

    useEffect(() => {
        if(!!conferencias){
            setData(conferencias)
        }
    }, [conferencias])

    return(

        <>
            <TablePaginator
                data = {data}
                config = {tableConfig}
                conditionalRender = {CR}
                options = {
                    <>
                        <ButtonGroup>
                            <Button
                                icon = {mdiTableCog}
                                onClick = {() => setOpenTableConfig(true)}
                            />
                        </ButtonGroup> 

                        <FormSearchSolicitacao
                            handleSearch = {handleSearch}
                            handleReset = {() => setData(conferencias)}
                        />
                    </>  
                }
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.id}</TableColumn>
                        <TableColumn>{item.usuario.nome}</TableColumn>
                        <TableColumn>{formatDate(item.dtCadastro)}</TableColumn>
                        <TableColumn>{item.motoristaNome}</TableColumn>
                        <TableColumn>{item.filial.descricao}</TableColumn>
                        <TableColumn>{item.tipo_operacao.descricao}</TableColumn>
                        <TableColumn>{item.flagRomaneio ? 'Sim' : 'Não'}</TableColumn>
                        
                        <TableColumn>{item.remetenteNome}</TableColumn>
                        <TableColumn>{item.flagCargaMista ? 'Sim' : 'Não'}</TableColumn>
                        <TableColumn>{item.flagRomaneio ? item.solicitacoes.length : item.lista_passagem_cidade.length}</TableColumn>
                        <TableColumn>{item.lista_tomador.map(i => i.nomeTomador)}</TableColumn>
                        <TableColumn>{item.destinatarioNome}</TableColumn>
                        <TableColumn>{`${item.remetenteEndereco} - ${item.remetenteUf}`}</TableColumn>
                        <TableColumn>{`${item.destinatarioCidade} - ${item.destinatarioUf}`}</TableColumn>

                        <TableColumn>{formatCurrency(Number(item.valorMercadoria))}</TableColumn>
                        <TableColumn>{formatCurrency(Number(item.valorFrete))}</TableColumn>

                        <TableColumn>
                            <Link to = {`validar/${item.id}`}>
                                <Box display = 'flex' justifyContent = 'center' alignItems = 'center'>
                                    <CircularProgress
                                        variant = "determinate"
                                        value = {item.porcentagemConcluida}
                                        sx = {{
                                            color: determinePercentualTheme(item.porcentagemConcluida),
                                        }}
                                    />
                                    <Typography position = "absolute">{`${item.porcentagemConcluida}%`}</Typography>
                                </Box>
                            </Link>
                            {item.flagRomaneio && (
                                <TableOption
                                    icon={mdiFilePdfBox}
                                    link={`/solicitacao/romaneio/protocolo/${item.id}`}
                                    target='_blank'
                                    onClick={() => {
                                        storage.setObject('pdfRomaneio', item)
                                    }}

                                />
                            )}
                            <TableOption
                                icon = {mdiPencil}
                                link = {`editar${item.flagRomaneio ? '-romaneio' : ''}/${item.id}`}
                            />
                            <TableOption
                                icon = {mdiDelete}
                                status = "error"
                                onClick = {() => {
                                    dispatch(setCurrentConferencia(item))
                                    setOpenModalDelete(true)
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />

            <TableConfig
                openModal = {openTableConfig}
                setOpenModal = {setOpenTableConfig}
                tableConfig = {tableConfig}
                setTableConfig = {setTableConfig}
            />
            <ModalDeleteConferencia
                open = {openModalDelete}
                setOpen = {setOpenModalDelete}
            />
        </>

    )

}

export default TableConferencia