import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import FormCreate from './components/FormCreate'
import { useAppDispatch } from '@redux/hooks'
import { setFilial } from '@modules/filial/reducers/filialReducer'
import { formFilialValues } from '@modules/filial/components/FormFilialFields/config'

const FilialCriar: React.FC = () => {
    const dispatch = useAppDispatch()


    useEffect(() => {
        dispatch(setFilial(null))
    }, [])

    return(

        <>
            <PageTitle title = "Criar Filial" />
            <Section>
                <FormCreate />
            </Section>
        </>

    )

}

export default FilialCriar