import * as yup from 'yup'
import { FormFile } from '@components/Common/File/interfaces/File'

export const formCreateDocumentoValues = {
    idFilial: null,
    // idStatus: '',
    
    motoristaPesquisa: '',

    motoristaNome: '',
    motoristaDoc: '',
    motoristaCep: '',
    motoristaEndereco: '',
    motoristaNumero: '',
    motoristaComplemento: '',
    motoristaBairro: '',
    motoristaCidade: '',
    motoristaUf: '',
    motoristaTelefone: '',

    docContrato: [] as FormFile[],
    docMdfe: [] as FormFile[], 
    docCts: [] as FormFile[],
}

export const formCreateDocumentoSchema = yup.object().shape({
    idFilial: yup.number().typeError('Campo obrigatório'),
    // idStatus: yup.string().required('Status obrigatório'),

    // motoristaPesquisa: yup.string().required('Motorista obrigatório'),

    motoristaNome: yup.string().required('Nome obrigatório'),
    motoristaDoc: yup.string().required('Documento obrigatório'),
    motoristaCep: yup.string().required('CEP obrigatório'),
    motoristaEndereco: yup.string().required('Endereço obrigatório'),
    motoristaNumero: yup.string().required('Número obrigatório'),
    motoristaComplemento: yup.string(),
    motoristaBairro: yup.string().required('Bairro obrigatório'),
    motoristaCidade: yup.string().required('Cidade obrigatória'),
    motoristaUf: yup.string().required('UF obrigatório'),
    motoristaTelefone: yup.string().required('Telefone obrigatório'),

    // docContrato: yup.array().min(1, 'Contrato obrigatório'),
    // docMdfe: yup.array().min(1, 'MDF-e obrigatório'),
    // docCts: yup.array().min(1, 'CTE obrigatório'),
})
