import { RoutePageType } from "@routes/interfaces"
import AcessoGrupos from "./pages/AcessoGrupos"
import AcessoGrupo from "./pages/AcessoGrupo"
import AcessoUsuarios from "./pages/AcessoUsuarios"

const acessoPages: RoutePageType[] = [
    {key: 'acesso-grupos', element: AcessoGrupos},
    {key: 'acesso-grupo', element: AcessoGrupo},
    {key: 'acesso-usuarios', element: AcessoUsuarios},
]

export default acessoPages