import React, { useState } from 'react'
import { mdiClose, mdiMagnify, mdiPlus } from '@mdi/js'
import Form from '@components/Common/Form'
import Select from '@components/Common/Select'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import selectUF from '@constants/select/uf'
import selectEscolta from '@constants/select/escolta'
import { maskPlaca } from '@constants/masks'
import selectGerenciadora from '@constants/select/gerenciadora'
import { selectRastreador1, selectRastreador2 } from '@constants/select/rastreador'
import { searchMotorista, searchVeiculo } from '@modules/viagem/controllers/viagemController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'
import { formatCurrency } from '@utils/format'
import ModalMotorista from '../Search/SearchMotorista/Modal'
import ModalEmbarcador from '../Search/SearchEmbarcador/Modal'
import ModalDestinatario from '../Search/SearchDestinatario/Modal'
import { FormViagemFieldsProps } from './types'
import { destinatarioFields, motoristaFields, remententeFields } from './config'

const FormViagemFields: React.FC <FormViagemFieldsProps> = ({
    children,
    values,
    setFieldValue,
    handleChange,
    readonly = false,
    showSectionEmbarcador = true,
    showSectionDestinatario = true,
    showSectionMotorista = true,
    showSectionVeiculo = true,
    showSectionValores = true,
    showSectionRastreadores = false,
    hideFields,
    disabledFields,
    autoHide = false
}) => {

    const dispatch = useAppDispatch()
    const { filiais, tiposOperacao, tiposVeiculo } = useAppSelector(s => s.core)
    const { requestSearchMotorista, requestSearchVeiculo /*, requestSearchEmbarcador, requestSearchDestinatario*/ } = useAppSelector(s => s.requestViagem)

    const [openModalEmbarcador, setOpenModalEmbarcador] = useState(false)
    const [openModalDestinatario, setOpenModalDestinatario] = useState(false)
    const [openModalMotorista, setOpenModalMotorista] = useState(false)

    return(

        <>
            <Form.Section>
                <Form.Row columns = {4} grid = "repeat(4, 1fr)">
                    {!hideFields?.includes('idFilial') && (
                        <Select
                            type = "outline"
                            label = "Filial"
                            fieldName = "idFilial"
                            readonly = {readonly}
                            value = {values.idFilial}
                            items = {createSelectItems(filiais, 'descricao', 'id')}
                            setFieldValue = {setFieldValue}
                        />
                    )}
                    <Select
                        type = "outline"
                        label = "Tipo de operação"
                        fieldName = "idTipoOperacao"
                        readonly = {readonly}
                        value = {values.idTipoOperacao}
                        items = {createSelectItems(tiposOperacao, 'descricao', 'id')}
                        setFieldValue = {setFieldValue}
                    />
                    {!hideFields?.includes('tomadorDoc') && (
                        <Form.Group
                            label = "Documento tomador"
                            inputID = "tomadorDoc"
                            inputName = "tomadorDoc"
                            readonly = {readonly}
                            mask = {[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                    )}
                    {!hideFields?.includes('flagEscolta') && (
                        <Select
                            type = "outline"
                            label = "Escolta"
                            fieldName = "flagEscolta"
                            value = {values.flagEscolta}
                            items = {selectEscolta}
                            setFieldValue = {setFieldValue}
                        />
                    )}
                    {!hideFields?.includes('gerenciadoraRastreio') && (
                        <Select
                            type = "outline"
                            label = "Gerenciadora"
                            fieldName = "gerenciadoraRastreio"
                            value = {values.gerenciadoraRastreio}
                            items = {selectGerenciadora}
                            setFieldValue = {setFieldValue}
                        />
                    )}
                    {!hideFields?.includes('idViagem') && (
                        <Form.Group
                            label = "Nº Viagem"
                            inputID = "idViagem"
                            inputName = "idViagem"
                            readonly = {readonly}
                        />
                    )}
                </Form.Row>
            </Form.Section>

            {showSectionEmbarcador && (
                <Form.Section
                    title = "Embarcador"
                    subTitle = {`${values.remetenteNome || 'Nome pendente!'} / ${values.remetenteDoc || 'Documento pendente!'}`}
                    status = {!values.remetenteDoc ? 'warning' : 'success'}
                    autoHide = {!values.remetenteDoc ? false : autoHide}
                >
                    {!readonly && (
                        <Form.Row columns = {3} grid = "auto auto 1fr">
                            {/* <Form.Group
                                label = "Pesquisar remetente"
                                inputID = "remententePesquisa"
                                inputName = "remententePesquisa"
                                readonly = {readonly}
                            /> */}
                            <ButtonGroup>
                                {/* <Button
                                    icon = {mdiMagnify}
                                    loading = {requestSearchEmbarcador.loading}
                                    status = "success"
                                    onClick = {() => searchEmbarcador(dispatch, values.remententePesquisa, setFieldValue)}
                                /> */}
                                <Button
                                    icon = {mdiClose}
                                    status = "error"
                                    onClick = {() => {
                                        ['remententePesquisa', ...remententeFields]
                                        .forEach(field => setFieldValue(field, ''))
                                    }}
                                />
                                <Button
                                    icon = {mdiPlus}
                                    status = "info"
                                    onClick = {() => {
                                        setOpenModalEmbarcador(true)
                                    }}
                                />
                            </ButtonGroup>
                        </Form.Row>
                    )}
                    <Form.Row columns = {4} grid = "200px 1fr auto auto">
                        <Form.Group
                            label = "Documento"
                            inputID = "remetenteDoc"
                            inputName = "remetenteDoc"
                            readonly = {readonly}
                            //mask = {[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                        <Form.Group
                            label = "Nome"
                            inputID = "remetenteNome"
                            inputName = "remetenteNome"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Responsável"
                            inputID = "remetenteResponsavel"
                            inputName = "remetenteResponsavel"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Telefone"
                            inputID = "remetenteTelefone"
                            inputName = "remetenteTelefone"
                            readonly = {readonly}
                            mask = {['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                    </Form.Row>
                    <Form.Row columns = {7} grid = "100px 1fr 70px 100px 150px 200px 70px">
                        <Form.Group
                            label = "CEP"
                            inputID = "remetenteCep"
                            inputName = "remetenteCep"
                            readonly = {readonly}
                            mask = {[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                        <Form.Group
                            label = "Endereço"
                            inputID = "remetenteEndereco"
                            inputName = "remetenteEndereco"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Número"
                            inputID = "remetenteNumero"
                            inputName = "remetenteNumero"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Complemento"
                            inputID = "remetenteComplemento"
                            inputName = "remetenteComplemento"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Bairro"
                            inputID = "remetenteBairro"
                            inputName = "remetenteBairro"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Cidade"
                            inputID = "remetenteCidade"
                            inputName = "remetenteCidade"
                            readonly = {readonly}
                        />
                        <Select
                            type = "outline"
                            label = "UF"
                            fieldName = "remetenteUf"
                            value = {values.remetenteUf}
                            items = {selectUF}
                            setFieldValue = {setFieldValue}
                        />
                    </Form.Row>
                </Form.Section>
            )}

            {showSectionDestinatario && (
                <Form.Section
                    title = "Destinatário"
                    subTitle = {`${values.destinatarioNome || 'Nome pendente!'} / ${values.destinatarioDoc || 'Documento pendente!'}`}
                    status = {!values.destinatarioDoc ? 'warning' : 'success'}
                    autoHide = {!values.destinatarioDoc ? false : autoHide}
                >
                    {!readonly && (
                        <Form.Row columns = {3} grid = "auto auto 1fr">
                            {/* <Form.Group
                                label = "Pesquisar destinatário"
                                inputID = "destinatarioPesquisa"
                                inputName = "destinatarioPesquisa"
                                readonly = {readonly}
                            /> */}
                            <ButtonGroup>
                                {/* <Button
                                    icon = {mdiMagnify}
                                    loading = {requestSearchDestinatario.loading}
                                    status = "success"
                                    onClick = {() => searchDestinatario(dispatch, values.destinatarioPesquisa, setFieldValue)}
                                /> */}
                                <Button
                                    icon = {mdiClose}
                                    status = "error"
                                    onClick = {() => {
                                        ['destinatarioPesquisa', ...destinatarioFields]
                                        .forEach(field => setFieldValue(field, ''))
                                    }}
                                />
                                <Button
                                    icon = {mdiPlus}
                                    status = "info"
                                    onClick = {() => {
                                        setOpenModalDestinatario(true)
                                    }}
                                />
                            </ButtonGroup>
                        </Form.Row>
                    )}
                    <Form.Row columns = {4} grid = "200px 1fr auto auto">
                        <Form.Group
                            label = "Documento"
                            inputID = "destinatarioDoc"
                            inputName = "destinatarioDoc"
                            readonly = {readonly}
                            //mask = {[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                        <Form.Group
                            label = "Nome"
                            inputID = "destinatarioNome"
                            inputName = "destinatarioNome"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Responsável"
                            inputID = "destinatarioResponsavel"
                            inputName = "destinatarioResponsavel"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Telefone"
                            inputID = "destinatarioTelefone"
                            inputName = "destinatarioTelefone"
                            readonly = {readonly}
                            mask = {['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                    </Form.Row>
                    <Form.Row columns = {7} grid = "100px 1fr 70px 100px 150px 200px 70px">
                        <Form.Group
                            label = "CEP"
                            inputID = "destinatarioCep"
                            inputName = "destinatarioCep"
                            readonly = {readonly}
                            mask = {[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                        <Form.Group
                            label = "Endereço"
                            inputID = "destinatarioEndereco"
                            inputName = "destinatarioEndereco"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Número"
                            inputID = "destinatarioNumero"
                            inputName = "destinatarioNumero"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Complemento"
                            inputID = "destinatarioComplemento"
                            inputName = "destinatarioComplemento"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Bairro"
                            inputID = "destinatarioBairro"
                            inputName = "destinatarioBairro"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Cidade"
                            inputID = "destinatarioCidade"
                            inputName = "destinatarioCidade"
                            readonly = {readonly}
                        />
                        <Select
                            type = "outline"
                            label = "UF"
                            fieldName = "destinatarioUf"
                            readonly = {readonly}
                            value = {values.destinatarioUf}
                            items = {selectUF}
                            setFieldValue = {setFieldValue}
                        />
                    </Form.Row>
                </Form.Section>
            )}

            {showSectionMotorista && (
                <Form.Section
                    title = "Motorista"
                    subTitle = {`${values.motoristaNome || 'Nome pendente!'} / ${values.motoristaDoc || 'Documento pendente!'}`}
                    status = {!values.motoristaDoc ? 'warning' : 'success'}
                    autoHide = {!values.motoristaDoc ? false : autoHide}
                >
                    {!readonly && (
                        <Form.Row columns = {3} grid = "auto auto 1fr">
                            <Form.Group
                                label = "Pesquisar motorista"
                                inputID = "motoristaPesquisa"
                                inputName = "motoristaPesquisa"
                            />
                            <ButtonGroup>
                                <Button
                                    icon = {mdiMagnify}
                                    loading = {requestSearchMotorista.loading}
                                    status = "success"
                                    onClick = {() => searchMotorista(dispatch, values.motoristaPesquisa, setFieldValue)}
                                />
                                <Button
                                    icon = {mdiClose}
                                    status = "error"
                                    onClick = {() => {
                                        ['motoristaPesquisa', ...motoristaFields]
                                        .forEach(field => setFieldValue(field, ''))
                                    }}
                                />
                                <Button
                                    icon = {mdiPlus}
                                    status = "info"
                                    onClick = {() => {
                                        setOpenModalMotorista(true)
                                    }}
                                />
                            </ButtonGroup>
                        </Form.Row>
                    )}
                    <Form.Row columns = {2} grid = "200px 1fr 200px">
                        <Form.Group
                            label = "Documento"
                            inputID = "motoristaDoc"
                            inputName = "motoristaDoc"
                            readonly = {readonly}
                            mask = {[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                        <Form.Group
                            label = "Nome"
                            inputID = "motoristaNome"
                            inputName = "motoristaNome"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Telefone"
                            inputID = "motoristaTelefone"
                            inputName = "motoristaTelefone"
                            readonly = {readonly}
                            mask = {['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                    </Form.Row>
                    <Form.Row columns = {7} grid = "100px 1fr 70px 100px 150px 200px 70px">
                        <Form.Group
                            label = "CEP"
                            inputID = "motoristaCep"
                            inputName = "motoristaCep"
                            readonly = {readonly}
                            mask = {[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                            handleChange = {handleChange}
                        />
                        <Form.Group
                            label = "Endereço"
                            inputID = "motoristaEndereco"
                            inputName = "motoristaEndereco"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Número"
                            inputID = "motoristaNumero"
                            inputName = "motoristaNumero"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Complemento"
                            inputID = "motoristaComplemento"
                            inputName = "motoristaComplemento"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Bairro"
                            inputID = "motoristaBairro"
                            inputName = "motoristaBairro"
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Cidade"
                            inputID = "motoristaCidade"
                            inputName = "motoristaCidade"
                            readonly = {readonly}
                        />
                        <Select
                            type = "outline"
                            label = "UF"
                            fieldName = "motoristaUf"
                            readonly = {readonly}
                            value = {values.motoristaUf}
                            items = {selectUF}
                            setFieldValue = {setFieldValue}
                        />
                    </Form.Row>
                </Form.Section>
            )}

            {showSectionVeiculo && (
                <Form.Section
                    title = "Veículo"
                    subTitle = {`${values.veiculoPlaca || 'Placa pendente!'}`}
                    status = {!values.veiculoPlaca ? 'warning' : 'success'}
                    autoHide = {!values.veiculoPlaca ? false : autoHide}
                >
                    {!readonly && (
                        <Form.Row columns = {3} grid = "auto auto 1fr">
                            <Form.Group
                                label = "Pesquisar veículo"
                                inputID = "veiculoPesquisa"
                                inputName = "veiculoPesquisa"
                            />
                            <ButtonGroup>
                                <Button
                                    icon = {mdiMagnify}
                                    loading = {requestSearchVeiculo.loading}
                                    status = "success"
                                    onClick = {() => searchVeiculo(dispatch, values.veiculoPesquisa, setFieldValue)}
                                />
                                <Button
                                    icon = {mdiClose}
                                    status = "error"
                                    onClick = {() => {
                                        ['veiculoPesquisa', 'veiculoPlaca']
                                        .forEach(field => setFieldValue(field, ''))
                                    }}
                                />
                            </ButtonGroup>
                        </Form.Row>
                    )}
                    <Form.Row columns = {4} grid = "200px repeat(3, 100px)">
                        <Select
                            type = "outline"
                            label = "Tipo de veículo"
                            fieldName = "idTipoVeiculo"
                            readonly = {readonly}
                            value = {values.idTipoVeiculo}
                            items = {createSelectItems(tiposVeiculo, 'descricao', 'id')}
                            setFieldValue = {setFieldValue}
                        />
                        <Form.Group
                            label = "Placa 1"
                            inputID = "veiculoPlaca"
                            inputName = "veiculoPlaca"
                            mask = {maskPlaca}
                            handleChange = {handleChange}
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Placa 2"
                            inputID = "veiculoPlaca2"
                            inputName = "veiculoPlaca2"
                            mask = {maskPlaca}
                            handleChange = {handleChange}
                            readonly = {readonly}
                        />
                        <Form.Group
                            label = "Placa 3"
                            inputID = "veiculoPlaca3"
                            inputName = "veiculoPlaca3"
                            mask = {maskPlaca}
                            handleChange = {handleChange}
                            readonly = {readonly}
                        />
                    </Form.Row>
                </Form.Section>
            )}

            {showSectionRastreadores && (
                <Form.Section
                    title = "Rastreadores"
                >
                    <Form.Row columns = {4} grid = "1fr 1fr 1fr 1fr">
                        <Select
                            type = "outline"
                            label = "Rastreador principal"
                            fieldName = "rastreadorPrincipal"
                            value = {values.rastreadorPrincipal}
                            items = {selectRastreador1}
                            setFieldValue = {setFieldValue}
                        />
                        <Form.Group
                            label = "ID Rastreador"
                            inputID = "idRasteadorPrincipal"
                            inputName = "idRasteadorPrincipal"
                            readonly = {readonly}
                        />

                        <Select
                            type = "outline"
                            label = "Bloqueador"
                            fieldName = "bloqueador"
                            value = {values.bloqueador}
                            items = {selectRastreador2}
                            setFieldValue = {setFieldValue}
                        />
                        <Form.Group
                            label = "ID Bloqueador"
                            inputID = "idBloqueador"
                            inputName = "idBloqueador"
                            readonly = {readonly}
                        />
                    </Form.Row>
                </Form.Section>
            )}

            {showSectionValores && (
                <Form.Section
                    title = "Valores (Opcional)"
                    subTitle = {`${!!values.valorMercadoria ? formatCurrency(values.valorMercadoria) : 'Valor mercadoria pendente!'} / ${!!values.valorFrete ? formatCurrency(values.valorFrete) : 'Valor frete pendente!'}`}
                    status = {!values.valorMercadoria || !values.valorFrete ? 'warning' : 'success'}
                    autoHide = {!values.valorMercadoria || !values.valorFrete ? false : autoHide}
                >
                    <Form.Row columns = {2} grid = "repeat(2, 1fr)">
                        <Form.Currency
                            label = "Valor mercadoria"
                            name = "valorMercadoria"
                            disabled = {disabledFields?.includes('valorMercadoria') || readonly}
                        />
                        <Form.Currency
                            label = "Valor contrato"
                            name = "valorFrete"
                            disabled = {disabledFields?.includes('valorFrete') || readonly}
                        />
                    </Form.Row>
                </Form.Section>
            )}

            {children}

            <Form.Section>
                <Form.Row columns = {1}>
                    <Form.Group
                        label = "Observações"
                        inputID = "obs"
                        inputName = "obs"
                        inputType = "textarea"
                        readonly = {readonly}
                    />
                </Form.Row>
            </Form.Section>

            <ModalEmbarcador
                open = {openModalEmbarcador}
                setOpen = {setOpenModalEmbarcador}
            />
            <ModalDestinatario
                open = {openModalDestinatario}
                setOpen = {setOpenModalDestinatario}
            />
            <ModalMotorista
                open = {openModalMotorista}
                setOpen = {setOpenModalMotorista}
            />
        </>

    )

}

export default FormViagemFields