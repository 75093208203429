import * as yup from 'yup'

export const formCreateCavaloValues = {
    placa: '',
    rastreadorTipo: '',
    rastreadorID: '',
    bloqueadorTipo: '',
    bloqueadorID: '',
}

export const formCreateCavaloSchema = yup.object().shape({
    placa: yup.string().required('Placa é obrigatório'),
    rastreadorTipo: yup.string().required('Rastreador Tipo é obrigatório'),
    rastreadorID: yup.string().required('Rastreador ID é obrigatório'),
    bloqueadorTipo: yup.string().required('Bloqueador Tipo é obrigatório'),
    bloqueadorID: yup.string().required('Bloqueador ID é obrigatório'),
})