import React, { useCallback } from 'react'
//import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import { ModalProps } from './types'
import './styles.scss'
import { useAppDispatch } from '../../../redux/hooks'
import ReactPortal from '../../Base/ReactPortal'
import handleFunction from '../../../utils/handleFunction'

const Modal: React.FC <ModalProps> = ({ children, zIndex = 999, width, height, title, open, setOpen, actions, onClose }) => {

    const dispatch = useAppDispatch()

    const handleClose = useCallback(() => {
        if(open){
            if(onClose) onClose()
            handleFunction(dispatch, () => setOpen(false))
        }
    }, [dispatch, onClose, setOpen, open])

    return(

        <>
            {(open &&
                <ReactPortal>
                    <div className = "modalWrapper" style = {{zIndex}}>
                        <>
                            <ul className = {`modalBox`} style = {{ width, height }}>
                                <li className = "modalBoxHeader">
                                    <h1>{title}</h1>
                                    <span className = "iconContainer" onClick = {handleClose}> 
                                        <Icon path = {mdiClose} size = "24px" />
                                    </span>
                                </li>
                                <OverlayScrollbarsComponent element = "li" style = {{height: '100%'}} defer>
                                    <div className = "modalBoxMain">
                                        <div className = "modalBoxMainContainer">
                                            {children}
                                            {!!actions && actions.length > 0 && (
                                                <div className = "modalBoxMainActionsContainer">
                                                    {actions.map((action, index) => (
                                                        <button key = {index} className = {action.theme ?? 'default'} onClick = {() => action.onClick()}>{action.label}</button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </OverlayScrollbarsComponent>
                            </ul>
                        </>
                    </div>
                </ReactPortal>
            )}
        </>

    )

}

export default Modal