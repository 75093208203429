import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Operacao } from "../interfaces/Operacao"

interface State {
    requestGetOperacoes: ResponseDefault<Operacao[]>
    requestGetOperacao: ResponseDefault<Operacao>
    requestCreateOperacao: ResponseDefault<any>
    requestUpdateOperacao: ResponseDefault<any>
    requestDeleteOperacao: ResponseDefault<any>
}

const initialState: State = {
    requestGetOperacoes: responseInitialValues,
    requestGetOperacao: responseInitialValues,
    requestCreateOperacao: responseInitialValues,
    requestUpdateOperacao: responseInitialValues,
    requestDeleteOperacao: responseInitialValues,
}

const requestOperacaoSlice = createSlice({
    name: 'requestOperacao',
    initialState,
    reducers: {
        setRequestGetOperacoesData: (state, action: PayloadAction<ResponsePattern<Operacao[]>>) => {
            state.requestGetOperacoes.data = action.payload
            state.requestGetOperacoes.loading = false
            state.requestGetOperacoes.error = false
        },
        setRequestGetOperacoesLoading: (state) => {
            state.requestGetOperacoes.loading = true
            state.requestGetOperacoes.error = false
        },
        setRequestGetOperacoesError: (state) => {
            state.requestGetOperacoes.loading = false
            state.requestGetOperacoes.error = true
        },
        setRequestGetOperacoesMessage: (state, action: PayloadAction<string>) => {
            state.requestGetOperacoes.message = action.payload
        },
        resetRequestGetOperacoes: (state) => {
            state.requestGetOperacoes = {...responseInitialValues}
        },

        setRequestGetOperacaoData: (state, action: PayloadAction<ResponsePattern<Operacao>>) => {
            state.requestGetOperacao.data = action.payload
            state.requestGetOperacao.loading = false
            state.requestGetOperacao.error = false
        },
        setRequestGetOperacaoLoading: (state) => {
            state.requestGetOperacao.loading = true
            state.requestGetOperacao.error = false
        },
        setRequestGetOperacaoError: (state) => {
            state.requestGetOperacao.loading = false
            state.requestGetOperacao.error = true
        },
        setRequestGetOperacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestGetOperacao.message = action.payload
        },
        resetRequestGetOperacao: (state) => {
            state.requestGetOperacao = {...responseInitialValues}
        },

        setRequestCreateOperacaoData: (state, action: PayloadAction<any>) => {
            state.requestCreateOperacao.data = action.payload
            state.requestCreateOperacao.loading = false
            state.requestCreateOperacao.error = false
        },
        setRequestCreateOperacaoLoading: (state) => {
            state.requestCreateOperacao.loading = true
            state.requestCreateOperacao.error = false
        },
        setRequestCreateOperacaoError: (state) => {
            state.requestCreateOperacao.loading = false
            state.requestCreateOperacao.error = true
        },
        setRequestCreateOperacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestCreateOperacao.message = action.payload
        },
        resetRequestCreateOperacao: (state) => {
            state.requestCreateOperacao = {...responseInitialValues}
        },

        setRequestUpdateOperacaoData: (state, action: PayloadAction<any>) => {
            state.requestUpdateOperacao.data = action.payload
            state.requestUpdateOperacao.loading = false
            state.requestUpdateOperacao.error = false
        },
        setRequestUpdateOperacaoLoading: (state) => {
            state.requestUpdateOperacao.loading = true
            state.requestUpdateOperacao.error = false
        },
        setRequestUpdateOperacaoError: (state) => {
            state.requestUpdateOperacao.loading = false
            state.requestUpdateOperacao.error = true
        },
        setRequestUpdateOperacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateOperacao.message = action.payload
        },
        resetRequestUpdateOperacao: (state) => {
            state.requestUpdateOperacao = {...responseInitialValues}
        },

        setRequestDeleteOperacaoData: (state, action: PayloadAction<any>) => {
            state.requestDeleteOperacao.data = action.payload
            state.requestDeleteOperacao.loading = false
            state.requestDeleteOperacao.error = false
        },
        setRequestDeleteOperacaoLoading: (state) => {
            state.requestDeleteOperacao.loading = true
            state.requestDeleteOperacao.error = false
        },
        setRequestDeleteOperacaoError: (state) => {
            state.requestDeleteOperacao.loading = false
            state.requestDeleteOperacao.error = true
        },
        setRequestDeleteOperacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestDeleteOperacao.message = action.payload
        },
        resetRequestDeleteOperacao: (state) => {
            state.requestDeleteOperacao = {...responseInitialValues}
        },
    }
})

const actions = requestOperacaoSlice.actions

export const requestGetOperacoesActions = {
    data: actions.setRequestGetOperacoesData,
    loading: actions.setRequestGetOperacoesLoading,
    error: actions.setRequestGetOperacoesError,
    message: actions.setRequestGetOperacoesMessage,
    reset: actions.resetRequestGetOperacoes,
}

export const requestGetOperacaoActions = {
    data: actions.setRequestGetOperacaoData,
    loading: actions.setRequestGetOperacaoLoading,
    error: actions.setRequestGetOperacaoError,
    message: actions.setRequestGetOperacaoMessage,
    reset: actions.resetRequestGetOperacao,
}

export const requestCreateOperacaoActions = {
    data: actions.setRequestCreateOperacaoData,
    loading: actions.setRequestCreateOperacaoLoading,
    error: actions.setRequestCreateOperacaoError,
    message: actions.setRequestCreateOperacaoMessage,
    reset: actions.resetRequestCreateOperacao,
}

export const requestUpdateOperacaoActions = {
    data: actions.setRequestUpdateOperacaoData,
    loading: actions.setRequestUpdateOperacaoLoading,
    error: actions.setRequestUpdateOperacaoError,
    message: actions.setRequestUpdateOperacaoMessage,
    reset: actions.resetRequestUpdateOperacao,
}

export const requestDeleteOperacaoActions = {
    data: actions.setRequestDeleteOperacaoData,
    loading: actions.setRequestDeleteOperacaoLoading,
    error: actions.setRequestDeleteOperacaoError,
    message: actions.setRequestDeleteOperacaoMessage,
    reset: actions.resetRequestDeleteOperacao,
}

export default requestOperacaoSlice.reducer