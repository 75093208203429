import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { mdiPlus } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Common/Button/Group'
import ModalCliente from '@modules/viagem/components/Search/SearchCliente/Modal'
import selectBoolean from '@constants/select/boolean'
import { formClienteValues } from './config'

const FormClienteFields: React.FC = () => {

    const [openModalCliente, setOpenModalCliente] = useState(false)

    const { values, setFieldValue } = useFormikContext<typeof formClienteValues>()

    return (

        <>
            <SectionBox
                title="Cliente"
                right={
                    <ButtonGroup>
                        <Button
                            icon={mdiPlus}
                            status="success"
                            onClick={() => {
                                setOpenModalCliente(true)
                            }}
                        />
                    </ButtonGroup>
                }
            >
                <Form.Section>
                    <Form.Row columns={4} grid="1fr 1fr 1fr 40px">
                        <Form.Group
                            label="Documento"
                            inputID="documentoCliente"
                            inputName="documentoCliente"
                            readonly
                        />

                        <Form.Group
                            label="Nome"
                            inputID="nomeCliente"
                            inputName="nomeCliente"
                            readonly
                        />

                        <Form.Group
                            label="Cidade"
                            inputID="cidadeCliente"
                            inputName="cidadeCliente"
                            readonly
                        />

                        <Form.Group
                            label="UF"
                            inputID="estadoCliente"
                            inputName="estadoCliente"
                            readonly
                        />
                    </Form.Row>
                </Form.Section>
            </SectionBox>

            <SectionBox title="Geral">
                <Form.Section>
                    <Form.Row columns={2}>
                        <Form.Currency
                            label="Quanto paga por tonelada (R$/Ton)"
                            name="valorTonelada"
                            value={values?.valorTonelada}
                        />

                        <Select
                            label="Pagador"
                            items={selectBoolean}
                            value={values.flagPagador}
                            fieldName="flagPagador"
                            setFieldValue={setFieldValue}
                        />
                    </Form.Row>
                    <Form.Row columns={1}>
                        <Form.Group
                            label="Particularidades (Opcional)"
                            inputID="particularidades"
                            inputName="particularidades"
                            inputType="textarea"
                        />
                    </Form.Row>
                </Form.Section>
            </SectionBox>

            <ModalCliente
                type='create'
                open={openModalCliente}
                setOpen={setOpenModalCliente}
            />
        </>

    )

}

export default FormClienteFields