import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Viagem } from "../interfaces/Viagem"
import { ViagemOne } from "../interfaces/ViagemOne"
import { ViagemAtiva } from "../interfaces/ViagemAtiva"
import { ViagemPosicao } from "../interfaces/ViagemPosicao"
import { SearchCliente } from "../interfaces/SearchCliente"
import { ViagemPernoite } from "../interfaces/ViagemPernoite"
import { ViagemManifesto } from "../interfaces/ViagemManifesto"
import { ViagemValidacao } from "../interfaces/ViagemValidacao"
import { ViagemFinalizada } from "../interfaces/ViagemFinalizada"
import { ViagemAplicativo } from "../interfaces/ViagemAplicativo"
import { ViagemAplicativoMotorista } from "../interfaces/ViagemAplicativoMotorista"
import { ViagemDocumento } from "../interfaces/ViagemDocumento"
import { SelectItem } from "@components/Common/Select/types"

interface State {
    requestGetAllViagens: ResponseDefault<Viagem[]>

    requestGetViagens: ResponseDefault<Viagem[]>
    requestGetViagem: ResponseDefault<ViagemOne>
    requestCreateViagem: ResponseDefault<any>
    requestUpdateViagem: ResponseDefault<any>
    requestDeleteViagem: ResponseDefault<any>

    requestGetViagensAtivas: ResponseDefault<any>
    requestGetViagemAtiva: ResponseDefault<ViagemAtiva>
    requestGetViagemPosicao: ResponseDefault<ViagemPosicao>
    
    requestGetPernoite: ResponseDefault<ViagemPernoite>
    requestGetCidades: ResponseDefault<SelectItem[]>
    
    requestGetViagensFinalizadas: ResponseDefault<ViagemFinalizada[]>
    requestGetViagemFinalizada: ResponseDefault<ViagemOne>

    requestEndViagem: ResponseDefault<any>
    requestSaveVeiculoBloqueado: ResponseDefault<any>
    requestSaveViagemParada: ResponseDefault<any>
    requestSaveViagemDemanda: ResponseDefault<any>

    requestSearchCliente: ResponseDefault<SearchCliente[]>
    requestSearchMotorista: ResponseDefault<any>
    requestSearchVeiculo: ResponseDefault<any>
    requestSearchEmbarcador: ResponseDefault<SearchCliente[]>
    requestSearchDestinatario: ResponseDefault<SearchCliente[]>
    requestSearchTomador: ResponseDefault<SearchCliente[]>
    requestSearchFilial: ResponseDefault<SearchCliente[]>
    requestSearchClienteSistema: ResponseDefault<SearchCliente[]>

    requestGetViagemManifestos: ResponseDefault<ViagemManifesto[]>
    requestSaveViagemManifestos: ResponseDefault<any>
    requestDeleteViagemManifesto: ResponseDefault<any>

    requestGetViagemValidacoes: ResponseDefault<ViagemValidacao[]>
    requestUpdateViagemValidacao: ResponseDefault<any>

    requestGetViagensAplicativo: ResponseDefault<ViagemAplicativo[]>
    requestGetViagemAplicativoMotorista: ResponseDefault<ViagemAplicativoMotorista>

    requestGetViagemDocumentos: ResponseDefault<ViagemDocumento[]>
}

const initialState: State = {
    requestGetAllViagens: responseInitialValues,
    requestGetViagens: responseInitialValues,
    requestGetViagem: responseInitialValues,
    requestCreateViagem: responseInitialValues,
    requestUpdateViagem: responseInitialValues,
    requestDeleteViagem: responseInitialValues,
    requestGetViagensAtivas: responseInitialValues,
    requestGetViagemAtiva: responseInitialValues,
    requestGetViagemPosicao: responseInitialValues,
    requestSearchMotorista: responseInitialValues,
    requestSearchVeiculo: responseInitialValues,
    requestSearchEmbarcador: responseInitialValues,
    requestSearchDestinatario: responseInitialValues,
    requestSearchTomador: responseInitialValues,
    requestSearchFilial: responseInitialValues,
    requestSearchClienteSistema: responseInitialValues,
    requestGetPernoite: responseInitialValues,
    requestGetCidades: responseInitialValues,
    requestEndViagem: responseInitialValues,
    requestSaveVeiculoBloqueado: responseInitialValues,
    requestSaveViagemParada: responseInitialValues,
    requestSaveViagemDemanda: responseInitialValues,
    requestGetViagemManifestos: responseInitialValues,
    requestSaveViagemManifestos: responseInitialValues,
    requestDeleteViagemManifesto: responseInitialValues,
    requestGetViagemValidacoes: responseInitialValues,
    requestGetViagensFinalizadas: responseInitialValues,
    requestGetViagemFinalizada: responseInitialValues,
    requestUpdateViagemValidacao: responseInitialValues,
    requestSearchCliente: responseInitialValues,
    requestGetViagensAplicativo: responseInitialValues,
    requestGetViagemAplicativoMotorista: responseInitialValues,
    requestGetViagemDocumentos: responseInitialValues,
}

const requestViagemSlice = createSlice({
    name: 'requestViagem',
    initialState,
    reducers: {
        setRequestGetAllViagensData: (state: State, action: PayloadAction<ResponsePattern<Viagem[]>>) => {
            state.requestGetAllViagens.data = action.payload
            state.requestGetAllViagens.loading = false
            state.requestGetAllViagens.error = false
        },
        setRequestGetAllViagensLoading: (state: State) => {
            state.requestGetAllViagens.loading = true
            state.requestGetAllViagens.error = false
        },
        setRequestGetAllViagensError: (state: State) => {
            state.requestGetAllViagens.loading = false
            state.requestGetAllViagens.error = true
        },
        setRequestGetAllViagensMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetAllViagens.message = action.payload
        },
        resetRequestGetAllViagens: (state: State) => {
            state.requestGetAllViagens = {...responseInitialValues}
        },

        setRequestGetViagensData: (state: State, action: PayloadAction<ResponsePattern<Viagem[]>>) => {
            state.requestGetViagens.data = action.payload
            state.requestGetViagens.loading = false
            state.requestGetViagens.error = false
        },
        setRequestGetViagensLoading: (state: State) => {
            state.requestGetViagens.loading = true
            state.requestGetViagens.error = false
        },
        setRequestGetViagensError: (state: State) => {
            state.requestGetViagens.loading = false
            state.requestGetViagens.error = true
        },
        setRequestGetViagensMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagens.message = action.payload
        },
        resetRequestGetViagens: (state: State) => {
            state.requestGetViagens = {...responseInitialValues}
        },

        setRequestGetViagemData: (state: State, action: PayloadAction<ResponsePattern<ViagemOne>>) => {
            state.requestGetViagem.data = action.payload
            state.requestGetViagem.loading = false
            state.requestGetViagem.error = false
        },
        setRequestGetViagemLoading: (state: State) => {
            state.requestGetViagem.loading = true
            state.requestGetViagem.error = false
        },
        setRequestGetViagemError: (state: State) => {
            state.requestGetViagem.loading = false
            state.requestGetViagem.error = true
        },
        setRequestGetViagemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagem.message = action.payload
        },
        resetRequestGetViagem: (state: State) => {
            state.requestGetViagem = {...responseInitialValues}
        },

        setRequestCreateViagemData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateViagem.data = action.payload
            state.requestCreateViagem.loading = false
            state.requestCreateViagem.error = false
        },
        setRequestCreateViagemLoading: (state: State) => {
            state.requestCreateViagem.loading = true
            state.requestCreateViagem.error = false
        },
        setRequestCreateViagemError: (state: State) => {
            state.requestCreateViagem.loading = false
            state.requestCreateViagem.error = true
        },
        setRequestCreateViagemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateViagem.message = action.payload
        },
        resetRequestCreateViagem: (state: State) => {
            state.requestCreateViagem = {...responseInitialValues}
        },

        setRequestUpdateViagemData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateViagem.data = action.payload
            state.requestUpdateViagem.loading = false
            state.requestUpdateViagem.error = false
        },
        setRequestUpdateViagemLoading: (state: State) => {
            state.requestUpdateViagem.loading = true
            state.requestUpdateViagem.error = false
        },
        setRequestUpdateViagemError: (state: State) => {
            state.requestUpdateViagem.loading = false
            state.requestUpdateViagem.error = true
        },
        setRequestUpdateViagemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestUpdateViagem.message = action.payload
        },
        resetRequestUpdateViagem: (state: State) => {
            state.requestUpdateViagem = {...responseInitialValues}
        },

        setRequestDeleteViagemData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteViagem.data = action.payload
            state.requestDeleteViagem.loading = false
            state.requestDeleteViagem.error = false
        },
        setRequestDeleteViagemLoading: (state: State) => {
            state.requestDeleteViagem.loading = true
            state.requestDeleteViagem.error = false
        },
        setRequestDeleteViagemError: (state: State) => {
            state.requestDeleteViagem.loading = false
            state.requestDeleteViagem.error = true
        },
        setRequestDeleteViagemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestDeleteViagem.message = action.payload
        },
        resetRequestDeleteViagem: (state: State) => {
            state.requestDeleteViagem = {...responseInitialValues}
        },

        setRequestGetViagensAtivasData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetViagensAtivas.data = action.payload
            state.requestGetViagensAtivas.loading = false
            state.requestGetViagensAtivas.error = false
        },
        setRequestGetViagensAtivasLoading: (state: State) => {
            state.requestGetViagensAtivas.loading = true
            state.requestGetViagensAtivas.error = false
        },
        setRequestGetViagensAtivasError: (state: State) => {
            state.requestGetViagensAtivas.loading = false
            state.requestGetViagensAtivas.error = true
        },
        setRequestGetViagensAtivasMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagensAtivas.message = action.payload
        },
        resetRequestGetViagensAtivas: (state: State) => {
            state.requestGetViagensAtivas = {...responseInitialValues}
        },

        setRequestGetViagemAtivaData: (state: State, action: PayloadAction<ResponsePattern<ViagemAtiva>>) => {
            state.requestGetViagemAtiva.data = action.payload
            state.requestGetViagemAtiva.loading = false
            state.requestGetViagemAtiva.error = false
        },
        setRequestGetViagemAtivaLoading: (state: State) => {
            state.requestGetViagemAtiva.loading = true
            state.requestGetViagemAtiva.error = false
        },
        setRequestGetViagemAtivaError: (state: State) => {
            state.requestGetViagemAtiva.loading = false
            state.requestGetViagemAtiva.error = true
        },
        setRequestGetViagemAtivaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagemAtiva.message = action.payload
        },
        resetRequestGetViagemAtiva: (state: State) => {
            state.requestGetViagemAtiva = {...responseInitialValues}
        },

        setRequestGetViagemPosicaoData: (state: State, action: PayloadAction<ResponsePattern<ViagemPosicao>>) => {
            state.requestGetViagemPosicao.data = action.payload
            state.requestGetViagemPosicao.loading = false
            state.requestGetViagemPosicao.error = false
        },
        setRequestGetViagemPosicaoLoading: (state: State) => {
            state.requestGetViagemPosicao.loading = true
            state.requestGetViagemPosicao.error = false
        },
        setRequestGetViagemPosicaoError: (state: State) => {
            state.requestGetViagemPosicao.loading = false
            state.requestGetViagemPosicao.error = true
        },
        setRequestGetViagemPosicaoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagemPosicao.message = action.payload
        },
        resetRequestGetViagemPosicao: (state: State) => {
            state.requestGetViagemPosicao = {...responseInitialValues}
        },

        setRequestSearchMotoristaData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSearchMotorista.data = action.payload
            state.requestSearchMotorista.loading = false
            state.requestSearchMotorista.error = false
        },
        setRequestSearchMotoristaLoading: (state: State) => {
            state.requestSearchMotorista.loading = true
            state.requestSearchMotorista.error = false
        },
        setRequestSearchMotoristaError: (state: State) => {
            state.requestSearchMotorista.loading = false
            state.requestSearchMotorista.error = true
        },
        setRequestSearchMotoristaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchMotorista.message = action.payload
        },
        resetRequestSearchMotorista: (state: State) => {
            state.requestSearchMotorista = {...responseInitialValues}
        },

        setRequestSearchVeiculoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSearchVeiculo.data = action.payload
            state.requestSearchVeiculo.loading = false
            state.requestSearchVeiculo.error = false
        },
        setRequestSearchVeiculoLoading: (state: State) => {
            state.requestSearchVeiculo.loading = true
            state.requestSearchVeiculo.error = false
        },
        setRequestSearchVeiculoError: (state: State) => {
            state.requestSearchVeiculo.loading = false
            state.requestSearchVeiculo.error = true
        },
        setRequestSearchVeiculoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchVeiculo.message = action.payload
        },
        resetRequestSearchVeiculo: (state: State) => {
            state.requestSearchVeiculo = {...responseInitialValues}
        },

        setRequestSearchEmbarcadorData: (state: State, action: PayloadAction<ResponsePattern<SearchCliente[]>>) => {
            state.requestSearchEmbarcador.data = action.payload
            state.requestSearchEmbarcador.loading = false
            state.requestSearchEmbarcador.error = false
        },
        setRequestSearchEmbarcadorLoading: (state: State) => {
            state.requestSearchEmbarcador.loading = true
            state.requestSearchEmbarcador.error = false
        },
        setRequestSearchEmbarcadorError: (state: State) => {
            state.requestSearchEmbarcador.loading = false
            state.requestSearchEmbarcador.error = true
        },
        setRequestSearchEmbarcadorMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchEmbarcador.message = action.payload
        },
        resetRequestSearchEmbarcador: (state: State) => {
            state.requestSearchEmbarcador = {...responseInitialValues}
        },

        setRequestSearchTomadorData: (state: State, action: PayloadAction<ResponsePattern<SearchCliente[]>>) => {
            state.requestSearchTomador.data = action.payload
            state.requestSearchTomador.loading = false
            state.requestSearchTomador.error = false
        },
        setRequestSearchTomadorLoading: (state: State) => {
            state.requestSearchTomador.loading = true
            state.requestSearchTomador.error = false
        },
        setRequestSearchTomadorError: (state: State) => {
            state.requestSearchTomador.loading = false
            state.requestSearchTomador.error = true
        },
        setRequestSearchTomadorMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchTomador.message = action.payload
        },
        resetRequestSearchTomador: (state: State) => {
            state.requestSearchTomador = {...responseInitialValues}
        },

        setRequestSearchFilialData: (state: State, action: PayloadAction<ResponsePattern<SearchCliente[]>>) => {
            state.requestSearchFilial.data = action.payload
            state.requestSearchFilial.loading = false
            state.requestSearchFilial.error = false
        },
        setRequestSearchFilialLoading: (state: State) => {
            state.requestSearchFilial.loading = true
            state.requestSearchFilial.error = false
        },
        setRequestSearchFilialError: (state: State) => {
            state.requestSearchFilial.loading = false
            state.requestSearchFilial.error = true
        },
        setRequestSearchFilialMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchFilial.message = action.payload
        },
        resetRequestSearchFilial: (state: State) => {
            state.requestSearchFilial = {...responseInitialValues}
        },

        setRequestSearchClienteSistemaData: (state: State, action: PayloadAction<ResponsePattern<SearchCliente[]>>) => {
            state.requestSearchClienteSistema.data = action.payload
            state.requestSearchClienteSistema.loading = false
            state.requestSearchClienteSistema.error = false
        },
        setRequestSearchClienteSistemaLoading: (state: State) => {
            state.requestSearchClienteSistema.loading = true
            state.requestSearchClienteSistema.error = false
        },
        setRequestSearchClienteSistemaError: (state: State) => {
            state.requestSearchClienteSistema.loading = false
            state.requestSearchClienteSistema.error = true
        },
        setRequestSearchClienteSistemaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchClienteSistema.message = action.payload
        },
        resetRequestSearchClienteSistema: (state: State) => {
            state.requestSearchClienteSistema = {...responseInitialValues}
        },

        setRequestSearchDestinatarioData: (state: State, action: PayloadAction<ResponsePattern<SearchCliente[]>>) => {
            state.requestSearchDestinatario.data = action.payload
            state.requestSearchDestinatario.loading = false
            state.requestSearchDestinatario.error = false
        },
        setRequestSearchDestinatarioLoading: (state: State) => {
            state.requestSearchDestinatario.loading = true
            state.requestSearchDestinatario.error = false
        },
        setRequestSearchDestinatarioError: (state: State) => {
            state.requestSearchDestinatario.loading = false
            state.requestSearchDestinatario.error = true
        },
        setRequestSearchDestinatarioMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchDestinatario.message = action.payload
        },
        resetRequestSearchDestinatario: (state: State) => {
            state.requestSearchDestinatario = {...responseInitialValues}
        },

        setRequestGetPernoiteData: (state: State, action: PayloadAction<ResponsePattern<ViagemPernoite>>) => {
            state.requestGetPernoite.data = action.payload
            state.requestGetPernoite.loading = false
            state.requestGetPernoite.error = false
        },
        setRequestGetPernoiteLoading: (state: State) => {
            state.requestGetPernoite.loading = true
            state.requestGetPernoite.error = false
        },
        setRequestGetPernoiteError: (state: State) => {
            state.requestGetPernoite.loading = false
            state.requestGetPernoite.error = true
        },
        setRequestGetPernoiteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetPernoite.message = action.payload
        },
        resetRequestGetPernoite: (state: State) => {
            state.requestGetPernoite = {...responseInitialValues}
        },

        setRequestGetCidadesData: (state: State, action: PayloadAction<ResponsePattern<SelectItem[]>>) => {
            state.requestGetCidades.data = action.payload
            state.requestGetCidades.loading = false
            state.requestGetCidades.error = false
        },
        setRequestGetCidadesLoading: (state: State) => {
            state.requestGetCidades.loading = true
            state.requestGetCidades.error = false
        },
        setRequestGetCidadesError: (state: State) => {
            state.requestGetCidades.loading = false
            state.requestGetCidades.error = true
        },
        setRequestGetCidadesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetCidades.message = action.payload
        },
        resetRequestGetCidades: (state: State) => {
            state.requestGetCidades = {...responseInitialValues}
        },

        setRequestEndViagemData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestEndViagem.data = action.payload
            state.requestEndViagem.loading = false
            state.requestEndViagem.error = false
        },
        setRequestEndViagemLoading: (state: State) => {
            state.requestEndViagem.loading = true
            state.requestEndViagem.error = false
        },
        setRequestEndViagemError: (state: State) => {
            state.requestEndViagem.loading = false
            state.requestEndViagem.error = true
        },
        setRequestEndViagemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestEndViagem.message = action.payload
        },
        resetRequestEndViagem: (state: State) => {
            state.requestEndViagem = {...responseInitialValues}
        },

        setRequestSaveVeiculoBloqueadoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveVeiculoBloqueado.data = action.payload
            state.requestSaveVeiculoBloqueado.loading = false
            state.requestSaveVeiculoBloqueado.error = false
        },
        setRequestSaveVeiculoBloqueadoLoading: (state: State) => {
            state.requestSaveVeiculoBloqueado.loading = true
            state.requestSaveVeiculoBloqueado.error = false
        },
        setRequestSaveVeiculoBloqueadoError: (state: State) => {
            state.requestSaveVeiculoBloqueado.loading = false
            state.requestSaveVeiculoBloqueado.error = true
        },
        setRequestSaveVeiculoBloqueadoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSaveVeiculoBloqueado.message = action.payload
        },
        resetRequestSaveVeiculoBloqueado: (state: State) => {
            state.requestSaveVeiculoBloqueado = {...responseInitialValues}
        },

        setRequestSaveViagemDemandaData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveViagemDemanda.data = action.payload
            state.requestSaveViagemDemanda.loading = false
            state.requestSaveViagemDemanda.error = false
        },
        setRequestSaveViagemDemandaLoading: (state: State) => {
            state.requestSaveViagemDemanda.loading = true
            state.requestSaveViagemDemanda.error = false
        },
        setRequestSaveViagemDemandaError: (state: State) => {
            state.requestSaveViagemDemanda.loading = false
            state.requestSaveViagemDemanda.error = true
        },
        setRequestSaveViagemDemandaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSaveViagemDemanda.message = action.payload
        },
        resetRequestSaveViagemDemanda: (state: State) => {
            state.requestSaveViagemDemanda = {...responseInitialValues}
        },

        setVequestSaveViagemParadaData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveViagemParada.data = action.payload
            state.requestSaveViagemParada.loading = false
            state.requestSaveViagemParada.error = false
        },
        setRequestSaveViagemParadaLoading: (state: State) => {
            state.requestSaveViagemParada.loading = true
            state.requestSaveViagemParada.error = false
        },
        setRequestSaveViagemParadaError: (state: State) => {
            state.requestSaveViagemParada.loading = false
            state.requestSaveViagemParada.error = true
        },
        setRequestSaveViagemParadaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSaveViagemParada.message = action.payload
        },
        resetRequestSaveViagemParada: (state: State) => {
            state.requestSaveViagemParada = {...responseInitialValues}
        },

        setRequestGetViagemManifestosData: (state: State, action: PayloadAction<ResponsePattern<ViagemManifesto[]>>) => {
            state.requestGetViagemManifestos.data = action.payload
            state.requestGetViagemManifestos.loading = false
            state.requestGetViagemManifestos.error = false
        },
        setRequestGetViagemManifestosLoading: (state: State) => {
            state.requestGetViagemManifestos.loading = true
            state.requestGetViagemManifestos.error = false
        },
        setRequestGetViagemManifestosError: (state: State) => {
            state.requestGetViagemManifestos.loading = false
            state.requestGetViagemManifestos.error = true
        },
        setRequestGetViagemManifestosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagemManifestos.message = action.payload
        },
        resetRequestGetViagemManifestos: (state: State) => {
            state.requestGetViagemManifestos = {...responseInitialValues}
        },

        setRequestSaveViagemManifestosData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveViagemManifestos.data = action.payload
            state.requestSaveViagemManifestos.loading = false
            state.requestSaveViagemManifestos.error = false
        },
        setRequestSaveViagemManifestosLoading: (state: State) => {
            state.requestSaveViagemManifestos.loading = true
            state.requestSaveViagemManifestos.error = false
        },
        setRequestSaveViagemManifestosError: (state: State) => {
            state.requestSaveViagemManifestos.loading = false
            state.requestSaveViagemManifestos.error = true
        },
        setRequestSaveViagemManifestosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSaveViagemManifestos.message = action.payload
        },
        resetRequestSaveViagemManifestos: (state: State) => {
            state.requestSaveViagemManifestos = {...responseInitialValues}
        },

        setRequestDeleteViagemManifestoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteViagemManifesto.data = action.payload
            state.requestDeleteViagemManifesto.loading = false
            state.requestDeleteViagemManifesto.error = false
        },
        setRequestDeleteViagemManifestoLoading: (state: State) => {
            state.requestDeleteViagemManifesto.loading = true
            state.requestDeleteViagemManifesto.error = false
        },
        setRequestDeleteViagemManifestoError: (state: State) => {
            state.requestDeleteViagemManifesto.loading = false
            state.requestDeleteViagemManifesto.error = true
        },
        setRequestDeleteViagemManifestoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestDeleteViagemManifesto.message = action.payload
        },
        resetRequestDeleteViagemManifesto: (state: State) => {
            state.requestDeleteViagemManifesto = {...responseInitialValues}
        },

        setRequestGetViagemValidacoesData: (state: State, action: PayloadAction<ResponsePattern<ViagemValidacao[]>>) => {
            state.requestGetViagemValidacoes.data = action.payload
            state.requestGetViagemValidacoes.loading = false
            state.requestGetViagemValidacoes.error = false
        },
        setRequestGetViagemValidacoesLoading: (state: State) => {
            state.requestGetViagemValidacoes.loading = true
            state.requestGetViagemValidacoes.error = false
        },
        setRequestGetViagemValidacoesError: (state: State) => {
            state.requestGetViagemValidacoes.loading = false
            state.requestGetViagemValidacoes.error = true
        },
        setRequestGetViagemValidacoesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagemValidacoes.message = action.payload
        },
        resetRequestGetViagemValidacoes: (state: State) => {
            state.requestGetViagemValidacoes = {...responseInitialValues}
        },

        setRequestUpdateViagemValidacaoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateViagemValidacao.data = action.payload
            state.requestUpdateViagemValidacao.loading = false
            state.requestUpdateViagemValidacao.error = false
        },
        setRequestUpdateViagemValidacaoLoading: (state: State) => {
            state.requestUpdateViagemValidacao.loading = true
            state.requestUpdateViagemValidacao.error = false
        },
        setRequestUpdateViagemValidacaoError: (state: State) => {
            state.requestUpdateViagemValidacao.loading = false
            state.requestUpdateViagemValidacao.error = true
        },
        setRequestUpdateViagemValidacaoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestUpdateViagemValidacao.message = action.payload
        },
        resetRequestUpdateViagemValidacao: (state: State) => {
            state.requestUpdateViagemValidacao = {...responseInitialValues}
        },

        setRequestGetViagensFinalizadasData: (state: State, action: PayloadAction<ResponsePattern<ViagemFinalizada[]>>) => {
            state.requestGetViagensFinalizadas.data = action.payload
            state.requestGetViagensFinalizadas.loading = false
            state.requestGetViagensFinalizadas.error = false
        },
        setRequestGetViagensFinalizadasLoading: (state: State) => {
            state.requestGetViagensFinalizadas.loading = true
            state.requestGetViagensFinalizadas.error = false
        },
        setRequestGetViagensFinalizadasError: (state: State) => {
            state.requestGetViagensFinalizadas.loading = false
            state.requestGetViagensFinalizadas.error = true
        },
        setRequestGetViagensFinalizadasMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagensFinalizadas.message = action.payload
        },
        resetRequestGetViagensFinalizadas: (state: State) => {
            state.requestGetViagensFinalizadas = {...responseInitialValues}
        },

        setRequestGetViagemFinalizadaData: (state: State, action: PayloadAction<ResponsePattern<ViagemOne>>) => {
            state.requestGetViagemFinalizada.data = action.payload
            state.requestGetViagemFinalizada.loading = false
            state.requestGetViagemFinalizada.error = false
        },
        setRequestGetViagemFinalizadaLoading: (state: State) => {
            state.requestGetViagemFinalizada.loading = true
            state.requestGetViagemFinalizada.error = false
        },
        setRequestGetViagemFinalizadaError: (state: State) => {
            state.requestGetViagemFinalizada.loading = false
            state.requestGetViagemFinalizada.error = true
        },
        setRequestGetViagemFinalizadaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagemFinalizada.message = action.payload
        },
        resetRequestGetViagemFinalizada: (state: State) => {
            state.requestGetViagemFinalizada = {...responseInitialValues}
        },

        setRequestSearchClienteData: (state: State, action: PayloadAction<ResponsePattern<SearchCliente[]>>) => {
            state.requestSearchCliente.data = action.payload
            state.requestSearchCliente.loading = false
            state.requestSearchCliente.error = false
        },
        setRequestSearchClienteLoading: (state: State) => {
            state.requestSearchCliente.loading = true
            state.requestSearchCliente.error = false
        },
        setRequestSearchClienteError: (state: State) => {
            state.requestSearchCliente.loading = false
            state.requestSearchCliente.error = true
        },
        setRequestSearchClienteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSearchCliente.message = action.payload
        },
        resetRequestSearchCliente: (state: State) => {
            state.requestSearchCliente = {...responseInitialValues}
        },

        setRequestGetViagensAplicativoData: (state: State, action: PayloadAction<ResponsePattern<ViagemAplicativo[]>>) => {
            state.requestGetViagensAplicativo.data = action.payload
            state.requestGetViagensAplicativo.loading = false
            state.requestGetViagensAplicativo.error = false
        },
        setRequestGetViagensAplicativoLoading: (state: State) => {
            state.requestGetViagensAplicativo.loading = true
            state.requestGetViagensAplicativo.error = false
        },
        setRequestGetViagensAplicativoError: (state: State) => {
            state.requestGetViagensAplicativo.loading = false
            state.requestGetViagensAplicativo.error = true
        },
        setRequestGetViagensAplicativoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagensAplicativo.message = action.payload
        },
        resetRequestGetViagensAplicativo: (state: State) => {
            state.requestGetViagensAplicativo = {...responseInitialValues}
        },

        setRequestGetViagemAplicativoMotoristaData: (state: State, action: PayloadAction<ResponsePattern<ViagemAplicativoMotorista>>) => {
            state.requestGetViagemAplicativoMotorista.data = action.payload
            state.requestGetViagemAplicativoMotorista.loading = false
            state.requestGetViagemAplicativoMotorista.error = false
        },
        setRequestGetViagemAplicativoMotoristaLoading: (state: State) => {
            state.requestGetViagemAplicativoMotorista.loading = true
            state.requestGetViagemAplicativoMotorista.error = false
        },
        setRequestGetViagemAplicativoMotoristaError: (state: State) => {
            state.requestGetViagemAplicativoMotorista.loading = false
            state.requestGetViagemAplicativoMotorista.error = true
        },
        setRequestGetViagemAplicativoMotoristaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagemAplicativoMotorista.message = action.payload
        },
        resetRequestGetViagemAplicativoMotorista: (state: State) => {
            state.requestGetViagemAplicativoMotorista = {...responseInitialValues}
        },

        setRequestGetViagemDocumentosData: (state: State, action: PayloadAction<ResponsePattern<ViagemDocumento[]>>) => {
            state.requestGetViagemDocumentos.data = action.payload
            state.requestGetViagemDocumentos.loading = false
            state.requestGetViagemDocumentos.error = false
        },
        setRequestGetViagemDocumentosLoading: (state: State) => {
            state.requestGetViagemDocumentos.loading = true
            state.requestGetViagemDocumentos.error = false
        },
        setRequestGetViagemDocumentosError: (state: State) => {
            state.requestGetViagemDocumentos.loading = false
            state.requestGetViagemDocumentos.error = true
        },
        setRequestGetViagemDocumentosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetViagemDocumentos.message = action.payload
        },
        resetRequestGetViagemDocumentos: (state: State) => {
            state.requestGetViagemDocumentos = {...responseInitialValues}
        },
    }
})

const { actions } = requestViagemSlice

export const requestGetAllViagensActions = {
    data: actions.setRequestGetAllViagensData,
    loading: actions.setRequestGetAllViagensLoading,
    error: actions.setRequestGetAllViagensError,
    message: actions.setRequestGetAllViagensMessage,
    reset: actions.resetRequestGetAllViagens,
}

export const requestGetViagensActions = {
    data: actions.setRequestGetViagensData,
    loading: actions.setRequestGetViagensLoading,
    error: actions.setRequestGetViagensError,
    message: actions.setRequestGetViagensMessage,
    reset: actions.resetRequestGetViagens,
}

export const requestGetViagemActions = {
    data: actions.setRequestGetViagemData,
    loading: actions.setRequestGetViagemLoading,
    error: actions.setRequestGetViagemError,
    message: actions.setRequestGetViagemMessage,
    reset: actions.resetRequestGetViagem,
}

export const requestCreateViagemActions = {
    data: actions.setRequestCreateViagemData,
    loading: actions.setRequestCreateViagemLoading,
    error: actions.setRequestCreateViagemError,
    message: actions.setRequestCreateViagemMessage,
    reset: actions.resetRequestCreateViagem,
}

export const requestUpdateViagemActions = {
    data: actions.setRequestUpdateViagemData,
    loading: actions.setRequestUpdateViagemLoading,
    error: actions.setRequestUpdateViagemError,
    message: actions.setRequestUpdateViagemMessage,
    reset: actions.resetRequestUpdateViagem,
}

export const requestDeleteViagemActions = {
    data: actions.setRequestDeleteViagemData,
    loading: actions.setRequestDeleteViagemLoading,
    error: actions.setRequestDeleteViagemError,
    message: actions.setRequestDeleteViagemMessage,
    reset: actions.resetRequestDeleteViagem,
}

export const requestGetViagensAtivasActions = {
    data: actions.setRequestGetViagensAtivasData,
    loading: actions.setRequestGetViagensAtivasLoading,
    error: actions.setRequestGetViagensAtivasError,
    message: actions.setRequestGetViagensAtivasMessage,
    reset: actions.resetRequestGetViagensAtivas,
}

export const requestGetViagemAtivaActions = {
    data: actions.setRequestGetViagemAtivaData,
    loading: actions.setRequestGetViagemAtivaLoading,
    error: actions.setRequestGetViagemAtivaError,
    message: actions.setRequestGetViagemAtivaMessage,
    reset: actions.resetRequestGetViagemAtiva,
}

export const requestGetViagemPosicaoActions = {
    data: actions.setRequestGetViagemPosicaoData,
    loading: actions.setRequestGetViagemPosicaoLoading,
    error: actions.setRequestGetViagemPosicaoError,
    message: actions.setRequestGetViagemPosicaoMessage,
    reset: actions.resetRequestGetViagemPosicao,
}

export const requestSearchMotoristaActions = {
    data: actions.setRequestSearchMotoristaData,
    loading: actions.setRequestSearchMotoristaLoading,
    error: actions.setRequestSearchMotoristaError,
    message: actions.setRequestSearchMotoristaMessage,
    reset: actions.resetRequestSearchMotorista,
}

export const requestSearchVeiculoActions = {
    data: actions.setRequestSearchVeiculoData,
    loading: actions.setRequestSearchVeiculoLoading,
    error: actions.setRequestSearchVeiculoError,
    message: actions.setRequestSearchVeiculoMessage,
    reset: actions.resetRequestSearchVeiculo,
}

export const requestSearchEmbarcadorActions = {
    data: actions.setRequestSearchEmbarcadorData,
    loading: actions.setRequestSearchEmbarcadorLoading,
    error: actions.setRequestSearchEmbarcadorError,
    message: actions.setRequestSearchEmbarcadorMessage,
    reset: actions.resetRequestSearchEmbarcador,
}

export const requestSearchDestinatarioActions = {
    data: actions.setRequestSearchDestinatarioData,
    loading: actions.setRequestSearchDestinatarioLoading,
    error: actions.setRequestSearchDestinatarioError,
    message: actions.setRequestSearchDestinatarioMessage,
    reset: actions.resetRequestSearchDestinatario,
}

export const requestSearchTomadorActions = {
    data: actions.setRequestSearchTomadorData,
    loading: actions.setRequestSearchTomadorLoading,
    error: actions.setRequestSearchTomadorError,
    message: actions.setRequestSearchTomadorMessage,
    reset: actions.resetRequestSearchTomador,
}

export const requestSearchFilialActions = {
    data: actions.setRequestSearchFilialData,
    loading: actions.setRequestSearchFilialLoading,
    error: actions.setRequestSearchFilialError,
    message: actions.setRequestSearchFilialMessage,
    reset: actions.resetRequestSearchFilial,
}

export const requestSearchClienteSistemaActions = {
    data: actions.setRequestSearchClienteSistemaData,
    loading: actions.setRequestSearchClienteSistemaLoading,
    error: actions.setRequestSearchClienteSistemaError,
    message: actions.setRequestSearchClienteSistemaMessage,
    reset: actions.resetRequestSearchClienteSistema,
}

export const requestGetPernoiteActions = {
    data: actions.setRequestGetPernoiteData,
    loading: actions.setRequestGetPernoiteLoading,
    error: actions.setRequestGetPernoiteError,
    message: actions.setRequestGetPernoiteMessage,
    reset: actions.resetRequestGetPernoite,
}

export const requestGetCidadesActions = {
    data: actions.setRequestGetCidadesData,
    loading: actions.setRequestGetCidadesLoading,
    error: actions.setRequestGetCidadesError,
    message: actions.setRequestGetCidadesMessage,
    reset: actions.resetRequestGetCidades,
}

export const requestEndViagemActions = {
    data: actions.setRequestEndViagemData,
    loading: actions.setRequestEndViagemLoading,
    error: actions.setRequestEndViagemError,
    message: actions.setRequestEndViagemMessage,
    reset: actions.resetRequestEndViagem,
}

export const requestSaveVeiculoBloqueadoActions = {
    data: actions.setRequestSaveVeiculoBloqueadoData,
    loading: actions.setRequestSaveVeiculoBloqueadoLoading,
    error: actions.setRequestSaveVeiculoBloqueadoError,
    message: actions.setRequestSaveVeiculoBloqueadoMessage,
    reset: actions.resetRequestSaveVeiculoBloqueado,
}

export const requestSaveViagemParadaActions = {
    data: actions.setVequestSaveViagemParadaData,
    loading: actions.setRequestSaveViagemParadaLoading,
    error: actions.setRequestSaveViagemParadaError,
    message: actions.setRequestSaveViagemParadaMessage,
    reset: actions.resetRequestSaveViagemParada,
}

export const requestSaveViagemDemandaActions = {
    data: actions.setRequestSaveViagemDemandaData,
    loading: actions.setRequestSaveViagemDemandaLoading,
    error: actions.setRequestSaveViagemDemandaError,
    message: actions.setRequestSaveViagemDemandaMessage,
    reset: actions.resetRequestSaveViagemDemanda,
}

export const requestGetViagemManifestosActions = {
    data: actions.setRequestGetViagemManifestosData,
    loading: actions.setRequestGetViagemManifestosLoading,
    error: actions.setRequestGetViagemManifestosError,
    message: actions.setRequestGetViagemManifestosMessage,
    reset: actions.resetRequestGetViagemManifestos,
}

export const requestSaveViagemManifestosActions = {
    data: actions.setRequestSaveViagemManifestosData,
    loading: actions.setRequestSaveViagemManifestosLoading,
    error: actions.setRequestSaveViagemManifestosError,
    message: actions.setRequestSaveViagemManifestosMessage,
    reset: actions.resetRequestSaveViagemManifestos,
}

export const requestDeleteViagemManifestoActions = {
    data: actions.setRequestDeleteViagemManifestoData,
    loading: actions.setRequestDeleteViagemManifestoLoading,
    error: actions.setRequestDeleteViagemManifestoError,
    message: actions.setRequestDeleteViagemManifestoMessage,
    reset: actions.resetRequestDeleteViagemManifesto,
}

export const requestGetViagemValidacoesActions = {
    data: actions.setRequestGetViagemValidacoesData,
    loading: actions.setRequestGetViagemValidacoesLoading,
    error: actions.setRequestGetViagemValidacoesError,
    message: actions.setRequestGetViagemValidacoesMessage,
    reset: actions.resetRequestGetViagemValidacoes,
}

export const requestGetViagensFinalizadasActions = {
    data: actions.setRequestGetViagensFinalizadasData,
    loading: actions.setRequestGetViagensFinalizadasLoading,
    error: actions.setRequestGetViagensFinalizadasError,
    message: actions.setRequestGetViagensFinalizadasMessage,
    reset: actions.resetRequestGetViagensFinalizadas,
}

export const requestGetViagemFinalizadaActions = {
    data: actions.setRequestGetViagemFinalizadaData,
    loading: actions.setRequestGetViagemFinalizadaLoading,
    error: actions.setRequestGetViagemFinalizadaError,
    message: actions.setRequestGetViagemFinalizadaMessage,
    reset: actions.resetRequestGetViagemFinalizada,
}

export const requestSearchClienteActions = {
    data: actions.setRequestSearchClienteData,
    loading: actions.setRequestSearchClienteLoading,
    error: actions.setRequestSearchClienteError,
    message: actions.setRequestSearchClienteMessage,
    reset: actions.resetRequestSearchCliente,
}

export const requestGetViagensAplicativoActions = {
    data: actions.setRequestGetViagensAplicativoData,
    loading: actions.setRequestGetViagensAplicativoLoading,
    error: actions.setRequestGetViagensAplicativoError,
    message: actions.setRequestGetViagensAplicativoMessage,
    reset: actions.resetRequestGetViagensAplicativo,
}

export const requestGetViagemAplicativoMotoristaActions = {
    data: actions.setRequestGetViagemAplicativoMotoristaData,
    loading: actions.setRequestGetViagemAplicativoMotoristaLoading,
    error: actions.setRequestGetViagemAplicativoMotoristaError,
    message: actions.setRequestGetViagemAplicativoMotoristaMessage,
    reset: actions.resetRequestGetViagemAplicativoMotorista,
}

export const requestUpdateViagemValidacaoActions = {
    data: actions.setRequestUpdateViagemValidacaoData,
    loading: actions.setRequestUpdateViagemValidacaoLoading,
    error: actions.setRequestUpdateViagemValidacaoError,
    message: actions.setRequestUpdateViagemValidacaoMessage,
    reset: actions.resetRequestUpdateViagemValidacao,
}

export const requestGetViagemDocumentosActions = {
    data: actions.setRequestGetViagemDocumentosData,
    loading: actions.setRequestGetViagemDocumentosLoading,
    error: actions.setRequestGetViagemDocumentosError,
    message: actions.setRequestGetViagemDocumentosMessage,
    reset: actions.resetRequestGetViagemDocumentos,
}

export default requestViagemSlice.reducer