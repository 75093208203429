import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Solicitacao } from "../interfaces/Solicitacao"
import { SolicitacaoCte } from "../interfaces/SolicitacaoCte"

interface State {
    solicitacoes: Solicitacao[] | null
    solicitacao: Solicitacao | null

    currentSolicitacao: Solicitacao | null

    solicitacoesCte: SolicitacaoCte[] | null

    openModalSolicitacaoListaView: boolean
}

const initialState: State = {
    solicitacoes: null,
    solicitacao: null,
    currentSolicitacao: null,

    solicitacoesCte: null,

    openModalSolicitacaoListaView: false
}

const solicitacaoSlice = createSlice({
    name: 'solicitacao',
    initialState,
    reducers: {
        setSolicitacoes: (state, action: PayloadAction<Solicitacao[]>) => {
            state.solicitacoes = action.payload
        },
        setSolicitacao: (state, action: PayloadAction<Solicitacao | null>) => {
            state.solicitacao = action.payload
        },

        setCurrentSolicitacao: (state, action: PayloadAction<Solicitacao>) => {
            state.currentSolicitacao = action.payload
        },

        setSolicitacoesCte: (state, action: PayloadAction<SolicitacaoCte[]>) => {
            state.solicitacoesCte = action.payload
        },

        setOpenModalSolicitacaoListaView: (state, action: PayloadAction<boolean>) => {
            state.openModalSolicitacaoListaView = action.payload
        },
    }
})

export const { setSolicitacoes, setSolicitacao, setCurrentSolicitacao, setSolicitacoesCte, setOpenModalSolicitacaoListaView } = solicitacaoSlice.actions
export default solicitacaoSlice.reducer