import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Motorista } from "../interfaces/Motorista"

interface State {
    motoristas: Motorista[] | null
    motorista: Motorista | null

    currentMotorista: Motorista | null
}

const initialState: State = {
    motoristas: null,
    motorista: null,

    currentMotorista: null
}

const motoristaSlice = createSlice({
    name: 'motorista',
    initialState,
    reducers: {
        setMotoristas: (state, action: PayloadAction<Motorista[]>) => {
            state.motoristas = action.payload
        },
        setMotorista: (state, action: PayloadAction<Motorista| null>) => {
            state.motorista = action.payload
        },

        setCurrentMotorista: (state, action: PayloadAction<Motorista | null>) => {
            state.currentMotorista = action.payload
        },
    }
})

export const { setMotoristas, setMotorista, setCurrentMotorista } = motoristaSlice.actions
export default motoristaSlice.reducer