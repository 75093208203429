import React from 'react'
import { mdiContentSave } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import Select from '@components/Common/Select'
import Checkbox from '@components/Common/Checkbox'
import ButtonGroup from '@components/Common/Button/Group'
import RadioButton from '@components/Common/RadioButton'
import RadioButtonGroup from '@components/Common/RadioButton/RadioButtonGroup'

const FormVeiculo: React.FC = () => {

    return(

        <>
            <Formik
                initialValues = {{}}
                // validationSchema = {{}}
                onSubmit = {() => {}}
            >
                {({ values, setFieldValue, handleChange }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Section>
                                <Form.Row columns = {2}>
                                    <Select
                                        label = "Empresa"
                                        fieldName = "nomeEmpresa"
                                        value = ""
                                        setFieldValue = {setFieldValue}
                                        items = {[]}
                                    />
                                    
                                    <Select
                                        label = "Filial"
                                        fieldName = "idFilial"
                                        value = ""
                                        setFieldValue = {setFieldValue}
                                        items = {[]}
                                    />
                                </Form.Row>

                                <Form.Row columns = {4} grid = "100px 1fr 1fr 1fr">
                                    <Form.Group
                                        label = "Prefixo"
                                        inputID = "prefixo"
                                        inputName = "prefixo"
                                    />

                                    <Form.Group
                                        label = "Placa"
                                        inputID = "placa"
                                        inputName = "placa"
                                    />

                                    <RadioButtonGroup
                                        label = "Condição"
                                    >
                                        <RadioButton
                                            label = "Ativo"
                                            name = "flagAtivo"
                                            value = "1"
                                        />

                                        <RadioButton
                                            label = "Inativo"
                                            name = "flagAtivo"
                                            value = "0"
                                        />
                                    </RadioButtonGroup>

                                    <Select
                                        label = "Garagem"
                                        fieldName = "idGaragem"
                                        value = ""
                                        setFieldValue = {setFieldValue}
                                        items = {[]}
                                    />
                                </Form.Row>
                            </Form.Section>

                            <Form.Section title = "Características" autoHide>
                                <Form.Row columns = {2} grid = "1fr 1fr">
                                    <Form.Group
                                        label = "Tipo de veículo - FRT"
                                        inputID = "tipoVeiculoFRT"
                                        inputName = "tipoVeiculoFRT"
                                    />

                                    <Form.Group
                                        label = "Tipo de veículo - CGS"
                                        inputID = "tipoVeiculoCGS"
                                        inputName = "tipoVeiculoCGS"
                                    />
                                </Form.Row>

                                <Form.Row columns = {2} grid = "1fr 1fr">
                                    <Form.Group
                                        label = "Grupo de revisão"
                                        inputID = "grupoRevisao"
                                        inputName = "grupoRevisao"
                                    />

                                    <Form.Group
                                        label = "Tipo de frota"
                                        inputID = "tipoFrota"
                                        inputName = "tipoFrota"
                                    />
                                </Form.Row>

                                <Form.Row columns = {2} grid = "1fr 1fr">
                                    <Form.Group
                                        label = "Categoria"
                                        inputID = "categoria"
                                        inputName = "categoria"
                                    />

                                    <Form.Group
                                        label = "Padrão de cor"
                                        inputID = "padraoCor    "
                                        inputName = "padraoCor"
                                    />
                                </Form.Row>

                                <Form.Row columns = {3} grid = "1fr 1fr 1fr">
                                    <Form.Group
                                        label = "Modelo de motor"
                                        inputID = "modeloMotor"
                                        inputName = "modeloMotor"
                                    />

                                    <Form.Group
                                        label = "Número do motor"
                                        inputID = "nroMotor"
                                        inputName = "nroMotor"
                                    />

                                    <Form.Group
                                        label = "Km/litro"
                                        inputID = "kmLitro"
                                        inputName = "kmLitro"
                                    />
                                </Form.Row>

                                <Form.Row columns = {3} grid = "1fr 1fr 1fr">
                                    <Form.Group
                                        label = "Modelo de chassi"
                                        inputID = "modeloChassi"
                                        inputName = "modeloChassi"
                                    />

                                    <Form.Group
                                        label = "Número do chassi"
                                        inputID = "nroChassi"
                                        inputName = "nroChassi"
                                    />

                                    <Form.Group
                                        label = "Mês/Ano de fabricação"
                                        inputID = "dtFabricacao"
                                        inputName = "dtFabricacao"
                                    />
                                </Form.Row>

                                <Form.Row columns = {4} grid = "1fr 1fr 1fr 100px">
                                    <Form.Group
                                        label = "Modelo de carroceria"
                                        inputID = "modeloCarroceria"
                                        inputName = "modeloCarroceria"
                                    />

                                    <Form.Group
                                        label = "Renavan"
                                        inputID = "renavan"
                                        inputName = "renavan"
                                    />

                                    <Form.Group
                                        label = "Rastreador"
                                        inputID = "rastreador"
                                        inputName = "rastreador"
                                    />

                                    <Checkbox
                                        label = "Calcula adiantamento"
                                        name = "check"
                                    />
                                </Form.Row>

                                <Form.Row columns = {2} grid = "1fr 1fr">
                                    <Form.Group
                                        label = "Município"
                                        inputID = "cidade"
                                        inputName = "cidade"
                                    />

                                    <Form.Group
                                        label = "UF"
                                        inputID = "uf"
                                        inputName = "uf"
                                    />
                                </Form.Row>

                                <Form.Row columns = {1}>
                                    <Form.Group
                                        label = "Observação"
                                        inputID = "obs"
                                        inputName = "obs"
                                        inputType = "textarea"
                                        inputRows = {5}
                                    />
                                </Form.Row>
                            </Form.Section>

                            <Form.Section title = "Centro de custos" autoHide>
                                <Form.Row columns = {2} grid = "100px 1fr">

                                </Form.Row>
                            </Form.Section>

                            <ButtonGroup>
                                <Button
                                    icon = {mdiContentSave}
                                    label = "Salvar"
                                    status = "success"
                                    type = "submit"
                                />
                            </ButtonGroup>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormVeiculo