import React from 'react'
import { Link } from 'react-router-dom'
import RoomIcon from '@mui/icons-material/Room'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Chip, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { mdiBed, mdiCheck, mdiDotsVertical, mdiMapMarker, mdiTruckAlert } from '@mdi/js'
import { TableColumn, TableOption, TableRow } from '@components/Common/Table'
import { deleteViagem } from '@modules/viagem/controllers/viagemController'
import getEnderecoViagem from '@modules/viagem/utils/getEnderecoViagem'
import { setCurrentViagemAtiva } from '@modules/viagem/reducers/viagemReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatCurrency, formatDate } from '@utils/format'
import { TableEmViagemRowProps } from './types'

const TableEmViagemRow: React.FC<TableEmViagemRowProps> = ({
    data,
    setOpenModalPernoite,
    setOpenModalVeiculoBloqueado,
    setOpenModalFinalizar
}) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)

    const { remetenteEndereco, destinatarioEndereco } = getEnderecoViagem(data as any)

    const onClose = () => {
        setAnchorEl(null)
    }

    return (

        <TableRow>
            <TableColumn>{data.id}</TableColumn>

            <TableColumn>{data.usuario.nome}</TableColumn>
            <TableColumn>{formatDate(data.dtCadastro)}</TableColumn>

            <TableColumn>{data.AE}</TableColumn>
            <TableColumn>{data.SM}</TableColumn>
            <TableColumn>
                {remetenteEndereco}
            </TableColumn>
            <TableColumn>
                {destinatarioEndereco}
            </TableColumn>

            <TableColumn>{data.tomadorNome}</TableColumn>

            <TableColumn>{data.motoristaNome}</TableColumn>

            <TableColumn>{formatCurrency(Number(data.valorMercadoria))}</TableColumn>
            <TableColumn>{formatCurrency(Number(data.valorFrete))}</TableColumn>

            <TableColumn>{data.destinatarioNome}</TableColumn>

            <TableColumn>
                {(data.ultimaPosicao.length > 0 && (
                    <>
                        <Chip
                            label={formatDate(data.ultimaPosicao[0].DTPOSICAO)}
                            color="info"
                            icon={<RoomIcon />}
                            onClick={() => {
                                window.open(`https://maps.google.com/maps/place/${data.ultimaPosicao[0].LATITUDE},${data.ultimaPosicao[0].LONGITUDE}`)
                            }}
                            
                        />
                        {/* <TableOption
                            icon = {mdiMapMarker}
                            status = "info"
                            target = "_blank"
                            link = {`https://maps.google.com/maps/place/${data.ultimaPosicao[0].LATITUDE},${data.ultimaPosicao[0].LONGITUDE}`}
                        /> */}
                    </>
                )) || 'Sem posição'}
            </TableColumn>
            <TableColumn>{data.ultimaPernoite}</TableColumn>
            <TableColumn>
                {!!data.ultimaMacro && data.ultimaMacro?.length > 0 && (
                    data.ultimaMacro[0].MACROMENSAGEM ?? data.ultimaMacro[0].MACROTEXTO
                )}
            </TableColumn>

            <TableColumn>{data.flagEscolta ? 'Sim' : 'Não'}</TableColumn>
            <TableColumn>{data.flagRomaneio ? 'Romaneio' : 'Demanda'}</TableColumn>
            <TableColumn>{data.obs}</TableColumn>
            <TableColumn>{data.nomeUsuarioBloqueador}</TableColumn>

            <TableColumn>
                <TableOption
                    icon={mdiTruckAlert}
                    status={data.flagVeiculoBloqueado ? 'error' : 'success'}
                    onClick={() => {
                        dispatch(setCurrentViagemAtiva(data))

                        setOpenModalVeiculoBloqueado(true)
                    }}
                />
                <TableOption
                    icon={mdiBed}
                    status="info"
                    onClick={() => {
                        dispatch(setCurrentViagemAtiva(data))

                        setOpenModalPernoite(true)
                    }}
                />
                <TableOption
                    icon={mdiCheck}
                    status="success"
                    onClick={() => {
                        dispatch(setCurrentViagemAtiva(data))

                        setOpenModalFinalizar(true)
                    }}
                />
                <TableOption
                    icon={mdiDotsVertical}
                    onClick={el => {
                        setAnchorEl(el.currentTarget)
                    }}
                />

                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onClose}
                >
                    <Link to={`${data.id}`}>
                        <MenuItem>
                            <ListItemIcon>
                                <VisibilityIcon fontSize="small" />
                            </ListItemIcon>
                            Visualizar
                        </MenuItem>
                    </Link>
                    <Link to={`${data.flagRomaneio ? 'romaneio/' : ''}editar/${data.id}`}>
                        <MenuItem>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            Editar
                        </MenuItem>
                    </Link>
                    {/* <MenuItem
                        onClick = {() => {
                            deleteViagem(dispatch, userData!, data.id)
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Apagar
                    </MenuItem> */}
                </Menu>
            </TableColumn>
        </TableRow>

    )

}

export default TableEmViagemRow