import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiAccount, mdiBell, mdiBellBadge, mdiBellRing, mdiBrightness4, mdiBrightness5, mdiCog, mdiLogout } from '@mdi/js'
import Logo from '@assets/images/logo-nav.png'
import { APP_VERSION } from '@config/index'
import { toggleTheme } from '@modules/theme/reducers/themeReducer'
import { setAuthLogout } from '@modules/auth/reducers/authReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formatLink } from '@utils/format'
import './styles.scss'

const Header: React.FC = () => {

    const dispatch = useAppDispatch()
    const { authRoutes, userData, routeIndex } = useAppSelector(s => s.auth)
    const { theme } = useAppSelector(s => s.theme)
    const { notifications } = useAppSelector(s => s.notification)

    return(

        <>
            <header className="navbar bg-base-100 gap-6 fixed top-0">
                {/* <div className="flex-none">
                    <button className="btn btn-square btn-ghost" onClick = {() => dispatch(toggleSidebarOpen())}>
                        <Icon path = {mdiMenu} size = "24px" color = "#fff" />
                    </button>
                </div> */}
                <div>
                    <Link to = {routeIndex}>
                        <img src={Logo} alt="Comando Reserve" className="w-auto h-12" />
                    </Link>
                </div>
                <div className="flex-1 flex flex-row justify-center navbar-center">
                    <div className="menu menu-horizontal p-0">
                        {authRoutes.map((item, index) => (
                            <ul key = {index} className="menu menu-horizontal p-0">
                                <li>
                                    {((formatLink(item.rotas[0].path ?? '').slice(0, -1) !== item.modulo.path) && (
                                        <>
                                            <a>
                                                {item.modulo.nome}
                                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 24 24">
                                                    <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                                                </svg>
                                            </a>
                                            <ul className="p-2 bg-gray-100 z-[500]">
                                                {item.rotas.map((rota, indexRota) => (
                                                    <li key = {indexRota}>
                                                        <Link to = {`${item.modulo.path}${formatLink(rota.path ?? '')}`}>{rota.nome}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )) || (
                                        <a href = {item.modulo.path ?? ''}>
                                            {item.modulo.nome}
                                        </a>
                                    )}
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>
                <div className="justify-end">
                    {(!!userData && [1, 7, 8].includes(userData.grupoAcesso.id)) && (
                        <div className="dropdown dropdown-end">
                            <button className="btn btn-square btn-ghost">
                                <Icon path = {(!!notifications && notifications.length > 0) ? mdiBellBadge : mdiBell} size = "24px" color = "#fff" />
                            </button>
                            
                            <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                                {(!!notifications && notifications.length > 0) && (
                                    <li>
                                        <button>
                                            <Link to = {`/notificacao`}>
                                                <Icon path = {mdiBellRing} size = "24px" color = "#fff" />
                                                {notifications[0].titulo}
                                            </Link>
                                        </button>
                                    </li>
                                )}
                                <li>
                                    <button>
                                        <Link to = {`/notificacao`}>
                                            Ver todas as notificações
                                        </Link>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="dropdown dropdown-end">
                        <button className="btn btn-ghost btn-square avatar">
                            <Icon path = {mdiCog} size = "24px" color = "#fff" />
                        </button>

                        <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                            <li>
                                <button onClick = {() => dispatch(toggleTheme())}>
                                    <a>
                                        <Icon path = {theme === "dark-mode" ? mdiBrightness5 : mdiBrightness4} size = "24px" color = "#fff" />
                                        Trocar tema
                                    </a>
                                </button>
                            </li>
                            <li>
                                <button onClick = {() => dispatch(setAuthLogout())}>
                                    <a>
                                        <Icon path = {mdiLogout} size = "24px" color = "#fff" />
                                        Encerrar sessão
                                    </a>
                                </button>
                            </li>
                        </ul>
                    </div>
                    {!!userData && (
                        <div className="dropdown dropdown-end">
                            <button className="btn btn-ghost btn-square avatar">
                                <Icon path = {mdiAccount} size = "24px" color = "#fff" />
                            </button>

                            <div tabIndex={0} className="mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow">
                                <div className="card-body">
                                    <span className="font-bold text-lg">{userData.nome}</span>
                                    <span className="text-info">{userData.grupoAcesso.nome}</span>
                                    <span className="text-info text-center">{APP_VERSION.NAME}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </header>
        </>

    )

}

export default Header