import { SearchCliente } from "@modules/viagem/interfaces/SearchCliente"

export default function getEnderecoSearchCliente(cliente: SearchCliente){
    if(!!cliente.cidade){
        const endereco = `${cliente.cidade} - ${cliente.uf}`
    
        return endereco
    }

    return ''
}