import React, { useEffect, useState } from 'react'
import { mdiDelete, mdiPencil, mdiPlus } from '@mdi/js'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatDocumento } from '@utils/format'
import getEnderecoFilial from '../../utils/getEnderecoFilial'
import { setCurrentFilial } from '@modules/filial/reducers/filialReducer'
import ModalDeleteFilial from '@modules/filial/components/ModalDeleteFilial'

const TableFiliais: React.FC = () => {
    const dispatch = useAppDispatch()

    const { filiais } = useAppSelector(s => s.filial)
    const { requestGetFiliais } = useAppSelector(s => s.requestFilial)

    const [data, setData] = useState(filiais)
    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false)

    const [tableConfig] = useState(createTableConfig(
        'filiais',
        ['Documento', 'Apelido', 'Nome', 'Endereço', 'ISS (%)', ""]
    ))

    const CR = conditionaRender(requestGetFiliais, filiais, true)

    useEffect(() => {
        if (!!filiais) {
            setData(filiais)
        }
    }, [filiais])

    return (

        <>
            <TablePaginator
                data={data}
                config={tableConfig}
                conditionalRender={CR}
                options={
                    <>
                        <div />

                        <ButtonGroup>
                            <Button
                                icon={mdiPlus}
                                label="Criar Filial"
                                status="success"
                                link="criar"
                            />
                        </ButtonGroup>
                    </>
                }
                renderItem={(item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableColumn width={160}>{formatDocumento(item?.nroDocumento) || "-"}</TableColumn>
                            <TableColumn>{item.descricao || "-"}</TableColumn>
                            <TableColumn>{item.nomeFantasia || "-"}</TableColumn>
                            <TableColumn>{getEnderecoFilial(item)}</TableColumn>
                            <TableColumn width={80}>{item.iss || "-"}</TableColumn>
                            <TableColumn width={80}>
                                <TableOption
                                    icon={mdiPencil}
                                    link={`${item.id}`}
                                />
                                <TableOption
                                    icon={mdiDelete}
                                    status="error"
                                    onClick={() => {
                                        dispatch(setCurrentFilial(item))
                                        setOpenModalDelete(true)
                                    }}
                                />
                            </TableColumn>
                        </TableRow>
                    )
                }}
            />

            <ModalDeleteFilial
                open={openModalDelete}
                setOpen={setOpenModalDelete}
            />
        </>

    )

}

export default TableFiliais