import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Common/Form'
import Modal from '@components/Common/Modal'
import Select from '@components/Common/Select'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import selectStatusDocumento from '@constants/select/statusDocumento'
import { setOpenModalStatus } from '@modules/documento/reducers/documentoReducer'
import { updateDocumentoStatus } from '@modules/documento/controllers/documentoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const ModalStatus: React.FC = () => {

    const dispatch = useAppDispatch()
    const { openModalStatus, currentDocumento } = useAppSelector(s => s.documento)
    const { requestUpdateDocumentoStatus } = useAppSelector(s => s.requestDocumento)

    return (

        <>
            <Modal
                open={openModalStatus}
                setOpen={setOpenModalStatus}
                title="Alterar Status"
            >
                <Formik
                    initialValues={{
                        status: currentDocumento?.status || ''
                    }}
                    onSubmit={v => {
                        updateDocumentoStatus(dispatch, currentDocumento!, v.status)
                    }}
                >
                    {({ values, setFieldValue, handleChange }) => (
                        <FormikForm>
                            <Form.Container padding={false}>
                                <Form.Section>
                                    <Form.Row columns={1} grid="1fr">
                                        <Select
                                            label="Status"
                                            value={values.status}
                                            items={selectStatusDocumento}
                                            fieldName="status"
                                            setFieldValue={setFieldValue}
                                        />
                                    </Form.Row>
                                </Form.Section>

                                <ButtonGroup>
                                    <Button
                                        label="Fechar"
                                        onClick={() => dispatch(setOpenModalStatus(false))}
                                    />
                                    <Button
                                        loading={requestUpdateDocumentoStatus.loading}
                                        label="Salvar"
                                        status="success"
                                        type="submit"
                                    />
                                </ButtonGroup>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </>

    )

}

export default ModalStatus