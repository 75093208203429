import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Solicitacao } from "../interfaces/Solicitacao"
import { SolicitacaoCte } from "../interfaces/SolicitacaoCte"

interface State {
    requestGetSolicitacoes: ResponseDefault<Solicitacao[]>
    requestGetSolicitacao: ResponseDefault<Solicitacao>
    requestCreateSolicitacao: ResponseDefault<any>
    requestUpdateSolicitacao: ResponseDefault<any>
    requestDeleteSolicitacao: ResponseDefault<any>

    requestGetSolicitacoesCte: ResponseDefault<SolicitacaoCte[]>
}

const initialState: State = {
    requestGetSolicitacoes: responseInitialValues,
    requestGetSolicitacao: responseInitialValues,
    requestCreateSolicitacao: responseInitialValues,
    requestUpdateSolicitacao: responseInitialValues,
    requestDeleteSolicitacao: responseInitialValues,

    requestGetSolicitacoesCte: responseInitialValues,
}

const requestSolicitacaoSlice = createSlice({
    name: 'requestSolicitacao',
    initialState,
    reducers: {
        setRequestGetSolicitacoesData: (state, action: PayloadAction<ResponsePattern<Solicitacao[]>>) => {
            state.requestGetSolicitacoes.data = action.payload
            state.requestGetSolicitacoes.loading = false
            state.requestGetSolicitacoes.error = false
        },
        setRequestGetSolicitacoesLoading: (state) => {
            state.requestGetSolicitacoes.loading = true
            state.requestGetSolicitacoes.error = false
        },
        setRequestGetSolicitacoesError: (state) => {
            state.requestGetSolicitacoes.loading = false
            state.requestGetSolicitacoes.error = true
        },
        setRequestGetSolicitacoesMessage: (state, action: PayloadAction<string>) => {
            state.requestGetSolicitacoes.message = action.payload
        },
        resetRequestGetSolicitacoes: (state) => {
            state.requestGetSolicitacoes = {...responseInitialValues}
        },

        setRequestGetSolicitacaoData: (state, action: PayloadAction<ResponsePattern<Solicitacao>>) => {
            state.requestGetSolicitacao.data = action.payload
            state.requestGetSolicitacao.loading = false
            state.requestGetSolicitacao.error = false
        },
        setRequestGetSolicitacaoLoading: (state) => {
            state.requestGetSolicitacao.loading = true
            state.requestGetSolicitacao.error = false
        },
        setRequestGetSolicitacaoError: (state) => {
            state.requestGetSolicitacao.loading = false
            state.requestGetSolicitacao.error = true
        },
        setRequestGetSolicitacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestGetSolicitacao.message = action.payload
        },
        resetRequestGetSolicitacao: (state) => {
            state.requestGetSolicitacao = {...responseInitialValues}
        },

        setRequestCreateSolicitacaoData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateSolicitacao.data = action.payload
            state.requestCreateSolicitacao.loading = false
            state.requestCreateSolicitacao.error = false
        },
        setRequestCreateSolicitacaoLoading: (state) => {
            state.requestCreateSolicitacao.loading = true
            state.requestCreateSolicitacao.error = false
        },
        setRequestCreateSolicitacaoError: (state) => {
            state.requestCreateSolicitacao.loading = false
            state.requestCreateSolicitacao.error = true
        },
        setRequestCreateSolicitacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestCreateSolicitacao.message = action.payload
        },
        resetRequestCreateSolicitacao: (state) => {
            state.requestCreateSolicitacao = {...responseInitialValues}
        },

        setRequestUpdateSolicitacaoData: (state, action: PayloadAction<any>) => {
            state.requestUpdateSolicitacao.data = action.payload
            state.requestUpdateSolicitacao.loading = false
            state.requestUpdateSolicitacao.error = false
        },
        setRequestUpdateSolicitacaoLoading: (state) => {
            state.requestUpdateSolicitacao.loading = true
            state.requestUpdateSolicitacao.error = false
        },
        setRequestUpdateSolicitacaoError: (state) => {
            state.requestUpdateSolicitacao.loading = false
            state.requestUpdateSolicitacao.error = true
        },
        setRequestUpdateSolicitacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateSolicitacao.message = action.payload
        },
        resetRequestUpdateSolicitacao: (state) => {
            state.requestUpdateSolicitacao = {...responseInitialValues}
        },

        setRequestDeleteSolicitacaoData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteSolicitacao.data = action.payload
            state.requestDeleteSolicitacao.loading = false
            state.requestDeleteSolicitacao.error = false
        },
        setRequestDeleteSolicitacaoLoading: (state) => {
            state.requestDeleteSolicitacao.loading = true
            state.requestDeleteSolicitacao.error = false
        },
        setRequestDeleteSolicitacaoError: (state) => {
            state.requestDeleteSolicitacao.loading = false
            state.requestDeleteSolicitacao.error = true
        },
        setRequestDeleteSolicitacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestDeleteSolicitacao.message = action.payload
        },
        resetRequestDeleteSolicitacao: (state) => {
            state.requestDeleteSolicitacao = {...responseInitialValues}
        },

        setRequestGetSolicitacoesCteData: (state, action: PayloadAction<ResponsePattern<SolicitacaoCte[]>>) => {
            state.requestGetSolicitacoesCte.data = action.payload
            state.requestGetSolicitacoesCte.loading = false
            state.requestGetSolicitacoesCte.error = false
        },
        setRequestGetSolicitacoesCteLoading: (state) => {
            state.requestGetSolicitacoesCte.loading = true
            state.requestGetSolicitacoesCte.error = false
        },
        setRequestGetSolicitacoesCteError: (state) => {
            state.requestGetSolicitacoesCte.loading = false
            state.requestGetSolicitacoesCte.error = true
        },
        setRequestGetSolicitacoesCteMessage: (state, action: PayloadAction<string>) => {
            state.requestGetSolicitacoesCte.message = action.payload
        },
        resetRequestGetSolicitacoesCte: (state) => {
            state.requestGetSolicitacoesCte = {...responseInitialValues}
        },
    }
})

const actions = requestSolicitacaoSlice.actions

export const requestGetSolicitacoesActions = {
    data: actions.setRequestGetSolicitacoesData,
    loading: actions.setRequestGetSolicitacoesLoading,
    error: actions.setRequestGetSolicitacoesError,
    message: actions.setRequestGetSolicitacoesMessage,
    reset: actions.resetRequestGetSolicitacoes,
}

export const requestGetSolicitacaoActions = {
    data: actions.setRequestGetSolicitacaoData,
    loading: actions.setRequestGetSolicitacaoLoading,
    error: actions.setRequestGetSolicitacaoError,
    message: actions.setRequestGetSolicitacaoMessage,
    reset: actions.resetRequestGetSolicitacao,
}

export const requestCreateSolicitacaoActions = {
    data: actions.setRequestCreateSolicitacaoData,
    loading: actions.setRequestCreateSolicitacaoLoading,
    error: actions.setRequestCreateSolicitacaoError,
    message: actions.setRequestCreateSolicitacaoMessage,
    reset: actions.resetRequestCreateSolicitacao,
}

export const requestUpdateSolicitacaoActions = {
    data: actions.setRequestUpdateSolicitacaoData,
    loading: actions.setRequestUpdateSolicitacaoLoading,
    error: actions.setRequestUpdateSolicitacaoError,
    message: actions.setRequestUpdateSolicitacaoMessage,
    reset: actions.resetRequestUpdateSolicitacao,
}

export const requestDeleteSolicitacaoActions = {
    data: actions.setRequestDeleteSolicitacaoData,
    loading: actions.setRequestDeleteSolicitacaoLoading,
    error: actions.setRequestDeleteSolicitacaoError,
    message: actions.setRequestDeleteSolicitacaoMessage,
    reset: actions.resetRequestDeleteSolicitacao,
}

export const requestGetSolicitacoesCteActions = {
    data: actions.setRequestGetSolicitacoesCteData,
    loading: actions.setRequestGetSolicitacoesCteLoading,
    error: actions.setRequestGetSolicitacoesCteError,
    message: actions.setRequestGetSolicitacoesCteMessage,
    reset: actions.resetRequestGetSolicitacoesCte,
}

export default requestSolicitacaoSlice.reducer