import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Pernoite } from "../interfaces/Pernoite"
import { setPernoite, setPernoites } from "../reducers/pernoiteReducer"
import { requestGetPernoiteActions, requestGetPernoitesActions } from "../reducers/requestPernoiteReducer"

export async function getPernoites(dispatch: DispatchType){
    initRequest(dispatch, requestGetPernoitesActions)

    const endpoint = `${ENV.APP_ENDPOINT}/`
    const response = await request.get<ResponsePattern<Pernoite[]>>({ endpoint })

    handleResponse('getPernoites', dispatch, response, requestGetPernoitesActions)
    .then(data => {
        dispatch(setPernoites(data))
    })
}

export async function getPernoite(dispatch: DispatchType, idViagem: string){
    initRequest(dispatch, requestGetPernoiteActions, setPernoite)

    const endpoint = `${ENV.APP_ENDPOINT}/`
    const params = `${idViagem}`
    const response = await request.get<ResponsePattern<Pernoite>>({ endpoint, params })

    handleResponse('getPernoite', dispatch, response, requestGetPernoiteActions)
    .then(data => {
        dispatch(setPernoite(data))
    })
}