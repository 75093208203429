import { RouteType } from "../../routes/interfaces"

const authRoutes: RouteType[] = [
    {
        key: 'login',
        path: 'login',
        title: 'Login',
        type: 'form',
    },
    {
        key: 'logout',
        path: 'logout',
        title: 'Logout',
        type: 'center',
        userVerification: true,
    }
]

export default authRoutes