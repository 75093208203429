import React, { useState } from 'react'
import { mdiTableCog } from '@mdi/js'
import Button from '@components/Common/Button'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableWrapper, TableColumn, TableConfig, TableHeader, TableBody, TableMessage, TableRow, TableOptions } from '@components/Common/Table'
import { useAppSelector } from '@redux/hooks'
import { formatCurrency } from '@utils/format'

const TableRomaneioSolicitacoes: React.FC = () => {

    const { viagem } = useAppSelector(s => s.viagem)

    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'romaneioSolicitacoes', 
        ["CTE", "NF", "Embarcador", "Doc remetente", "Destinatário", "Doc destinatário", "Origem", "CTE origem", "Destino", "CTE destino", "Doc consignatário", "Doc pagador frete", "Mercadoria", "Frete", "Dias entrega", "Dias armazém", "Peso"]
    ))

    return(

        <>
            <TableWrapper config = {tableConfig}>
                <TableOptions>
                    <Button
                        icon = {mdiTableCog}
                        onClick = {() => setOpenTableConfig(true)}
                    />
                </TableOptions>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {viagem!.solicitacoes.length === 0 && <TableMessage text = "Nenhuma solicitação adicionada!" />}
                        {viagem!.solicitacoes.map((item, index) => (
                            <TableRow key = {index}>
                                <TableColumn>{`${item.nrCto} ${item.serieCto}`}</TableColumn>
                                <TableColumn>{`${item.nrNf} ${item.serieNf}`}</TableColumn>

                                <TableColumn>{item.nmEmbarcador}</TableColumn>
                                <TableColumn>{item.docEmbarcador}</TableColumn>
                                <TableColumn>{item.nmDestinatario}</TableColumn>
                                <TableColumn>{item.docDestinatario}</TableColumn>

                                <TableColumn>{`${item.cidadeOrigem} ${item.ufOrigem}`}</TableColumn>
                                <TableColumn>{item.filialOrigemCto}</TableColumn>
                                <TableColumn>{`${item.cidadeDestino} ${item.ufDestino}`}</TableColumn>
                                <TableColumn>{item.descFilialOrigemCto}</TableColumn>

                                <TableColumn>{item.docConsignatario}</TableColumn>
                                <TableColumn>{item.docPagadorFrete}</TableColumn>

                                <TableColumn>{formatCurrency(Number(item.valorMercadoria))}</TableColumn>
                                <TableColumn>{formatCurrency(Number(item.valorFreteCobrado))}</TableColumn>

                                <TableColumn>{item.diasParaEntrega}</TableColumn>
                                <TableColumn>{item.qtdeDiasArmazem}</TableColumn>

                                <TableColumn>{item.pesoMercadoria}</TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
            <TableConfig
                openModal = {openTableConfig}
                setOpenModal = {setOpenTableConfig}
                tableConfig = {tableConfig}
                setTableConfig = {setTableConfig}
            />
        </>

    )

}

export default TableRomaneioSolicitacoes