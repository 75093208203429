import React from 'react'
import Modal from '@components/Common/Modal'
import { ModalProps } from '@interfaces/Modal'
import TableCidadesPassagem from '../Table'
import Button from '@components/Common/Button'

const ModalCidadesPassagem: React.FC<ModalProps> = props => {

    return (

        <>
            <Modal
                {...props}
                title="Cidades de passagem"
            >
                <TableCidadesPassagem {...props} />
                <div className='ml-auto mt-4'>
                    <Button
                        label="Fechar"
                        onClick={() => props.setOpen(false)}
                    />
                </div>
            </Modal>
        </>

    )

}

export default ModalCidadesPassagem