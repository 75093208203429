import React, { useState } from 'react'
import Icon from '@mdi/react'
import { mdiChevronUp } from '@mdi/js'
import { FormSectionProps } from '@components/Common/Form/types'
import createClassName from '@utils/createClassName'

const FormSection: React.FC <FormSectionProps> = ({ children, title, subTitle, status, autoHide }) => {

    const [showContent, setShowContent] = useState(!autoHide)

    const subTitleClasses = createClassName('formSectionSubTitle', [status && `status ${status}`])
    const iconClasses = createClassName('iconContainer', [showContent && 'showData'])

    return(

        <div className = "formSection">
            {!!title && (
                <div className = "FormSectionHeader" onClick = {() => setShowContent(!showContent)}>
                    <div className = "headerTextContainer">
                        <span className = "formSectionTitle">{title}</span>
                        {!!subTitle && <span className = {subTitleClasses}>{subTitle}</span>}
                    </div>
                    <div className = {iconClasses}>
                        <Icon
                            path = {mdiChevronUp}
                            size = "24px"
                        />
                    </div>
                </div>
            )}
            {showContent && <div className = "formSectionContent">{children}</div>}
        </div>

    )

}

export default FormSection