import React from 'react'
import Form from '@components/Common/Form'
import { FormCreateMotoristasProps } from './types'

const FormCreateMotoristasFields: React.FC<FormCreateMotoristasProps> = ({ handleChange }) => {

    return (

        <>
            <Form.Row columns={1}>
                <Form.Group
                    inputID="documento"
                    inputName="documento"
                    label="Documento"
                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                    handleChange={handleChange}
                />
            </Form.Row>
            <Form.Row columns={1}>
                <Form.Group inputID="nome" inputName="nome" label="Nome" />
            </Form.Row>
        </>

    )

}

export default FormCreateMotoristasFields