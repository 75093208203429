import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DashboardChart } from "../interfaces/DashboardChart"

interface State {
    chartTransferencias: DashboardChart | null
    chartColetas: DashboardChart | null
    chartDistribuicoes: DashboardChart | null
    chartLotacao: DashboardChart | null
    chartPluma: DashboardChart | null

    dashboardAutoRefresh: boolean

    openModalView: boolean
}

const initialState: State = {
    chartTransferencias: null,
    chartColetas: null,
    chartDistribuicoes: null,
    chartLotacao: null,
    chartPluma: null,

    dashboardAutoRefresh: false,

    openModalView: false
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setChartTransferencias: (state, action: PayloadAction<DashboardChart | null>) => {
            state.chartTransferencias = action.payload
        },
        setChartColetas: (state, action: PayloadAction<DashboardChart | null>) => {
            state.chartColetas = action.payload
        },
        setChartDistribuicoes: (state, action: PayloadAction<DashboardChart | null>) => {
            state.chartDistribuicoes = action.payload
        },
        setChartLotacao: (state, action: PayloadAction<DashboardChart | null>) => {
            state.chartLotacao = action.payload
        },
        setChartPluma: (state, action: PayloadAction<DashboardChart | null>) => {
            state.chartPluma = action.payload
        },

        setDashboardAutoRefresh: (state, action: PayloadAction<boolean>) => {
            state.dashboardAutoRefresh = action.payload
        },

        setOpenModalView: (state, action: PayloadAction<boolean>) => {
            state.openModalView = action.payload
        },
    }
})

export const {
    setChartTransferencias, setChartColetas, setChartDistribuicoes, setChartLotacao, setChartPluma,
    setDashboardAutoRefresh,
    setOpenModalView
} = dashboardSlice.actions
export default dashboardSlice.reducer