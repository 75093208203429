import React, { useState } from 'react'
import { mdiDelete, mdiInformation, mdiPencil, mdiPlus } from '@mdi/js'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatCurrency } from '@utils/format'
import { setCurrentCliente } from '@modules/cliente/reducers/clienteReducer'
import ModalDeleteCliente from '../ModalDeleteCliente'
import ModalParticularidadesCliente from '../ModalParticularidadesCliente'
import FormSearchCliente from '@modules/viagem/components/Search/FormSearchCliente'
import { getClientes } from '@modules/cliente/controllers/clienteController'

const TableClientes: React.FC = () => {

    const dispatch = useAppDispatch()
    const { clientes } = useAppSelector(s => s.cliente)
    const { requestGetClientes } = useAppSelector(s => s.requestCliente)
    const { requestSearchEmbarcador, requestSearchCliente } = useAppSelector(s => s.requestViagem)


    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [openModalParticularidades, setOpenModalParticularidades] = useState(false)
    const [tableConfig] = useState(createTableConfig(
        'clientes',
        ['ID', 'Nome', 'Documento', 'R$/Ton', 'Pagador', 'Opções']
    ))

    const CR = conditionaRender(requestGetClientes, clientes, true)

    return (

        <>
            <TablePaginator
                data={clientes}
                config={tableConfig}
                conditionalRender={CR}
                options={
                    <>
                        <ButtonGroup>
                            <Button
                                icon={mdiPlus}
                                label="Criar Cliente"
                                status="success"
                                link="criar"
                            />
                        </ButtonGroup>

                        <FormSearchCliente
                            handleSearchSistema={v => {
                                getClientes(dispatch, v)
                            }}
                        />
                    </>
                }
                renderItem={(item, index) => (
                    <TableRow key={index}>
                        <TableColumn>{item.id}</TableColumn>
                        <TableColumn>{item.nome}</TableColumn>
                        <TableColumn>{item.nroDocumento}</TableColumn>
                        <TableColumn>{formatCurrency(item.valorPeso)}</TableColumn>
                        <TableColumn>{item.flagPagador ? 'Sim' : 'Não'}</TableColumn>
                        <TableColumn className='justify-end flex'>
                            {item?.particularidades && (
                                <TableOption
                                    icon={mdiInformation}
                                    status='warning'
                                    onClick={() => {
                                        dispatch(setCurrentCliente(item))

                                        setOpenModalParticularidades(true)
                                    }}
                                />
                            )}
                            <TableOption
                                icon={mdiPencil}
                                link={`${item.id}`}
                            />
                            <TableOption
                                icon={mdiDelete}
                                status="error"
                                onClick={() => {
                                    dispatch(setCurrentCliente(item))

                                    setOpenModalDelete(true)
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />

            <ModalDeleteCliente
                open={openModalDelete}
                setOpen={setOpenModalDelete}
            />
            <ModalParticularidadesCliente
                open={openModalParticularidades}
                setOpen={setOpenModalParticularidades}
            />
        </>

    )

}

export default TableClientes