import React from 'react'
import Form from '@components/Common/Form'
import Select from '@components/Common/Select'
import Checkbox from '@components/Common/Checkbox'
import RadioButton from '@components/Common/RadioButton'
import RadioButtonGroup from '@components/Common/RadioButton/RadioButtonGroup'

const Documentacao2: React.FC = () => {

    return(

        <>
            <Form.Section title = "Info. Estrangeiro">
                <Form.Row columns = {3} grid = "1fr auto auto">
                    <Form.Group
                        label = "Chegada ao Brasil"
                        inputID = "dtChegadaBrasil"
                        inputName = "dtChegadaBrasil"
                        inputType = "date"
                    />

                    <RadioButtonGroup label = "Casado com brasileiro(a)">
                        <RadioButton
                            label = "Sim"
                            name = "casadoBrasileiro"
                            value = "sim"
                        />

                        <RadioButton
                            label = "Não"
                            name = "casadoBrasileiro"
                            value = "nao"
                        />
                    </RadioButtonGroup>

                    <RadioButtonGroup label = "Tem filhos brasileiros">
                        <RadioButton
                            label = "Sim"
                            name = "filhosBrasileiros"
                            value = "sim"
                        />

                        <RadioButton
                            label = "Não"
                            name = "filhosBrasileiros"
                            value = "nao"
                        />
                    </RadioButtonGroup>
                </Form.Row>
            </Form.Section>

            <Form.Section>
                <Form.Row columns = {1}>
                    <Form.Group
                        label = "Classificação da condição do trabalhador estrangeiro no Brasil"
                        inputID = "classificacaoCondicaoTrabalhador"
                        inputName = "classificacaoCondicaoTrabalhador"
                    />
                </Form.Row>

                <Form.Row columns = {5}>
                    <Form.Group
                        label = "Classe contribuição"
                        inputID = "classeContribuicao"
                        inputName = "classeContribuicao"
                    />

                    <Form.Group
                        label = "Categoria"
                        inputID = "categoria"
                        inputName = "categoria"
                    />

                    <Form.Group
                        label = "Opção de FGTS"
                        inputID = "dtOpcaoFGTS"
                        inputName = "dtOpcaoFGTS"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "Número do R.N.T.R.C."
                        inputID = "nroRNTRC"
                        inputName = "nroRNTRC"
                    />

                    <Form.Group
                        label = "Vencimento R.N.T.R.C."
                        inputID = "dtValidadeRNTRC"
                        inputName = "dtValidadeRNTRC"
                        inputType = "date"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section title = "Condutores de veículos de transp. coletivos/Mopp">
                <Form.Row columns = {3}>
                    <Form.Group
                        label = "Número"
                        inputID = "nroMOPP"
                        inputName = "nroMOPP"
                    />

                    <Form.Group
                        label = "Expedição"
                        inputID = "dtExpedicaoMOPP"
                        inputName = "dtExpedicaoMOPP"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "Vencimento"
                        inputID = "dtValidadeMOPP"
                        inputName = "dtValidadeMOPP"
                        inputType = "date"
                    />
                </Form.Row>

                <Form.Row columns = {4}>
                    <Checkbox
                        label = "Cesta básica"
                        name = "flagCestaBasica"
                    />

                    <Checkbox
                        label = "Calcula FGTS"
                        name = "flagCalculaFGTS"
                    />

                    <Checkbox
                        label = "Recebe VR/VA"
                        name = "flagValeRefeicao"
                    />

                    <Checkbox
                        label = "Recebe adiantamento"
                        name = "flagAdiantamento"
                    />
                </Form.Row>
            </Form.Section>
            
            <Form.Section title = "Chave Pix">
                <Form.Row columns = {2} grid = "200px 1fr">
                    <Select
                        label = "Tipo"
                        value = ""
                        items = {[]}
                    />

                    <Form.Group
                        label = "Chave"
                        inputID = "chavePix"
                        inputName = "chavePix"
                    />
                </Form.Row>
            </Form.Section>

            <Form.Section>
                <Form.Row columns = {2} grid = "200px 1fr">
                    <Form.Group
                        label = "Tempo de residência"
                        inputID = "tempoResidencia"
                        inputName = "tempoResidencia"
                    />
                </Form.Row>
            </Form.Section>
        </>

    )

}

export default Documentacao2