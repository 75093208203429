import ENV from "@env/index"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ROUTE_INDEX } from "@config/index"
import storage from "@utils/storage"
import setAuthRoutes from "../scripts/setAuthRoutes"
import { UserDataRota, UserData } from "../interfaces/UserData"

interface State {
    userData: UserData | null
    loading: boolean
    isLogged: boolean
    authRoutes: UserDataRota[]
    routeIndex: string
}

const initialState: State = {
    userData: null,
    loading: true,
    isLogged: false,
    authRoutes: [],
    routeIndex: ROUTE_INDEX,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthLogin: (state, action: PayloadAction<UserData>) => {
            const initialRoute = action.payload.rotas.find(f => f.modulo.flagIndex)!.rotas.find(f => f.modulo.flagIndex)?.modulo.path

            if(!!initialRoute) state.routeIndex = initialRoute

            state.authRoutes = setAuthRoutes(action.payload)

            state.userData = action.payload
            state.loading = false
            state.isLogged = true

            storage.setItem('flagSystem', ENV.FLAG_SYSTEM)
            storage.setObject('user', action.payload)
        },
        setAuthLogout: (state) => {
            state.userData = null
            state.isLogged = false
            state.routeIndex = initialState.routeIndex
            
            storage.clear()
            window.location.reload()
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setAuthIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLogged = action.payload
        },
    }
})

export const { setAuthLogin, setAuthLogout, setAuthLoading, setAuthIsLoggedIn } = authSlice.actions
export default authSlice.reducer