import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Conferencia } from "../interfaces/Conferencia"
import { ConferenciaValidacao } from "../interfaces/ConferenciaValidacao"

interface State {
    conferencias: Conferencia[] | null
    conferencia: Conferencia | null

    currentConferencia: Conferencia | null

    conferenciaValidacoes: ConferenciaValidacao[] | null
    currentConferenciaValidacao: ConferenciaValidacao | null
}

const initialState: State = {
    conferencias: null,
    conferencia: null,

    currentConferencia: null,

    conferenciaValidacoes: null,
    currentConferenciaValidacao: null
}

const conferenciaSlice = createSlice({
    name: 'conferencia',
    initialState,
    reducers: {
        setConferencias: (state, action: PayloadAction<Conferencia[]>) => {
            state.conferencias = action.payload
        },
        setConferencia: (state, action: PayloadAction<Conferencia | null>) => {
            state.conferencia = action.payload
        },

        setCurrentConferencia: (state, action: PayloadAction<Conferencia>) => {
            state.currentConferencia = action.payload
        },

        setConferenciaValidacoes: (state, action: PayloadAction<ConferenciaValidacao[]>) => {
            state.conferenciaValidacoes = action.payload
        },
        setCurrentConferenciaValidacao: (state, action: PayloadAction<ConferenciaValidacao>) => {
            state.currentConferenciaValidacao = action.payload
        },
    }
})

export const { setConferencias, setConferencia, setCurrentConferencia, setConferenciaValidacoes, setCurrentConferenciaValidacao } = conferenciaSlice.actions
export default conferenciaSlice.reducer