import React, { useState } from 'react'
import { mdiEye } from '@mdi/js'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import Table, { TableBody, TableColumn, TableHeader, TableLoader, TableMessage, TableOption, TableRow, TableWrapper } from '@components/Common/Table'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formatFilenameByURL } from '@utils/format'
import formatDocumentos from '@modules/documento/scripts/formatDocumentos'

const TableDocumentos: React.FC = () => {

    const { viagemDocumentos } = useAppSelector(s => s.viagem)
    const { requestGetViagemDocumentos } = useAppSelector(s => s.requestViagem)

    const [tableConfig] = useState(createTableConfig(
        'viagemDocumentos',
        ["Tipo", "ID", "Titulo", "Opções"]
    ))

    const CR = conditionaRender(requestGetViagemDocumentos, viagemDocumentos, true)

    return (

        <>
            <TableWrapper config={tableConfig}>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {CR.LOADING && <TableLoader />}
                        {CR.NODATA && <TableMessage text="Nenhum documento encontrado" />}
                        {CR.DATA && (
                            <>
                                {viagemDocumentos![0].CTS?.map((d, index) => (
                                    <TableRow key={index}>
                                        <TableColumn>CTE</TableColumn>
                                        <TableColumn>{d.number}</TableColumn>
                                        <TableColumn>{formatFilenameByURL(d.url)}</TableColumn>
                                        <TableColumn>
                                            <TableOption
                                                icon={mdiEye}
                                                target="_blank"
                                                link={d.url}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                ))}
                                {viagemDocumentos![0].MDF?.map((d, index) => (
                                    <TableRow key={index}>
                                        <TableColumn>MDF</TableColumn>
                                        <TableColumn>{d.number}</TableColumn>
                                        <TableColumn>{formatFilenameByURL(d.url)}</TableColumn>
                                        <TableColumn>
                                            <TableOption
                                                icon={mdiEye}
                                                target="_blank"
                                                link={d.url}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                ))}
                                {viagemDocumentos![0].contrato?.map((d, index) => (
                                    <TableRow key={index}>
                                        <TableColumn>Contrato</TableColumn>
                                        <TableColumn>{d.number}</TableColumn>
                                        <TableColumn>{formatFilenameByURL(d.url)}</TableColumn>
                                        <TableColumn>
                                            <TableOption
                                                icon={mdiEye}
                                                target="_blank"
                                                link={d.url}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableWrapper>
        </>

    )

}

export default TableDocumentos