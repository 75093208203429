import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormEdit from './components/FormEdit'
import conditionaRender from '@utils/conditionalRender'
import { getFilial } from '@modules/filial/controllers/filialController'
import { useFormikContext } from 'formik'
import Loader from '@components/Feedback/Loader'

const FilialEditar: React.FC = () => {
    const dispatch = useAppDispatch()
    const { filial } = useAppSelector(s => s.filial)
    const { requestGetFilial } = useAppSelector(s => s.requestFilial)


    const { idFilial } = useParams()

    const CR = conditionaRender(requestGetFilial, filial)

    useEffect(() => {

        if (!!idFilial) {
            getFilial(dispatch, idFilial)
        }
    }, [dispatch, idFilial])


    return (

        <>
            <PageTitle title="Editar Filial" />
            {CR.LOADING && (
                <Section align="center">
                    <Loader />
                </Section>
            )}
            {CR.DATA && (
                <Section>
                    <FormEdit />
                </Section>
            )}
        </>

    )

}

export default FilialEditar