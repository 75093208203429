import { ViagemAtiva } from "@modules/viagem/interfaces/ViagemAtiva"
import getEnderecoViagem from "@modules/viagem/utils/getEnderecoViagem"
import saveExcel from "@utils/saveExcel"
import { formatCurrency, formatDate } from "@utils/format"

export default function createExcel(viagens: ViagemAtiva[]){
    const header = ["ID", "AE/SM", "Origem", "Destino", "Tomador", "Usuário", "Criado em", "Motorista", "Mercadoria", "Frete", "Destinatário", "Posição", "Pernoite", "Macro", "Escolta", "Tipo", "Obs"]
    const values = viagens.map(viagem => {
        const { remetenteEndereco, destinatarioEndereco } = getEnderecoViagem(viagem as any)

        return {
            "ID": viagem.id,
            "AE/SM": viagem.idViagem,
            "Origem": remetenteEndereco,
            "Destino": destinatarioEndereco,
            "Tomador": viagem.tomadorNome,
            "Usuário": viagem.usuario.nome,
            "Criado em": formatDate(viagem.dtCadastro),
            "Motorista": viagem.motoristaNome,
            "Mercadoria": formatCurrency(Number(viagem.valorMercadoria)),
            "Frete": formatCurrency(Number(viagem.valorFrete)),
            "Destinatário": viagem.destinatarioNome,
            "Posição": viagem.ultimaPosicao.length > 0 ? formatDate(viagem.ultimaPosicao[0].DTPOSICAO) : 'Sem posição',
            "Pernoite": viagem.ultimaPernoite,
            "Macro": viagem.ultimaMacro.length > 0 && (
                viagem.ultimaMacro[0].MACROMENSAGEM ?? viagem.ultimaMacro[0].MACROTEXTO
            ) || '',
            "Escolta": viagem.flagEscolta ? 'Sim' : 'Não',
            "Operação": viagem.flagRomaneio ? 'Romaneio' : 'Demanda',
            "Obs": viagem.obs,
        }
    })

    saveExcel(`viagens_ativas.xlsx`, header, values)
}