import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Section from '@components/Page/Section'
import PageTitle from '@components/Page/Title'
import Loader, { LoaderContainer } from '@components/Feedback/Loader'
import { getSolicitacao } from '@modules/solicitacao/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormSolicitacaoEdit from './components/FormSolicitacaoEdit'

const SolicitacaoEditar: React.FC = () => {

    const dispatch = useAppDispatch()
    const { solicitacao } = useAppSelector(s => s.solicitacao)
    const { requestGetSolicitacao } = useAppSelector(s => s.requestSolicitacao)

    const { idSolicitacao } = useParams()

    const CR = conditionaRender(requestGetSolicitacao, solicitacao)

    useEffect(() => {
        if(!!idSolicitacao){
            getSolicitacao(dispatch, idSolicitacao)
        }
    }, [dispatch, idSolicitacao])

    return(

        <>
            <PageTitle title = "Editar solicitação" />
            {CR.LOADING && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            {CR.DATA && (
                <Section>
                    <FormSolicitacaoEdit />
                </Section>
            )}
        </>

    )

}

export default SolicitacaoEditar