import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Conferencia } from "../interfaces/Conferencia"
import { ConferenciaValidacao } from "../interfaces/ConferenciaValidacao"

interface State {
    requestGetConferencias: ResponseDefault<Conferencia[]>
    requestGetConferencia: ResponseDefault<Conferencia>
    requestGetConferenciaValidacoes: ResponseDefault<ConferenciaValidacao[]>
    requestUpdateConferencia: ResponseDefault<any>
    requestDeleteConferencia: ResponseDefault<any>
    requestValidateConferencia: ResponseDefault<any>
    requestInvalidateConferencia: ResponseDefault<any>
    requestUpdateConferenciaValidacao: ResponseDefault<any>
}

const initialState: State = {
    requestGetConferencias: responseInitialValues,
    requestGetConferencia: responseInitialValues,
    requestGetConferenciaValidacoes: responseInitialValues,
    requestUpdateConferencia: responseInitialValues,
    requestDeleteConferencia: responseInitialValues,
    requestValidateConferencia: responseInitialValues,
    requestInvalidateConferencia: responseInitialValues,
    requestUpdateConferenciaValidacao: responseInitialValues,
}

const requestConferenciaSlice = createSlice({
    name: 'requestConferencia',
    initialState,
    reducers: {
        setRequestGetConferenciasData: (state, action: PayloadAction<ResponsePattern<Conferencia[]>>) => {
            state.requestGetConferencias.data = action.payload
            state.requestGetConferencias.loading = false
            state.requestGetConferencias.error = false
        },
        setRequestGetConferenciasLoading: (state) => {
            state.requestGetConferencias.loading = true
            state.requestGetConferencias.error = false
        },
        setRequestGetConferenciasError: (state) => {
            state.requestGetConferencias.loading = false
            state.requestGetConferencias.error = true
        },
        setRequestGetConferenciasMessage: (state, action: PayloadAction<string>) => {
            state.requestGetConferencias.message = action.payload
        },
        resetRequestGetConferencias: (state) => {
            state.requestGetConferencias = {...responseInitialValues}
        },

        setRequestGetConferenciaData: (state, action: PayloadAction<ResponsePattern<Conferencia>>) => {
            state.requestGetConferencia.data = action.payload
            state.requestGetConferencia.loading = false
            state.requestGetConferencia.error = false
        },
        setRequestGetConferenciaLoading: (state) => {
            state.requestGetConferencia.loading = true
            state.requestGetConferencia.error = false
        },
        setRequestGetConferenciaError: (state) => {
            state.requestGetConferencia.loading = false
            state.requestGetConferencia.error = true
        },
        setRequestGetConferenciaMessage: (state, action: PayloadAction<string>) => {
            state.requestGetConferencia.message = action.payload
        },
        resetRequestGetConferencia: (state) => {
            state.requestGetConferencia = {...responseInitialValues}
        },

        setRequestGetConferenciaValidacoesData: (state, action: PayloadAction<ResponsePattern<ConferenciaValidacao[]>>) => {
            state.requestGetConferenciaValidacoes.data = action.payload
            state.requestGetConferenciaValidacoes.loading = false
            state.requestGetConferenciaValidacoes.error = false
        },
        setRequestGetConferenciaValidacoesLoading: (state) => {
            state.requestGetConferenciaValidacoes.loading = true
            state.requestGetConferenciaValidacoes.error = false
        },
        setRequestGetConferenciaValidacoesError: (state) => {
            state.requestGetConferenciaValidacoes.loading = false
            state.requestGetConferenciaValidacoes.error = true
        },
        setRequestGetConferenciaValidacoesMessage: (state, action: PayloadAction<string>) => {
            state.requestGetConferenciaValidacoes.message = action.payload
        },
        resetRequestGetConferenciaValidacoes: (state) => {
            state.requestGetConferenciaValidacoes = {...responseInitialValues}
        },

        setRequestUpdateConferenciaData: (state, action: PayloadAction<any>) => {
            state.requestUpdateConferencia.data = action.payload
            state.requestUpdateConferencia.loading = false
            state.requestUpdateConferencia.error = false
        },
        setRequestUpdateConferenciaLoading: (state) => {
            state.requestUpdateConferencia.loading = true
            state.requestUpdateConferencia.error = false
        },
        setRequestUpdateConferenciaError: (state) => {
            state.requestUpdateConferencia.loading = false
            state.requestUpdateConferencia.error = true
        },
        setRequestUpdateConferenciaMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateConferencia.message = action.payload
        },
        resetRequestUpdateConferencia: (state) => {
            state.requestUpdateConferencia = {...responseInitialValues}
        },

        setRequestDeleteConferenciaData: (state, action: PayloadAction<any>) => {
            state.requestDeleteConferencia.data = action.payload
            state.requestDeleteConferencia.loading = false
            state.requestDeleteConferencia.error = false
        },
        setRequestDeleteConferenciaLoading: (state) => {
            state.requestDeleteConferencia.loading = true
            state.requestDeleteConferencia.error = false
        },
        setRequestDeleteConferenciaError: (state) => {
            state.requestDeleteConferencia.loading = false
            state.requestDeleteConferencia.error = true
        },
        setRequestDeleteConferenciaMessage: (state, action: PayloadAction<string>) => {
            state.requestDeleteConferencia.message = action.payload
        },
        resetRequestDeleteConferencia: (state) => {
            state.requestDeleteConferencia = {...responseInitialValues}
        },

        setRequestValidateConferenciaData: (state, action: PayloadAction<any>) => {
            state.requestValidateConferencia.data = action.payload
            state.requestValidateConferencia.loading = false
            state.requestValidateConferencia.error = false
        },
        setRequestValidateConferenciaLoading: (state) => {
            state.requestValidateConferencia.loading = true
            state.requestValidateConferencia.error = false
        },
        setRequestValidateConferenciaError: (state) => {
            state.requestValidateConferencia.loading = false
            state.requestValidateConferencia.error = true
        },
        setRequestValidateConferenciaMessage: (state, action: PayloadAction<string>) => {
            state.requestValidateConferencia.message = action.payload
        },
        resetRequestValidateConferencia: (state) => {
            state.requestValidateConferencia = {...responseInitialValues}
        },

        setRequestInvalidateConferenciaData: (state, action: PayloadAction<any>) => {
            state.requestInvalidateConferencia.data = action.payload
            state.requestInvalidateConferencia.loading = false
            state.requestInvalidateConferencia.error = false
        },
        setRequestInvalidateConferenciaLoading: (state) => {
            state.requestInvalidateConferencia.loading = true
            state.requestInvalidateConferencia.error = false
        },
        setRequestInvalidateConferenciaError: (state) => {
            state.requestInvalidateConferencia.loading = false
            state.requestInvalidateConferencia.error = true
        },
        setRequestInvalidateConferenciaMessage: (state, action: PayloadAction<string>) => {
            state.requestInvalidateConferencia.message = action.payload
        },
        resetRequestInvalidateConferencia: (state) => {
            state.requestInvalidateConferencia = {...responseInitialValues}
        },

        setRequestUpdateConferenciaValidacaoData: (state, action: PayloadAction<any>) => {
            state.requestUpdateConferenciaValidacao.data = action.payload
            state.requestUpdateConferenciaValidacao.loading = false
            state.requestUpdateConferenciaValidacao.error = false
        },
        setRequestUpdateConferenciaValidacaoLoading: (state) => {
            state.requestUpdateConferenciaValidacao.loading = true
            state.requestUpdateConferenciaValidacao.error = false
        },
        setRequestUpdateConferenciaValidacaoError: (state) => {
            state.requestUpdateConferenciaValidacao.loading = false
            state.requestUpdateConferenciaValidacao.error = true
        },
        setRequestUpdateConferenciaValidacaoMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateConferenciaValidacao.message = action.payload
        },
        resetRequestUpdateConferenciaValidacao: (state) => {
            state.requestUpdateConferenciaValidacao = {...responseInitialValues}
        },
    }
})

const actions = requestConferenciaSlice.actions

export const requestGetConferenciasActions = {
    data: actions.setRequestGetConferenciasData,
    loading: actions.setRequestGetConferenciasLoading,
    error: actions.setRequestGetConferenciasError,
    message: actions.setRequestGetConferenciasMessage,
    reset: actions.resetRequestGetConferencias,
}

export const requestGetConferenciaActions = {
    data: actions.setRequestGetConferenciaData,
    loading: actions.setRequestGetConferenciaLoading,
    error: actions.setRequestGetConferenciaError,
    message: actions.setRequestGetConferenciaMessage,
    reset: actions.resetRequestGetConferencia,
}

export const requestGetConferenciaValidacoesActions = {
    data: actions.setRequestGetConferenciaValidacoesData,
    loading: actions.setRequestGetConferenciaValidacoesLoading,
    error: actions.setRequestGetConferenciaValidacoesError,
    message: actions.setRequestGetConferenciaValidacoesMessage,
    reset: actions.resetRequestGetConferenciaValidacoes,
}

export const requestUpdateConferenciaActions = {
    data: actions.setRequestUpdateConferenciaData,
    loading: actions.setRequestUpdateConferenciaLoading,
    error: actions.setRequestUpdateConferenciaError,
    message: actions.setRequestUpdateConferenciaMessage,
    reset: actions.resetRequestUpdateConferencia,
}

export const requestDeleteConferenciaActions = {
    data: actions.setRequestDeleteConferenciaData,
    loading: actions.setRequestDeleteConferenciaLoading,
    error: actions.setRequestDeleteConferenciaError,
    message: actions.setRequestDeleteConferenciaMessage,
    reset: actions.resetRequestDeleteConferencia,
}

export const requestValidateConferenciaActions = {
    data: actions.setRequestValidateConferenciaData,
    loading: actions.setRequestValidateConferenciaLoading,
    error: actions.setRequestValidateConferenciaError,
    message: actions.setRequestValidateConferenciaMessage,
    reset: actions.resetRequestValidateConferencia,
}

export const requestInvalidateConferenciaActions = {
    data: actions.setRequestInvalidateConferenciaData,
    loading: actions.setRequestInvalidateConferenciaLoading,
    error: actions.setRequestInvalidateConferenciaError,
    message: actions.setRequestInvalidateConferenciaMessage,
    reset: actions.resetRequestInvalidateConferencia,
}

export const requestUpdateConferenciaValidacaoActions = {
    data: actions.setRequestUpdateConferenciaValidacaoData,
    loading: actions.setRequestUpdateConferenciaValidacaoLoading,
    error: actions.setRequestUpdateConferenciaValidacaoError,
    message: actions.setRequestUpdateConferenciaValidacaoMessage,
    reset: actions.resetRequestUpdateConferenciaValidacao,
}

export default requestConferenciaSlice.reducer