import React from 'react'
import Form from '@components/Common/Form'
import RadioButton from '@components/Common/RadioButton'
import RadioButtonGroup from '@components/Common/RadioButton/RadioButtonGroup'

const TabDadosPessoais: React.FC = () => {

    return(

        <>
            <Form.Section>
                <Form.Row columns = {2} grid = "1fr 1fr">
                    <Form.Group
                        label = "Nome completo"
                        inputID = "nomeCompleto"
                        inputName = "nomeCompleto"
                    />

                    <Form.Group
                        label = "Apelido"
                        inputID = "apelido"
                        inputName = "apelido"
                    />
                </Form.Row>

                <Form.Row columns = {3} grid = "1fr 1fr 1fr">
                    <Form.Group
                        label = "Nascimento"
                        inputID = "dtNascimento"
                        inputName = "dtNascimento"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "Município de nascimento"
                        inputID = "municipioNascimento"
                        inputName = "municipioNascimento"
                    />

                    <Form.Group
                        label = "UF de nascimento"
                        inputID = "ufNascimento"
                        inputName = "ufNascimento"
                    />
                </Form.Row>

                <Form.Row columns = {3} grid = "auto 1fr 1fr">
                    <RadioButtonGroup
                        label = "Sexo"
                    >
                        <RadioButton
                            label = "Masculino"
                            name = "sexo"
                            value = "masculino"
                        />

                        <RadioButton
                            label = "Feminino"
                            name = "sexo"
                            value = "feminino"
                        />
                    </RadioButtonGroup>

                    <Form.Group
                        label = "Grau de instrução"
                        inputID = "grauInstrucao"
                        inputName = "grauInstrucao"
                    />

                    <Form.Group
                        label = "Nacionalidade"
                        inputID = "nacionalidade"
                        inputName = "nacionalidade"
                    />
                </Form.Row>

                <Form.Row columns = {6} grid = "1fr 100px 100px 1fr 1fr auto">
                    <Form.Group
                        label = "Banco conta corrente"
                        inputID = "bancoContaCorrente"
                        inputName = "bancoContaCorrente"
                    />

                    <Form.Group
                        label = "Agência"
                        inputID = "agencia"
                        inputName = "agencia"
                    />

                    <Form.Group
                        label = "DV"
                        inputID = "dv"
                        inputName = "dv"
                    />

                    <Form.Group
                        label = "Conta corrente"
                        inputID = "conta"
                        inputName = "conta"
                    />

                    <Form.Group
                        label = "Nº da conta"
                        inputID = "nroConta"
                        inputName = "nroConta"
                    />

                    <RadioButtonGroup
                        label = "Conta"
                    >
                        <RadioButton
                            label = "Corrente"
                            name = "tipoConta"
                            value = "corrente"
                        />
                        <RadioButton
                            label = "Popança"
                            name = "tipoConta"
                            value = "poupanca"
                        />
                        <RadioButton
                            label = "Salário"
                            name = "tipoConta"
                            value = "salario"
                        />
                    </RadioButtonGroup>
                </Form.Row>

                <Form.Row columns = {4} grid = "1fr 1fr 1fr 1fr">
                    <Form.Group
                        label = "Estado civil"
                        inputID = "estadoCivil"
                        inputName = "estadoCivil"
                    />

                    <Form.Group
                        label = "Admissão"
                        inputID = "dtAdmissao"
                        inputName = "dtAdmissao"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "Demissão"
                        inputID = "dtDemissao"
                        inputName = "dtDemissao"
                        inputType = "date"
                    />

                    <Form.Group
                        label = "Tipo sanguíneo"
                        inputID = "tipoSanguineo"
                        inputName = "tipoSanguineo"
                    />
                </Form.Row>

                <Form.Row columns = {2} grid = "1fr 1fr">
                    <Form.Group
                        label = "Raça"
                        inputID = "raca"
                        inputName = "raca"
                    />

                    <Form.Group
                        label = "Motivo de desligamento"
                        inputID = "motivoDesligamento"
                        inputName = "motivoDesligamento"
                    />
                </Form.Row>

                <Form.Row columns = {2} grid = "1fr 1fr">
                    <Form.Group
                        label = "País de nascimento"
                        inputID = "paisNascimento"
                        inputName = "paisNascimento"
                    />

                    <Form.Group
                        label = "País de nacionalidade"
                        inputID = "paisNacionalidade"
                        inputName = "paisNacionalidade"
                    />
                </Form.Row>
            </Form.Section>
        </>

    )

}

export default TabDadosPessoais