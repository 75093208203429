import { formatValue } from "react-currency-input-field"
import moment from 'moment'
import 'moment/locale/pt-br'

export function formatDate(date: Date | null){
    if(!date){
        return ''
    }
    
    return `${moment(date).locale('pt-br').format('L')} ${moment(date).locale('pt-br').format('LT')}`
}

export function firstLetterToUpperCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatToBrNumber(value: string | number) {
    return value.toLocaleString().replaceAll(',', '*').replaceAll('.', ',').replaceAll('*', '.')
}

export function formatCurrency(value?: string | number | null){
    if(!value) return ''

    return formatValue({
        value: `${value}`,
        groupSeparator: '.',
        decimalSeparator: ',',
        prefix: 'R$',
        decimalScale: 2
    })
}

export function formatCNPJ(value: string){
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

export function formatLink(link: string){
    if(link.includes('http')) return link.replace('/','')
    return link
}

export function formatDocumento(value: string){
    if(value.length > 11){
        return formatCNPJ(value)
    }

    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
}

export function formatPunctuationFromCNPJ(cnpj: string): string {
    return cnpj.replace(/[^\d]/g, '')
}

export function formatFilenameByURL(url: string){
    if(!url) return ''
    
    const regex = /https:\/\/denunc\.s3\.sa-east-1\.amazonaws\.com\/.{37}/g
    const formattedFilename = url.replace(regex, '')

    return formattedFilename
}