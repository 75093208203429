import React, { useState } from 'react'
import { mdiDelete, mdiPencil, mdiPlus } from '@mdi/js'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import ModalConfirm from '@components/Feedback/ModalConfirm'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Common/Table'
import { deleteOperacao } from '@modules/operacao/controllers/operacaoController'
import { setCurrentOperacao } from '@modules/operacao/reducers/operacaoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import ModalEditOperacao from '../ModalEdit'
import ModalCreateOperacao from '../ModalCreate'

const TableOperacao: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { operacoes, currentOperacao } = useAppSelector(s => s.operacao)
    const { requestGetOperacoes, requestDeleteOperacao } = useAppSelector(s => s.requestOperacao)

    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalUpdate, setOpenModalUpdate] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [tableConfig] = useState(createTableConfig(
        'operacoes',
        ['ID', 'Tipo de Operação', 'Ativo', 'Opções']
    ))

    const CR = conditionaRender(requestGetOperacoes, operacoes, true)

    return(

        <>
            <TablePaginator
                data = {operacoes}
                config = {tableConfig}
                conditionalRender = {CR}
                options = {
                    <>
                        <div />

                        <ButtonGroup>
                            <Button
                                icon = {mdiPlus}
                                status = "success"
                                label = "Criar Tipo de Operação"
                                onClick = {() => {
                                    setOpenModalCreate(true)
                                }}
                            />
                        </ButtonGroup>
                    </>
                }
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.codigoTipoOperacao}</TableColumn>
                        <TableColumn>{item.descricao}</TableColumn>
                        <TableColumn>{item.flagAtivo ? 'Sim': 'Não'}</TableColumn>
                        <TableColumn width = {80}>
                            <TableOption
                                icon = {mdiPencil}
                                onClick = {() => {
                                    dispatch(setCurrentOperacao(item))
                                    
                                    setOpenModalUpdate(true)
                                }}
                                />
                            <TableOption
                                icon = {mdiDelete}
                                status = "error"
                                onClick = {() => {
                                    dispatch(setCurrentOperacao(item))

                                    setOpenModalDelete(true)
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />

            <ModalEditOperacao
                open = {openModalUpdate}
                setOpen = {setOpenModalUpdate}
            />
            <ModalCreateOperacao
                open = {openModalCreate}
                setOpen = {setOpenModalCreate}
            />
            <ModalConfirm
                open = {openModalDelete}
                setOpen = {setOpenModalDelete}
                title = "Excluir tipo de operação"
                message = "Deseja realmente excluir esse tipo de operação?"
                loading = {requestDeleteOperacao.loading}
                onConfirm = {props => {
                    deleteOperacao(dispatch, userData!, currentOperacao, props)
                }}
            />
        </>

    )

}

export default TableOperacao