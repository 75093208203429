import { RouteType } from "@routes/interfaces"

const viagemRoutes: RouteType[] = [
    {
        key: 'viagem',
        path: 'viagem',
        title: 'Viagem',
        userVerification: true,
        subRoutes: [
            { key: 'ViagemAtivaLista', path: '', title: 'Em viagem', userVerification: true },
            { key: 'ViagemAtiva', path: ':idViagem', title: 'Viagem atual', userVerification: true },
            { key: 'ViagemAtivaEditar', path: 'editar/:idSolicitacao', title: 'Viagem atual', userVerification: true },
            { key: 'ViagemAtivaRomaneioEditar', path: 'romaneio/editar/:idRomaneio', title: 'Viagem atual', userVerification: true },
            { key: 'viagemCriar', path: 'criar', title: 'Criar Viagem', userVerification: true },
            { key: 'viagemPernoite', path: 'pernoite/:idViagem', title: 'Pernoite', userVerification: true },
            { key: 'viagemFinalizadaLista', path: 'finalizada', title: 'Finalizadas', userVerification: true },
            { key: 'viagemFinalizada', path: 'finalizada/:idViagem', title: 'Finalizadas', userVerification: true },
            { key: 'viagemProtocolo', path: 'protocolo/:id', title: 'Viagem Protocolo', type: 'pdf', userVerification: true },

            { key: 'viagemAplicativoDashboard', path: 'aplicativo/acompanhamento', title: 'Acompanhamento Aplicativo', userVerification: true },
            { key: 'viagemAplicativoMotorista', path: 'aplicativo/motorista/:idMotorista/:nomeMotorista', title: 'Acompanhamento Aplicativo', userVerification: true },

            { key: 'viagemDocumentacaoFinanceiro', path: 'documento/financeiro', title: 'Documentações de Viagens', userVerification: true },
            { key: 'viagemEditarDocumentacao', path: 'documento/financeiro/:idDocumento', title: 'Editar Documentação', userVerification: true },
        ]
    }
]

export default viagemRoutes