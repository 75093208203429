import React, { useEffect, useState } from 'react'
import { mdiCheck, mdiClose } from '@mdi/js'
import { FieldArray, useFormikContext } from 'formik'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import { ModalProps } from '@interfaces/Modal'
import { formSolicitacaoValues } from '../../FormSolicitacaoFields/config'
import Select from '@components/Common/Select'
import selectUF from '@constants/select/uf'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getCidades } from '@modules/viagem/controllers/viagemController'
import createSelectItems from '@utils/createSelectItems'
import { setAlert } from '@modules/app/reducers/alertReducer'
import createAlert from '@modules/app/scripts/alert/createAlert'

const TableCidadesPassagem: React.FC<ModalProps> = ({ setOpen }) => {

    const dispatch = useAppDispatch()
    const { values, setFieldValue } = useFormikContext<typeof formSolicitacaoValues>()
    const { cidades } = useAppSelector(s => s.viagem)

    useEffect(() => {
        values.pesquisaPassagemUf && getCidades(dispatch, values.pesquisaPassagemUf) 
        setFieldValue('pesquisaPassagemCidade', '')  

    }, [values.pesquisaPassagemUf])

    return (

        <>
            <FieldArray name="dadosCidade">
                {({ push, remove }) => (
                    <Form.Container padding={false}>
                        {values.dadosCidade.map((item, index) => (
                            <Form.Row
                                key={index}
                                columns={3}
                                grid="80px 1fr auto"
                            >
                                <Form.Group
                                    label="UF"
                                    inputID={`dadosCidade.${index}.estado`}
                                    inputName={`dadosCidade.${index}.estado`}
                                    readonly={true}
                                />

                                <Form.Group
                                    label="Cidade"
                                    inputID={`dadosCidade.${index}.cidade`}
                                    inputName={`dadosCidade.${index}.cidade`}
                                    readonly={true}
                                />

                                <Button
                                    icon={mdiClose}
                                    status="error"
                                    onClick={() => {
                                        remove(index)
                                    }}
                                />
                            </Form.Row>
                        ))}

                        <Form.Row columns={3} grid="80px 1fr auto">
                            <Select
                                type="outline"
                                label="UF"
                                fieldName="pesquisaPassagemUf"
                                value={values.pesquisaPassagemUf}
                                items={selectUF}
                                setFieldValue={setFieldValue}
                            />
                            <Select
                                type="outline"
                                label="Cidade"
                                fieldName="pesquisaPassagemCidade"
                                value={values.pesquisaPassagemCidade}
                                items = {createSelectItems(cidades, 'nome', 'nome')}

                                setFieldValue={setFieldValue}
                            />

                            <Button
                                icon={mdiCheck}
                                status="success"
                                onClick={() => {
                                    if (!values.pesquisaPassagemUf) {
                                        return dispatch(setAlert(createAlert('warning', "Informe um UF para continuar")))
                                    }
                                    if (!values.pesquisaPassagemCidade) {
                                        return dispatch(setAlert(createAlert('warning', "Informe uma Cidade para continuar")))
                                    }

                                    push({
                                        cidade: values.pesquisaPassagemCidade,
                                        estado: values.pesquisaPassagemUf
                                    })

                                    setFieldValue('pesquisaPassagemCidade', '')
                                    setFieldValue('pesquisaPassagemUf', '')
                                }}
                            />
                        </Form.Row>
                    </Form.Container>
                )}
            </FieldArray>
        </>

    )

}

export default TableCidadesPassagem