import React, { useEffect, useState } from 'react'
import { mdiMicrosoftExcel, mdiTableCog } from '@mdi/js'
import Button from '@components/Common/Button'
import ButtonGroup from '@components/Common/Button/Group'
import ModalConfirm from '@components/Feedback/ModalConfirm'
import { createTableConfig } from '@components/Common/Table/scripts/tableConfig'
import { TableConfig, TablePaginator } from '@components/Common/Table'
import filterViagem from '@modules/viagem/utils/filterViagem'
import { endViagem } from '@modules/viagem/controllers/viagemController'
import FormSearchViagem from '@modules/viagem/components/FormSearchViagem'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import createExcel from '../../scripts/createExcel'
import TableEmViagemRow from './components/TableRow'
import ModalVeiculoBloqueado from '../VeiculoBloqueado/Modal'
import ModalPernoite from '../Pernoite/Modal'

const TableEmViagem: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { viagensAtivas, currentViagemAtiva } = useAppSelector(s => s.viagem)
    const { requestGetViagensAtivas, requestEndViagem } = useAppSelector(s => s.requestViagem)

    const [data, setData] = useState(viagensAtivas)
    const [openTableConfig, setOpenTableConfig] = useState(false)
    const [openModalPernoite, setOpenModalPernoite] = useState(false)
    const [openModalFinalizar, setOpenModalFinalizar] = useState(false)
    const [openModalVeiculoBloqueado, setOpenModalVeiculoBloqueado] = useState(false)

    const [tableConfig, setTableConfig] = useState(createTableConfig(
        'emViagem',
        ["ID", "Usuário", "Criado em", "AE", "SM", "Origem", "Destino", "Tomador", "Motorista", "Mercadoria", "Frete", "Destinatário", "Posição", "Pernoite", "Macro", "Escolta", "Operação", "Obs", "Bloqueado por", "Opções"]
    ))

    const CR = conditionaRender(requestGetViagensAtivas, viagensAtivas, true)

    const handleSearch = (search: string) => {
        if (!!viagensAtivas) {
            setData(viagensAtivas!.filter(v => filterViagem(v, search)))
        }
    }

    useEffect(() => {
        if (!!viagensAtivas) {
            setData(viagensAtivas)
        }
    }, [viagensAtivas])

    return (

        <>
            <TablePaginator
                data={data}
                config={tableConfig}
                conditionalRender={CR}
                options={
                    <>
                        <ButtonGroup>
                            <Button
                                icon={mdiTableCog}
                                onClick={() => setOpenTableConfig(true)}
                            />
                            <Button
                                icon={mdiMicrosoftExcel}
                                status="success"
                                onClick={() => {
                                    createExcel(viagensAtivas!)
                                }}
                            />
                        </ButtonGroup>

                        <FormSearchViagem
                            handleSearch={handleSearch}
                            handleReset={() => setData(viagensAtivas)}
                        />
                    </>
                }
                renderItem={(item, index) => (
                    <TableEmViagemRow
                        key={index}
                        data={item}
                        setOpenModalPernoite={setOpenModalPernoite}
                        setOpenModalFinalizar={setOpenModalFinalizar}
                        setOpenModalVeiculoBloqueado={setOpenModalVeiculoBloqueado}
                    />
                )}
            />

            <TableConfig
                openModal={openTableConfig}
                setOpenModal={setOpenTableConfig}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
            />
            <ModalVeiculoBloqueado
                open={openModalVeiculoBloqueado}
                setOpen={setOpenModalVeiculoBloqueado}
            />
            <ModalPernoite
                open={openModalPernoite}
                setOpen={setOpenModalPernoite}
            />
            <ModalConfirm
                open={openModalFinalizar}
                setOpen={setOpenModalFinalizar}
                title="Finalizar viagem"
                message="Deseja finalizar a viagem?"
                loading={requestEndViagem.loading}
                onConfirm={() => {
                    endViagem(dispatch, userData!, currentViagemAtiva!)
                }}
            />
        </>

    )

}

export default TableEmViagem