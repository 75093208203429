import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { mdiContentSave } from '@mdi/js'
import Form from '@components/Common/Form'
import Button from '@components/Common/Button'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormFilialFields from '@modules/filial/components/FormFilialFields'
import { formFilialSchema, formFilialValues } from '@modules/filial/components/FormFilialFields/config'
import { updateFilial } from '@modules/filial/controllers/filialController'
import removePesquisaFields from '@modules/viagem/components/DEPRACTED_FormViagemFields/utils/removePesquisaFields'
import { Navigate, useParams } from 'react-router'

const FormEdit: React.FC = () => {
    const [redirect, setRedirect] = useState(false)

    const { idFilial } = useParams()


    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    return (

        <>
            {redirect && <Navigate to={`/cadastro/filial`} />}
            <Formik
                initialValues={formFilialValues}
                validationSchema = {formFilialSchema}
                onSubmit={(v) => {
                    updateFilial(dispatch, userData!, removePesquisaFields(v), idFilial!, setRedirect)
                }}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding={false}>
                            <FormFilialFields />

                            <div className="formFooter">
                                <Button
                                    icon={mdiContentSave}
                                    label="Salvar"
                                    status="success"
                                    type="submit"
                                />
                            </div>
                        </Form.Container>
                    </FormikForm>

                )}
            </Formik>
        </>

    )

}

export default FormEdit