import React from 'react'
import Modal from '@components/Common/Modal'
import TableTomador from '../Table'
import { ModalSearchTomadorProps } from '../types'
import Button from '@components/Common/Button'

const ModalTomador: React.FC <ModalSearchTomadorProps> = props => {

    return(

        <Modal
            {...props}
            title = "Buscar tomador"
            width = "100%"
        >
            <TableTomador {...props} />
            <div className='ml-auto mt-4'>
                <Button
                    label="Fechar"
                    onClick={() => props.setOpen(false)}
                />
            </div>
        </Modal>

    )

}

export default ModalTomador