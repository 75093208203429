import React from 'react'
import Modal from '@components/Common/Modal'
import { handleOmitColumns } from '@components/Common/Table/scripts/tableConfig'
import { TableConfigProps } from './types'
import './styles.scss'

const TableConfig: React.FC <TableConfigProps> = ({
    openModal,
    setOpenModal,
    tableConfig,
    setTableConfig,
}) => {

    const isActive = (column: number) => !tableConfig.omitColumns.includes(column)

    return(

        <Modal title = "Configurar tabela" width = {300} open = {openModal} setOpen = {setOpenModal}>
            <div className = "tableConfigContainer">
                <div className = "tableConfigColumnsGroup">
                    {tableConfig.columns.map((item, index) => (
                        <div key = {index} className = {`tableConfigColumnItem ${(isActive(index)) ? 'active' : 'disabled'}`} onClick = {() => handleOmitColumns(tableConfig, setTableConfig, index)}>
                            {!!item ? item : 'Opções'}
                        </div>
                    ))}
                </div>
            </div>
        </Modal>

    )

}

export default TableConfig