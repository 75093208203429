import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Documento } from "../interfaces/Documento"
import { Solicitacao } from "@modules/solicitacao/interfaces/Solicitacao"

interface State {
    requestGetDocumentosFinanceiro: ResponseDefault<Documento[]>
    requestGetDocumentosExpedicao: ResponseDefault<Documento[]>
    requestCreateDocumento: ResponseDefault<any>
    requestSendDocumentoFile: ResponseDefault<any>
    requestUpdateDocumentoStatus: ResponseDefault<any>
    requestDeleteDocumento: ResponseDefault<any>
    requestUpdateDocumento: ResponseDefault<any>
    requestGetDocumento: ResponseDefault<Documento>
}

const initialState: State = {
    requestGetDocumentosFinanceiro: responseInitialValues,
    requestGetDocumentosExpedicao: responseInitialValues,
    requestCreateDocumento: responseInitialValues,
    requestSendDocumentoFile: responseInitialValues,
    requestUpdateDocumentoStatus: responseInitialValues,
    requestDeleteDocumento: responseInitialValues,
    requestUpdateDocumento: responseInitialValues,
    requestGetDocumento: responseInitialValues,
}

const requestDocumentosSlice = createSlice({
    name: 'requestDocumentos',
    initialState,
    reducers: {
        setRequestGetDocumentosFinanceiroData: (state: State, action: PayloadAction<ResponsePattern<Documento[]>>) => {
            state.requestGetDocumentosFinanceiro.data = action.payload
            state.requestGetDocumentosFinanceiro.loading = false
            state.requestGetDocumentosFinanceiro.error = false
        },
        setRequestGetDocumentosFinanceiroLoading: (state: State) => {
            state.requestGetDocumentosFinanceiro.loading = true
            state.requestGetDocumentosFinanceiro.error = false
        },
        setRequestGetDocumentosFinanceiroError: (state: State) => {
            state.requestGetDocumentosFinanceiro.loading = false
            state.requestGetDocumentosFinanceiro.error = true
        },
        setRequestGetDocumentosFinanceiroMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetDocumentosFinanceiro.message = action.payload
        },
        resetRequestGetDocumentosFinanceiro: (state: State) => {
            state.requestGetDocumentosFinanceiro = {...responseInitialValues}
        },

        setRequestGetDocumentosExpedicaoData: (state: State, action: PayloadAction<ResponsePattern<Documento[]>>) => {
            state.requestGetDocumentosExpedicao.data = action.payload
            state.requestGetDocumentosExpedicao.loading = false
            state.requestGetDocumentosExpedicao.error = false
        },
        setRequestGetDocumentosExpedicaoLoading: (state: State) => {
            state.requestGetDocumentosExpedicao.loading = true
            state.requestGetDocumentosExpedicao.error = false
        },
        setRequestGetDocumentosExpedicaoError: (state: State) => {
            state.requestGetDocumentosExpedicao.loading = false
            state.requestGetDocumentosExpedicao.error = true
        },
        setRequestGetDocumentosExpedicaoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetDocumentosExpedicao.message = action.payload
        },
        resetRequestGetDocumentosExpedicao: (state: State) => {
            state.requestGetDocumentosExpedicao = {...responseInitialValues}
        },

        setRequestCreateDocumentoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateDocumento.data = action.payload
            state.requestCreateDocumento.loading = false
            state.requestCreateDocumento.error = false
        },
        setRequestCreateDocumentoLoading: (state: State) => {
            state.requestCreateDocumento.loading = true
            state.requestCreateDocumento.error = false
        },
        setRequestCreateDocumentoError: (state: State) => {
            state.requestCreateDocumento.loading = false
            state.requestCreateDocumento.error = true
        },
        setRequestCreateDocumentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateDocumento.message = action.payload
        },
        resetRequestCreateDocumento: (state: State) => {
            state.requestCreateDocumento = {...responseInitialValues}
        },

        setRequestSendDocumentoFileData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSendDocumentoFile.data = action.payload
            state.requestSendDocumentoFile.loading = false
            state.requestSendDocumentoFile.error = false
        },
        setRequestSendDocumentoFileLoading: (state: State) => {
            state.requestSendDocumentoFile.loading = true
            state.requestSendDocumentoFile.error = false
        },
        setRequestSendDocumentoFileError: (state: State) => {
            state.requestSendDocumentoFile.loading = false
            state.requestSendDocumentoFile.error = true
        },
        setRequestSendDocumentoFileMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSendDocumentoFile.message = action.payload
        },
        resetRequestSendDocumentoFile: (state: State) => {
            state.requestSendDocumentoFile = {...responseInitialValues}
        },

        setRequestUpdateDocumentoStatusData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateDocumentoStatus.data = action.payload
            state.requestUpdateDocumentoStatus.loading = false
            state.requestUpdateDocumentoStatus.error = false
        },
        setRequestUpdateDocumentoStatusLoading: (state: State) => {
            state.requestUpdateDocumentoStatus.loading = true
            state.requestUpdateDocumentoStatus.error = false
        },
        setRequestUpdateDocumentoStatusError: (state: State) => {
            state.requestUpdateDocumentoStatus.loading = false
            state.requestUpdateDocumentoStatus.error = true
        },
        setRequestUpdateDocumentoStatusMessage: (state: State, action: PayloadAction<string>) => {
            state.requestUpdateDocumentoStatus.message = action.payload
        },
        resetRequestUpdateDocumentoStatus: (state: State) => {
            state.requestUpdateDocumentoStatus = {...responseInitialValues}
        },

        setRequestDeleteDocumentoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteDocumento.data = action.payload
            state.requestDeleteDocumento.loading = false
            state.requestDeleteDocumento.error = false
        },
        setRequestDeleteDocumentoLoading: (state: State) => {
            state.requestDeleteDocumento.loading = true
            state.requestDeleteDocumento.error = false
        },
        setRequestDeleteDocumentoError: (state: State) => {
            state.requestDeleteDocumento.loading = false
            state.requestDeleteDocumento.error = true
        },
        setRequestDeleteDocumentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestDeleteDocumento.message = action.payload
        },
        resetRequestDeleteDocumento: (state: State) => {
            state.requestDeleteDocumento = {...responseInitialValues}
        },

        setRequestUpdateDocumentoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateDocumento.data = action.payload
            state.requestUpdateDocumento.loading = false
            state.requestUpdateDocumento.error = false
        },
        setRequestUpdateDocumentoLoading: (state: State) => {
            state.requestUpdateDocumento.loading = true
            state.requestUpdateDocumento.error = false
        },
        setRequestUpdateDocumentoError: (state: State) => {
            state.requestUpdateDocumento.loading = false
            state.requestUpdateDocumento.error = true
        },
        setRequestUpdateDocumentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestUpdateDocumento.message = action.payload
        },
        resetRequestUpdateDocumento: (state: State) => {
            state.requestUpdateDocumento = {...responseInitialValues}
        },

        setRequestGetDocumentoData: (state: State, action: PayloadAction<ResponsePattern<Documento>>) => {
            state.requestGetDocumento.data = action.payload
            state.requestGetDocumento.loading = false
            state.requestGetDocumento.error = false
        },
        setRequestGetDocumentoLoading: (state: State) => {
            state.requestGetDocumento.loading = true
            state.requestGetDocumento.error = false
        },
        setRequestGetDocumentoError: (state: State) => {
            state.requestGetDocumento.loading = false
            state.requestGetDocumento.error = true
        },
        setRequestGetDocumentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetDocumento.message = action.payload
        },
        resetRequestGetDocumento: (state: State) => {
            state.requestGetDocumento = {...responseInitialValues}
        },
    }
})

const { actions } = requestDocumentosSlice

export const requestGetDocumentosFinanceiroActions = {
    data: actions.setRequestGetDocumentosFinanceiroData,
    loading: actions.setRequestGetDocumentosFinanceiroLoading,
    error: actions.setRequestGetDocumentosFinanceiroError,
    message: actions.setRequestGetDocumentosFinanceiroMessage,
    reset: actions.resetRequestGetDocumentosFinanceiro,
}

export const requestGetDocumentosExpedicaoActions = {
    data: actions.setRequestGetDocumentosExpedicaoData,
    loading: actions.setRequestGetDocumentosExpedicaoLoading,
    error: actions.setRequestGetDocumentosExpedicaoError,
    message: actions.setRequestGetDocumentosExpedicaoMessage,
    reset: actions.resetRequestGetDocumentosExpedicao,
}

export const requestCreateDocumentoActions = {
    data: actions.setRequestCreateDocumentoData,
    loading: actions.setRequestCreateDocumentoLoading,
    error: actions.setRequestCreateDocumentoError,
    message: actions.setRequestCreateDocumentoMessage,
    reset: actions.resetRequestCreateDocumento,
}

export const requestSendDocumentoFileActions = {
    data: actions.setRequestSendDocumentoFileData,
    loading: actions.setRequestSendDocumentoFileLoading,
    error: actions.setRequestSendDocumentoFileError,
    message: actions.setRequestSendDocumentoFileMessage,
    reset: actions.resetRequestSendDocumentoFile,
}

export const requestUpdateDocumentoStatusActions = {
    data: actions.setRequestUpdateDocumentoStatusData,
    loading: actions.setRequestUpdateDocumentoStatusLoading,
    error: actions.setRequestUpdateDocumentoStatusError,
    message: actions.setRequestUpdateDocumentoStatusMessage,
    reset: actions.resetRequestUpdateDocumentoStatus,
}

export const requestDeleteDocumentoActions = {
    data: actions.setRequestDeleteDocumentoData,
    loading: actions.setRequestDeleteDocumentoLoading,
    error: actions.setRequestDeleteDocumentoError,
    message: actions.setRequestDeleteDocumentoMessage,
    reset: actions.resetRequestDeleteDocumento,
}

export const requestUpdateDocumentoActions = {
    data: actions.setRequestUpdateDocumentoData,
    loading: actions.setRequestUpdateDocumentoLoading,
    error: actions.setRequestUpdateDocumentoError,
    message: actions.setRequestUpdateDocumentoMessage,
    reset: actions.resetRequestUpdateDocumento,
}

export const requestGetDocumentoActions = {
    data: actions.setRequestGetDocumentoData,
    loading: actions.setRequestGetDocumentoLoading,
    error: actions.setRequestGetDocumentoError,
    message: actions.setRequestGetDocumentoMessage,
    reset: actions.resetRequestGetDocumento,
}

export default requestDocumentosSlice.reducer