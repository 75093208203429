import React from 'react'
import Icon from '@mdi/react'
import { useNavigate } from 'react-router'
import { mdiArrowLeft } from '@mdi/js'
import { PageTitleProps } from './types'
import './styles.scss'

const PageTitle: React.FC <PageTitleProps> = ({ children, title, goBack = true }) => {

    const navigate = useNavigate()

    return(

        <div id = "pageTitleContainer">
            <div className = "pageTitleTextContainer">
                {goBack && (
                    <span className = "goBackAction" onClick = {() => navigate(-1)}>
                        <Icon path = {mdiArrowLeft} size = "24px" />
                    </span>
                )}
                <h1>{title}</h1>
            </div>
            {children && (
                <div className = "pageTitleContent">{children}</div>
            )}
        </div>

    )

}

export default PageTitle