import React, { useEffect, useState } from 'react'
import { interval } from 'rxjs'
import useObservable from 'beautiful-react-hooks/useObservable'
import { getNotifications } from '@modules/notification/controllers/notificationController'
import { useAppDispatch } from '@redux/hooks'
import getRemainder from '@utils/getRemainder'

const requestInterval = interval(1000)

const AppTimers: React.FC = () => {

    const dispatch = useAppDispatch()

    const [seconds, setSeconds] = useState(0)

    useObservable(requestInterval, setSeconds)

    useEffect(() => {
        if(getRemainder(seconds, 60)){
            getNotifications(dispatch)
        }
    }, [dispatch, seconds])

    return null

}

export default AppTimers