import { Cliente } from "@modules/cliente/interfaces/Cliente"

export const formClienteEditValues = (data: Cliente) => {
    return {
        id: data.id,
        nomeCliente: data.nome,
        razaoSocial: data.razaoSocial,
        documentoCliente: data.nroDocumento,
        valorTonelada: data.valorPeso,
        flagPagador: data.flagPagador,
        particularidades: data.particularidades,
        cidadeCliente: data.cidade,
        estadoCliente: data.uf,
    }   
}