import { SelectItem } from "@components/Common/Select/types"

export default function createSelectItems<T>(data: T[] | null, label: keyof T, value: keyof T): SelectItem[] {
    if(!!data){
        return data.map(item => ({
            value: item[value],
            label: String(item[label]),
        }))
    }
    return []
}