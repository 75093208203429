import React from 'react'
import Icon from '@mdi/react'
import createClassName from '@utils/createClassName'
import { TextContainerProps } from './types'
import './styles.scss'

const TextContainer: React.FC <TextContainerProps> = ({
    icon,
    title,
    text,
    focusOnText = false,
    className,
    titleStyle,
    textStyle
}) => {

    const textContainerWrapperClasses = createClassName('textContainerWrapper', [className])
    const textContainerClasses = createClassName('textContainer', [focusOnText && 'focusOnText'])

    return(

        <div className = {textContainerWrapperClasses}>
            {!!icon && (
                <div className = "iconContainer">
                    <Icon path = {icon} size = "42px" />
                </div>
            )}
            <div className = {textContainerClasses}>
                <span className = "title" style = {titleStyle}>{title}</span>
                <span className = "text" style = {textStyle}>{text}</span>
            </div>
        </div>

    )

}

export default TextContainer