import React from 'react'
import { Navigate } from 'react-router-dom'
import { RenderRoutePageProps } from './types'
import { routesKeys } from '@routes/routes'
import { routesPages } from '@routes/pages'
    
const RenderRoutePage: React.FC <RenderRoutePageProps> = ({ name }) => {
    if(routesKeys.includes(name) && !!routesPages.find(page => page.key === name)){
        const Page = routesPages.find(page => page.key === name)!.element

        return <Page />
    }

    return <Navigate to = "/error/system-404" />
}

export default React.memo(RenderRoutePage, (prevProps, nextProps) => prevProps.name === nextProps.name)