import React, { useContext } from 'react'
import TableContext from '@components/Common/Table/context'
import createClassName from '@utils/createClassName'
import { TableRowProps } from '../types'
import TableColumn from '../Column'

const TableRow: React.FC <TableRowProps> = ({
    children,
    expand,
    expandComponent,
    onClick,
    status
}) => {
    
    const { omitColumns, columns } = useContext(TableContext)

    const newChildren = (omitColumns && omitColumns.length > 0) ? (children as any[]).filter((item, index) => !omitColumns.includes(index)) : children

    const rowClasses = createClassName('', [status && `status ${status}`, !!onClick && 'clickable'])

    return(

        <>
            <tr className = {rowClasses}>{newChildren}</tr>
            {expand && (
                <tr className = "expandedRow">
                    <TableColumn colSpan = {columns.length - omitColumns.length}>
                        {expandComponent}
                    </TableColumn>
                </tr>
            )}
        </>

    )

}

export default TableRow