import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Operacao } from "../interfaces/Operacao"

interface State {
    operacoes: Operacao[] | null

    operacao: Operacao | null

    currentOperacao: Operacao | null
}

const initialState: State = {
    operacoes: null,

    operacao: null,

    currentOperacao: null
}

const operacaoSlice = createSlice({
    name: 'operacao',
    initialState,
    reducers: {
        setOperacoes: (state, action: PayloadAction<Operacao[]>) => {
            state.operacoes = action.payload
        },
        setOperacao: (state, action: PayloadAction<Operacao>) => {
            state.operacao = action.payload
        },
        setCurrentOperacao: (state, action: PayloadAction<Operacao>) => {
            state.currentOperacao = action.payload
        }
    }
})

export const { setOperacoes, setOperacao, setCurrentOperacao } = operacaoSlice.actions
export default operacaoSlice.reducer