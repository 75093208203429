import { RoutePageType } from "@routes/interfaces"
import DocumentoCriar from "./pages/DocumentoCriar"
import DocumentoEditar from "./pages/DocumentoEditar"
import DocumentosListaFinanceiro from "./pages/DocumentosListaFinanceiro"
import DocumentosListaExpedicao from "./pages/DocumentosListaExpedicao"

const documentoPages: RoutePageType[] = [
    {key: 'documentosFinanceiro', element: DocumentosListaFinanceiro},
    {key: 'documentosExpedicao', element: DocumentosListaExpedicao},
    {key: 'documentoEditar', element: DocumentoEditar},
    {key: 'documentoCriar', element: DocumentoCriar},
]

export default documentoPages