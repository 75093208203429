import { Viagem } from "@modules/viagem/interfaces/Viagem"

export default function getEnderecoViagem(viagem: Viagem){
    const remetenteEndereco = `${viagem.remetenteCidade} - ${viagem.remetenteUf}`.replaceAll('null', '')
    const destinatarioEndereco = `${viagem.destinatarioCidade} - ${viagem.destinatarioUf}`.replaceAll('null', '')
    const motoristaEndereco = `${viagem.motoristaEndereco} - ${viagem.motoristaNumero} ${viagem.motoristaComplemento} ${viagem.motoristaBairro} ${viagem.motoristaCidade} ${viagem.motoristaUf} ${viagem.motoristaCep}`.replaceAll('null', '')

    return {
        remetenteEndereco,
        destinatarioEndereco,
        motoristaEndereco,
    }
}