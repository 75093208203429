import authPages from "@modules/auth/pages"
import acessoPages from "@modules/acesso/pages"
import viagemPages from "@modules/viagem/pages"
import filialPages from "@modules/filial/pages"
import clientePages from "@modules/cliente/pages"
import modulosPages from "@modules/modulos/pages"
import operacaoPages from "@modules/operacao/pages"
import pernoitePages from "@modules/pernoite/pages"
import motoristaPages from "@modules/motorista/pages"
import veiculoPages from "@modules/veiculo/pages"
import documentoPages from "@modules/documento/pages"
import conferenciaPages from "@modules/conferencia/pages"
import solicitacaoPages from "@modules/solicitacao/pages"
import notificationPages from "@modules/notification/pages"
import { RoutePageType } from "./interfaces"

const routesPages: RoutePageType[] = [
    ...authPages,
    ...conferenciaPages,
    ...viagemPages,
    ...pernoitePages,
    ...acessoPages,
    ...modulosPages,
    ...notificationPages,
    ...motoristaPages,
    ...veiculoPages,
    ...documentoPages,
    ...solicitacaoPages,
    ...clientePages,
    ...filialPages,
    ...operacaoPages,
]

export { routesPages }