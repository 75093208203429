import React from 'react'
import { RadioButtonGroupProps } from '../types'

const RadioButtonGroup: React.FC <RadioButtonGroupProps> = ({ children, label }) => {

    return(

        <>
            <div className = "formRadioButtonGroup">
                <label>{label}</label>
                <div className = "radioButtonWrapper">
                    {children}
                </div>
            </div>
        </>

    )

}

export default RadioButtonGroup