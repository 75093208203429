import ENV from '@env/index'
import { ModalProps } from '@interfaces/Modal'
import { UserData } from '@modules/auth/interfaces/UserData'
import { getRomaneios } from '@modules/romaneio/controllers/romaneioController'
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { jsonToParams } from '@utils/jsonToParams'
import { ResponsePattern } from "@utils/response/types"
import { formatPunctuationFromCNPJ } from '@utils/format'
import { handleResponse, handleResponseError, initRequest, toResponsePattern } from "@utils/response"
import { Viagem } from '../interfaces/Viagem'
import { ViagemOne } from '../interfaces/ViagemOne'
import { ViagemAtiva } from '../interfaces/ViagemAtiva'
import { SearchCliente } from '../interfaces/SearchCliente'
import { ViagemPernoite } from '../interfaces/ViagemPernoite'
import { SearchMotorista } from '../interfaces/SearchMotorista'
import { ViagemManifesto } from '../interfaces/ViagemManifesto'
import { ViagemValidacao } from '../interfaces/ViagemValidacao'
import { ViagemDocumento } from '../interfaces/ViagemDocumento'
import { ViagemAplicativo } from '../interfaces/ViagemAplicativo'
import { ViagemFinalizada } from '../interfaces/ViagemFinalizada'
import formatCNPJA from '../utils/formatCNPJA'
import setClienteValues from '../utils/setClienteValues'
import { formViagemEditValues } from '../pages/ViagemAtivaEditar/components/FormEdit/config'
import { ViagemAplicativoMotorista } from '../interfaces/ViagemAplicativoMotorista'
import { resetSelectedManifestos, setAllViagens, setCidades, setSearchCliente, setSearchClienteSistema, setSearchDestinatario, setSearchEmbarcador, setSearchMotorista, setSearchTomador, setViagem, setViagemAplicativoMotorista, setViagemDocumentos, setViagemManifestos, setViagemPernoite, setViagemPosicao, setViagemValidacoes, setViagens, setViagensAplicativo, setViagensAtivas, setViagensFinalizadas, setViagensGraficos } from '../reducers/viagemReducer'
import { requestCreateViagemActions, requestDeleteViagemActions, requestDeleteViagemManifestoActions, requestEndViagemActions, requestGetAllViagensActions, requestGetPernoiteActions, requestGetViagemActions, requestGetViagemAplicativoMotoristaActions, requestGetViagemManifestosActions, requestGetViagemPosicaoActions, requestGetViagemValidacoesActions, requestGetViagensActions, requestGetViagensAplicativoActions, requestGetViagensAtivasActions, requestGetViagensFinalizadasActions, requestSaveVeiculoBloqueadoActions, requestSaveViagemDemandaActions, requestSaveViagemManifestosActions, requestSaveViagemParadaActions, requestSearchClienteActions, requestSearchClienteSistemaActions, requestSearchDestinatarioActions, requestSearchMotoristaActions, requestSearchEmbarcadorActions, requestSearchTomadorActions, requestSearchVeiculoActions, requestUpdateViagemActions, requestGetViagemDocumentosActions, requestGetCidadesActions } from "../reducers/requestViagemReducer"
import formatCidades from '../utils/formatCidades'

export async function getAllViagens(dispatch: DispatchType, filterValues?: any){
    initRequest(dispatch, requestGetAllViagensActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/listar-todas`
    const params = `/?${jsonToParams(filterValues)}`
    const response = await request.get<ResponsePattern<Viagem[]>>({ endpoint, params })

    handleResponse('getAllViagens', dispatch, response, requestGetAllViagensActions)
    .then(data => {
        dispatch(setAllViagens(data))
    })
}

export async function getViagens(dispatch: DispatchType){
    initRequest(dispatch, requestGetViagensActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/conferencias`
    const params = `/?flagRomaneio=0`
    const response = await request.get<ResponsePattern<Viagem[]>>({ endpoint, params })

    handleResponse('getViagens', dispatch, response, requestGetViagensActions)
    .then(data => {
        dispatch(setViagens(data))
    })
}

export async function getViagem(dispatch: DispatchType, idViagem: string){
    initRequest(dispatch, requestGetViagemActions, setViagem)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/listar-uma`
    const params = `/${idViagem}`
    const response = await request.get<ResponsePattern<ViagemOne>>({ endpoint, params })

    handleResponse('getViagem', dispatch, response, requestGetViagemActions)
    .then(data => {
        dispatch(setViagem(data))
    })
}

export async function createViagem(
    dispatch: DispatchType,
    values: any,
    redirect: () => void
){
    initRequest(dispatch, requestCreateViagemActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/salvar-viagem`
    const body = {
        ...values,
        destinatarioDoc: !!values.destinatarioDoc ? (values as any).destinatarioDoc.replace(/\D/g, '') : '',
        destinatarioTelefone: !!values.destinatarioTelefone ? (values as any).destinatarioTelefone.replace(/\D/g, '') : '',
        motoristaDoc: !!values.motoristaDoc ? (values as any).motoristaDoc.replace(/\D/g, '') : '',
        motoristaTelefone: !!values.motoristaTelefone ? (values as any).motoristaTelefone.replace(/\D/g, '') : '',
        remetenteDoc: !!values.remetenteDoc ? (values as any).remetenteDoc.replace(/\D/g, '') : '',
        remetenteTelefone: !!values.remetenteTelefone ? (values as any).remetenteTelefone.replace(/\D/g, '') : '',
        tomadorDoc: !!values.tomadorDoc ? (values as any).tomadorDoc.replace(/\D/g, '') : '',
        veiculoPlaca: values.veiculoPlaca.replaceAll('-', ''),
        veiculoPlaca2: values.veiculoPlaca.replaceAll('-', ''),
        veiculoPlaca3: values.veiculoPlaca.replaceAll('-', ''),
        valorMercadoria: Number(`${values.valorMercadoria}`.replace(',', '.')),
        valorFrete: Number(`${values.valorFrete}`.replace(',', '.')),
    }

    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createViagem', dispatch, response, requestCreateViagemActions, options)
    .then(() => {
        getAllViagens(dispatch)
        getViagens(dispatch)
        getRomaneios(dispatch)

        redirect()
    })
}

export async function updateViagem(dispatch: DispatchType, user: UserData, values: ReturnType<typeof formViagemEditValues>){
    initRequest(dispatch, requestUpdateViagemActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/demanda/salvar-viagem`
    const body = {
        ...values,
        dadosTomador: values.dadosTomador,
        dadosCidade: values.dadosCidade,

        idUsuario: user.id,
        flagCargaMista: false,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateViagem', dispatch, response, requestUpdateViagemActions, options)
    .then(() => {
        getViagem(dispatch, (body as any).id)
    })
}

export async function deleteViagem(dispatch: DispatchType, user: UserData, idViagem: number, closeModal?: () => void){
    initRequest(dispatch, requestDeleteViagemActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/apagar`
    const body = {
        idUsuarioExclusao: user.id,
        idLista: idViagem
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteViagem', dispatch, response, requestDeleteViagemActions, options)
    .then(() => {
        if(!!closeModal) closeModal()

        getAllViagens(dispatch)
        getViagens(dispatch)
        getRomaneios(dispatch)
    })
}

export async function getViagensAtivas(dispatch: DispatchType){
    initRequest(dispatch, requestGetViagensAtivasActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/em-viagem`
    const response = await request.get<ResponsePattern<any>>({ endpoint })

    handleResponse('getViagensAtivas', dispatch, response, requestGetViagensAtivasActions)
    .then(data => {
        dispatch(setViagensAtivas(data.viagens))
        dispatch(setViagensGraficos(data.graficos))
    })
}

export async function getViagemPosicao(dispatch: DispatchType, placa: string){
    initRequest(dispatch, requestGetViagemPosicaoActions)

    const endpoint = `${ENV.APP_ENDPOINT}/veiculo/posicao`
    const params = `/?q=${placa}`
    const response = await request.get<ResponsePattern<any>>({ endpoint, params })

    handleResponse('getViagemPosicao', dispatch, response, requestGetViagemPosicaoActions)
    .then(data => {
        dispatch(setViagemPosicao(data))
    })
}

export async function searchMotorista(
    dispatch: DispatchType,
    searchValue: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFirstValue: boolean = true
){
    initRequest(dispatch, requestSearchMotoristaActions)

    const options = {messages: {errorMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/motorista/autocomplete`
    const params = `?q=${searchValue.toUpperCase()}`
    const response = await request.get<ResponsePattern<SearchMotorista[]>>({ endpoint, params })

    handleResponse('searchMotorista', dispatch, response, requestSearchMotoristaActions, options)
    .then(data => {
        dispatch(setSearchMotorista(data))
        
        if(data.length > 0 && setFirstValue){
            const { NRODOCUMENTO, NOME, ENDERECO, NUMERO, BAIRRO, CIDADE, UF, CEP, TELEFONE } = data[0]

            setFieldValue('motoristaNome', NOME)
            setFieldValue('motoristaDoc', NRODOCUMENTO)
            setFieldValue('motoristaEndereco', ENDERECO)
            setFieldValue('motoristaNumero', NUMERO)
            setFieldValue('motoristaBairro', BAIRRO)
            setFieldValue('motoristaCidade', CIDADE)
            setFieldValue('motoristaUf', UF)
            setFieldValue('motoristaCep', CEP)
            setFieldValue('motoristaTelefone', TELEFONE)
        }
    })
}

export async function searchVeiculo(
    dispatch: DispatchType,
    searchValue: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
){
    initRequest(dispatch, requestSearchVeiculoActions)
    
    const endpoint = `${ENV.APP_ENDPOINT}/veiculo/autocomplete`
    const params = `?q=${searchValue.toUpperCase()}`
    const response = await request.get<ResponsePattern<any[]>>({ endpoint, params })
    
    handleResponse('searchVeiculo', dispatch, response, requestSearchVeiculoActions)
    .then(data => {
        if(data.length > 0){
            const { PLACA } = data[0]
            
            setFieldValue('veiculoPlaca', PLACA)
        }
    })
}

export async function searchCliente(
    dispatch: DispatchType,
    searchValue: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFirstValue: boolean = true,
    key: 'remetente' | 'destinatario' | 'tomador' | 'remetente' | 'cliente'
){
    initRequest(dispatch, requestSearchClienteActions)

    const options = {messages: {errorMessage: {}}}
    const endpoint = `https://api.cnpja.com/office`
    const params = `/${formatPunctuationFromCNPJ(searchValue)}`
    const authorization = ENV.CNPJA_KEY
    const responseData = await request.get<any>({ endpoint, params, authorization })
    
    if('code' in responseData && responseData.code === 400){
        handleResponseError('searchCliente', dispatch, requestSearchClienteActions, responseData.message, options)
    }else{
        const response = toResponsePattern([formatCNPJA(responseData)])
    
        handleResponse('searchCliente', dispatch, response, requestSearchClienteActions, options)
        .then(data => {
            if(!!data){
                dispatch(setSearchCliente(data))
    
                if(key === 'remetente'){
                    dispatch(setSearchEmbarcador(data))
                }
                if(key === 'destinatario'){
                    dispatch(setSearchDestinatario(data))
                }
                if(key === 'tomador'){
                    dispatch(setSearchTomador(data))
                }
                if(key === 'remetente'){
                    dispatch(setSearchEmbarcador(data))
                }
                if(key === 'cliente'){
                    dispatch(setSearchClienteSistema(data))
                }
        
                // if(data.length > 0 && setFirstValue){
                //     setClienteValues(key, data[0], setFieldValue)
                // }
            }
        })
    }

}

export async function getCidades(
    dispatch: DispatchType,
    uf: string,
){
    initRequest(dispatch, requestGetCidadesActions)

    const options = {messages: {errorMessage: {}}}
    const endpoint = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?orderBy=nome`
    const responseData = await request.get<any>({ endpoint })
    
    if('code' in responseData && responseData.code === 400){
        handleResponseError('getCidades', dispatch, requestGetCidadesActions, responseData.message, options)
    }else{
        const response = toResponsePattern([formatCidades(responseData)])
    
        handleResponse('getCidades', dispatch, response, requestGetCidadesActions, options)
        .then(data => {
            dispatch(setCidades(data[0]))
        })
    }
}
    
export async function searchEmbarcador(
    dispatch: DispatchType,
    searchValue: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFirstValue: boolean = true
){
    initRequest(dispatch, requestSearchEmbarcadorActions)

    const endpoint = `${ENV.APP_ENDPOINT}/clientes/listar`
    const params = `?q=${searchValue.toUpperCase()}`
    const response = await request.get<ResponsePattern<SearchCliente[]>>({ endpoint, params })

    handleResponse('searchEmbarcador', dispatch, response, requestSearchEmbarcadorActions)
    .then(data => {
        dispatch(setSearchEmbarcador(data))

        if(data.length > 0 && setFirstValue){
            setClienteValues('remetente', data[0], setFieldValue)
        }
    })
}

export async function searchClienteSistema(
    dispatch: DispatchType,
    searchValue: string,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFirstValue: boolean = true
){
    initRequest(dispatch, requestSearchClienteSistemaActions)

    const endpoint = `${ENV.APP_ENDPOINT}/clientes/listar`
    const params = `?q=${searchValue.toUpperCase()}`
    const response = await request.get<ResponsePattern<SearchCliente[]>>({ endpoint, params })

    handleResponse('searchClienteSistema', dispatch, response, requestSearchClienteSistemaActions)
    .then(data => {
        dispatch(setSearchClienteSistema(data))

        if(data.length > 0 && setFirstValue && setFieldValue){
            setClienteValues('cliente', data[0], setFieldValue)
        }
    })
}

export async function searchDestinatario(
    dispatch: DispatchType,
    searchValue: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFirstValue: boolean = true
){
    initRequest(dispatch, requestSearchDestinatarioActions)

    const endpoint = `${ENV.APP_ENDPOINT}/clientes/listar`
    const params = `?q=${searchValue.toUpperCase()}`
    const response = await request.get<ResponsePattern<SearchCliente[]>>({ endpoint, params })

    handleResponse('searchDestinatario', dispatch, response, requestSearchDestinatarioActions)
    .then(data => {
        dispatch(setSearchDestinatario(data))

        if(data.length > 0 && setFirstValue){
            setClienteValues('destinatario', data[0], setFieldValue)
        }
    })
}

export async function searchTomador(
    dispatch: DispatchType,
    searchValue: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFirstValue: boolean = true
){
    initRequest(dispatch, requestSearchTomadorActions)

    const endpoint = `${ENV.APP_ENDPOINT}/clientes/listar`
    const params = `?q=${searchValue.toUpperCase()}`
    const response = await request.get<ResponsePattern<SearchCliente[]>>({ endpoint, params })

    handleResponse('searchTomador', dispatch, response, requestSearchTomadorActions)
    .then(data => {
        dispatch(setSearchTomador(data))

        if(data.length > 0 && setFirstValue){
            setClienteValues('tomador', data[0], setFieldValue)
        }
    })
}

// export async function searchEmbarcador(
//     dispatch: DispatchType,
//     searchValue: string,
//     setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
//     setFirstValue: boolean = true
// ){
//     initRequest(dispatch, requestSearchEmbarcadorActions)

//     const endpoint = `${ENV.APP_ENDPOINT}/clientes/listar`
//     const params = `?q=${searchValue.toUpperCase()}`
//     const response = await request.get<ResponsePattern<SearchCliente[]>>({ endpoint, params })

//     handleResponse('searchEmbarcador', dispatch, response, requestSearchEmbarcadorActions)
//     .then(data => {
//         dispatch(setSearchEmbarcador(data))

//         if(data.length > 0 && setFirstValue){
//             setClienteValues('remetente', data[0], setFieldValue)
//         }
//     })
// }

export async function getPernoite(dispatch: DispatchType, idViagem: string){
    initRequest(dispatch, requestGetPernoiteActions)

    const options = {messages: {errorMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/pernoite/paradas`
    const params = `/?q=${idViagem}`
    const response = await request.get<ResponsePattern<ViagemPernoite>>({ endpoint, params })

    handleResponse('getPernoite', dispatch, response, requestGetPernoiteActions, options)
    .then(data => {
        dispatch(setViagemPernoite(data))
    })
}

export async function saveVeiculoBloqueado(dispatch: DispatchType, body: any, { setOpen }: ModalProps){
    initRequest(dispatch, requestSaveVeiculoBloqueadoActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/veiculo-bloqueado`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('saveVeiculoBloqueado', dispatch, response, requestSaveVeiculoBloqueadoActions, options)
    .then(() => {
        setOpen(false)
        getViagensAtivas(dispatch)
    })
}

export async function saveViagemDemanda(dispatch: DispatchType, body: any, { setOpen }: ModalProps){
    initRequest(dispatch, requestSaveViagemDemandaActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/exibir-demanda`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('saveViagemDemanda', dispatch, response, requestSaveViagemDemandaActions, options)
    .then(() => {
        setOpen(false)
        getRomaneios(dispatch)
    })
}

export async function saveViagemParada(dispatch: DispatchType, body: any, { setOpen }: ModalProps){
    initRequest(dispatch, requestSaveViagemParadaActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/pernoite/confirmar-parada`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('saveViagemParada', dispatch, response, requestSaveViagemParadaActions, options)
    .then(() => {
        setOpen(false)
        getPernoite(dispatch, body.idLista)
    })
}

export async function endViagem(dispatch: DispatchType, user: UserData, viagem: ViagemAtiva){
    initRequest(dispatch, requestEndViagemActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/finalizar`
    const body = {
        idLista: viagem.id,
        idUsuarioFinalizado: user.id
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('endViagem', dispatch, response, requestEndViagemActions, options)
    .then(() => {
        getViagensAtivas(dispatch)
    })
}

export async function getViagemManifestos(dispatch: DispatchType, body: any){
    initRequest(dispatch, requestGetViagemManifestosActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/manifestos`
    const response = await request.post<ResponsePattern<ViagemManifesto[]>>({ endpoint, body })

    handleResponse('getViagemManifestos', dispatch, response, requestGetViagemManifestosActions)
    .then(data => {
        dispatch(setViagemManifestos(data))
    })
}

export async function saveViagemManifestos(
    dispatch: DispatchType,
    romaneio: ViagemOne,
    solicitacoes: ViagemManifesto[],
    reload: () => void
){
    initRequest(dispatch, requestSaveViagemManifestosActions)
    
    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/salvar-manifestos`
    const body = {
        romaneio,
        solicitacoes
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('saveViagemManifestos', dispatch, response, requestSaveViagemManifestosActions, options)
    .then(() => {
        dispatch(resetSelectedManifestos())

        reload()
    })
}

export async function deleteViagemManifesto(
    dispatch: DispatchType,
    manifesto: ViagemManifesto,
    reload: () => void,
){
    initRequest(dispatch, requestDeleteViagemManifestoActions)

    const options = { messages: {successMessage: {}, errorMessage: {}} }
    const endpoint = `${ENV.APP_ENDPOINT}/lista/remover-manifesto`
    const body = {
        manifestos: [manifesto.MANIFESTO]
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteViagemManifesto', dispatch, response, requestDeleteViagemManifestoActions, options)
    .then(() => {
        reload()
    })
}

export async function getViagemValidacoes(dispatch: DispatchType, idLista: string){
    initRequest(dispatch, requestGetViagemValidacoesActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista-processo-validacao/listar`
    const params = `/?q=${idLista}`
    const response = await request.get<ResponsePattern<ViagemValidacao[]>>({ endpoint, params })

    handleResponse('getViagemValidacoes', dispatch, response, requestGetViagemValidacoesActions)
    .then(data => {
        dispatch(setViagemValidacoes((data as any).processos))
    })
}

export async function getViagensFinalizadas(dispatch: DispatchType){
    initRequest(dispatch, requestGetViagensFinalizadasActions)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/finalizadas`
    const response = await request.get<ResponsePattern<ViagemFinalizada[]>>({ endpoint })

    handleResponse('getViagensFinalizadas', dispatch, response, requestGetViagensFinalizadasActions)
    .then(data => {
        dispatch(setViagensFinalizadas(data))
    })
}

export async function getViagemFinalizada(dispatch: DispatchType, idViagem: string){
    initRequest(dispatch, requestGetViagemActions, setViagem)

    const endpoint = `${ENV.APP_ENDPOINT}/lista/listar-uma-finalizada`
    const params = `/${idViagem}`
    const response = await request.get<ResponsePattern<ViagemOne>>({ endpoint, params })

    handleResponse('getViagemFinalizada', dispatch, response, requestGetViagemActions)
    .then(data => {
        dispatch(setViagem(data))
    })
}

export async function getViagensAplicativo(dispatch: DispatchType, date: string){
    initRequest(dispatch, requestGetViagensAplicativoActions)

    const endpoint = `${ENV.MOBILE_ENDPOINT}/motoristas-resumo`
    const params = `/${date}`
    const response = await request.get<ResponsePattern<ViagemAplicativo[]>>({ endpoint, params })

    handleResponse('getViagensAplicativo', dispatch, response, requestGetViagensAplicativoActions)
    .then(data => {
        dispatch(setViagensAplicativo(data))
    })
}

export async function getViagensAplicativoMotorista(dispatch: DispatchType, idMotorista: number){
    initRequest(dispatch, requestGetViagemAplicativoMotoristaActions)

    const endpoint = `${ENV.MOBILE_ENDPOINT}/manifesto`
    const params = `/${idMotorista}`
    const response = await request.get<ResponsePattern<ViagemAplicativoMotorista>>({ endpoint, params })

    handleResponse('getViagensAplicativoMotorista', dispatch, response, requestGetViagemAplicativoMotoristaActions)
    .then(data => {
        dispatch(setViagemAplicativoMotorista(data))
    })
}

export async function getViagemDocumentos(dispatch: DispatchType, idViagem: string){
    initRequest(dispatch, requestGetViagemDocumentosActions)

    const endpoint = `${ENV.APP_ENDPOINT}/documentacoes-viagem/lista`
    const params = `/${idViagem}`
    const response = await request.get<ResponsePattern<ViagemDocumento[]>>({ endpoint, params })

    handleResponse('getViagemDocumentos', dispatch, response, requestGetViagemDocumentosActions)
    .then(data => {
        dispatch(setViagemDocumentos(data as any))
    })
}