import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Documento } from "../interfaces/Documento"
import { Solicitacao } from "@modules/solicitacao/interfaces/Solicitacao"
import { DocumentoVisualizacao } from "../interfaces/DocumentoVisualizacao"

interface State {
    documentosFinanceiro: Documento[] | null
    documentosExpedicao: Documento[] | null
    documento: Documento | null
    currentDocumento: Documento | null
    currentDocumentos: DocumentoVisualizacao[] | null

    openModalDocumentos: boolean
    openModalStatus: boolean
}

const initialState: State = {
    documentosFinanceiro: null,
    documentosExpedicao: null,
    documento: null,
    currentDocumento: null,
    currentDocumentos: null,
    openModalDocumentos: false,
    openModalStatus: false,
}

const documentosSlice = createSlice({
    name: 'documentos',
    initialState,
    reducers: {
        setDocumentosFinanceiro: (state, action: PayloadAction<Documento[]>) => {
            state.documentosFinanceiro = action.payload
        },
        setDocumentosExpedicao: (state, action: PayloadAction<Documento[]>) => {
            state.documentosExpedicao = action.payload
        },
        setDocumento: (state, action: PayloadAction<Documento | null>) => {
            state.documento = action.payload
        },
        setCurrentDocumento: (state, action: PayloadAction<Documento>) => {
            state.currentDocumento = action.payload
        },
        setCurrentDocumentos: (state, action: PayloadAction<DocumentoVisualizacao[] | null>) => {
            state.currentDocumentos = action.payload
        },

        setOpenModalDocumentos: (state, action: PayloadAction<boolean>) => {
            state.openModalDocumentos = action.payload
        },
        setOpenModalStatus: (state, action: PayloadAction<boolean>) => {
            state.openModalStatus = action.payload
        },
    }
})

export const { setDocumentosFinanceiro, setDocumentosExpedicao, setDocumento, setCurrentDocumento, setCurrentDocumentos, setOpenModalDocumentos, setOpenModalStatus } = documentosSlice.actions
export default documentosSlice.reducer