import Romaneio from "@modules/romaneio/pages/Romaneio"
// import RomaneioLista from "@modules/romaneio/pages/RomaneioLista"
import RomaneioCriar from "@modules/romaneio/pages/RomaneioCriar"
import RomaneioEditar from "@modules/romaneio/pages/RomaneioEditar"
import RomaneioProtocolo from "@modules/romaneio/pages/RomaneioProtocolo"
import { RoutePageType } from "@routes/interfaces"
import SolicitacaoCriar from "./pages/SolicitacaoCriar"
import SolicitacaoLista from "./pages/SolicitacaoLista"
import SolicitacaoEditar from "./pages/SolicitacaoEditar"
import SolicitacaoCteLista from "./pages/SolicitacaoCteLista"

const solicitacaoPages: RoutePageType[] = [
    {key: 'solicitacaoLista', element: SolicitacaoLista},
    {key: 'solicitacaoCriar', element: SolicitacaoCriar},
    {key: 'solicitacaoEditar', element: SolicitacaoEditar},

    {key: 'solicitacaoCteLista', element: SolicitacaoCteLista},

    // { key: 'solicitacaoRomaneioLista', element: RomaneioLista },
    { key: 'solicitacaoRomaneioLista', element: SolicitacaoCteLista },
    { key: 'SolicitacaoRomaneio', element: Romaneio },
    { key: 'solicitacaoRomaneioCriar', element: RomaneioCriar },
    { key: 'solicitacaoRomaneioEditar', element: RomaneioEditar },
    { key: 'solicitacaoRomaneioProtocolo', element: RomaneioProtocolo },
]

export default solicitacaoPages